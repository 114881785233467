import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { getImageUrl, saveDataStorage, getDataStorage, encryptData, decryptData } from "@util/util";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ValidationMessage from "@components/shared/ValidationMessage";
import { Web3util } from "@util/web3util";
import { notify } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCube, faFaucet, faGasPump, faRefresh, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { LocalStorage } from "@util/enum";
import { etherRound } from "../../util/util";

import Web3 from "web3";

const PopupWallet = ({ setParentWallet, contractsNetworkEditor }) => {
    // console.log("PopupWallet");
    const { getWallets, dataLoaded, wallets, getWalletBalance, connectedAccount, createBurnWallet, connectWalletProvider } = useContext(AppContext);
    // const [wallets, setWallets] = useState();
    const [wallet, setWallet] = useState();
    const [walletBalance, setWalletBalance] = useState();
    const [walletFromPK, setWalletFromPK] = useState();
    const [walletPK, setWalletPK] = useState();
    const [validationMessages, setValidationMessages] = useState({});
    const [localWallets, setLocalWallets] = useState([]);
    const [refreshingWalletBalance, setRefreshingWalletBalance] = useState(false);
    const ulRef = useRef();

    useEffect(() => {
        getWallets();
        getWalletfromLocalStorage();
    }, []);

    useEffect(() => {
        if (wallet) getCurrentWalletBalance();
    }, [wallet]);

    async function getCurrentWalletBalance() {
        try {
            setRefreshingWalletBalance(true);
            const _walletBalance = await getWalletBalance(wallet, contractsNetworkEditor.id);
            setWalletBalance(_walletBalance);
            setRefreshingWalletBalance(false);
        } catch (error) {
            setRefreshingWalletBalance(false);
        }
    }

    // useEffect(() => {
    //     if (dataLoaded) loadWallets();
    // }, [dataLoaded]);

    // async function loadWallets() {
    //     console.log("Loading wallets");
    //     const _wallets = await getWallets(false);
    //     console.log("Got wallets", _wallets);
    //     setWallets(_wallets);
    // }

    // console.log(wallets);

    async function getWalletfromLocalStorage() {
        const _wallet = getDataStorage(LocalStorage.EditorWallet);
        // unencrypt wallet
        if (_wallet) {
            const _decryptedWallet = await decryptData(_wallet);
            const _account = Web3util.validatePK(_decryptedWallet);
            if (_account) {
                const _newAccount = {
                    name: _account.address + " (From PK)",
                    address: _account.address,
                    pk: _account.privateKey,
                };
                // setWallet(_newAccount);
                // setWalletFromPK(_newAccount);
                // setParentWallet(_newAccount);
                const _localWallets = [];
                _localWallets.push(_newAccount);
                setLocalWallets(_localWallets);
            }
        }
    }

    async function onInputChange(event) {
        if (event.target.name === "walletPK") {
            setWalletPK(event.target.value);
            console.log(event.target.value);
        }

        if (event.target.name === "walletPK" && event.target.value) {
            const _account = Web3util.validatePK(event.target.value);
            if (!_account && event.target.value) {
                updateValidationMessages(event, "Private Key is invalid");
                // setWallet();
                // setParentWallet();
            } else {
                updateValidationMessages(event, null);
                //setWalletFromPk(_account);
            }
        } else if (!event.target.value) {
            updateValidationMessages(event, null);
        }
    }

    async function setWalletFromPk(text_) {
        const _account = Web3util.validatePK(text_);
        if (_account && text_) {
            // Get the account and set the state to show to the user
            const _newAccount = {
                name: _account.address + " (From PK)",
                address: _account.address,
                pk: _account.privateKey,
            };
            setWallet(_newAccount);
            setWalletFromPK(_newAccount);
            setParentWallet(_newAccount);

            saveWalletToLocalStorage(_newAccount);
        } else {
            notify("Error validating Private Key", "warn");
        }
    }

    async function saveWalletToLocalStorage(account_) {
        // Save PK ENCRYPTED in the local storage
        const _encryptedData = await encryptData(account_.privateKey);
        saveDataStorage(LocalStorage.EditorWallet, _encryptedData);
        console.log("Wallet PK saved in local storage (encrypted)", getDataStorage(LocalStorage.EditorWallet));
    }

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    // function setCurrentWalletFromPK() {
    //     if (walletFromPK) {
    //         setWallet(walletFromPK);
    //         setParentWallet(walletFromPK);
    //     } else {
    //         notify("Enter a valid Private Key to use this option");
    //     }
    // }

    function createNewWallet() {
        const _newWallet = createBurnWallet();
        _newWallet.name = "New Wallet";
        _newWallet.pk = _newWallet.privateKey; // We use the pk property, not the privateKey one
        setWallet(_newWallet);
        setWalletPK(_newWallet.privateKey);
        setWalletFromPK(_newWallet);
        setParentWallet(_newWallet);
        saveWalletToLocalStorage(_newWallet);
    }

    function openFaucetPage(walletAddress) {
        try {
            navigator.clipboard.writeText(walletAddress);
        } catch (error) {}
        window.open(contractsNetworkEditor.faucetURL, "_blank");
    }

    // async function connectWalletProvider() {
    //     try {
    //         if (!ethereum) {
    //             notify("MetaMask not detect. You need install the extension and try it again.", "warn");
    //             return;
    //         }
    //         const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    //         let _wallet = {};
    //         _wallet.type = "provider";
    //         _wallet.name = "MetaMask";
    //         _wallet.address = accounts[0];
    //         setWallet(_wallet);
    //         setParentWallet(_wallet);
    //         console.log("wallet", wallet);
    //     } catch (err) {
    //         if (err.code === 4001) {
    //             console.log("User rejected the connection. Please connect to MetaMask.");
    //         } else {
    //             console.error(err);
    //             notify("Erros connecting MetaMask. Try it again later.", "warn");
    //         }
    //     }
    // }
    async function connectWallet() {
        const _wallet = await connectWalletProvider();
        setWallet(_wallet);
        setParentWallet(_wallet);
    }

    if (!wallets) return;
    return (
        <>
            <dialog id="popupWallet" className="rounded-lg bg-transparent w-[750px]">
                {/* <input type="checkbox" id="popupWallet" className="modal-toggle" /> */}
                {/* <div className="modal">
                <div className="modal-box min-w-[650px]"> */}
                {/* <form method="dialog" className="modal-box w-full h-full max-w-full max-h-full text-light"> */}
                <div className="modal-box w-full h-full max-w-full max-h-full text-light">
                    <p className="text-white font-bold text-lg">Select a Wallet</p>
                    <div className="items-center text-secondary pt-8">
                        <div className="flex flex-row">
                            <p className="text-secondary font-semibold">Wallet selected</p>
                            {wallet && (
                                <div>
                                    <FontAwesomeIcon icon={faGasPump} className={`pl-4 ${walletBalance <= 0.01 ? "text-red" : ""}`} />
                                    <span className={`pl-1 ${walletBalance <= 0.01 ? "text-red" : ""}`}>{walletBalance}</span>
                                    <FontAwesomeIcon
                                        id="PopupWalletRefreshWalletBalanceButtonIcon"
                                        name="PopupWalletRefreshWalletBalanceButtonIcon"
                                        data-info={wallet.address}
                                        icon={faRefresh}
                                        spin={refreshingWalletBalance}
                                        className="pl-1 text-secondary cursor-pointer"
                                        onClick={() => {
                                            getCurrentWalletBalance();
                                        }}
                                    />
                                    {contractsNetworkEditor.type === "testnet" && (
                                        <FontAwesomeIcon
                                            id="PopupWalletOpenFaucetButtonIcon"
                                            name="PopupWalletOpenFaucetButtonIcon"
                                            data-info={JSON.stringify({ faucet: contractsNetworkEditor.faucetURL, address: wallet.address })}
                                            icon={faFaucet}
                                            className="pl-1 text-secondary cursor-pointer"
                                            onClick={() => {
                                                openFaucetPage(wallet.address);
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <p>
                            Name:
                            <span className="text-light pl-2">{wallet && wallet.name}</span>
                        </p>
                        <p>
                            Address:
                            <span className="text-light pl-2">{wallet && wallet.address}</span>
                            {wallet && (
                                <FontAwesomeIcon
                                    id="PopupWalletCopyAddressButtonIcon"
                                    name="PopupWalletCopyAddressButtonIcon"
                                    icon={faCopy}
                                    onClick={() => {
                                        navigator.clipboard.writeText(wallet.address);
                                    }}
                                    className="pl-2 text-secondary cursor-pointer"
                                />
                            )}
                            {wallet && (
                                <a
                                    id="PopupWalletWalletBlockExplorer"
                                    name="PopupWalletWalletBlockExplorer"
                                    href={contractsNetworkEditor.blockExplorerURL + "/address/" + wallet.address}
                                    target="_blank">
                                    <FontAwesomeIcon icon={faCube} className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5" />
                                </a>
                            )}
                        </p>
                        {wallet && wallet.privateKey && (
                            <div>
                                Private Key:
                                <span className="text-light pl-2">{wallet && wallet.privateKey}</span>
                                <p className="text-red text-xl">This private key will never be displayed again. Save it in a secure place if you want to use it later.</p>
                            </div>
                        )}
                    </div>
                    {/* <p>
                        <button
                            className="btn"
                            onClick={async () => {
                                // const _key = await generateEncryptionKey();
                                // console.log("ENCYPRTION KEY", _key);
                                // console.log("ENCYPRTION KEY exp", await exportEncryptionKey(_key));

                                const _encryptedData = await encryptData("TEST");
                                console.log("_encryptedData", _encryptedData);
                            }}>
                            Test Encryption
                        </button>
                        <button
                            className="btn"
                            onClick={async () => {
                                // const _key = await generateEncryptionKey();
                                // console.log("ENCYPRTION KEY", _key);
                                // console.log("ENCYPRTION KEY exp", await exportEncryptionKey(_key));

                                const _decryptedData = await decryptData("gKaGkIOVZABCOtti|hzn4ASw6acGjOM4fBDzMYkx2FbM=");
                                console.log("_decryptedData", _decryptedData);
                            }}>
                            Test decryption
                        </button>
                        <span className="text-white bg-yellow-600">ADD A SESSION FOR LOCAL WALLETS / DISCLOSURE ABOUT SAVING THE WALLET IN LOCALSTORAGE</span>
                    </p> */}
                    {connectedAccount && (
                        <>
                            <p className="text-secondary pt-6">
                                Wallets in your Profile
                                <span className="text-secondary text-sm font-semibold pl-6">
                                    <FontAwesomeIcon icon={faTriangleExclamation} className="pr-2" />
                                    DO NOT EVER STORE INVESTMENT ASSETS IN THIS WALLET
                                </span>
                            </p>
                            <div className="w-full">
                                <ul id="results" className="ul-main" ref={ulRef}>
                                    {wallets &&
                                        wallets.map((option, index) => {
                                            return (
                                                <button
                                                    id="PopupWalletWalletSelectItemProfile"
                                                    nanme="PopupWalletWalletSelectItemProfile"
                                                    data-info={option.name}
                                                    type="button"
                                                    key={index}
                                                    onClick={(e) => {
                                                        setWallet(option);
                                                        setParentWallet(option);
                                                        console.debug("Contract Explorer wallet set to:", option);
                                                    }}
                                                    className="ul-item focus:border-transparent focus:outline-none">
                                                    {option.name}
                                                </button>
                                            );
                                        })}
                                </ul>
                            </div>
                        </>
                    )}
                    <div>
                        <p className="text-secondary pt-6">
                            Wallet in your Browser
                            <span className="text-secondary text-sm font-semibold pl-6">
                                <FontAwesomeIcon icon={faTriangleExclamation} className="pr-2" />
                                DO NOT EVER STORE INVESTMENT ASSETS IN THIS WALLET
                            </span>
                        </p>
                        {localWallets && localWallets.length > 0 && (
                            <div className="w-full">
                                <ul id="results" className="ul-main" ref={ulRef}>
                                    {localWallets.map((option, index) => {
                                        return (
                                            <button
                                                id="PopupWalletWalletSelectItemBrowser"
                                                name="PopupWalletWalletSelectItemBrowser"
                                                data-info={option.name}
                                                type="button"
                                                key={index}
                                                onClick={(e) => {
                                                    setWallet(option);
                                                    setParentWallet(option);
                                                    console.debug("Contract Explorer wallet set to:", option);
                                                }}
                                                className="ul-item focus:border-transparent focus:outline-none">
                                                {option.name}
                                            </button>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {(!localWallets || localWallets.length === 0) && <p className="pt-1">No wallet found. Click below to create a new one.</p>}
                        <div className="pt-2">
                            <button
                                id="PopupWalletCreateNewWalletButton"
                                name="PopupWalletCreateNewWalletButton"
                                className="btn-default"
                                onClick={() => {
                                    createNewWallet();
                                }}>
                                Create new Wallet in Store in Your Browser
                            </button>
                        </div>
                    </div>
                    <p className="text-secondary pt-6">Enter your Wallet Private Key</p>
                    <div>
                        <div className="flex flex-row items-center text-white font-bold text-lg">
                            <input type="text" name="walletPK" placeholder={"Wallet Private Key"} onChange={onInputChange} autoComplete="new-password" spellCheck="false" value={walletPK || ""} />
                            {/* <FontAwesomeIcon icon={faCheck} className="pl-2 cursor-pointer h-5 text-green" onClick={() => setCurrentWalletFromPK()} /> */}
                        </div>
                        {validationMessages["walletPK"] && <ValidationMessage message={validationMessages["walletPK"]} />}
                        <button
                            id="PopupWalletSetWalletFromPKButton"
                            name="PopupWalletSetWalletFromPKButton"
                            className="btn-default mt-2"
                            onClick={() => {
                                setWalletFromPk(walletPK);
                            }}>
                            Set as your Wallet
                        </button>
                    </div>

                    <div className="pt-6">
                        <p className="text-secondary">Wallet Provider</p>
                        <p>
                            <button
                                id="PopupWalletConnectWalletButton"
                                name="PopupWalletConnectWalletButton"
                                className="btn-default"
                                onClick={() => {
                                    connectWallet();
                                }}>
                                MetaMask
                                <img src={`${getImageUrl("mask")}`} className="pl-4 h-5 w-[40px] cursor-pointer" />
                            </button>
                        </p>
                    </div>

                    <div className="flex justify-end pt-10">
                        <button
                            id="PopupWalletCloseButton"
                            name="PopupWalletCloseButton"
                            className="btn-default mt-2 justify-end"
                            onClick={() => {
                                window.popupWallet.close();
                            }}>
                            Close
                            <CloseOutlined className="pl-2 text-red" />
                        </button>
                    </div>
                    {/* </div>
            </div> */}
                    {/* </form> */}
                </div>
            </dialog>
        </>
    );
};

export default PopupWallet;
