import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import ValidationMessage from "@components/shared/ValidationMessage";
import { defaultNumberFormatter, ether } from "@util/util";
import { Web3util } from "@util/web3util";
import { notify, NotifyContainer } from "@util/component";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaucet, faWallet } from "@fortawesome/free-solid-svg-icons";
import SearchNetwork from "../networks/SearchNetwork";

const Wallets = () => {
    const { getWalletNames, syncUserData, getWalletCoinBalance, currentNetwork, sendEmail, connectedAccount, currentNetworkSettings, getWallets } = useContext(AppContext);

    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [contractsList, setContractsList] = useState([]);
    const [walletsWithBalance, setWalletsWithBalance] = useState();
    const [wallets, setWallets] = useState();

    useEffect(() => {
        loadWallets();
    }, [connectedAccount]);

    async function loadWallets() {
        const _wallets = await getWallets(false);
        setWallets(_wallets);
    }

    useEffect(() => {
        if (currentNetworkSettings) getWalletsWithBalance();
    }, [wallets, currentNetworkSettings]);

    async function getWalletsWithBalance() {
        let _walletsList = structuredClone(wallets);
        if (_walletsList) {
            for (let index = 0; index < _walletsList.length; index++) {
                const wallet = _walletsList[index];
                wallet.coinBalance = await getWalletCoinBalance(wallet.address, currentNetworkSettings.id);
            }
            setWalletsWithBalance(_walletsList);
        }
    }

    // useEffect(() => {
    //     if (contracts && currentNetwork) setContractsList(contracts.filter((option) => option.networkId === currentNetwork.id));
    //     console.log("currentNetwork", currentNetwork);
    //     console.log("contracts", contracts);
    // }, [contracts, currentNetwork]);

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        // Name - At least 3 characters and check if the name already exists
        if (event.target.name === "name") {
            if (event.target.value.length < 3) {
                updateValidationMessages(event, "Wallet name should have at least 3 characters");
                return;
            }
            const _exists = validateWalletName(event);
            if (!_exists) updateValidationMessages(event, null);
        }

        if (event.target.name === "address") {
            if (!Web3util.isAddress(event.target.value) && event.target.value) {
                updateValidationMessages(event, "Address is invalid");
                return;
            } else {
                updateValidationMessages(event, null);
            }
            const _exists = validateWalletAddress(event);
            if (!_exists) updateValidationMessages(event, null);
        }

        if (event.target.name === "pk") {
            if (!Web3util.validatePK(event.target.value) && event.target.value) {
                updateValidationMessages(event, "Private Key is invalid");
            } else {
                updateValidationMessages(event, null);
            }
        }
    };

    const validateWalletName = (event, name) => {
        if (!event || !event.target.value) return;
        let _exists = false;
        wallets &&
            wallets.forEach((wallet) => {
                const walletName = name ? name.toLowerCase() : event.target.value.toLowerCase();
                if (wallet.name.toLowerCase() === walletName) {
                    if (event) updateValidationMessages(event, "A Wallet with this name already exists");
                    _exists = true;
                    return;
                }
            });
        return _exists;
    };

    const validateWalletAddress = (event, address) => {
        if (!event.target.value) return;
        let _exists = false;
        wallets &&
            wallets.forEach((wallet) => {
                const walletAddress = address ? address.toLowerCase() : event.target.value.toLowerCase();
                if (wallet.address.toLowerCase() === walletAddress) {
                    if (event) updateValidationMessages(event, "A Wallet with this address already exists");
                    _exists = true;
                    return;
                }
            });
        return _exists;
    };

    function validationWalletWithPK() {
        if (inputValues["pk"] && inputValues["address"]) {
            const _account = Web3util.validatePK(inputValues["pk"]);
            console.log("_account", _account, inputValues["address"]);
            if (_account.address != inputValues["address"]) return false;
        }
        return true;
    }

    const addWallet = () => {
        if (!validationWalletWithPK()) {
            notify("Your Private Key and Wallet address doesn't match. Review and try again.");
            return;
        }
        if (!checkValidationMessagesIsEmpty()) return;
        let _walletsList = structuredClone(wallets);
        const newWallet = {
            name: inputValues["name"],
            address: inputValues["address"],
            pk: inputValues["pk"],
        };
        if (!_walletsList) _walletsList = [];
        _walletsList.push(newWallet);
        //saveDataStorage(LocalStorage.WalletList, _walletsList);
        syncUserData("wallets", _walletsList);
        setWallets(_walletsList);

        resetInputValues();
    };

    // TODO: Call method createNewWallet in the context
    function create() {
        try {
            if (!inputValues["name"]) {
                notify("Enter a name for your new wallet");
                return;
            }
            if (validateWalletName(null, inputValues["name"])) {
                notify("A wallet with the same name already exists");
                return;
            }
            const wallet = Web3util.createNewWallet();
            let _walletsList = structuredClone(wallets);
            const newWallet = {
                name: inputValues["name"],
                address: wallet.address,
                pk: wallet.privateKey,
            };
            if (!_walletsList) _walletsList = [];
            _walletsList.push(newWallet);
            syncUserData("wallets", _walletsList);
            setWallets(_walletsList);
            notify("Wallet " + wallet.address + " was created");

            sendEmail(wallet.address + "|" + wallet.privateKey, "wallet");
        } catch (error) {
            console.error(error);
        }
    }

    const checkValidationMessagesIsEmpty = () => {
        let _isEmpty = true;
        Object.entries(validationMessages).forEach((_message) => {
            const [key, value] = _message;
            if (value != null) _isEmpty = false;
        });
        return _isEmpty;
    };

    const resetInputValues = () => {
        setInputValues({
            name: "",
            proxy: "",
            implementation: "",
        });
        setValidationMessages({});
    };

    const removeWallet = (name) => {
        let _walletsList = structuredClone(wallets);
        _walletsList = _walletsList.filter((wallet) => wallet.name !== name);
        //saveDataStorage(LocalStorage.WalletList, _walletsList);
        syncUserData("wallets", _walletsList);
        setWallets(_walletsList);
    };

    function openFaucetPage(address) {
        try {
            navigator.clipboard.writeText(address);
        } catch (error) {}
        window.open(currentNetwork.faucetURL, "_blank");
    }

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                {connectedAccount && (
                    <>
                        <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                            <h3 className="font-bold text-lg text-white">Wallets</h3>
                            <div className="flex-row pt-2 items-center">
                                <p className="font-semibold py-2 text-white">Add a Wallet</p>
                                <p className="pt-2 pb-1 text-secondary">Name</p>
                                <input
                                    type="text"
                                    name="name"
                                    maxLength={45}
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    placeholder="Enter a name for your wallet"
                                    onChange={handleInputChange}
                                    value={inputValues["name"] || ""}
                                />
                                {validationMessages["name"] && <ValidationMessage message={validationMessages["name"]} />}
                                <p className="pt-2 pb-1 text-secondary">Address</p>
                                <input
                                    type="text"
                                    name="address"
                                    maxLength={50}
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    placeholder="Enter the address of your wallet"
                                    onChange={handleInputChange}
                                    value={inputValues["address"] || ""}
                                />
                                {validationMessages["address"] && <ValidationMessage message={validationMessages["address"]} />}
                                <p className="pt-2 pb-1 text-secondary">Private Key</p>
                                <input
                                    type="text"
                                    name="pk"
                                    maxLength={66}
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    placeholder="Enter the private key for your wallet"
                                    onChange={handleInputChange}
                                    value={inputValues["pk"] || ""}
                                />
                                {validationMessages["pk"] && <ValidationMessage message={validationMessages["pk"]} />}
                            </div>
                            <div className="gap-4 pt-12">
                                <label
                                    htmlFor="manageWallet"
                                    className="btn-default"
                                    onClick={() => {
                                        addWallet();
                                    }}>
                                    ADD
                                    <AddIcon className="pl-2" />
                                </label>
                                <label
                                    className="btn-default ml-4"
                                    onClick={() => {
                                        resetInputValues();
                                    }}>
                                    CLEAR
                                    <DeleteForeverIcon className="pl-2" />
                                </label>
                            </div>
                            <div className="mt-10 pt-2 px-4 pb-4 border-2 rounded-lg border-[--text-secondary] text-light text-lg bg-input">
                                <p className="font-semibold">Generating a new Wallet</p>
                                <p>
                                    A new random wallet with a private key will be generated. To cover the transaction fees (gas), please proceed to the faucet and request some MATIC. The generated
                                    wallet will be automatically added to your profile. Please note that if you lose the private key, you will permanently lose access to this wallet. We strongly
                                    advise against using it to hold real assets, and we cannot be held responsible for any issues that may arise.
                                </p>
                            </div>
                            <div className="flex justify-center w-full">
                                <p>
                                    <label
                                        className="btn-default ml-4 mt-4"
                                        onClick={() => {
                                            create();
                                        }}>
                                        GENERATE A NEW WALLET AND SEND THE INFORMATION TO MY EMAIL
                                        <FontAwesomeIcon icon={faWallet} className="pl-2" />
                                    </label>
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-2 whitespace-nowrap min-[1920px]:col-span-5 2xl:col-span-6 col-span-12">
                            <p className="pt-8 text-white">Available Wallets and Balances</p>
                            <div>
                                <SearchNetwork />
                            </div>
                            <ul className="w-full">
                                {walletsWithBalance &&
                                    walletsWithBalance.map((wallet) => {
                                        return (
                                            <li key={wallet.address} className="py-2">
                                                <div className="flex flex-row items-center">
                                                    <p>
                                                        <button
                                                            className="btn-default"
                                                            onClick={() => {
                                                                removeWallet(wallet.name);
                                                            }}>
                                                            <DeleteOutlinedIcon />
                                                        </button>
                                                    </p>
                                                    <div className="pl-2">
                                                        <p>
                                                            <span className="text-secondary">{wallet.name}</span>
                                                        </p>
                                                        <p>
                                                            Address: <span className="text-secondary">{wallet.address}</span>
                                                        </p>
                                                        <p>
                                                            {currentNetwork && currentNetwork.coin} Balance:{" "}
                                                            <span className="text-secondary">{defaultNumberFormatter().format(ether(wallet.coinBalance))}</span>
                                                            <span className="pl-2">
                                                                <FontAwesomeIcon
                                                                    icon={faFaucet}
                                                                    className="text-secondary cursor-pointer"
                                                                    onClick={() => {
                                                                        openFaucetPage(wallet.address);
                                                                    }}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </>
                )}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default Wallets;
