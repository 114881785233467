import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import { useNavigate } from "react-router-dom";
import { getDataStorage, saveDataStorage, removeDataStorage } from "@util/util";
import { LocalStorage } from "@util/enum";
import { notify } from "@util/component";
import HistoryItem from "@components/history/HistoryItem";
import SideMenu from "@components/sidemenu/SideMenu";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import ExecutionQueue from "./ExecutionQueue";
import ExecutionQueueResults from "./ExecutionQueueResults";
import ExecutionDetail from "./ExecutionDetail";
import PopupQueueItemSettings from "./PopupQueueItemSettings";
import ValidationStep from "./ValidationStep";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { NotifyContainer } from "@util/component";
import AddIcon from "@mui/icons-material/Add";
import BoltIcon from "@mui/icons-material/Bolt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactJson from "react-json-view";
import { ModalTheme } from "@util/enum";
import { Web3util } from "@util/web3util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import Accordion from "./Accordion";

const Queue = () => {
    const {
        executeQueue,
        executionQueue,
        executing,
        setExecutionQueue,
        setExecutionQueueResults,
        syncUserData,
        updateQueueListItem,
        getConnectedWallet,
        setCurrentQueue,
        currentQueue,
        setExecutionQueueDetail,
    } = useContext(AppContext);
    const [clearResults, setClearResults] = useState(true);
    const [addExecutionHistory, setAddExecutionHistory] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentQueue) {
            if (currentQueue.queue) {
                // console.log("Queue currentQueue", JSON.stringify(currentQueue, null, 2));
                setExecutionQueue(currentQueue.queue);
                cleanExecutionQueueResults();
            } else setExecutionQueue([]);

            // TODO: TEST the new json
            // currentQueue.queue.forEach((_queueItem) => {
            //     console.log("Queue Item", Web3util.transformExecution2Queue(_queueItem));
            // });
        } else {
            //notify("You should create a new execution queue before start here");
            navigate("/queuelist");
        }
    }, [currentQueue]);

    // useEffect(() => {
    //     console.log("Queue - executionQueue", executionQueue);
    // }, [executeQueue]);

    // console.log("Queue render", executionQueue);

    function handleValidationModal() {
        setShowValidationModal((prev) => !prev);
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(executionQueue);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const _currentQueue = currentQueue;
        _currentQueue.queue = items;

        setCurrentQueue(_currentQueue);
        updateQueueListItem(_currentQueue);
        setExecutionQueue(items);
    }

    function updateQueueandExecute() {
        executeQueue(false);
        if (clearResults) cleanExecutionQueueResults();
    }

    function cleanQueue() {
        setExecutionQueue([]);
        const _currentQueue = currentQueue;
        _currentQueue.queue = null;
        setCurrentQueue(_currentQueue);
        setExecutionQueue(null);
        setExecutionQueueDetail(null);
        updateQueueListItem(_currentQueue);
    }

    function cleanExecutionQueueResults() {
        setExecutionQueueResults([]);
        removeDataStorage(LocalStorage.ExecutionQueueResults);
    }

    function handleClearResults(event) {
        setClearResults(event.target.checked);
    }

    // function handleAddExecutionHistory(event) {
    //     setAddExecutionHistory(event.target.checked);
    // }

    const getItemStyle = (isDragging) => {
        if (isDragging) {
            return "bg-[#322b26] rounded-lg";
        } else {
            return "bg-black rounded-lg";
        }
    };

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="2xl:col-span-6 col-span-12 p-2">
                    <p className="text-white font-semibold text-xl">Execution Queue</p>
                    <p className="text-white font-semibold text-md pt-4">{currentQueue && currentQueue.name}</p>
                    <div className="flex pt-4">
                        <label htmlFor="executionQueue" className="btn-default">
                            Add Functions
                            <AddIcon className="pl-2" />
                        </label>
                        <label
                            className="btn-default ml-4"
                            onClick={() => {
                                setExecutionQueueDetail(null);
                                setShowValidationModal(true);
                            }}>
                            Add Validation Step
                            <AddIcon className="pl-2" />
                        </label>
                        <button
                            className="btn-default ml-4"
                            onClick={() => {
                                cleanQueue();
                            }}>
                            Clean Queue
                            <DeleteForeverIcon className="pl-2" />
                        </button>
                        {/* <div className="w-full">
                                <label className="label cursor-pointer pl-5">
                                    <span className="label-text">Clear results on every execution</span>
                                    <input type="checkbox" checked={clearResults} className="checkbox ml-4" onChange={handleClearResults} />
                                </label>
                            </div> */}
                    </div>
                    {/* <label className="label cursor-pointer">
                            <span className="label-text">Add to the execution history</span>
                            <input type="checkbox" checked={addExecutionHistory} className="checkbox" onChange={handleAddExecutionHistory} />
                        </label> */}
                    {executionQueue && executionQueue.length > 0 && (
                        <div>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="executionInfoList">
                                    {(provided) => (
                                        <ul className="mt-4" {...provided.droppableProps} ref={provided.innerRef}>
                                            {executionQueue.map((history_, index_) => {
                                                return (
                                                    <Draggable key={history_.uuid} draggableId={history_.uuid} index={index_}>
                                                        {(provided, snapshot) => (
                                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={getItemStyle(snapshot.isDragging)}>
                                                                {!history_.validation && <HistoryItem historyItem={history_} behavior={"queueItem"} parent={currentQueue.id} />}
                                                                {history_.validation && (
                                                                    <HistoryItem
                                                                        historyItem={history_}
                                                                        behavior={"queueItemValidation"}
                                                                        parent={currentQueue.id}
                                                                        stateToBeUpdated={setShowValidationModal}
                                                                    />
                                                                )}
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                    {!executionQueue ||
                        (executionQueue && executionQueue.length === 0) && (
                            <div className="flex items-center h-20 my-10 text-xl text-semibold">
                                Start adding functions in your Queue. Click on <strong className="px-1">ADD FUNCTIONS</strong> button.
                            </div>
                        )}
                    <div>
                        <div className="flex flex-row mt-5 items-center">
                            <button
                                className="btn-default"
                                onClick={() => {
                                    updateQueueandExecute();
                                }}>
                                Execute Queue
                                <BoltIcon className="pl-2" />
                            </button>
                            {executing && <span className="loader"></span>}
                        </div>
                    </div>
                </div>
                {/* <div className="w-full px-2 whitespace-nowrap 2xl:col-span-6 col-span-12"> */}
                <div className="2xl:col-span-6 col-span-12 p-2">
                    <p className="text-white font-semibold text-xl">Execution Queue Results</p>
                    <div className="pt-14">
                        <button
                            className="btn-default"
                            onClick={() => {
                                cleanExecutionQueueResults();
                            }}>
                            Clean Results
                            <DeleteForeverIcon className="pl-2" />
                        </button>
                    </div>
                    <ExecutionQueueResults />
                </div>
                {/* <div className="2xl:col-span-6 col-span-12 p-2">
                    Checking executionQueue JSON
                    {executionQueue && <ReactJson src={executionQueue} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                </div>
                <div className="2xl:col-span-6 col-span-12 p-2 bg-yellow-600">
                    <Accordion title={"Title here"} items={[<p>Information about the item here</p>, 2, 3, 4]} />
                </div> */}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
            <ExecutionQueue />
            <ValidationStep open={showValidationModal} closeModal={handleValidationModal} />
            <ExecutionDetail />
            <PopupQueueItemSettings />
        </div>
    );
};

export default Queue;
