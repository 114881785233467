import { useState, useEffect, useContext } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBurn, faCopy } from "@fortawesome/free-solid-svg-icons";
import Web3 from "web3";
import { Helmet } from "react-helmet";

const BurnWallet = () => {
    const [burnWallet, setBurnWallet] = useState();

    function createNewBurnWallet() {
        const web3 = new Web3();
        const wallet = web3.eth.accounts.create();
        navigator.clipboard.writeText(wallet.privateKey);
        console.log(wallet);
        setBurnWallet(wallet);
    }

    return (
        <div className="container">
            <Helmet>
                <meta charset="UTF-8" />
                <title>Create a new web3 burn wallet | DevWeb3</title>
                <meta name="description" content="Create a new web3 burn wallet to test and execute functions of Solidity contracts wihtout risks." />
                <meta property="og:title" content="Create a new web3 burn wallet | DevWeb3" />
                <meta property="og:description" content="Create a new web3 burn wallet to test and execute functions of Solidity contracts wihtout risks." />
                <meta name="twitter:title" content="Create a new web3 burn wallet | DevWeb3" />
                <meta name="twitter:description" content="Create a new web3 burn wallet to test and execute functions of Solidity contracts wihtout risks." />
            </Helmet>
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                    <h3 className="font-bold text-lg text-white">Create a new burn wallet</h3>
                    <p>
                        <button id="BurnWalletCreateWalletButton" name="BurnWalletCreateWalletButton" className="btn-default" onClick={createNewBurnWallet}>
                            Create
                            <FontAwesomeIcon icon={faBurn} className="text-secondary ml-2 cursor-pointer" />
                        </button>
                    </p>
                    {burnWallet && (
                        <div className="border-2 border-[#AA6840] rounded-lg mt-10 p-2">
                            <p className="font-semibold text-white">New Wallet Created</p>
                            <p className="text-secondary">
                                Address:
                                <span id="BurnWalletWalletAddress" name="BurnWalletWalletAddress" className="text-light pl-1">
                                    {burnWallet.address}
                                </span>
                                <button
                                    id="BurnWalletWalletAddressCopyButton"
                                    name="BurnWalletWalletAddressCopyButton"
                                    className="btn-default ml-4"
                                    onClick={() => {
                                        navigator.clipboard.writeText(burnWallet.address);
                                    }}>
                                    Copy
                                    <FontAwesomeIcon
                                        id="BurnWalletWalletAddressCopyButtonIcon"
                                        name="BurnWalletWalletAddressCopyButtonIcon"
                                        icon={faCopy}
                                        className="text-secondary ml-2 cursor-pointer"
                                    />
                                </button>
                            </p>
                            <p className="text-secondary">
                                Private Key:
                                <span id="BurnWalletWalletPK" name="BurnWalletWalletPK" className="text-light pl-1">
                                    {burnWallet.privateKey}
                                </span>
                                <button
                                    id="BurnWalletWalletPKCopyButton"
                                    name="BurnWalletWalletPKCopyButton"
                                    className="btn-default ml-4"
                                    onClick={() => {
                                        navigator.clipboard.writeText(burnWallet.privateKey);
                                    }}>
                                    Copy
                                    <FontAwesomeIcon id="BurnWalletWalletPKCopyButtonIcon" name="BurnWalletWalletPKCopyButtonIcon" icon={faCopy} className="text-secondary ml-2 cursor-pointer" />
                                </button>
                            </p>
                            <p className="text-secondary pt-4">The wallet information is not saved anywhere. Please copy, use and discard.</p>
                            <p className="text-secondary">A burn wallet is not intented to store assets.</p>
                            {/* <p>Choose a netowrk to see mor details </p>
                            WHICH NETWORK ???
                            <button
                                className="btn-default"
                                onClick={() => {
                                    window.open(contractsNetworkEditor.blockExplorerURL + "address/" + burnWallet.address);
                                }}>
                                Transactions
                            </button> */}
                            {/* // window.open(window.location.origin + "/editor?network=" + contractsNetworkEditor.id + "&contract=" + lastDeploymentInfo.contractAddress, "_blank");
                            // <a href={contractsNetworkEditor.blockExplorerURL + "address/" + contractsInfoEditor.proxyAdminFromNetwork} target="_blank">
                            //         <FontAwesomeIcon icon={faCube} className="text-secondary ml-2 cursor-pointer" />
                            //     </a> */}
                            {/* <p>Faucets for all networks</p>
                            <p>button to check balances of this wallet in all networks</p>
                            <p>Transactions of this wallet (link to block explorer)</p>
                            <p>Later: List of last contracts and wallets that interacted with this wallet</p>
                            <p></p> */}
                        </div>
                    )}
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default BurnWallet;
