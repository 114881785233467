import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import { ModalTheme } from "@util/enum";
import Result from "./result/Result";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ReactJson from "react-json-view";
import { dateDefaultFormat, timeSince } from "@util/util";

const PopupExecutionResult = ({ currentFunction, executionResult, network }) => {
    const {} = useContext(AppContext);
    const [versionsList, setVersionsList] = useState();
    const [inputValues, setInputValues] = useState({});

    useEffect(() => {}, []);

    // console.log("PopupExecutionResult executionResult", executionResult);

    if (!executionResult) {
        return (
            <dialog id="popupExecutionResult" className="rounded-lg bg-transparent w-[750px] h-[750px]">
                <form method="dialog" className="modal-box w-full h-full max-w-full max-h-full text-light">
                    <h1 className="font-bold text-white">Execution Result</h1>
                    {!executionResult && <h1 className="text-secondary">Execute a function by pressing F8 on top of a function code.</h1>}
                    <div className="modal-action flex justify-end">
                        <button className="btn-default mt-2 justify-end">
                            Close
                            <CloseOutlined className="pl-2 text-red" />
                        </button>
                    </div>
                </form>
            </dialog>
        );
    }

    return (
        <dialog id="popupExecutionResult" className="rounded-lg bg-transparent w-[750px] h-[750px]">
            <form method="dialog" className="modal-box w-full h-full max-w-full max-h-full text-light">
                <h3 className="font-bold text-lg text-white">Execution Result</h3>
                <div className="pt-4">
                    {executionResult && executionResult.success && (
                        <p className="text-secondary">
                            Status:<span className="text-green pl-1">Success</span>
                        </p>
                    )}
                    {executionResult && !executionResult.success && (
                        <p className="text-secondary">
                            Status:<span className="text-red pl-1">Reverted</span>
                        </p>
                    )}
                    <p className="text-secondary">
                        Type:<span className="text-light pl-1">{executionResult && executionResult.input && executionResult.input.type}</span>
                    </p>
                    <p className="text-secondary">
                        Network:
                        <span className="text-light pl-1">
                            {network && network.name} ({network && network.id})
                        </span>
                    </p>
                    <p className="text-secondary">
                        Function:<span className="text-light pl-1">{executionResult && executionResult.function && executionResult.function.name}</span>
                    </p>
                    {/* {executionResult && executionResult.function.inputs && executionResult.function.inputs.length > 0 && (
                            <p className="text-secondary">
                                Parameters: <span className="text-light">{executionResult && executionResult.function.inputs}</span>
                            </p>
                        )} */}
                    {executionResult && executionResult.function && executionResult.function.inputs && executionResult.function && executionResult.function.inputs.length === 0 && (
                        <p className="text-secondary">
                            Parameters: <span className="text-light">None</span>
                        </p>
                    )}
                    <p className="text-secondary">
                        Executed:{" "}
                        <span className="text-light">
                            {executionResult && dateDefaultFormat(executionResult.timestamp)}
                            {/* ({executionResult && timeSince(executionResult.timestamp)}) */}
                        </span>
                    </p>
                    <p className="text-secondary">
                        Sender:
                        {executionResult && executionResult.input && executionResult.input.type === "execution" && executionResult.wallet && (
                            <span className="text-light pl-2">
                                {executionResult.wallet.address} ({executionResult.wallet.name})
                            </span>
                        )}
                        {executionResult && executionResult.input && executionResult.input.type === "execution" && !executionResult.wallet && <span className="text-light pl-2">N/A</span>}
                        {executionResult && executionResult.input && executionResult.input.type === "simulation" && <span className="text-light pl-2">N/A</span>}
                    </p>
                    <p className="text-secondary">
                        Receiver: <span className="text-light">{executionResult && executionResult.contract && executionResult.contract.implementation}</span>
                    </p>
                    <div>
                        <Result currentFunction={currentFunction} lastExecution={executionResult} network={network} />
                    </div>
                </div>
                <div className="modal-action flex justify-end">
                    <button id="PopupExecutionResultCloseButton" name="PopupExecutionResultCloseButton" className="btn-default mt-2 justify-end">
                        Close
                        <CloseOutlined className="pl-2 text-red" />
                    </button>
                </div>
                {/* </div>
            </div> */}
            </form>
        </dialog>
    );
};

export default PopupExecutionResult;
