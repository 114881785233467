import { useState } from "react";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faLocationDot, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Web3util } from "@util/web3util";
import ValidationMessage from "@components/shared/ValidationMessage";
import Web3 from "web3";
import { Helmet } from "react-helmet";

const AddressFromPK = () => {
    const [validationMessages, setValidationMessages] = useState();
    const [pk, setPK] = useState();
    const [address, setAddress] = useState();

    const handleInputChange = (event) => {
        const _pk = event.target.value;
        if (_pk) {
            let _valid;
            try {
                _valid = Web3util.validatePK(_pk);
            } catch (error) {}
            console.log("valid", _valid);
            if (!_valid) {
                setValidationMessages("Private Key is invalid");
            } else {
                setValidationMessages(null);
                getAddressFromPK(_pk);
            }
        }
        setPK(event.target.value);
    };

    function getAddressFromPK(pk_) {
        let _pk = pk_;
        if (!pk_) _pk = pk;
        console.log("_pk", _pk);
        const web3 = new Web3();
        const _address = web3.eth.accounts.privateKeyToAccount(_pk).address;
        console.log(_address);
        setAddress(_address);
    }

    return (
        <div className="container">
            <Helmet>
                <meta charset="UTF-8" />
                <title>Get Wallet Address from a Web3 Wallet Private Key | DevWeb3</title>
                <meta name="description" content="Get the Web3 Wallet Address from a Wallet Private Key." />
                <meta property="og:title" content="Get Wallet Address from a Web3 Wallet Private Key | DevWeb3" />
                <meta property="og:description" content="Get the Web3 Wallet Address from a Wallet Private Key." />
                <meta name="twitter:title" content="Get Wallet Address from a Web3 Wallet Private Key | DevWeb3" />
                <meta name="twitter:description" content="Get the Web3 Wallet Address from a Wallet Private Key." />
            </Helmet>
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                    <h3 className="font-bold text-lg text-white">Get Wallet Address from a Private Key</h3>
                    <p>
                        <input
                            type="text"
                            name="privateKeyInput"
                            onChange={handleInputChange}
                            placeholder="Type or Paste the Private Key"
                            autoComplete="new-password"
                            spellCheck="false"
                            maxLength={66}
                            value={pk || ""}
                        />
                        {validationMessages && <ValidationMessage message={validationMessages} />}
                        <button id="AddressFromPKWalletAddressButton" name="AddressFromPKWalletAddressButton" className="btn-default mt-2" onClick={() => getAddressFromPK()}>
                            Get Wallet Address
                            <FontAwesomeIcon id="AddressFromPKWalletAddressButtonIcon" name="AddressFromPKWalletAddressButtonIcon" icon={faWallet} className="text-secondary ml-2 cursor-pointer" />
                        </button>
                    </p>
                    {address && (
                        <div className="border-2 border-[#AA6840] rounded-lg mt-10 p-2">
                            <p className="font-semibold text-white">Wallet Found</p>
                            <p className="pt-6 text-secondary">
                                Private Key:
                                <span id="AddressFromPKWalletAddressPK" name="AddressFromPKWalletAddressPK" className="text-light pl-1">
                                    {pk}
                                </span>
                            </p>
                            <p className="pt-6 text-secondary">
                                Address:
                                <span id="AddressFromPKWalletAddressAddress" name="AddressFromPKWalletAddressAddress" className="text-light pl-1">
                                    {address}
                                </span>
                                <button
                                    id="AddressFromPKWalletAddressCopyButton"
                                    name="AddressFromPKWalletAddressCopyButton"
                                    className="btn-default ml-4"
                                    onClick={() => {
                                        navigator.clipboard.writeText(address);
                                    }}>
                                    Copy
                                    <FontAwesomeIcon icon={faCopy} className="text-secondary ml-2 cursor-pointer" />
                                </button>
                            </p>
                        </div>
                    )}
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default AddressFromPK;
