import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import { Link } from "react-router-dom";
import { getImageUrl, saveDataStorage } from "@util/util";
import { notify } from "@util/component";
import { useProSidebar } from "react-pro-sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { LocalStorage } from "@util/enum";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCheck, faExclamation, faFloppyDisk, faLockOpen, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import SearchNetwork from "./SearchNetwork";
import { useLocation } from "react-router-dom";
import { LogoTop } from "@components/shared/Logo/Logo";
import Tooltip from "../shared/Tooltip/Tooltip";

const NavBar = () => {
    const { isAccountConnected, connectedAccount, syncing, getCurrentOnboardingStep, dataLoaded, getOnboardingStatus } = useContext(AppContext);
    const { collapseSidebar, collapsed, toggleSidebar, broken } = useProSidebar();
    const [isOnboarded, setIsOnboarded] = useState("finished");

    const location = useLocation();
    // console.log(location.pathname);

    // useEffect(() => {
    //     getOnboardingStatusNavBar();
    // if (dataLoaded && getOnboardingStatus() != 'finished') {
    //     //getCurrentOnboardingStep() < 5) {
    //     console.log('navbar isOnboarded to FALSE');
    //     setIsOnboarded(false);
    // }
    // }, [dataLoaded]);

    // async function getOnboardingStatusNavBar() {
    //     const _status = await getOnboardingStatus();
    //     // console.log("_status", _status);
    //     if ((_status && _status.status === "finished") || getCurrentOnboardingStep() >= 5) setIsOnboarded("finished");
    //     else if (getCurrentOnboardingStep() > 1) setIsOnboarded("inprogress");
    //     else setIsOnboarded("notstarted");
    // }

    const isInsideTools = location.pathname.startsWith("/tools");

    return (
        <>
            {location.pathname != "/editor" && (
                <div className="navbar bg-input">
                    <div className="navbar-start flex items-center">
                        {location.pathname != "/" && broken && (
                            <button className="text-white w-[45px]" onClick={() => toggleSidebar()}>
                                <MenuIcon />
                            </button>
                        )}
                        {location.pathname != "/" && !broken && (
                            <button
                                id="NavBarSideMenuCollapseButton"
                                name="NavBarSideMenuCollapseButton"
                                className="text-white w-[45px]"
                                onClick={() => {
                                    collapseSidebar();
                                    saveDataStorage(LocalStorage.SideMenuState, !collapsed);
                                }}>
                                <FontAwesomeIcon id="NavBarSideMenuCollapseButtonIcon" name="NavBarSideMenuCollapseButtonIcon" icon={faBars} />
                            </button>
                        )}
                        <Link to="/">
                            <LogoTop id="NavBarSideLogo" name="NavBarSideLogo" size={25} backgroundColor="bg-input" foregroundColor="#FFF" />
                        </Link>
                    </div>
                    {/* {location.pathname != "/" && !isInsideTools && location.pathname != "/editor" && <div className="navbar-center">{connectedAccount && <SearchNetwork />}</div>} */}
                    <div className="navbar-end pr-2"></div>
                </div>
            )}
        </>
    );
};

export default NavBar;
