import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import NavBar from "../../components/navbar/NavBar";

const InitialLoad = () => {
    const { syncUserData } = useContext(AppContext);

    const _contracts = [
        {
            name: "Q4X (BSC Testnet) - invest FOREX",
            implementation: "0x9Fa0613426Ddf4226493cB3F8e683B2e8987B2cA",
            proxy: "0x5B0fe7eF0500330f54fB1F60f48e20a4Ac171D38",
        },
        {
            name: "Taylor BSC Mainnet",
            implementation: "0xfc56b67bB54dd089Ca2B0C5A0a86B655030A85F2",
            proxy: "0xc8313BbE9cf98AcD5F09A609fFF01dC06FCFD85F",
        },
        {
            name: "PSTN BSC Mainnet",
            implementation: "0xf2168B37cc4F66b8B3e73e5BE67eC29be4205FDc",
            proxy: "0xbd6e5D331A09fb39D28CB76510ae9b7d7781aE68",
        },
        {
            name: "Baca Dex Trade (wallet Owner)",
            implementation: "0xAfA787ebffD367bd243CeB9D26BCAFD6e0AD419f",
            proxy: "0xFC784ccD87973619745506f60aB706945dBe8496",
        },
        {
            name: "Juji Polygon Mainnet",
            implementation: "0x4c5c6568fFB886dF7dbA4e3ABD856d902B1909AE",
            proxy: "",
        },
    ];

    const _wallets = [
        {
            name: "Test User Q4X",
            address: "0x0C9d518757b4a5971F398bD7dd032F3E762Ea5dF",
            pk: "6befc44f6a16df1183a41731921d8b4243b5754298a9626550772e0d9541863f",
        },
        {
            name: "Wallet Owner DEX/Q4X",
            address: "0x1ECBDC64505fe4095eCE347984cc015D10d23361",
            pk: "02ee9afe48feb356a0981a92b452ea4983c4a2f99cceba96a07ca605c631b351",
        },
        {
            name: "Test User BacaDex",
            address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
            pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
        },
    ];

    function initialDataLoad () {
        syncUserData("contracts", _contracts);
        syncUserData("wallets", _wallets);
    }

    return (
        <div className="master">
            <NavBar />
            <p className="text-3xl font-bold w-full text-center">Initial Data Load</p>
            <button className="btn-default" onClick={() => {initialDataLoad();}}>Load Data</button>
        </div>
    );
};

export default InitialLoad;
