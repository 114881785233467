import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import HistoryItem from "../../components/history/HistoryItem";
import { saveDataStorage, getDataStorage } from "../../util/util";
import { LocalStorage, FunctionGasType } from "../../util/enum";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";

function ExecutionQueue() {
    const { executionHistory, currentNetwork } = useContext(AppContext);
    const [functions, setFunctions] = useState([]);

    const onInputChange = (event) => {
        if (event.target.value) {
            setFunctions(
                functions.filter((option) => {
                    if (option.function.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) && option.network.name === currentNetwork.name && option.function) return true;
                    else return false;
                })
            );
        } else {
            setFunctions(executionHistory.filter((option) => option.network.name === currentNetwork.name && option.function).slice(0, 20));
        }
    };

    useEffect(() => {
        if (currentNetwork && executionHistory) setFunctions(executionHistory.filter((option) => option.network.name === currentNetwork.name && option.function).slice(0, 10));
    }, [executionHistory, currentNetwork]);

    return (
        <>
            <input type="checkbox" id="executionQueue" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box min-w-[75%] max-h-[85%]">
                    <h3 className="font-bold text-lg text-white">Execution History</h3>
                    <input type="text" className="mt-4" placeholder={"Function Name"} autoComplete="new-password" spellCheck="false" onChange={onInputChange} />
                    {/* <p>Order by: Time asc, Time Desc, Function Name,</p> */}
                    <div className="pt-4">
                        {functions &&
                            functions.map((history_) => {
                                return (
                                    <div key={history_.uuid}>
                                        <HistoryItem historyItem={history_} behavior={"queueItemToBeAdded"} />
                                    </div>
                                );
                            })}
                    </div>
                    <div className="modal-action gap-4 pt-12">
                        <label htmlFor="executionQueue" className="btn-default">
                            CLOSE
                            <CloseOutlined className="pl-2" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExecutionQueue;
