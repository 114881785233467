import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import ValidationMessage from "@components/shared/ValidationMessage";
import SearchbarDropdown from "@pages/networks/SearchbarDropdown";
import { isObjEmpty } from "@util/util";
import { Web3util } from "@util/web3util";
import { notify, NotifyContainer } from "@util/component";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCode, faCubes, faXmark } from "@fortawesome/free-solid-svg-icons";

const SourceCodePage = () => {
    const { networkOptions, networkSettingsOptions, setNetworkSettingsOptions, networks, currentNetworkSettings, loadContractSourceCode, getAllNetworks } = useContext(AppContext);
    const [currentNetworkSearchInput, setCurrentNetworkSearchInput] = useState();
    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        getAllNetworksList();
    }, [networks]);

    async function getAllNetworksList() {
        const _allNetworks = await getAllNetworks();
        console.log("SourceCodePage _allNetworks", _allNetworks);
        console.log("SourceCodePage networks", networks);
        setNetworkSettingsOptions(_allNetworks);
    }

    const onInputChange = (event) => {
        if (event.target.value) {
            console.log("onInputChange - event.target.value", event.target.value);
            setNetworkSettingsOptions(networks.filter((option) => option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentNetworkSearchInput(event.target.value);
        } else {
            setCurrentNetworkSearchInput("");
            setNetworkSettingsOptions(networks);
            //setCurrentNetwork({});
        }
    };

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        if (event.target.name === "address") {
            if (!Web3util.isAddress(event.target.value) && event.target.value) {
                updateValidationMessages(event, "Address is invalid");
                return;
            } else {
                // updateValidationMessages(event, null);
                setValidationMessages(null);
            }
        }
    };

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    async function getSourceCode() {
        try {
            if (validationMessages) {
                notify("The contract address is not valid");
                return;
            }
            if (!inputValues["address"] || !currentNetworkSettings || !currentNetworkSearchInput) {
                notify("Select a network and provide a contract address");
                return;
            }

            const _contractSourceCode = await loadContractSourceCode(currentNetworkSettings.id, inputValues["address"]);
            if (!_contractSourceCode) {
                notify("The code for this contract is not available. Check if the contract code is published on " + currentNetworkSettings.blockExplorerURL, "warn");
                return;
            }

            // navigate to codeeditor page using query string network id and contract address
            // console.log("inputValues[address]", inputValues["address"]);
            // console.log("currentNetworkSettings", currentNetworkSettings);
            const url = "/editor?network=" + currentNetworkSettings.id + "&contract=" + inputValues["address"];
            navigate(url);
        } catch (error) {
            notify("Error getting the source code for this network/contract.");
            console.error(error);
        }
    }

    function openBlockExplorer() {
        if (validationMessages) {
            notify("The contract address is not valid");
            return;
        }
        if (!inputValues["address"] || !currentNetworkSettings || !currentNetworkSearchInput) {
            notify("Select a network and provide a contract address");
            return;
        }

        const url = currentNetworkSettings.blockExplorerURL + "/address/" + inputValues["address"] + "#code";
        // console.log("opening ", url);
        window.open(url, "_blank");
    }

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                    <h3 className="font-bold text-lg text-white">Source Code Editor</h3>
                    <div className="flex-row pt-2 items-center">
                        <p className="font-semibold py-2 text-white">Network</p>
                        <p className="pt-2 pb-1 text-secondary">Name</p>
                        <div>
                            <SearchbarDropdown
                                options={networkSettingsOptions}
                                onInputChange={onInputChange}
                                placeholder="Select a Network"
                                setOptions={setNetworkSettingsOptions}
                                currentNetworkSearchInput={currentNetworkSearchInput}
                                setCurrentNetworkSearchInput={setCurrentNetworkSearchInput}
                            />
                        </div>
                        <p className="pt-2 pb-1 text-secondary">Address</p>
                        <input
                            type="text"
                            name="address"
                            maxLength={50}
                            autoComplete="new-password"
                            spellCheck="false"
                            placeholder="Enter the address of the contract"
                            onChange={handleInputChange}
                            value={inputValues["address"] || ""}
                        />
                        {validationMessages && validationMessages["address"] && <ValidationMessage message={(validationMessages && validationMessages["address"]) || ""} />}
                        <button
                            id="SourceCodePageLaunchEditorButton"
                            name="SourceCodePageLaunchEditorButton"
                            // data-info={JSON.stringify({ network: currentNetworkSettings.id, contract: inputValues["address"] })}
                            className="btn-default mt-10"
                            onClick={() => {
                                getSourceCode();
                            }}>
                            Launch Editor
                            <FontAwesomeIcon icon={faCode} className="pl-2" />
                        </button>
                        <button
                            id="SourceCodePageViewBlockExplorerButton"
                            name="SourceCodePageViewBlockExplorerButton"
                            // data-info={JSON.stringify({ network: currentNetworkSettings.id, contract: inputValues["address"] })}
                            className="btn-default mt-10 ml-4"
                            onClick={() => {
                                openBlockExplorer();
                            }}>
                            View in the Block Explorer
                            <FontAwesomeIcon icon={faCubes} className="pl-2" />
                        </button>
                    </div>
                    {/* <div className="border-2 border-[#AA6840] rounded-lg text-secondary mt-10 p-2">
                        <p className="font-semibold text-white">The editor is in Beta Testing</p>
                        <p className="font-semibold pt-2">What it can do</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Open a contract from the network</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Open a contract from the network with Imports</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Execute a function</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Compile</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Re-Deploy</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Verify the code in the Block Explorer</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />MetaMask Integration</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Save contracts in your local machine</p>
                        <p><FontAwesomeIcon icon={faCheck} className="text-green pr-2" />Handle Proxy Contracts</p> */}
                    {/* <p className="font-semibold pt-2">What it can't do</p>
                        <p><FontAwesomeIcon icon={faXmark} className="text-red pr-2" />Debug</p>
                        <p><FontAwesomeIcon icon={faXmark} className="text-red pr-2" />Safely store your Private Keys</p> */}
                    {/* </div> */}
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default SourceCodePage;
