import React, { useState, useRef, useEffect } from "react";
import "./Accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faSquareMinus, faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import RemoveIcon from "@mui/icons-material/Remove";

const Accordion = ({ title, subtitle = "", items, opened = true, topBorder = true, compactMode = false, titleTooltip = "", alertMessage = "" }) => {
    const [isOpen, setIsOpen] = useState(opened);
    const contentRef = useRef(null);

    useEffect(() => {
        setIsOpen(opened);
    }, [opened]);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const getContentHeight = () => {
        if (contentRef.current) {
            return isOpen ? `${contentRef.current.scrollHeight + 80}px` : "0px";
        }
        return "0px";
    };

    return (
        <div className="accordion">
            {topBorder && <div className="border-t border-gray-500"></div>}
            <div className="accordion-header unselectable" onClick={handleClick}>
                <div className="flex flex-col items-center justify-center">
                    <div className="text-white font-semibold text-md" title={titleTooltip}>
                        {title} <span className="text-light font-normal text-sm">{subtitle}</span>
                        {alertMessage && (
                            <span className="text-red-500 text-sm ml-2" title={alertMessage}>
                                <FontAwesomeIcon icon={faTriangleExclamation} className="text-secondary pr-2" />
                            </span>
                        )}
                    </div>
                </div>
                <div className={`accordion-icon flex items-center justify-center ${!isOpen ? "open" : ""} mr-2`}></div>
            </div>
            <div className="accordion-content" style={{ maxHeight: getContentHeight() }}>
                <div ref={contentRef}>
                    {items.map((item, index) =>
                        compactMode ? (
                            <div key={index} className="accordion-item-compact">
                                {item}
                            </div>
                        ) : (
                            <div key={index} className="accordion-item">
                                {item}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
