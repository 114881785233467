import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import ValidationMessage from "@components/shared/ValidationMessage";
import { Web3util } from "@util/web3util";
import { notify, NotifyContainer } from "@util/component";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Contracts = () => {
    const { contracts, connectedAccount, setContracts, syncUserData, currentNetwork, getImplementationFromProxy } = useContext(AppContext);

    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [contractsList, setContractsList] = useState([]);

    console.log("Contracts Page", currentNetwork, contracts);

    useEffect(() => {
        if (contracts && currentNetwork) setContractsList(contracts.filter((option) => option.networkId === currentNetwork.id));
    }, [contracts, currentNetwork]);

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        // Name - At least 3 characters and check if the name already exists
        if (event.target.name === "name") {
            if (event.target.value.length < 3) {
                updateValidationMessages(event, "Contract name should have at least 3 characters");
                return;
            }
            const _exists = validateContractName(event);
            if (!_exists) updateValidationMessages(event, null);
        }

        if (event.target.name === "proxy" || event.target.name === "implementation") {
            if (event.target.value && !Web3util.isAddress(event.target.value)) {
                updateValidationMessages(event, "Address is invalid");
                return;
            } else {
                updateValidationMessages(event, null);
            }

            const _exists = validateContractAddress(event);
            if (!_exists) updateValidationMessages(event, null);
        }
    };

    const validateContractName = (event) => {
        if (!event.target.value) return;
        let _exists = false;
        contracts &&
            contracts.forEach((contract) => {
                const contractName = event.target.value.toLowerCase();
                if (contract.name.toLowerCase() === contractName) {
                    if (event) updateValidationMessages(event, "A Contract with this name already exists");
                    _exists = true;
                    return;
                }
            });
        return _exists;
    };

    const validateContractAddress = (event) => {
        if (!event.target.value) return;
        let _exists = false;
        contracts &&
            contracts.forEach((contract) => {
                const contractAddress = event.target.value.toLowerCase();
                if (event.target.name === "proxy") {
                    if (contract.proxy.toLowerCase() === contractAddress) {
                        if (event) updateValidationMessages(event, "A Contract with this proxy address already exists");
                        _exists = true;
                        return;
                    }
                }
                if (event.target.name === "implementation") {
                    if (contract.implementation.toLowerCase() === contractAddress) {
                        if (event) updateValidationMessages(event, "A Contract with this implementation address already exists");
                        _exists = true;
                        return;
                    }
                }
            });
        return _exists;
    };

    const addContract = () => {
        if (!checkValidationMessagesIsEmpty()) {
            notify("Check the validations messages below and try again", "warn");
            return;
        }
        let _contractsList = structuredClone(contracts);
        const newContract = {
            name: inputValues["name"],
            proxy: inputValues["proxy"],
            implementation: inputValues["implementation"],
            networkId: currentNetwork.id,
        };
        if (!_contractsList) _contractsList = [];
        _contractsList.push(newContract);
        // saveDataStorage(LocalStorage.ContractList, _contractsList);
        syncUserData("contracts", _contractsList);
        setContracts(_contractsList);

        resetInputValues();
    };

    const checkValidationMessagesIsEmpty = () => {
        let _isEmpty = true;
        Object.entries(validationMessages).forEach((_message) => {
            const [key, value] = _message;
            if (value != null) _isEmpty = false;
        });
        return _isEmpty;
    };

    const resetInputValues = () => {
        setInputValues({
            name: "",
            proxy: "",
            implementation: "",
        });
        setValidationMessages({});
    };

    const removeContract = (name) => {
        let _contractsList = structuredClone(contracts);
        _contractsList = _contractsList.filter((contract) => contract.name !== name);
        //saveDataStorage(LocalStorage.ContractList, _contractsList);
        syncUserData("contracts", _contractsList);
        setContracts(_contractsList);
    };

    async function getProxy() {
        if (inputValues["proxy"]) {
            let _implementation = await getImplementationFromProxy(inputValues["proxy"]);
            if (_implementation) {
                _implementation = Web3util.removeLeadingZeros(_implementation);
                setInputValues((prevValues) => {
                    return {
                        ...prevValues,
                        ["implementation"]: _implementation,
                    };
                });
            }
        }
    }

    // console.log("contracts", contracts);

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                {connectedAccount && (
                    <>
                        <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                            <h3 className="font-bold text-lg text-white">Contracts</h3>
                            <div className="flex-row pt-2 items-center">
                                <p className="font-semibold py-2 text-white">Add a Contract</p>
                                <p className="pt-2 pb-1 text-secondary">Name</p>
                                <input
                                    type="text"
                                    name="name"
                                    maxLength={45}
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    placeholder="Enter the name of your contract"
                                    onChange={handleInputChange}
                                    value={inputValues["name"] || ""}
                                />
                                {validationMessages["name"] && <ValidationMessage message={validationMessages["name"]} />}
                                <p className="pt-2 pb-1 text-secondary">Proxy Address</p>
                                <input
                                    type="text"
                                    name="proxy"
                                    maxLength={50}
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    placeholder="Same as implementation or enter the proxy address of your contract"
                                    onChange={handleInputChange}
                                    value={inputValues["proxy"] || ""}
                                />
                                {validationMessages["proxy"] && <ValidationMessage message={validationMessages["proxy"]} />}
                                <p className="pt-2 pb-1 text-secondary">
                                    Implementation Address
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="pl-2 cursor-pointer"
                                        onClick={() => {
                                            getProxy();
                                        }}
                                    />
                                </p>
                                <input
                                    type="text"
                                    name="implementation"
                                    maxLength={50}
                                    autoComplete="new-password"
                                    spellCheck="false"
                                    placeholder="Enter the implementation address of your contract"
                                    onChange={handleInputChange}
                                    value={inputValues["implementation"] || ""}
                                />
                                {validationMessages["implementation"] && <ValidationMessage message={validationMessages["implementation"]} />}
                            </div>
                            <div className="gap-4 pt-12">
                                <label
                                    className="btn-default"
                                    onClick={() => {
                                        addContract();
                                    }}>
                                    ADD
                                    <AddIcon className="pl-2" />
                                </label>
                                <label
                                    className="btn-default ml-4"
                                    onClick={() => {
                                        resetInputValues();
                                    }}>
                                    CLEAR
                                    <DeleteForeverIcon className="pl-2" />
                                </label>
                            </div>
                        </div>
                        <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                            <h3 className="font-bold text-lg text-white">List</h3>
                            <ul className="w-full">
                                {contractsList &&
                                    contractsList.map((contract) => {
                                        return (
                                            <li key={contract.implementation} className="py-2">
                                                <div className="flex flex-row items-center">
                                                    <p>
                                                        <button
                                                            className="btn-default"
                                                            onClick={() => {
                                                                removeContract(contract.name);
                                                            }}>
                                                            <DeleteOutlinedIcon />
                                                        </button>
                                                    </p>
                                                    <div className="pl-2">
                                                        <p>
                                                            <span className="text-secondary">{contract.name}</span>
                                                        </p>
                                                        <p className="">
                                                            Implementation: <span className="text-secondary">{contract.implementation}</span>
                                                        </p>
                                                        {contract.proxy && (
                                                            <p className="">
                                                                Proxy: <span className="text-secondary">{contract.proxy}</span>
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </>
                )}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default Contracts;
