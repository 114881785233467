import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import PopupCompilationResults from "./PopupCompilationResults";
import { Web3util } from "../../util/web3util";
import { notify } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrochip, faRotate, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import Accordion from "@components/shared/Accordion/Accordion";
import { CodeEditorUtil } from "@util/codeEditorUtil";

const CompilationPanel = React.forwardRef(
    (
        {
            sourceCode,
            dependencesSourceCode,
            contractsInfo,
            compilerVersion,
            compilerOptimization,
            compilerRuns,
            contractName,
            setLastCompilationInfo,
            lastCompilationInfo,
            activeTabLocalNetwork,
            setRootContract,
            setContractsSourceCodeEditorFlattened,
            activeTab,
            setIssuesHighlighting,
        },
        ref
    ) => {
        const { compileContract } = useContext(AppContext);
        const [numErrors, setNumErrors] = useState(0);
        const [numWarnings, setNumWarnings] = useState(0);
        const [compiling, setCompiling] = useState(false);
        const [autoCompile, setAutoCompile] = useState(true);
        const [compilationPanelWasOpen, setCompilationPanelWasOpen] = useState(false);
        //const [errorHighlightCodeDecoration, setErrorHighlightCodeDecoration] = useState([]);

        function onInputChange(event) {
            if (event.target.name === "autocompile") {
                setAutoCompile(event.target.checked);
            }
        }

        React.useImperativeHandle(ref, () => ({
            compile,
        }));

        async function compile(autoCompile_ = false) {
            try {
                if (autoCompile_ && !autoCompile) return;
                setNumErrors(-1);
                setNumWarnings(0);

                if (!sourceCode || (!contractsInfo && activeTabLocalNetwork === "network")) {
                    notify("Can't compile. Check the selected contract and source code in the editor.", "warn");
                    return;
                }

                let _contractNameFromSourceCode;
                if (activeTabLocalNetwork === "local") {
                    _contractNameFromSourceCode = Web3util.findContractName(sourceCode);
                    if (!_contractNameFromSourceCode) console.warn("Couldn't find the contract name in the source code provided.");
                }
                if (activeTabLocalNetwork === "local" && (!compilerRuns || (!contractName && !_contractNameFromSourceCode))) {
                    notify("Can't compile. Provide the contract name and check other contract information.", "warn");
                    return;
                }

                setCompiling(true);

                let _compilationResult;
                let _codeFlattened;
                if (activeTabLocalNetwork === "network") {
                    // Get the contract for the main contract (key contract)
                    //const _keyContract = sourceCode[0].code;
                    _codeFlattened = sourceCode[0].code; // - Why not look for the root/key contract? Review this

                    // Get all dependecies contracts code
                    if (sourceCode) {
                        let _contractList = sourceCode.filter((contract) => contract.type === "implementation");

                        console.debug("Compilation - Contract with dependencies (Implementation contracts only):", _contractList);

                        // ! BUG: PSTN - We need the entire path, not only the contract name

                        console.debug("Compiling this code - contract list", _contractList);
                        _codeFlattened = await Web3util.getImportsCode(_contractList);
                        console.debug("Compiling this code", _codeFlattened);
                        setContractsSourceCodeEditorFlattened(_codeFlattened);
                    }

                    _compilationResult = await compileContract(
                        "network",
                        contractsInfo.ContractName,
                        contractsInfo.CompilerVersion,
                        contractsInfo.OptimizationUsed,
                        contractsInfo.Runs,
                        _codeFlattened
                    );
                } else {
                    if (contractName) _contractNameFromSourceCode = contractName;
                    _compilationResult = await compileContract("local", _contractNameFromSourceCode, compilerVersion, compilerOptimization, compilerRuns, sourceCode, dependencesSourceCode);
                    setRootContract(_contractNameFromSourceCode);
                }

                // Code highlight on errors and warnings
                createErrorsMarkers(_compilationResult, _codeFlattened);

                // Fill setLastCompilationInfo with the compilation info
                setLastCompilationInfo(_compilationResult);
                setCompiling(false);
            } catch (error) {
                setCompiling(false);
                console.log("Error compiling contract", error);
                notify("Error compiling the contract. Please try again.", "error");
            }
        }

        function createErrorsMarkers(compilationResult_, codeFlattened_) {
            try {
                if (compilationResult_ && compilationResult_.output && compilationResult_.output.errors) {
                    const { numErrors, numWarnings } = Web3util.countErrorsAndWarnings(compilationResult_);
                    setNumErrors(numErrors);
                    setNumWarnings(numWarnings);
                    let _issuesHighlighting = [];
                    for (let index = 0; index < compilationResult_.output.errors.length; index++) {
                        // Compilation NOK
                        console.warn(JSON.stringify(compilationResult_.output.errors, null, 4));
                        const _error = compilationResult_.output.errors[index];
                        const errorSourceInfo = _error.sourceLocation;
                        const _sourcecodeStart = errorSourceInfo.start;
                        const _sourcecodeEnd = errorSourceInfo.end;
                        const _type = _error.severity;
                        const _curentFile = compilationResult_.output.errors[index].sourceLocation.file;
                        // console.log("compilation - _error", _error);
                        // console.log("compilation - errorSourceInfo", errorSourceInfo);
                        // console.log("compilation - _sourcecodeStart", _sourcecodeStart);
                        // console.log("compilation - _sourcecodeEnd", _sourcecodeEnd);
                        // console.log("compilation - _type", _type);
                        // Create a list of markers
                        // console.log("CompilationPanel - compilation - activeTab", activeTab);
                        // console.log("CompilationPanel - compilation - sourceLocation.file", compilationResult_.output.errors[index].sourceLocation.file);
                        // console.log("CompilationPanel - compilation - _codeFlattened.path[activeTab]", codeFlattened_[0].path[activeTab]);
                        const codeFlattenedIndex_ = codeFlattened_.findIndex((item) => item.path.includes(_curentFile));
                        // TODO
                        // if (codeFlattenedIndex_ !== -1) {
                        //     console.log(`Index of the object with path '${desiredPath}' is: ${index}`);
                        // } else {
                        //     console.log(`Object with path '${desiredPath}' not found in the array.`);
                        // }
                        // Create an array with all highlights and markers
                        const range = CodeEditorUtil.calculateLineColumn(codeFlattened_[codeFlattenedIndex_].code, _sourcecodeStart, _sourcecodeEnd);
                        // New highlight
                        let _linesDecorationsClassName;
                        let _className;
                        if (_type === "error") {
                            _linesDecorationsClassName = "errorCurrentCodeLine";
                            _className = "errorCurrentCode";
                        } else if (_type === "warning") {
                            _linesDecorationsClassName = "warningCurrentCodeLine";
                            _className = "warningCurrentCode";
                        }
                        const _range = new window.monaco.Range(range.startLine, range.startColumn, range.endLine, range.endColumn);
                        const _errorCurrentCodeDecoration = {
                            range: _range,
                            options: {
                                isWholeLine: false,
                                linesDecorationsClassName: _linesDecorationsClassName,
                                className: _className,
                            },
                        };
                        // New Marker
                        const _marker = {
                            startLineNumber: range.startLine,
                            endLineNumber: range.endLine,
                            startColumn: range.startColumn,
                            endColumn: range.endColumn,
                            message: _error.type + ": " + _error.message,
                            severity: _type === "error" ? monaco.MarkerSeverity.Error : monaco.MarkerSeverity.Warning,
                        };
                        // If there is a item with the same file in the array _issuesHighlighting, use it adding the new marker and highlight
                        let _fileIssuesList = _issuesHighlighting.filter((issue) => issue.file === compilationResult_.output.errors[index].sourceLocation.file);
                        if (_fileIssuesList.length === 0) {
                            _fileIssuesList = {
                                file: compilationResult_.output.errors[index].sourceLocation.file,
                                highlight: [_errorCurrentCodeDecoration],
                                marker: [_marker],
                            };
                            _issuesHighlighting.push(_fileIssuesList);
                        } else {
                            _fileIssuesList[0].highlight.push(_errorCurrentCodeDecoration);
                            _fileIssuesList[0].marker.push(_marker);
                        }
                    }
                    setIssuesHighlighting(_issuesHighlighting);
                    // CodeEditorUtil.highlightErrorCode(setErrorHighlightCodeDecoration, errorHighlightCodeDecoration, _highlightList);
                    // CodeEditorUtil.addErrorMarker(_markersList);
                    //}
                } else {
                    setNumErrors(0);
                    setNumWarnings(0);
                    setIssuesHighlighting(null);
                    // console.log("No errors or warnings - Cleaning highlights and markers 1", errorHighlightCodeDecoration);
                    // CodeEditorUtil.highlightErrorCode(setErrorHighlightCodeDecoration, errorHighlightCodeDecoration, []);
                    // CodeEditorUtil.addErrorMarker(null);
                }
            } catch (error) {
                console.log("Error creating issue markers", error);
                notify("Error creating issue markers. Please try again.", "error");
            }
        }

        function reviewErrorsWarnings() {
            let _contractName = "";
            if (activeTabLocalNetwork === "network") _contractName = contractsInfo.ContractName;
            if (activeTabLocalNetwork === "local") _contractName = contractName ?? Web3util.findContractName(sourceCode);

            // console.log("reviewErrorsWarnings numErrors", numErrors, "numWarnings", numWarnings, "_contractName", _contractName, "contractsInfo.ContractName", contractsInfo.ContractName);
            // _contractName = ""; // BUG! if you try to use this variable, after 3 compilations the application crash!

            const _return = (
                <>
                    {/* <span>{_contractName} </span> */}
                    <span> </span>
                    {numErrors === -1 ? (
                        <span></span>
                    ) : numErrors > 0 ? (
                        <span id="CompilationPanelLastCompilationInfoError" name="CompilationPanelLastCompilationInfoError" className="text-red">
                            Error
                        </span>
                    ) : numWarnings > 0 ? (
                        <span id="CompilationPanelLastCompilationInfoWarning" name="CompilationPanelLastCompilationInfoWarning" className="text-yellow">
                            Warning
                        </span>
                    ) : numErrors === 0 && numWarnings === 0 ? (
                        <span id="CompilationPanelLastCompilationInfoOk" name="CompilationPanelLastCompilationInfoOk" className="text-green">
                            Ok
                        </span>
                    ) : (
                        <span id="CompilationPanelLastCompilationInfoNA" name="CompilationPanelLastCompilationInfoNA">
                            N/A
                        </span>
                    )}
                </>
            );

            return _return;
        }

        useEffect(() => {
            if (compiling) setCompilationPanelWasOpen(true);
        }, [compiling]);

        return (
            <div className="py-1">
                <Accordion
                    id="CompilationPanelAccordion"
                    name="CompilationPanelAccordion"
                    title={"Compilation"}
                    opened={compiling || compilationPanelWasOpen}
                    items={[
                        <div className="flex items-center">
                            <input id="autocompile" name="autocompile" type="checkbox" checked={autoCompile} className="checkbox checkbox-xs px-0" onChange={onInputChange} />
                            <span className="pl-2">Autocompile</span>
                        </div>,
                        compiling ? (
                            <p className="text-secondary font-semibold text-md">
                                <FontAwesomeIcon icon={faTriangleExclamation} className="text-secondary pr-2" fade />
                                Compiling contract...
                                <FontAwesomeIcon icon={faRotate} spin={compiling} className="text-secondary ml-2" />
                            </p>
                        ) : null,
                        <div className="text-light font-normal">
                            <p>
                                <span className="text-secondary">Last Compilation: </span>
                                <span
                                    id="CompilationPanelLastCompilationInfoPopupButton"
                                    name="CompilationPanelLastCompilationInfoPopupButton"
                                    data-info="CompilationPanelLastCompilationInfoPopupButton"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        window.popupCompilationResults.showModal();
                                        if (lastCompilationInfo) console.log(JSON.stringify(lastCompilationInfo, null, 2));
                                    }}>
                                    {!lastCompilationInfo ? (
                                        <span id="CompilationPanelLastCompilationInfoNAButton" name="CompilationPanelLastCompilationInfoNAButton">
                                            N/A
                                        </span>
                                    ) : (
                                        reviewErrorsWarnings()
                                    )}
                                    <FontAwesomeIcon
                                        id="CompilationPanelLastCompilationInfoPopupIconButton"
                                        name="CompilationPanelLastCompilationInfoPopupIconButton"
                                        icon={faSearchengin}
                                        className="text-secondary ml-2"
                                    />
                                </span>
                            </p>
                        </div>,
                        <div className="flex items-center justify-center mt-4">
                            <button
                                id="CompilationPanelCompileButton"
                                name="CompilationPanelCompileButton"
                                className="btn btn-default"
                                onClick={() => {
                                    compile();
                                }}>
                                Compile
                                <FontAwesomeIcon id="CompilationPanelCompileButtonIcon" name="CompilationPanelCompileButtonIcon" icon={faMicrochip} className="pl-3" />
                            </button>
                        </div>,
                    ]}
                />
                <PopupCompilationResults lastCompilationInfo={lastCompilationInfo} />
            </div>
        );
    }
);

export default CompilationPanel;
