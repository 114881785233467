import { useEffect, useState } from "react";
import { getCookieValue, setCookieValue } from "@util/util";
import { HistoryCookieKey } from "@util/enum";
import PopupHistorySearchHistory from "./PopupHistorySearchHistory";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faCode, faCopy, faCube, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const PopupHistory = () => {
    const [currentHistory, setCurrentHistory] = useState();

    // function addCookieTest() {
    //     console.log("PopupHistory HistoryCookieKey", HistoryCookieKey);
    //     const _cookie = getCookieValue(HistoryCookieKey);
    //     console.log("PopupHistory _cookie", _cookie);

    //     const _newValue = [
    //         {
    //             network: 80001,
    //             from: "0x0420e01ee4e177a8aa088406c91b6841e3d5bec1",
    //             address: "0x6827fD095f34874a664d89895EE98facd317F191",
    //             transaction: "0x1332f56b5d66497a02627c2f16f8cc24aa385051aa7afab35c53d6a2a5590bbb",
    //             status: true,
    //             date: "2023-09-18T01:21:01.702Z",
    //         },
    //         {
    //             network: 80001,
    //             from: "0x0420e01ee4e177a8aa088406c91b6841e3d5bec2",
    //             address: "0x6827fD095f34874a664d89895EE98facd317F192",
    //             transaction: "0x1332f56b5d66497a02627c2f16f8cc24aa385051aa7afab35c53d6a2a5590bbb",
    //             status: true,
    //             date: "2023-09-18T01:21:02.702Z",
    //         },
    //         {
    //             network: 80001,
    //             from: "0x0420e01ee4e177a8aa088406c91b6841e3d5bec3",
    //             address: "0x6827fD095f34874a664d89895EE98facd317F193",
    //             transaction: "0x1332f56b5d66497a02627c2f16f8cc24aa385051aa7afab35c53d6a2a5590bbb",
    //             status: true,
    //             date: "2023-09-18T01:22:02.702Z",
    //         },
    //     ];

    //     setCookieValue(HistoryCookieKey, _newValue);

    //     const _cookie2 = getCookieValue(HistoryCookieKey);
    //     console.log("PopupHistory _cookie2", _cookie2);
    // }

    return (
        <dialog id="popupHistory" className="modal rounded-lg bg-transparent">
            <form method="dialog" className="modal-box text-light min-w-[730px] min-h-[650px]">
                <h3 className="font-bold text-lg text-white">Deployment History</h3>
                <div className="pt-4">
                    <PopupHistorySearchHistory currentHistory={currentHistory} setCurrentHistory={setCurrentHistory} />
                </div>
                <div className="modal-action flex justify-end pt-6">
                    {/* <button
                        className="btn-default mt-2 justify-end"
                        onClick={() => {
                            addCookieTest();
                        }}>
                        ADD COOKIE
                        <CloseOutlined className="pl-2 text-red" />
                    </button> */}
                    <button
                        id="PopupHistoryOpenContractButton"
                        name="PopupHistoryOpenContractButton"
                        className="btn-default justify-end"
                        onClick={() => {
                            window.open(window.location.origin + "/editor?network=" + currentHistory.network + "&contract=" + currentHistory.address, "_blank");
                        }}>
                        open this contract
                        <FontAwesomeIcon icon={faCode} className="pl-2" />
                    </button>
                    <button id="PopupHistoryCloseButton" name="PopupHistoryCloseButton" className="btn-default ml-6 justify-end">
                        Close
                        <CloseOutlined className="pl-2 text-red" />
                    </button>
                </div>
            </form>
        </dialog>
    );
};

export default PopupHistory;
