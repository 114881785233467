import { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { dateDefaultFormat, extractJSON } from "@util/util";
import { ModalTheme } from "@util/enum";
import { FunctionGasType } from "@util/enum";
import ReactJson from "react-json-view";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import Tabs from "@components/tabs/Tabs";

const ResultView = ({ currentFunction, lastExecution, network }) => {
    const { getFunctionGasType } = useContext(AppContext);
    const [changed, setChanged] = useState(false);
    const [functionType, setFunctionType] = useState();

    console.log("ResultView lastExecution", lastExecution);
    console.log("ResultView currentFunction", currentFunction);
    // TODO: We need
    // function name // currentFunction.name
    // network name // props
    // inputs // currentFunction.inputs
    // receiver // contract.implementation
    // sender ? // only if you have a wallet
    //

    useEffect(() => {
        setChanged(true);
    }, [currentFunction]);

    useEffect(() => {
        setChanged(false);
        if (lastExecution) {
            const _functionGasType = getFunctionGasType(lastExecution.function.stateMutability);
            if (_functionGasType === FunctionGasType.NoGasFee) setFunctionType("View or Pure (No Gas Used)");
            if (_functionGasType === FunctionGasType.PayGasFee) setFunctionType("Payable or Non-Payable");
        }
    }, [lastExecution]);

    // console.log("ResultView", lastExecution);

    const tabsData = [
        {
            title: (
                <span>
                    Result
                    {!lastExecution.error && <FontAwesomeIcon icon={faCheck} className="pl-1 text-green" />}
                    {lastExecution.error && <FontAwesomeIcon icon={faXmark} className="pl-1 text-red" />}
                </span>
            ),
            content: (
                <div>
                    {!lastExecution.result && (
                        <p className="text-secondary">
                            Result:<span className="text-light pl-2">N/A</span>
                        </p>
                    )}
                    {lastExecution.result && typeof lastExecution.result != "object" && (
                        <p className="text-secondary">
                            Result:<span className="text-light pl-2">{lastExecution.result.toString()}</span>
                            <FontAwesomeIcon
                                id="ResultViewResultCopyButtonIcon"
                                name="ResultViewResultCopyButtonIcon"
                                icon={faCopy}
                                onClick={() => {
                                    navigator.clipboard.writeText(lastExecution.result.toString());
                                }}
                                className="ml-2 text-secondary cursor-pointer"
                            />
                        </p>
                    )}
                    {lastExecution.result && typeof lastExecution.result === "object" && (
                        <div>
                            <p className="text-secondary">
                                Result
                                <FontAwesomeIcon
                                    id="ResultViewResultCopyButtonIcon"
                                    name="ResultViewResultCopyButtonIcon"
                                    icon={faCopy}
                                    onClick={() => {
                                        navigator.clipboard.writeText(lastExecution.result);
                                    }}
                                    className="ml-2 text-secondary cursor-pointer"
                                />
                            </p>
                            <div className="ReactJson-container">
                                <ReactJson src={lastExecution.result} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                            </div>
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: "Detail",
            content: (
                <div>
                    {/* <p className="text-secondary">
                        Network:<span className="text-light pl-2">{network.name} ({network.id})</span>
                    </p>
                    <p className="text-secondary">
                        Function:<span className="text-light pl-2">{lastExecution.function.name}</span>
                    </p> */}
                    <p className="text-secondary">
                        Function Type:<span className="text-light pl-2">{functionType}</span>
                    </p>
                    {/* <p className="text-secondary">
                        Timestamp:<span className="text-light pl-2">{dateDefaultFormat(lastExecution.timestamp)}</span>
                    </p> */}
                    {/* <p className="text-secondary">
                        Receiver:<span className="text-light pl-2">{lastExecution.contract.implementation}</span>
                    </p> */}
                </div>
            ),
        },
        {
            title: "Events/Logs",
            content: (
                <div className="text-light">
                    {!lastExecution.events && (
                        <p className="text-secondary">
                            Events/Logs:<span className="pl-2 text-light">none</span>
                        </p>
                    )}
                    {lastExecution.events && (
                        <div className="ReactJson-container">
                            <ReactJson src={lastExecution.events} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: "Inputs",
            content: (
                <div className="text-light">
                    {Object.keys(currentFunction.inputs).length === 0 && (
                        <p className="text-secondary">
                            Input <span className="pl-2 text-light">none</span>
                        </p>
                    )}
                    {lastExecution.function.parameters && lastExecution.function.parameters.length > 0 && (
                        <div className="py-1 ReactJson-container">
                            {/* <p>{lastExecution.input.parameters}</p> */}
                            <ReactJson src={lastExecution.function.parameters} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: "Errors",
            content: (
                <div className="text-light">
                    {!lastExecution.error && (
                        <p className="text-secondary">
                            Error:<span className="pl-2 text-light">none</span>
                        </p>
                    )}
                    {lastExecution.error && typeof lastExecution.error === "object" && (
                        <div>
                            <div className="text-secondary">
                                Error:
                                <span className="text-light pl-2">{extractJSON(lastExecution.error.error).errorMessageBefore}</span>
                                <div className="ReactJson-container">
                                    <ReactJson src={extractJSON(lastExecution.error.error).json} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                                <span>{extractJSON(lastExecution.error.error).errorMessageAfter}</span>
                            </div>
                            <div className="pt-4">
                                <span className="text-secondary">StackTrace</span>
                                <span className="text-light pl-2">{extractJSON(lastExecution.error.stackTrace).errorMessageBefore}</span>
                                <div className="ReactJson-container">
                                    <ReactJson src={extractJSON(lastExecution.error.stackTrace).json} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                                <p className="text-light pl-2">{extractJSON(lastExecution.error.stackTrace).errorMessageAfter}</p>
                            </div>
                        </div>
                    )}
                    {lastExecution.error && typeof lastExecution.error != "object" && (
                        <div className="text-secondary">
                            Error:<span className="pl-2 text-light">{lastExecution.error}</span>
                        </div>
                    )}
                </div>

                // <div className="text-light">
                //     {!lastExecution.error && (
                //         <p className="text-secondary">
                //             Errors:<span className="pl-2 text-light">none</span>
                //         </p>
                //     )}
                //     {lastExecution.error && <ReactJson src={lastExecution.error} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />}
                // </div>
            ),
        },
        {
            title: "Output (JSON Data)",
            content: (
                <div>
                    <p className="text-secondary">
                        Output JSON
                        <ContentCopyOutlinedIcon
                            id="ResultViewOutputJSONCopyButtonIcon"
                            name="ResultViewOutputJSONCopyButtonIcon"
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(lastExecution, null, 4));
                            }}
                            className="pl-2 cursor-pointer max-h-4 min-h-4"
                        />
                    </p>
                    <div className="py-1 ReactJson-container">
                        <ReactJson src={lastExecution} iconStyle="triangle" collapsed={1} displayDataTypes={false} theme={ModalTheme} />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            {!changed && (
                <div>
                    <Tabs tabsData={tabsData} />
                </div>
            )}
        </>
    );
};

export default ResultView;
