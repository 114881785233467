import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const PopupContractInformation = ({ contractName, setContractName, compilerVersion, setCompilerVersion, compilerOptimization, setCompilerOptimization, compilerRuns, setCompilerRuns }) => {
    const { loadCompilerVersions } = useContext(AppContext);
    const [versionsList, setVersionsList] = useState();
    const [inputValues, setInputValues] = useState({});

    useEffect(() => {
        loadCompilerVersionsList();
    }, []);

    async function loadCompilerVersionsList() {
        setVersionsList(await loadCompilerVersions());
    }

    function handleInputChange(event) {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        if (event.target.name === "contractName") {
            setContractName(event.target.value);
        }
        if (event.target.name === "runs") {
            setCompilerRuns(event.target.value);
        }
    }

    function handleRadioChange(event) {
        // console.log("handleRadioChange event", event);
        // console.log("handleRadioChange event.target.value", event.target.value);
        // console.log("handleRadioChange event.target.value typeof", typeof event.target.value);
        // // setCompilerOptimization(event.target.value === "on");
        // console.log("handleRadioChange compilerOptimization", compilerOptimization);
        // if (event.target.value === "on") setCompilerOptimization(true);

        const value = event.target.value === "true" ? true : false;
        setCompilerOptimization(value);
    }

    return (
        <>
            <input type="checkbox" id="popupContractInformation" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box min-w-fit max-h-[85%]">
                    <h3 className="font-bold text-lg text-white">Contract Info</h3>
                    <div className="pt-4">
                        <p className="text-secondary">Contract Name</p>
                        <p>
                            <input type="text" name="contractName" placeholder="" maxLength={80} autoComplete="new-password" value={contractName || ""} onChange={handleInputChange} />
                        </p>
                        <p className="pt-2 text-secondary">Compiler Version</p>
                        <ul id="results" className="compiler-versions-container">
                            {versionsList &&
                                versionsList.map((item, index) => (
                                    <li id="PopupContractInformationCompilerVersionListItem" data-indo={item.version} className="" key={item.id}>
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                setCompilerVersion(item.version);
                                            }}
                                            className="ul-item w-full">
                                            {item.version}
                                        </button>
                                    </li>
                                ))}
                        </ul>
                        <p className="text-secondary pt-1">
                            Compiler Version: <span className="text-light">{compilerVersion}</span>
                        </p>
                        <div className="flex items-center h-10 text-secondary pt-1">
                            <span className="pr-4">Optimization: </span>
                            <input
                                type="radio"
                                id="radioOptimization"
                                name="radioOptimization"
                                className="radio pl-1"
                                checked={compilerOptimization === true}
                                onChange={handleRadioChange}
                                value="true"
                            />
                            <span className="pl-2 text-light">Yes</span>
                            <input
                                id="radioOptimization"
                                type="radio"
                                name="radioOptimization"
                                className="radio pl-1 ml-5"
                                checked={compilerOptimization === false}
                                onChange={handleRadioChange}
                                value="false"
                            />
                            <span className="pl-2 text-light">No</span>
                        </div>
                        <p className="w-32 text-secondary">Runs</p>
                        <p>
                            <input
                                type="text"
                                name="runs"
                                placeholder="Number of executions for Optimization"
                                maxLength={4}
                                autoComplete="new-password"
                                value={compilerRuns || "200"}
                                onChange={handleInputChange}
                            />
                        </p>
                        {/* <div className="pt-2">
                            <p className="text-secondary">Constructor Parameters:</p>
                            <input type="text" name="contructorParamaters" placeholder="" value="" disabled />
                        </div> */}
                    </div>
                    <div className="flex justify-end pt-4">
                        <label id="PopupContractInformationCloseButton" name="PopupContractInformationCloseButton" htmlFor="popupContractInformation" className="btn-default mt-2 justify-end">
                            Close
                            <CloseOutlined className="pl-2 text-red" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopupContractInformation;
