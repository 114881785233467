const Todo = () => {
    return (
        <>
            <div className="overflow-x-auto pt-4">
                <table className="table table-zebra table-compact w-full">
                    <thead>
                        <tr className="hover">
                            <th className="td-center">TO DO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="hover h-3">
                            <td className="td-center">Install and test npm i react-table. We need move rows, order, search, etc</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Settings pane (network selection and set url for the RPCs)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Wallet balance</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">More Info about the contract (size, etc)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">More Info about transactions (gas used, etc)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Save info and changes like "pinned rows", methods more called - can be a history session and these info saved</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Create a switch (normal or focused) list of most unused methods like upgradeToAndCall, upgradeTo, transferOwnership, proxiableUUID, renounceOwnership, initialize
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Add option to create deploy script in hardhat and other tools</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Add <a href="https://storyset.com/people">People illustrations by Storyset</a> in the illustration
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">button to see the code of the function. Get the code using the etherscan API published</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Debug code of a function??? error in the exact point of failure???</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Use localstorage to save the configuration, input fields. See crypto-js library and SHA-512 to store the data.</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Hide and show columns (easy to implment with state)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Display or not the type of parameter input (checkbox on the top of the column)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Input parameter type validation</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">a button in the code modal to copy the code to the clipboard</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Use Tenderly as the baseline for UX. Focus on different areas: development, testing, analytics (future), alerts (future), collaborators, settings, networks, address,
                                dashboard (future)<br></br>
                                Contracts: View Transactions, View Contract ABI, Simulate Transaction, Rename Contract, Hide Contract, Remove Contract<br></br>
                                Simulate Transaction: Select Contract, Select Function (name, inputs and outputs) - list of parameters with values, Parameters (not inputs - like gas, wallet, etc)
                                <br></br>
                                Approach is select contract, select function and then add the paramters.<br></br>
                                Advantage: Create a script to execute more than one transaction in different contracts with different wallets (testing approach)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Checkbox when performing the execution queue to wait for the next execution or execute all (async). Anothe option is to request to the user add an "wait" between the
                                commands (or add it automatically). Be able to add actions like: check for a wallet balance, allownace, etc.<br></br>
                                Study further options to create/generate a deploy + test script (like we have for DEX).
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Todo;
