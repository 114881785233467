import { useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { Web3util } from "@util/web3util";
import { useNavigate } from "react-router-dom";
// import { saveDataStorage, getDataStorage } from "@util/util";
// import { LocalStorage } from "@util/enum";
import ValidationMessage from "@components/shared/ValidationMessage";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

/*
How to use: Add this code in the form that you want call this modal
Most important is the htmlFor="manageWallet" prop.
<label htmlFor="manageWallet" className="btn bg-transparent border-transparent m-0 p-0 hover:bg-transparent hover:border-transparent">
    <AddIcon className="ml-2 text-secondary cursor-pointer" htmlFor="manageWallet" />
</label>
*/
function ManageWallet() {
    // Get the current wallets in the appcontext
    const { wallets, getWalletNames, setWallets, syncUserData } = useContext(AppContext);

    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});

    const navigate = useNavigate();

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    const handleInputChange = async (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        if (event.target.name == "pk") {
            // Validate PK
            console.log("Validating as a PK", event.target.value)
            const account = await Web3util.validatePK(event.target.value);
            console.log(account);

            // Get the address and fill the address field
            if (account) {
                updateValidationMessages(event, null);
                setInputValues((prevValues) => {
                    return {
                        ...prevValues,
                        ["address"]: account.address,
                    };
                });
            } else {
                updateValidationMessages(event, "Private Key is invalid");
            }
        }

        // Name - At least 3 characters and check if the name already exists
        if (event.target.name == "name") {
            if (event.target.value.length < 3) {
                updateValidationMessages(event, "Wallet name should have at least 3 characters");
                return;
            }
            let _exists = false;
            wallets.forEach((wallet) => {
                if (wallet.name.toLowerCase() === event.target.value.toLowerCase()) {
                    updateValidationMessages(event, "A Wallet with this name already exists");
                    _exists = true;
                    return;
                }
            });
            if (!_exists) updateValidationMessages(event, null);
        }

        // if (event.target.name == "address") {
        //     if (!Web3util.isAddress(event.target.value) && event.target.value) {
        //         updateValidationMessages(event, "Address is invalid");
        //     } else {
        //         updateValidationMessages(event, null);
        //     }
        // }
    };

    const addWallet = () => {
        if (!checkValidationMessagesIsEmpty()) return;
        let _walletsList = structuredClone(wallets);
        const newWallet = {
            name: inputValues["name"],
            address: inputValues["address"],
            pk: inputValues["pk"],
        };
        _walletsList.push(newWallet);
        //saveDataStorage(LocalStorage.WalletList, _walletsList);
        syncUserData("wallets", _walletsList);
        setWallets(_walletsList);

        resetInputValues();
    };

    const checkValidationMessagesIsEmpty = () => {
        let _isEmpty = true;
        Object.entries(validationMessages).forEach((_message) => {
            const [key, value] = _message;
            if (value != null) _isEmpty = false;
        });
        return _isEmpty;
    };

    const resetInputValues = () => {
        setInputValues({
            name: "",
            proxy: "",
            implementation: "",
        });
        setValidationMessages({});
    };

    const removeWallet = (name) => {
        let _walletsList = structuredClone(wallets);
        _walletsList = _walletsList.filter((wallet) => wallet.name !== name);
        //saveDataStorage(LocalStorage.WalletList, _walletsList);
        syncUserData("wallets", _walletsList);
        setWallets(_walletsList);
    };

    return (
        <>
            <input type="checkbox" id="manageWallet" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box min-w-fit">
                    <h3 className="font-bold text-lg text-white">Wallets</h3>
                    {/* <p className="py-4 text-white">Available Wallets</p>
                    {wallets && wallets.map((wallet) => {
                        return (
                            <div key={wallet.name}>
                                <p className="flex text-secondary items-center h-8">
                                    <LockOutlinedIcon className="max-h-4 mr-2" />
                                    {wallet.name}
                                    <DeleteOutlinedIcon
                                        className="ml-2 max-h-5 text-secondary cursor-pointer"
                                        onClick={() => {
                                            removeWallet(wallet.name);
                                        }}
                                    />
                                </p>
                                <pre className="pt-1 px-2 text-sm bg-black text-light">{JSON.stringify(wallet, null, "\t")}</pre>
                            </div>
                        );
                    })} */}
                    <div className="flex-row pt-4 items-center">
                        <p className="font-semibold py-2 text-white">Add a Wallet</p>
                        <p className="pt-2 pb-1 text-secondary">Name</p>
                        <input type="text" name="name" maxLength={45} autoComplete="new-password" spellCheck="false" onChange={handleInputChange} value={inputValues["name"] || ""} />
                        {validationMessages["name"] && <ValidationMessage message={validationMessages["name"]} />}
                        <p className="pt-2 pb-1 text-secondary">Address</p>
                        <input type="text" name="address" maxLength={50} autoComplete="new-password" spellCheck="false" onChange={handleInputChange} value={inputValues["address"] || ""} disabled />
                        {validationMessages["address"] && <ValidationMessage message={validationMessages["address"]} />}
                        <p className="pt-2 pb-1 text-secondary">Private Key</p>
                        <input type="text" name="pk" maxLength={66} autoComplete="new-password" spellCheck="false" onChange={handleInputChange} value={inputValues["pk"] || ""} />
                        {validationMessages["pk"] && <ValidationMessage message={validationMessages["pk"]} />}
                    </div>
                    <div className="modal-action gap-4 pt-12">
                        <label
                            id="ExecuteWalletManageWallet"
                            name="ExecuteWalletManageWallet"
                            htmlFor="manageWallet"
                            className="btn-default"
                            onClick={() => {
                                navigate("/wallets");
                            }}>
                            GENERATE NEW WALLET
                            <AddIcon className="pl-2" />
                        </label>
                        <label
                            id="ExecuteWalletManageWalletAdd"
                            name="ExecuteWalletManageWalletAdd"
                            className="btn-default"
                            onClick={() => {
                                addWallet();
                            }}>
                            ADD MORE
                            <AddTaskOutlinedIcon className="pl-2" />
                        </label>
                        <label
                            id="ExecuteWalletManageWalletClose"
                            name="ExecuteWalletManageWalletClose"
                            htmlFor="manageWallet"
                            className="btn-default"
                            onClick={() => {
                                addWallet();
                            }}>
                            ADD
                            <AddIcon className="pl-2" />
                        </label>
                        <label id="ExecuteWalletManageWalletClose" name="ExecuteWalletManageWalletClose" htmlFor="manageWallet" className="btn-default">
                            CLOSE
                            <CloseOutlined className="pl-2" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageWallet;
