import { useEffect, useRef, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { getImageUrl } from "../../../util/util";

function SearchbarDropdown(props) {
    const { setCurrentWallet, walletNames, getWallet, currentWalletInput, setCurrentWalletInput, clearExecuteForm } = useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions, inputRef } = props;
    const ulRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });
        inputRef.current.addEventListener("focusout", (event) => {
            if (inputRef.current && !inputRef.current.value) {
                setCurrentWallet();
            }
        });
    }, [inputRef]);

    return (
        <div className="dropdown dropdown-bottom w-full z-10">
            <input type="text" placeholder={placeholder} ref={inputRef} onChange={onInputChange} autoComplete="new-password" spellCheck="false" value={currentWalletInput} />
            <ul id="results" className="ul-main" ref={ulRef}>
                {options &&
                    options.map((option, index) => {
                        return (
                            <button
                                id="ExecuteWalletSearchbarDropdownItem"
                                name="ExecuteWalletSearchbarDropdownItem"
                                type="button"
                                key={index}
                                onClick={(e) => {
                                    clearExecuteForm("wallet");
                                    setCurrentWalletInput(option);
                                    ulRef.current.style.display = "none";
                                    // inputRef.current.value = option;
                                    setCurrentWallet(getWallet(option));
                                    setOptions(walletNames.filter((_option) => _option.toLocaleLowerCase().includes(option.toLocaleLowerCase())));
                                    console.debug("Current wallet set to:", option);
                                }}
                                className="ul-item">
                                {option}
                            </button>
                        );
                    })}
            </ul>
        </div>
    );
}

export default SearchbarDropdown;
