// export const Logo = ({ size }) => (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width={size} height={size}>
//         <rect width="400" height="400" fill="#FFF" />
//         <rect x="50" y="50" width="300" height="300" fill="#000" />
//         <rect x="75" y="75" width="100" height="100" fill="#FFF" />
//         <rect x="225" y="75" width="100" height="100" fill="#FFF" />
//         <rect x="225" y="225" width="100" height="100" fill="#FFF" />
//     </svg>
// );

// export const Logo = ({ size, backgroundColor, foregroundColor }) => (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width={size} height={size}>
//         <rect width="400" height="400" fill={foregroundColor} />
//         <rect x="50" y="50" width="300" height="300" fill={backgroundColor} />
//         <rect x="75" y="75" width="100" height="100" fill={foregroundColor} />
//         <rect x="225" y="75" width="100" height="100" fill={foregroundColor} />
//         <rect x="225" y="225" width="100" height="100" fill={foregroundColor} />
//     </svg>
// );

export const LogoTop = ({ size, backgroundColor, foregroundColor }) => (
    <div className="flex flex-row items-center h-9 pl-2">
        {<Logo id="LogoTop" name="LogoTop" size={size} backgroundColor={backgroundColor} foregroundColor={foregroundColor} />}
        <span className="text-2xl font-bold pl-3 text-white">DevWeb3</span>
        <div className="flex items-end h-full">
            <span className="pl-1">beta</span>
        </div>
    </div>
);

export const LogoMin = ({ size, backgroundColor, foregroundColor }) => <Logo id="LogoMin" name="LogoMin" size={size} backgroundColor={backgroundColor} foregroundColor={foregroundColor} />;

export const LogoBottom = ({ size, backgroundColor, foregroundColor }) => (
    <div className="flex flex-row items-center h-9 pl-2">
        {<Logo id="LogoBottom" name="LogoBottom" size={size} backgroundColor={backgroundColor} foregroundColor={foregroundColor} />}
        <span className="md:text-3xl text-xl font-bold pl-3 text-white">DevWeb3</span>
        {/* <div className="flex items-end h-full">
            <span className="pl-1">beta</span>
        </div> */}
    </div>
);

export const Logo = ({ size, backgroundColor, foregroundColor }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width={size} height={size}>
        <rect width="400" height="400" fill={foregroundColor} />
        <rect x="50" y="50" width="300" height="300" fill={backgroundColor} />
        <rect x="75" y="75" width="100" height="100" fill={foregroundColor} />
        <rect x="225" y="75" width="100" height="100" fill={foregroundColor} />
        <rect x="225" y="225" width="100" height="100" fill={foregroundColor} />
    </svg>
);

export const LogoResponsive = ({ backgroundColor, foregroundColor }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" style={{ width: "100%", height: "auto" }}>
        <rect width="100%" height="100%" fill={foregroundColor} />
        <rect x="10%" y="10%" width="80%" height="80%" fill={backgroundColor} />
        <rect x="15%" y="15%" width="30%" height="30%" fill={foregroundColor} />
        <rect x="55%" y="15%" width="30%" height="30%" fill={foregroundColor} />
        <rect x="55%" y="55%" width="30%" height="30%" fill={foregroundColor} />
    </svg>
);

export const LogoResponsiveFloat = ({ backgroundColor, foregroundColor }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" style={{ width: "100%", height: "auto" }}>
        <rect width="100%" height="100%" fill={foregroundColor} />
        <rect x="10%" y="10%" width="80%" height="80%" fill={backgroundColor} />
        <rect className="floatingBlock1" x="15%" y="15%" width="30%" height="30%" fill={foregroundColor} />
        <rect className="floatingBlock2" x="55%" y="15%" width="30%" height="30%" fill={foregroundColor} />
        <rect className="floatingBlock3" x="55%" y="55%" width="30%" height="30%" fill={foregroundColor} />
        {/* <line x1="20%" y1="30%" x2="80%" y2="30%" stroke="#000" stroke-width="10" class="movingLine" />
        <line x1="70%" y1="20%" x2="70%" y2="80%" stroke="#000" stroke-width="10" class="movingLineVertical" /> */}
    </svg>
);
