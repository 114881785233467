const Todo = () => {
    return (
        <>
            <div className="overflow-x-auto pt-4 text-white">
                <table className="table table-zebra table-compact w-full">
                    <thead>
                        <tr className="hover">
                            <th className="td-center">TO DO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="hover h-3">
                            <td className="td-center">NAMES: devweb3.co ESSE! -- BlockVerify.com, web3testlab.com, ethertestlab.com, Web3TestKit.com, web3tester.com, w3testlab.com, soliditytestlab.com</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Use the color pallete theme from VS Studio</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Logo: https://illustroke.com/?ref=futuretools.io</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Execute Screen - Is it good to have an accordion to hide/show mode information about each section? like Network - show all details or not? Is it useful?
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Execute Screen - Display address inside a popup like "Name (0x...0f4e)". Maybe for wallet make more sense. Check if search will work after that
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Contracts - In the future, code icon will show a popup with the code not open a new window using etherscan</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Wallet - Add a message about using a pk (ONLY FOR TESTING), PK field is hidden but not in the popup (what to do?)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Wallet - When you use a PK, grab the address automaticaly (POPUP)</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Wallet - Show the coin balance of the wallet like: 3.455 Matic using this icon InfoOutlinedIcon</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Function - Change the input to a list directly listing the methods</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Function - List functions in this format buy (uint amount, address token) - check search</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Function - Create a checkbox to hide "system" functions as initiate, upgradeTo, transferOwnership, etc</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Execute Screen - Function - Use icons to indicate the type of the funtion view/pure or nonpayable/payable (use gas fee to be executed). We can use a $ before the name
                                to indicate that (MoneyOffCsredOutlinedIcon)
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Execute Screen - Function - TODO: FUNCTION SELECTED format should be: buy (uint amount, address token) use getFunctionSignature(currentFunction)
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Results - Think about how or what to show more info like, function type</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Results - IDEA - Add log info from results (BSC scan - tab events or log) in the test pad results</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Execute Screen - Execute Button - Execute all functions type view/pure that doesn't require a input and show the results in the combo when the user is selecting the
                                functions like buy (uint amount) 0xfaf329e45a51A969e1073A0f67f9016e0710d282 -=result
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Execute Screen - Execute Button - Open a popup to show what is happening. Tx generated, waiting for the confirmation, etc...</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Idea - Execute Screen - Create a Historic session with all executions. Save all the data to be possible to the user click in a item and execute the combination again
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">Idea - ALERTS - Create alerts, when a function of a contract is equal to X send an email</td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Account Sync - create a new wallet, show the pk and ask to the user to save it. Offer an email as a plan b. Think about using a email passwordledd solution, and how to
                                not ask during x days the email again.
                            </td>
                        </tr>
                        <tr className="hover h-3">
                            <td className="td-center">
                                Execute - Contract not verified - Add functions manually or import ABI - e.g. execute methods on Juji contract - partially implement but not ok, should create an option
                                with a new input and adapt the flow. Review how to call a contract without abi - not possible, offer an option to create the abi on the fly{" "}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Todo;
