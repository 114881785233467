import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExclamation, faFaceSmile, faLocationDot, faTriangleExclamation, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Web3util } from "@util/web3util";
import ValidationMessage from "@components/shared/ValidationMessage";
import { getImageUrlWithExt } from "@util/util";
import Accordion from "@components/shared/Accordion/Accordion";
import { Helmet } from "react-helmet";

const BalanceChecker = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let _walletParam;
    if (searchParams && searchParams.has("wallet")) _walletParam = searchParams.get("wallet");

    const { getWalletBalance, getTokenBalances, networks } = useContext(AppContext);
    const [validationMessages, setValidationMessages] = useState();
    const [wallet, setWallet] = useState();
    const [balances, setBalances] = useState();
    const [tokens, setTokens] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        if (_walletParam && networks) {
            setWallet(_walletParam);
            //getBalances(_walletParam);
        }
    }, [networks]);

    const handleInputChange = (event) => {
        const _wallet = event.target.value;
        if (_wallet) {
            let _valid;
            try {
                _valid = Web3util.isAddress(_wallet);
            } catch (error) {}
            console.log("valid", _valid);
            if (!_valid) {
                setValidationMessages("Wallet Address is invalid");
            } else {
                setValidationMessages(null);
            }
        }
        setWallet(event.target.value);
    };

    async function getBalances(wallet_) {
        try {
            setLoading(true);
            if (wallet || wallet_) {
                let _walletAddress = wallet || wallet_;
                let _wallet = {};
                _wallet.address = _walletAddress;
                const balancePromises = networks.map(async (network) => {
                    const _balance = await getWalletBalance(_wallet, network.id);
                    console.log("network + balance", network, _balance);
                    return { network: network.name, coin: network.coin, balance: _balance, logo: network.logo, networkId: network.id, networkblockExplorerURL: network.blockExplorerURL };
                });
                const balances = await Promise.all(balancePromises);

                // Loop through the balances and add the token balances
                const tokenBalances = await getTokenBalances(_walletAddress);
                console.log("tokenBalances", tokenBalances);
                setTokens(tokenBalances);
                for (let i = 0; i < balances.length; i++) {
                    const _tokenBalances = tokenBalances.filter((tokenBalance) => tokenBalance.networkId === balances[i].networkId);
                    console.log("Mathing token balances with coin balances", _tokenBalances);
                    balances[i].tokenBalances = _tokenBalances;
                }

                setBalances(balances);
                console.log("balances", balances);
                setLoading(false);
            } else {
                setLoading(false);
                notify("Enter a valid Wallet Address", "info");
            }
        } catch (error) {
            setLoading(false);
            notify("Error getting the Wallet Balance. Please, try again.", "error");
            console.error(error);
        }
    }
    return (
        <div className="container">
            <Helmet>
                <meta charset="UTF-8" />
                <title>Check the balances of any wallet | DevWeb3</title>
                <meta name="description" content="Check the balances of any web3 wallet in ethereum blockchain, polygon blockchain and binance blockchain." />
                <meta property="og:title" content="Check the balances of any wallet | DevWeb3" />
                <meta property="og:description" content="Check the balances of any web3 wallet in ethereum blockchain, polygon blockchain and binance blockchain." />
                <meta name="twitter:title" content="Check the balances of any wallet | DevWeb3" />
                <meta name="twitter:description" content="Check the balances of any web3 wallet in ethereum blockchain, polygon blockchain and binance blockchain." />
            </Helmet>
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="min-[1920px]:col-span-12 2xl:col-span-12 col-span-12 p-2">
                    <h3 className="font-bold text-lg text-white">Get Wallet Balance</h3>
                    <div>
                        <input
                            type="text"
                            name="walletInput"
                            onChange={handleInputChange}
                            placeholder="Type or Paste the Wallet Address"
                            autoComplete="new-password"
                            spellCheck="false"
                            maxLength={42}
                            value={wallet || ""}
                            className="max-w-[600px]"
                        />
                        {validationMessages && <ValidationMessage message={validationMessages} />}
                    </div>
                    <div className="flex justify-start items-center mt-2">
                        <button id="BalanceCheckerWalletBalanceButton" name="BalanceCheckerWalletBalanceButton" className="btn-default mt-2" onClick={() => getBalances()}>
                            Get Wallet Balance
                            <FontAwesomeIcon id="BalanceCheckerWalletBalanceButtonIcon" name="BalanceCheckerWalletBalanceButton" icon={faWallet} className="text-secondary ml-2 cursor-pointer" />
                        </button>
                        {loading && (
                            <div>
                                <span className="loader ml-1"></span>
                            </div>
                        )}
                    </div>
                    {wallet && balances && (
                        <div className="border-2 border-[#AA6840] rounded-lg mt-10 p-2">
                            <p className="font-semibold text-white">Wallet Balances</p>
                            <p className="pt-6 text-secondary">
                                Wallet Address:<span className="text-light pl-1">{wallet}</span>
                            </p>
                            <div className="pt-6 text-secondary">
                                <ul>
                                    {balances.map((balance, index) => (
                                        <li key={balance.network}>
                                            <Accordion
                                                id={"accordion" + balance.network}
                                                name={"accordion" + balance.network}
                                                title={
                                                    <div>
                                                        <img className="h-5 inline pr-2 " src={`${getImageUrlWithExt(balance.logo)}`} alt={balance.logo} />
                                                        {balance.network} - {balance.tokenBalances.length} Tokens Found
                                                    </div>
                                                }
                                                opened={false}
                                                items={[
                                                    <div>
                                                        <span id={"balanceValueFor" + balance.coin + "In" + balance.network.replace(" ", "")} className="text-secondary pl-1">
                                                            {balance.balance} {balance.coin}
                                                        </span>
                                                    </div>,
                                                    ...balance.tokenBalances.map((tokenBalance) => (
                                                        <div key={tokenBalance.tokenAddress}>
                                                            {tokenBalance.scamLevel === 1 ? (
                                                                <span className="text-light pl-1">
                                                                    <FontAwesomeIcon icon={faExclamation} title="Alert: Be careful performing transactions using this token." />
                                                                </span>
                                                            ) : tokenBalance.scamLevel === 2 ? (
                                                                <span className="text-light pl-1">
                                                                    <FontAwesomeIcon icon={faTriangleExclamation} title="Warning: This token can be a scam. It's advised to avoid any transactions." />
                                                                </span>
                                                            ) : null}
                                                            <span className="text-light pl-2">
                                                                {tokenBalance.balance} {tokenBalance.tokenSymbol} ({tokenBalance.tokenName}) - 
                                                                <a href={`${balance.networkblockExplorerURL}token/${tokenBalance.tokenAddress}`} target="_blank" >{tokenBalance.tokenAddress}</a>
                                                            </span>
                                                        </div>
                                                    )),
                                                ]}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default BalanceChecker;
