import { useEffect, useRef, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { getImageUrl } from "@util/util";
import { FunctionGasType } from "@util/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";

function SearchbarDropdown(props) {
    const { setCurrentFunction, functions, setResult2Null, setParameters, currentFunctionInput, clearExecuteForm, setCurrentFunctionInput, getFunctionGasType } = useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions } = props;
    const ulRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            if (ulRef && ulRef.current && ulRef.current.style) ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });
        inputRef.current.addEventListener("focusout", (event) => {
            if (!inputRef.current.value) {
                setCurrentFunction();
            }
        });
    }, []);

    useEffect(() => {
        if (functions.length === 0) {
            if (ulRef && ulRef.current && ulRef.current.style) ulRef.current.style.display = "none";
            setOptions([]);
        }
    }, [functions]);

    function getFunctionIcon(stateMutability) {
        if (getFunctionGasType(stateMutability) === FunctionGasType.NoGasFee) return <FontAwesomeIcon icon={faEye} className="mr-2 text-secondary" />;
        if (getFunctionGasType(stateMutability) === FunctionGasType.PayGasFee) return <FontAwesomeIcon icon={faPen} className="mr-2 text-secondary" />;
    }

    function formatParameters(parameters) {
        if (!parameters || parameters.length === 0) return;
        const formattedParams = parameters.map((param) => `${param.type} ${param.name}`);
        return `(${formattedParams.join(", ")})`;
    }

    // console.log("SearchbarDropdown options", options);

    return (
        <div className="dropdown-search bg-black">
            {/* BUG: CHANGE THE dropdown-search TO THE INNER CSS TO HIDE AND ONLY SHOW WHEN YOU HAVE A LIST, BUT DOESN'T RESPECT THE OVERFLOW */}
            <input type="text" placeholder={placeholder} ref={inputRef} onChange={onInputChange} autoComplete="new-password" spellCheck="false" value={currentFunctionInput} />
            {options && options.length > 0 && (
                <div className="overflow-auto max-h-[150px]">
                    <ul id="results" className="hidden ul-main" ref={ulRef}>
                        {options &&
                            options.map((option, index) => {
                                // console.log("SearchbarDropdown Function - option", option);
                                return (
                                    <button
                                        id="ExecuteFunctionSearchbarDropdownItem"
                                        name="ExecuteFunctionSearchbarDropdownItem"
                                        type="button"
                                        key={index}
                                        className="ul-item"
                                        onClick={(e) => {
                                            clearExecuteForm("function");
                                            setCurrentFunctionInput(option.name);
                                            ulRef.current.style.display = "none";
                                            // inputRef.current.value = option.name;
                                            setCurrentFunction(option);
                                            setOptions(functions.filter((_option) => _option.name.toLocaleLowerCase().includes(option.name.toLocaleLowerCase())));
                                            setResult2Null();
                                            setParameters();
                                        }}>
                                        {getFunctionIcon(option.stateMutability)}
                                        {option.name}
                                        <span className="pl-1">{formatParameters(option.inputs)}</span>
                                        {/* // TODO: Add the actual of a read-only function if we have it */}
                                        {option.result && <span className="text-sm">= {option.result}</span>}
                                        {/* {option.input > 0 && {option.inputs[0].name} {option.inputs[0].type} returns TODO } */}
                                        {/* Buy (uint amount, address token) returns uint */}
                                    </button>
                                );
                            })}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default SearchbarDropdown;
