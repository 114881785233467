import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import ValidationMessage from "@components/shared/ValidationMessage";
import { Web3util } from "@util/web3util";
import { NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faWallet, faCube, faFileCircleCheck, faCopy, faRotate } from "@fortawesome/free-solid-svg-icons";
import { convertEtherUnits, debounce, moneyNumberFormatter } from "@util/util";

const EthConverterPage = () => {
    const { getCoinPrice } = useContext(AppContext);
    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [coinPrices, setCoinsPrices] = useState();
    // console.log("EthConverterPage render");

    useEffect(() => {
        getCoinPrices();
    }, []);

    async function getCoinPrices() {
        try {
            const _ETH = await getCoinPrice("ETH");
            const _MATIC = await getCoinPrice("MATIC");
            const _BNB = await getCoinPrice("BNB");
            const _AVAX = await getCoinPrice("AVAX");
            const _FTM = await getCoinPrice("FTM");

            const _prices = { ETH: _ETH, MATIC: _MATIC, BNB: _BNB, AVAX: _AVAX, FTM: _FTM };
            setCoinsPrices(_prices);
        } catch (error) {
            setCoinsPrices(null);
        }
    }

    // const updateCoinsPrice = debounce(() => {
    //     console.log("debounce2");
    //     // getCoinPrices();
    //     // updateCoinValueQty();
    // }, 2000);

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        // const _input = event.target.value.replace(/[^0-9]/g, "");
        //updateInputValues(event.target.name, _input);
        // setInputValues((prevValues) => {
        //     return {
        //         ...prevValues,
        //         [event.target.name]: _input,
        //     };
        // });

        lengthConverter(event.target.name, event.target.value);
        // lengthConverter(event.target.name, _input);

        // Name - At least 3 characters and check if the name already exists
        // if (event.target.name === "name") {
        //     if (event.target.value.length < 3) {
        //         updateValidationMessages(event, "Wallet name should have at least 3 characters");
        //         return;
        //     }
        // }
    };

    function updateInputValues(element, input_) {
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [element]: input_,
            };
        });
    }

    // BigNumber.config({ DECIMAL_PLACES: 30 });

    function lengthConverter(source, value) {
        if (!value) {
            updateInputValues("wei", value);
            updateInputValues("gwei", value);
            updateInputValues("ether", value);
        } else {
            if (source == "wei") {
                updateInputValues("wei", value);
                updateInputValues("gwei", convertEtherUnits(value, source, "gwei"));
                updateInputValues("ether", convertEtherUnits(value, source, "ether"));
            }
            if (source == "gwei") {
                updateInputValues("wei", convertEtherUnits(value, source, "wei"));
                updateInputValues("gwei", value);
                updateInputValues("ether", convertEtherUnits(value, source, "ether"));
            }
            if (source == "ether") {
                updateInputValues("wei", convertEtherUnits(value, source, "wei"));
                updateInputValues("gwei", convertEtherUnits(value, source, "gwei"));
                updateInputValues("ether", value);
            }
        }
    }

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                    <h3 className="font-bold text-lg text-white">Eth Converter</h3>
                    {/* <label for="weiInput">Wei:</label>
                    <input id="weiInput" type="text" oninput="convert('wei')" /> */}

                    <p className="pt-2 text-white">Wei</p>
                    <div className="flex items-center pt-2">
                        <input
                            type="number"
                            name="wei"
                            className="number-input"
                            maxLength={150}
                            autoComplete="new-password"
                            spellCheck="false"
                            placeholder="Enter a value in Wei"
                            onChange={handleInputChange}
                            value={inputValues["wei"] || ""}
                        />
                        <FontAwesomeIcon
                            id="EthConverterPageCopyWeiButtonIcon"
                            name="EthConverterPageCopyWeiButtonIcon"
                            icon={faCopy}
                            onClick={() => {
                                navigator.clipboard.writeText(inputValues["wei"]);
                            }}
                            className="pl-2 text-secondary cursor-pointer"
                        />
                        {validationMessages["wei"] && <ValidationMessage message={validationMessages["wei"]} />}
                    </div>
                    <p className="pt-2 text-white">Gwei</p>
                    <div className="flex items-center pt-2">
                        <input
                            type="number"
                            name="gwei"
                            className="number-input"
                            maxLength={150}
                            autoComplete="new-password"
                            spellCheck="false"
                            placeholder="Enter a value in Gwei"
                            onChange={handleInputChange}
                            value={inputValues["gwei"] || ""}
                        />
                        <FontAwesomeIcon
                            id="EthConverterPageCopyGweiButtonIcon"
                            name="EthConverterPageCopyGweiButtonIcon"
                            icon={faCopy}
                            onClick={() => {
                                navigator.clipboard.writeText(inputValues["gwei"]);
                            }}
                            className="pl-2 text-secondary cursor-pointer"
                        />
                        {validationMessages["gwei"] && <ValidationMessage message={validationMessages["gwei"]} />}
                    </div>
                    <p className="pt-2 text-white">Ether</p>
                    <div className="flex items-center pt-2">
                        <input
                            type="number"
                            name="ether"
                            className="number-input"
                            maxLength={150}
                            autoComplete="new-password"
                            spellCheck="false"
                            placeholder="Enter a value in Ether"
                            onChange={handleInputChange}
                            value={inputValues["ether"] || ""}
                        />
                        <FontAwesomeIcon
                            id="EthConverterPageCopyEtherButtonIcon"
                            name="EthConverterPageCopyEtherButtonIcon"
                            icon={faCopy}
                            onClick={() => {
                                navigator.clipboard.writeText(inputValues["ether"]);
                            }}
                            className="pl-2 text-secondary cursor-pointer"
                        />
                        {validationMessages["ether"] && <ValidationMessage message={validationMessages["ether"]} />}
                    </div>
                    <h3 className="font-bold text-lg text-white pt-10">Current Coin Prices</h3>
                    {coinPrices && inputValues && (
                        <div>
                            <p className="text-lg py-2">
                                <span className="text-secondary">ETH: </span>
                                {coinPrices && moneyNumberFormatter().format(coinPrices.ETH)} USD x {inputValues["ether"] ?? 0} =
                                <span id="EthConverterETHPrice" name="EthConverterETHPrice" className="pl-1 font-semibold">
                                    ${moneyNumberFormatter().format(coinPrices.ETH * (inputValues["ether"] ?? 0))} USD
                                </span>
                            </p>
                            <p className="text-lg py-2">
                                <span className="text-secondary">MATIC: </span>
                                {coinPrices && moneyNumberFormatter().format(coinPrices.MATIC)} USD x {inputValues["ether"] ?? 0} =
                                <span id="EthConverterMATICPrice" name="EthConverterMATICPrice" className="pl-1 font-semibold">
                                    ${moneyNumberFormatter().format(coinPrices.MATIC * (inputValues["ether"] ?? 0))} USD
                                </span>
                            </p>
                            <p className="text-lg py-2">
                                <span className="text-secondary">BNB: </span>${coinPrices && moneyNumberFormatter().format(coinPrices.BNB)} USD x {inputValues["ether"] ?? 0} ={" "}
                                <span id="EthConverterBNBPrice" name="EthConverterBNBPrice" className="pl-1 font-semibold">
                                    ${moneyNumberFormatter().format(coinPrices.BNB * (inputValues["ether"] ?? 0))} USD
                                </span>
                            </p>
                            <p className="text-lg py-2">
                                <span className="text-secondary">AVAX: </span>${coinPrices && moneyNumberFormatter().format(coinPrices.AVAX)} USD x {inputValues["ether"] ?? 0} ={" "}
                                <span id="EthConverterAVAXPrice" name="EthConverterAVAXPrice" className="pl-1 font-semibold">
                                    ${moneyNumberFormatter().format(coinPrices.AVAX * (inputValues["ether"] ?? 0))} USD
                                </span>
                            </p>
                            <p className="text-lg py-2">
                                <span className="text-secondary">FTM: </span>${coinPrices && moneyNumberFormatter().format(coinPrices.FTM)} USD x {inputValues["ether"] ?? 0} ={" "}
                                <span id="EthConverterFTMPrice" name="EthConverterFTMPrice" className="pl-1 font-semibold">
                                    ${moneyNumberFormatter().format(coinPrices.FTM * (inputValues["ether"] ?? 0))} USD
                                </span>
                            </p>
                        </div>
                    )}
                    {!coinPrices && <p className="text-lg py-2">Not Available</p>}
                </div>
                {/* <div className="min-[1920px]:col-span-3 2xl:col-span-12 col-span-12 p-2">
                    <p>TODO: add the current gas price here</p>
                </div> */}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default EthConverterPage;
