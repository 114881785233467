import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCopy, faCube, faLocationDot, faLocationPinLock, faRobot, faTriangleExclamation, faWallet } from "@fortawesome/free-solid-svg-icons";
import ReactJson from "react-json-view";
import { ModalTheme } from "@util/enum";
import { Helmet } from "react-helmet";

const ABIGenerator = () => {
    const { compileContract } = useContext(AppContext);
    const [code, setCode] = useState();
    const [abi, setABI] = useState();
    const [bytecode, setBytecode] = useState();
    const [compilationResults, setCompilationResults] = useState();

    const handleInputChange = (event) => {
        const _code = event.target.value;
        setCode(_code);
    };

    async function getABI() {
        try {
            if (code) {
                console.log("Compiling this code", code);

                const _code = [
                    {
                        contract: "GetABI",
                        code: code,
                        path: "contracts/getabi.sol",
                        type: "implementation",
                        index: 0,
                    },
                ];

                const _compilationResults = await compileContract("network", "getabi", "v0.8.10+commit.fc410830", true, 200, _code, null);
                if (_compilationResults) {
                    const contracts = _compilationResults.output.contracts["contracts/getabi.sol"];
                    const contractName = Object.keys(contracts)[0];
                    // ABI
                    const _abi = contracts[contractName].abi;
                    if (_abi) setABI(_abi);
                    console.log("ABI", _abi);
                    // bytecode
                    const _bytecode = contracts[contractName].evm.bytecode.object;
                    setBytecode(_bytecode);
                    console.log("bytecode", _bytecode);
                }

                console.log("_compilationResults", _compilationResults);
                setCompilationResults(_compilationResults);
            } else {
                notify("Source Code is empty", "info");
            }
        } catch (error) {
            console.error(error);
            notify("Error getting the ABI, please use a simple contract code (no imports) and try again", "error");
            setABI(null);
            setBytecode(null);
        }
    }

    return (
        <div className="container">
            <Helmet>
                <meta charset="UTF-8" />
                <title>Generate the ABI Interface for your Web3 Solidity Contract | DevWeb3</title>
                <meta name="description" content="Generate the ABI interface for your Web3 Solidity smartcontract." />
                <meta property="og:title" content="Generate the ABI Interface for your Web3 Solidity Contract | DevWeb3" />
                <meta property="og:description" content="Generate the ABI interface for your Web3 Solidity smartcontract." />
                <meta name="twitter:title" content="Generate the ABI Interface for your Web3 Solidity Contract | DevWeb3" />
                <meta name="twitter:description" content="Generate the ABI interface for your Web3 Solidity smartcontract." />
            </Helmet>
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="col-span-full max-w-[1280px] min-w-[635px] p-2">
                    <h3 className="font-bold text-lg text-white">Generate ABI from a Source Code</h3>
                    <div className="flex-row pt-2 items-center">
                        <p className="font-semibold py-2 text-white">Source Code</p>
                        <textarea
                            style={{
                                fontFamily: "Consolas, 'Courier New', monospace",
                                width: "100%",
                                height: "100%",
                                resize: "none",
                            }}
                            rows={20}
                            className="w-full text-light bg-input border-[#AA6840] placeholder-[#865C2F] focus:outline-0 hover:outline-0 disabled:bg-[#1A1511] input-primary w-full"
                            onChange={handleInputChange}
                        />
                        <p>
                            <button id="ABIGeneratorGenerateABIButton" name="ABIGeneratorGenerateABIButton" className="btn-default mt-2" onClick={() => getABI()}>
                                Generate ABI and Bytecode
                                <FontAwesomeIcon id="ABIGeneratorGenerateABIButtonIcon" name="ABIGeneratorGenerateABIButtonIcon" icon={faRobot} className="text-secondary ml-2 cursor-pointer" />
                            </button>
                        </p>
                        {abi && (
                            <div>
                                <div>
                                    <p className="font-semibold pt-6 text-white">
                                        ABI
                                        <FontAwesomeIcon
                                            id="ABIGeneratorCopyABIButtonIcon"
                                            name="ABIGeneratorCopyABIButtonIcon"
                                            icon={faCopy}
                                            className="text-secondary ml-2 cursor-pointer"
                                            onClick={() => {
                                                navigator.clipboard.writeText(JSON.stringify(abi));
                                            }}
                                        />
                                    </p>
                                    <div className="bg-black py-1 rounded-lg ReactJson-container">
                                        <ReactJson id="ABIGeneratorReactJson" name="ABIGeneratorReactJson" src={abi} iconStyle="triangle" collapsed={2} displayDataTypes={false} theme={ModalTheme} />
                                    </div>
                                </div>
                                <div>
                                    <p className="font-semibold pt-6 text-white">
                                        Bytecode
                                        <FontAwesomeIcon
                                            id="ABIGeneratorCopyBytecodeButtonIcon"
                                            name="ABIGeneratorCopyBytecodeButtonIcon"
                                            icon={faCopy}
                                            className="text-secondary ml-2 cursor-pointer"
                                            onClick={() => {
                                                navigator.clipboard.writeText(bytecode);
                                            }}
                                        />
                                    </p>
                                    <div className="bg-black py-1 rounded-lg ReactJson-container">
                                        <textarea
                                            id="ABIGeneratorBytecode"
                                            name="ABIGeneratorBytecode"
                                            readonly
                                            rows="5"
                                            className="p-1 w-full text-light bg-black border-[#AA6840] placeholder-[#865C2F] focus:outline-0 hover:outline-0 disabled:bg-[#1A1511] input-primary w-full">
                                            {bytecode}
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default ABIGenerator;
