import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Routes, Route } from "react-router-dom";
import { initTracking } from "./util/trackingLibrary";

// Pages
import MainPage from "@pages/main/MainPage";
import ExecutePage from "@pages/execute/ExecutePage";
import Queue from "@pages/queue/Queue";
import QueueList from "@pages/queueList/QueueList";
import RunnerAPI from "@pages/runnerAPI/RunnerAPIPage";
import Networks from "@pages/networks/Networks";
import Contracts from "@pages/contracts/Contracts";
import Wallets from "@pages/wallets/Wallets";
import Login from "@pages/login/Login";
import ErrorPage from "@pages/errorpage/ErrorPage";
import Account from "@pages/account/Account";
import Import from "@pages/import/Import";
import CodeEditor from "@pages/codeeditor/CodeEditor";
import SourceCodePage from "@pages/sourcecode/SourceCodePage";
import AgreementPage from "@pages/agreement/AgreementPage";

// Tools
import EthConverterPage from "@pages/ethconverter/EthConverter";
import BurnWallet from "@pages/burnwallet/BurnWallet";
import AddressFromPK from "@pages/addressFromPK/AddressFromPK";
import GetImplementation from "@pages/getImplementation/GetImplementation";
import ABIGenerator from "@pages/abigenerator/ABIGenerator";
import BalanceChecker from "@pages/balanceChecker/BalanceChecker";

import Todo from "@pages/todo/Todo";
import TodoExecute from "@pages/todo/TodoExecute";
import QueueFlow from "@pages/queueflow/QueueFlow";
import InitialLoad from "@pages/test/InitialLoad";
import Test from "@pages/test/Test";
import Onboarding from "@pages/onboarding/OnboardingPage";
import GetOpcodes from "@pages/test/GetOpcodes";
// import Trace from "@pages/test/Trace";

// TODO: Create 2 different configurations for dev and prod
// For now, just trace error in prod, not in localhost
Sentry.init({
    dsn: "https://bed2520d7c534089b5696c206648163d@o318431.ingest.sentry.io/4505304806457344",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/devweb3\.co\//],
            tracePropagationTargets: [/^https:\/\/devweb3\.co\//],
        }),
        new Sentry.Replay(),
    ],
    enabled: process.env.NODE_ENV === "production" ? true : false,
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
    useEffect(() => {
        // Attach click event listeners when the component mounts
        initTracking();
    }, []);

    if (process.env.NODE_ENV === "production") {
        //console.log = () => {}
        //console.error = () => {}
        console.debug = () => {};
    }

    function Tools() {
        return (
            <>
                <Routes>
                    <Route path="converter" element={<EthConverterPage />} />
                    <Route path="sourcecode" element={<SourceCodePage />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </>
        );
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="execute" element={<ExecutePage />} />
                <Route path="queue" element={<Queue />} />
                <Route path="queueList" element={<QueueList />} />
                <Route path="runnerAPI" element={<RunnerAPI />} />
                <Route path="networks" element={<Networks />} />
                <Route path="contracts" element={<Contracts />} />
                <Route path="wallets" element={<Wallets />} />
                <Route path="editor" element={<CodeEditor />} />
                <Route path="login" element={<Login />} />
                <Route path="account" element={<Account />} />
                <Route path="import" element={<Import />} />

                <Route path="tools/*" element={<Tools />} />

                <Route path="sourcecode" element={<SourceCodePage />} />
                <Route path="converter" element={<EthConverterPage />} />
                <Route path="createwallet" element={<BurnWallet />} />
                <Route path="addressfrompk" element={<AddressFromPK />} />
                <Route path="implementation" element={<GetImplementation />} />
                <Route path="abi" element={<ABIGenerator />} />
                <Route path="balance" element={<BalanceChecker />} />

                <Route path="agreement" element={<AgreementPage />} />

                <Route path="load" element={<InitialLoad />} />

                <Route path="todo" element={<Todo />} />
                <Route path="todoexecute" element={<TodoExecute />} />
                <Route path="*" element={<ErrorPage />} />

                {/* <Route path="trace" element={<Trace />} /> */}
                <Route path="test" element={<Test />} />
                <Route path="onboarding" element={<Onboarding />} />
                <Route path="opcode" element={<GetOpcodes />} />
                <Route path="queueflow" element={<QueueFlow />} />
                {/* <Route path="liquidity/:id" element={<Liquidity />} /> EXAMPLE WITH PARAMETER JUST IN CASE */}
            </Routes>
        </>
    );
}

export default App;
