import { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { FunctionGasType } from "@util/enum";
import { dateDefaultFormat } from "@util/util";
import ResultPayable from "./ResultPayable";
import ResultView from "./ResultView";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

// TEST REACT FLOW
import { Web3util } from "@util/web3util";

const Result = () => {
    const { lastExecution, getFunctionGasType } = useContext(AppContext);

    // console.log("Results render");

    let _functionGasType;
    if (lastExecution) _functionGasType = getFunctionGasType(lastExecution.function.stateMutability);

    // TEST _ JSON REACT FLOW
    useEffect(() => {
        // console.log("useEffect lastExecution", lastExecution);
        let _json;
        if (lastExecution) _json = Web3util.transformExecution2Queue(lastExecution);
        // console.log("lastExecution cleaned - _json", _json);
    }, [lastExecution]);

    return (
        <div>
            <p className="text-white font-semibold text-xl">
                Result
                {lastExecution && (
                    <ContentCopyOutlinedIcon
                        onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(lastExecution));
                        }}
                        className="pl-2 cursor-pointer max-h-4 min-h-4"
                    />
                )}
                {/* <span> TODO: button to copy the content of the result, but only the top section</span> */}
            </p>
            {lastExecution && _functionGasType === FunctionGasType.NoGasFee && <ResultView />}
            {lastExecution && _functionGasType === FunctionGasType.PayGasFee && <ResultPayable />}
        </div>
    );
};

export default Result;
