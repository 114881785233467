import { getCookieValue, setCookieValue } from "@util/util";
import SideMenu from "@components/sidemenu/SideMenu";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import { useNavigate } from "react-router-dom";

const AgreementPage = () => {
    // useEffect(() => {
    // TODO: Check if the user acepted in the cookies
    // }, []);

    const navigate = useNavigate();

    return (
        <div className="master">
            <NavBar />
            <div className="flex flex-row main-container bg-[#1a1511]">
                <div>
                    <SideMenu />
                </div>
                <div className="pb-4 w-full p-10">
                    <div>
                        <h1 className="font-bold text-secondary">Using this website you agree with our terms of service.</h1>
                    </div>
                    <div>
                        <h2 className="font-bold pt-6">TL;DR of User Agreement:</h2>
                        <ul>
                            <li>We offer a platform for Solidity developers.</li>
                            <li>In some cases, you may need a private key to run contract functions; it's your responsibility to keep them safe.</li>
                            <li>Only maintain enough crypto in your developer wallet to cover gas fees.</li>
                            <li>We don't oversee your wallet; exercise caution.</li>
                            <li>We recommend creating a separate wallet solely for development to interact with contracts.</li>
                            <li>Ensure your passwords and login credentials are secure; we're not liable if someone gains unauthorized access to your account.</li>
                            <li>We may occasionally update this agreement; we'll notify you when we do.</li>
                            <li>Any legal issues will be addressed based on US laws.</li>
                        </ul>
                        <p>This is a brief overview. Always refer to the detailed terms below.</p>
                    </div>

                    <div class="pt-10">
                        <h1 className="font-bold">USER AGREEMENT (Version: 1.1, Date: 2023-09-21)</h1>

                        <h2>1. Introduction</h2>
                        <p>Welcome to DevWeb3.co. By accessing or using our services tailored for Solidity developers, you agree to this User Agreement.</p>

                        <h2>2. Definitions</h2>
                        <ul>
                            <li>"Platform": DevWeb3.co and its associated tools and services.</li>
                            <li>"User": Solidity developers or anyone using our Platform.</li>
                            <li>"Wallet": Digital mechanism for managing and executing smart contracts and transactions.</li>
                            <li>"Private Key": A cryptographic key unique to every wallet, used to sign transactions or smart contract interactions, proving one's identity and permissions.</li>
                        </ul>

                        <h2>3. Wallets and Security</h2>
                        <p>
                            Executing contracts might require a private key. Ensure its safety. Only store enough cryptocurrency in your wallet to manage gas fees. While we facilitate new wallet
                            generation and coin acquisition for testnets, you bear the primary security responsibility.
                        </p>
                        <p>Developers are encouraged to use a separate wallet for development, safeguarding their primary assets.</p>
                        <p>
                            We neither monitor nor guarantee the security of any wallets. Your wallet and key's security is your concern. We might store wallet data to aid platform usage, emphasizing
                            testnets that don't involve real assets.
                        </p>

                        <h2>4. Login and Passwords</h2>
                        <p>
                            We provide login methods, but you're responsible for password and login details. Ensure the use of any two-factor authentication (2FA) feature provided. We're not
                            accountable for unauthorized access due to compromised credentials.
                        </p>

                        <h2>5. Liability</h2>
                        <p>
                            We aren't liable for losses or breaches from your private key mismanagement or tool misuse. By using our platform, you indemnify DevWeb3.co against claims from such
                            incidents.
                        </p>

                        <h2>6. Changes to the Agreement</h2>
                        <p>We can change this User Agreement at our discretion. We'll notify you at least 30 days before changes take effect. Review this agreement periodically.</p>

                        <h2>7. Governing Law</h2>
                        <p>
                            Disputes from this agreement fall under US laws. Both parties commit to arbitration in English, either via teleconference or, if you request, in San Francisco, California,
                            U.S.A., under JAMS Streamlined Arbitration Rules and Procedures at
                            <a href="https://www.jamsadr.com/rules-streamlined-arbitration/">https://www.jamsadr.com/rules-streamlined-arbitration/</a>. Both parties waive the right to a jury trial.
                            Costs associated with arbitration are borne by the initiating party.
                        </p>

                        <h2>8. Contact</h2>
                        <p>
                            For questions or concerns about this User Agreement, contact us via the social networks at the bottom of this page or through our official email address (provided
                            separately).
                        </p>
                    </div>

                    <div className="flex w-full justify-center mt-10">
                        <button
                            className="btn-default"
                            onClick={() => {
                                navigate("/");
                            }}>
                            I DO NOT ACCEPT
                        </button>
                        <button className="btn-default ml-6">I ACCEPT</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AgreementPage;
