import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRocket } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";

const OnboardingPage = () => {
    const { getCurrentOnboardingStep, dataLoaded, connectedAccount, currentNetwork, contracts, wallets, updateOnboardingStatus } = useContext(AppContext);
    const navigate = useNavigate();

    const [onboardingStep, setOnboardingStep] = useState(0);
    useEffect(() => {
        const _currentOnboardingStep = getCurrentOnboardingStep();
        setOnboardingStep(_currentOnboardingStep);
        if (_currentOnboardingStep >= 5) {
            updateOnboardingStatus();
            console.debug("Closing onboarding");
        }
    }, [dataLoaded, connectedAccount, currentNetwork, contracts, wallets]);

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-full">
                <div className="flex flex-col justify-center max-w-[1024px]">
                    <p className="text-3xl font-semibold text-white">Welcome to DevWeb3!</p>
                    {!connectedAccount && (
                        <>
                            <div className={`flex flex-col items-center text-2xl pt-6`}>
                                <p>I already completed my Onboarding before. Take me to the Login page.</p>
                                <p className="pt-4">
                                    <button
                                        className="btn-default"
                                        onClick={() => {
                                            navigate("/login");
                                        }}>
                                        LOGIN
                                        {/* <FontAwesomeIcon icon={faRocket} className="pl-2" /> */}
                                    </button>
                                </p>
                            </div>
                            <p className="text-3xl font-semibold text-white">Onboarding</p>
                        </>
                    )}
                    <div className={`flex flex-row items-center text-2xl ${onboardingStep >= 1 ? "" : "text-light-dark"} pt-6`}>
                        <p>
                            {connectedAccount && <FontAwesomeIcon icon={faCheck} className="text-green pr-4" />}
                            {!connectedAccount && <FontAwesomeIcon icon={faClock} className="text-yellow pr-4" />}
                        </p>
                        <div className="flex flex-col">
                            <p className="font-semibold">1st Step: Login</p>
                            <p>Click on the top right of your screen to start the login process.</p>
                            <p className="text-lg">We need this to sync your data. All contracts, network configurations and wallets will be saved to expedite the use of the application.</p>
                        </div>
                    </div>
                    <div className={`flex flex-row items-center text-2xl ${onboardingStep >= 2 ? "" : "text-light-dark"} pt-6`}>
                        <p>{onboardingStep > 2 ? <FontAwesomeIcon icon={faCheck} className="text-green pr-4" /> : <FontAwesomeIcon icon={faClock} className="text-yellow pr-4" />}</p>
                        <div className="flex flex-col">
                            <p className="font-semibold">2nd Step: Select a Network</p>
                            <p>Click on List of Network on top of this page.</p>
                            <p className="text-lg">Select your preferred network.</p>
                        </div>
                    </div>
                    <div className={`flex flex-row items-center text-2xl ${onboardingStep >= 3 ? "" : "text-light-dark"} pt-6`}>
                        <p>{onboardingStep > 3 ? <FontAwesomeIcon icon={faCheck} className="text-green pr-4" /> : <FontAwesomeIcon icon={faClock} className="text-yellow pr-4" />}</p>
                        <div className="flex flex-col">
                            <p className="font-semibold">3rd Step: Add Contracts</p>
                            <p>Click on Settings in the left menu and then Contracts.</p>
                            <p className="text-lg">Easy to select contracts in a list to interact with them. You can always add more later.</p>
                        </div>
                    </div>
                    <div className={`flex flex-row items-center text-2xl ${onboardingStep >= 4 ? "" : "text-light-dark"} pt-6`}>
                        <p>{onboardingStep > 4 ? <FontAwesomeIcon icon={faCheck} className="text-green pr-4" /> : <FontAwesomeIcon icon={faClock} className="text-yellow pr-4" />}</p>
                        <div className="flex flex-col">
                            <p className="font-semibold">4rd Step: Add Wallets</p>
                            <p>Click on the left menu to add your wallets info.</p>
                            <p className="text-lg">Quick and easy way to execute the methods in the contracts. Link to a faucet where you can request coins to pay for gas.</p>
                        </div>
                    </div>
                    <p className="pt-10"></p>
                    {onboardingStep >= 5 && (
                        <div className={`flex flex-col items-center text-2xl text-white font-semibold pt-16`}>
                            <p>You are Ready!</p>
                            <p className="pt-4">
                                <button
                                    className="btn-main text-white"
                                    onClick={() => {
                                        navigate("/execute");
                                    }}>
                                    LET'S GO
                                    <FontAwesomeIcon icon={faRocket} className="pl-2" />
                                </button>
                            </p>
                        </div>
                    )}
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
        </div>
    );
};

export default OnboardingPage;
