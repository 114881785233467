import Web3 from "web3";
import secureLocalStorage from "react-secure-storage";
import { v4 as uuidv4 } from "uuid";
import BigNumber from "bignumber.js";
import Cookies from "js-cookie";

export const getImageUrl = (name, extension = "png", withUrl = false) => {
    const image = new URL(`../assets/images/${name}.${extension}`, import.meta.url).href;

    if (withUrl) {
        return `url(${image})`;
    }

    return image;
};

export const getImageUrlWithExt = (name, withUrl = false) => {
    const image = new URL(`../assets/images/${name}`, import.meta.url).href;

    if (withUrl) {
        return `url(${image})`;
    }

    return image;
};

export const getVideoUrl = (name, extension = "mp4", withUrl = false) => {
    const video = new URL(`../assets/videos/${name}.${extension}`, import.meta.url).href;

    if (withUrl) {
        return `url(${video})`;
    }

    return video;
};

export const saveDataStorage = (id, data) => {
    if (!id || id === "undefined" || id === "null") throw new Error("saveDataStorage id can't be null");
    secureLocalStorage.setItem(id, data);
};
export const getDataStorage = (id) => {
    return secureLocalStorage.getItem(id);
};
export const removeDataStorage = (id) => {
    secureLocalStorage.removeItem(id);
};

// export const dateDefaultFormat = (date) => {
//     return new Intl.DateTimeFormat("default", { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }).format(date);
// };

export const dateDefaultFormat = (dateOrString) => {
    const date = new Date(dateOrString);
    if (isNaN(date.getTime())) {
        // Invalid date string, return it as it is
        return null;
    }

    return new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    }).format(date);
};

export const defaultNumberFormatter = () => {
    const formatter = new Intl.NumberFormat(navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language, {
        //useGrouping: "always",
        useGrouping: "false", // NOT WORKING!
        maximumFractionDigits: "4",
        minimumFractionDigits: "2",
    });
    return formatter;
};

export const roundedNumberFormatter = () => {
    const formatter = new Intl.NumberFormat(navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language, {
        //useGrouping: "always",
        useGrouping: "false", // NOT WORKING!
        maximumFractionDigits: "1",
        minimumFractionDigits: "0",
    });
    return formatter;
};

export const moneyNumberFormatter = () => {
    const formatter = new Intl.NumberFormat(navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language, {
        //useGrouping: "always",
        useGrouping: "false", // NOT WORKING!
        maximumFractionDigits: "2",
        minimumFractionDigits: "2",
    });
    return formatter;
};

export const longNumberFormatter = () => {
    const formatter = new Intl.NumberFormat(navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language, {
        //useGrouping: "always",
        useGrouping: "false", // NOT WORKING!
        maximumFractionDigits: "8",
        minimumFractionDigits: "0",
    });
    return formatter;
};

export const extendedMoneyNumberFormatter = () => {
    const formatter = new Intl.NumberFormat("en-us", {
        //useGrouping: "always",
        useGrouping: false,
        maximumFractionDigits: 10,
        minimumFractionDigits: 2,
    });
    return formatter;
};

export const shortenAddress = (address) => {
    if (address) return `${address.slice(0, 5)}...${address.slice(-4)}`;
    else return "";
};

export const ether = (wei) => {
    try {
        if (wei) {
            return Web3.utils.fromWei(wei.toString(), "ether");
        } else return null;
    } catch (e) {
        return null;
    }
};

export const etherRound = (wei) => {
    try {
        if (wei) {
            let _return = Web3.utils.fromWei(wei.toString(), "ether");
            return _return.replace(/[\.|\,]\d+$/, ""); // remove decimals
        } else return null;
    } catch (e) {
        return null;
    }
};

export const wei = (ether) => {
    try {
        if (ether) return Web3.utils.toWei(ether.toString());
        else return null;
    } catch (e) {
        return null;
    }
};

export const toBN = (number) => {
    try {
        if (number) return Web3.utils.toBN(number);
        else return null;
    } catch (e) {
        return null;
    }
};

// -1 for number1 < number2
//  0 for number1 = number2
//  1 for number1 > number2
export const BNgreater = (number1, number2) => {
    try {
        if (number1 && number2) return Web3.utils.toBN(number1).cmp(Web3.utils.toBN(number2));
        else return null;
    } catch (e) {
        return null;
    }
};

export const copyArray = (array) => {
    return JSON.parse(JSON.stringify(array));
};

export function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
    );
}

export const tooLong = (str, maxSize) => {
    if (str)
        if (str.length > maxSize) return `${str.slice(0, maxSize)}...`;
        else return str;
};

// export const timeSince = (date) => {
//     var seconds = Math.floor((new Date() - date) / 1000);
//     var interval = seconds / 31536000;

//     if (interval >= 1 && interval < 2) {
//         return Math.floor(interval) + " year ago";
//     } else if (interval >= 2) {
//         return Math.floor(interval) + " years ago";
//     }
//     interval = seconds / 2592000;
//     if (interval >= 1 && interval < 2) {
//         return Math.floor(interval) + " month ago";
//     } else if (interval >= 2) {
//         return Math.floor(interval) + " months ago";
//     }
//     interval = seconds / 86400;
//     if (interval >= 1 && interval < 2) {
//         return Math.floor(interval) + " day ago";
//     } else if (interval >= 2) {
//         return Math.floor(interval) + " days ago";
//     }
//     interval = seconds / 3600;
//     if (interval >= 1 && interval < 2) {
//         return Math.floor(interval) + " hour ago";
//     } else if (interval >= 2) {
//         return Math.floor(interval) + " hours ago";
//     }
//     interval = seconds / 60;
//     if (interval >= 1 && interval < 2) {
//         return Math.floor(interval) + " minute ago";
//     } else if (interval >= 2) {
//         return Math.floor(interval) + " minutes ago";
//     }
//     return Math.floor(seconds) + " seconds ago";
// };

export const timeSince = (date_, abbreviate = true) => {
    const date = new Date(date_);

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;

    const secondsElapsed = Math.floor((new Date() - date) / second);
    if (secondsElapsed < 60) {
        return `${secondsElapsed}${abbreviate ? "s" : " seconds"} ago`;
    }

    const minutesElapsed = Math.floor(secondsElapsed / 60);
    if (minutesElapsed < 60) {
        return `${minutesElapsed}${abbreviate ? "m" : " minutes"} ago`;
    }

    const hoursElapsed = Math.floor(minutesElapsed / 60);
    if (hoursElapsed < 24) {
        return `${hoursElapsed}${abbreviate ? "h" : " hours"} ago`;
    }

    const daysElapsed = Math.floor(hoursElapsed / 24);
    if (daysElapsed < 30) {
        return `${daysElapsed}${abbreviate ? "d" : " days"} ago`;
    }

    const monthsElapsed = Math.floor(daysElapsed / 30);
    if (monthsElapsed < 12) {
        return `${monthsElapsed}${abbreviate ? "mo" : " months"} ago`;
    }

    const yearsElapsed = Math.floor(monthsElapsed / 12);
    return `${yearsElapsed}${abbreviate ? "y" : " years"} ago`;
};

export const isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

export const newUUID = () => {
    return uuidv4();
};

// export const sortArrayByDate = (array, desc = true) => {
//     return (array = array.sort(function (a, b) {
//         var dateA = new Date(a.timestamp).getTime();
//         var dateB = new Date(b.timestamp).getTime();
//         if (desc) return dateA < dateB ? 1 : -1;
//         else return dateA < dateB ? -1 : 1;
//     }));
// };

export const sortArrayByDate = (array, isDescending = true) => {
    return (array = array.sort((a, b) => (isDescending ? new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime() : new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())));
};

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const callAfterDelay = (funcToCall, delayInSeconds) => {
    const timeoutId = setTimeout(funcToCall, delayInSeconds * 1000);
    return timeoutId;
};
export const cancelCallAfterDelay = (timeoutId) => {
    console.log("cancelCallAfterDelay", timeoutId);
    // timeoutId.forEach((id) => clearTimeout(id));
    clearTimeout(timeoutId);
};

// export const isObjectEmpty = (obj) => {
//     return Object.keys(obj).length === 0;
// };

export const generateRandomName = () => {
    const warriorNames = [
        "Mulan",
        "Hercules",
        "Aladdin",
        "Jasmine",
        "Tarzan",
        "Pocahontas",
        "Merida",
        "Anna",
        "Elsa",
        "Moana",
        "Simba",
        "Mufasa",
        "Scar",
        "Hades",
        "Jafar",
        "Ursula",
        "Maleficent",
        "Cruella de Vil",
        "Gaston",
        "Captain Hook",
        "Darth Vader",
        "Luke Skywalker",
        "Leia Organa",
        "Han Solo",
        "Yoda",
        "Obi-Wan Kenobi",
        "Anakin Skywalker",
        "Padmé Amidala",
        "Rey",
        "Finn",
        "Aragorn",
        "Gandalf",
        "Frodo",
        "Legolas",
        "Gimli",
        "Sam",
        "Bilbo",
        "Gollum",
        "Boromir",
        "Elrond",
        "Galadriel",
        "Treebeard",
        "Faramir",
        "Eowyn",
        "Eomer",
        "Theoden",
        "Meriadoc",
        "Peregrin",
        "Celeborn",
        "Haldir",
        "Sauron",
        "Saruman",
        "Witch-King",
        "Nazgul",
        "Grima",
        "Mouth of Sauron",
        "Shelob",
        "Balrog",
        "Ugluk",
        "Lurtz",
        "Luke Skywalker",
        "Leia Organa",
        "Han Solo",
        "Obi-Wan Kenobi",
        "Anakin Skywalker",
        "Padmé Amidala",
        "Yoda",
        "Mace Windu",
        "Qui-Gon Jinn",
        "Rey",
        "Finn",
        "Poe Dameron",
        "Chewbacca",
        "C-3PO",
        "R2-D2",
        "Lando Calrissian",
        "Ahsoka Tano",
        "Ezra Bridger",
        "Kanan Jarrus",
        "Hera Syndulla",
        "Sabine Wren",
        "Bo-Katan Kryze",
        "Cara Dune",
        "Greef Karga",
        "Asajj Ventress",
        "Darth Vader",
        "Emperor Palpatine",
        "Darth Maul",
        "Boba Fett",
        "Kylo Ren",
    ];
    const characteristics = [
        "Brave",
        "Wise",
        "Cunning",
        "Intelligent",
        "Loyal",
        "Honorable",
        "Fearless",
        "Resourceful",
        "Noble",
        "Determined",
        "Swift",
        "Mighty",
        "Witty",
        "Courageous",
        "Valiant",
        "Legendary",
        "Heroic",
        "Fierce",
        "Spirited",
        "Tenacious",
        "Dauntless",
        "Unyielding",
        "Resilient",
        "Stalwart",
        "Indomitable",
        "Invincible",
        "Unbeatable",
        "Formidable",
        "Unstoppable",
        "Irresistible",
    ];
    const colors = [
        "Sangria",
        "Champagne",
        "Mauvelous",
        "Cerulean",
        "Goldenrod",
        "Lavender",
        "Periwinkle",
        "Viridian",
        "Fuchsia",
        "Turquoise",
        "Amethyst",
        "Jade",
        "Crimson",
        "Emerald",
        "Sapphire",
        "Ruby",
        "Topaz",
        "Onyx",
        "Ivory",
        "Azul",
        "Vermillion",
        "Platinum",
        "Obsidian",
        "Indigo",
        "Aquamarine",
        "Chartreuse",
        "Rose",
        "Magenta",
        "Olive",
        "Teal",
    ];

    const randomWarrior = warriorNames[Math.floor(Math.random() * warriorNames.length)];
    const randomCharacteristic = characteristics[Math.floor(Math.random() * characteristics.length)];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    return `${randomColor} ${randomCharacteristic} ${randomWarrior}`;
};

export const addNewItemArray = (array, newItem) => {
    let newArray = [];
    if (array) newArray = [...array];
    newArray.push(newItem);
    return newArray;
};

export const capitalize = (text) => {
    return text
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
};

export function reverseArray(array) {
    console.log("reverseArray", array);
    return array.slice().reverse();
}

export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const extractJSON = (str) => {
    let jsonObj;
    let jsonStr;
    let beforeJSON;
    let afterJSON;
    try {
        const startIndex = str.indexOf("{");
        const endIndex = str.lastIndexOf("}");

        beforeJSON = str.substring(0, startIndex);
        afterJSON = str.substring(endIndex + 1);

        beforeJSON = beforeJSON.trim();
        afterJSON = afterJSON.trim();

        jsonStr = str.substring(startIndex, endIndex + 1);
        jsonObj = JSON.parse(jsonStr);
    } catch (error) {}
    return { json: jsonObj, errorMessageBefore: beforeJSON, errorMessageAfter: afterJSON };
};

export const convertEtherUnits = (value, from, to) => {
    var rawUnits = {
        wei: "1",
        kwei: "1000",
        Kwei: "1000",
        babbage: "1000",
        femtoether: "1000",
        mwei: "1000000",
        Mwei: "1000000",
        lovelace: "1000000",
        picoether: "1000000",
        gwei: "1000000000",
        Gwei: "1000000000",
        shannon: "1000000000",
        nanoether: "1000000000",
        nano: "1000000000",
        szabo: "1000000000000",
        microether: "1000000000000",
        micro: "1000000000000",
        finney: "1000000000000000",
        milliether: "1000000000000000",
        milli: "1000000000000000",
        ether: "1000000000000000000",
        eth: "1000000000000000000",
        kether: "1000000000000000000000",
        grand: "1000000000000000000000",
        mether: "1000000000000000000000000",
        gether: "1000000000000000000000000000",
        tether: "1000000000000000000000000000000",
    };

    var units = {};

    Object.keys(rawUnits).map(function (unit) {
        units[unit] = new BigNumber(rawUnits[unit], 10);
    });

    var re = RegExp(/^[0-9]+\.?[0-9]*$/);

    if (!re.test(value)) {
        throw new Error("Unsupported value");
    }

    from = from.toLowerCase();
    if (!units[from]) {
        throw new Error("Unsupported input unit");
    }

    to = to.toLowerCase();
    if (!units[to]) {
        throw new Error("Unsupported output unit");
    }

    return new BigNumber(value, 10).multipliedBy(units[from]).decimalPlaces(0, BigNumber.ROUND_DOWN).div(units[to]).toString(10);
};

export const getRandomMotivationalMessage = () => {
    const messages = [
        "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
        "Success is not final, failure is not fatal: It is the courage to continue that counts.",
        "The only way to do great work is to love what you do. Keep pushing forward and never give up on your dreams.",
        "Dream big and dare to fail.",
        "The future belongs to those who believe in the beauty of their dreams.",
        "Success is not the key to happiness. Happiness is the key to success.",
        "Believe you can and you're halfway there.",
        "The harder you work for something, the greater you'll feel when you achieve it.",
        "Your time is limited, don't waste it living someone else's life.",
        "Don't watch the clock; do what it does. Keep going.",
        "The only limit to our realization of tomorrow will be our doubts of today.",
        "In the middle of every difficulty lies opportunity.",
        "The only person you should try to be better than is the person you were yesterday.",
        "Success is not in what you have, but who you are.",
        "You miss 100% of the shots you don't take.",
        "Every great story on the planet happened when someone decided not to give up.",
        "You are never too old to set another goal or to dream a new dream.",
        "Believe in yourself, take on your challenges, dig deep within yourself to conquer fears.",
        "You don't have to be perfect to be amazing.",
        "The best way to predict the future is to create it.",
        "Don't be afraid to give up the good to go for the great.",
        "Your only limit is your mind.",
        "The only way to do great work is to love what you do.",
        "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
        "The best revenge is massive success.",
        "Don't wait for opportunity. Create it.",
        "Your attitude determines your direction.",
        "The road to success and the road to failure are almost exactly the same.",
        "The key to success is to focus on goals, not obstacles.",
        "Success usually comes to those who are too busy to be looking for it.",
        "You have within you right now, everything you need to deal with whatever the world can throw at you.",
        "The only person you are destined to become is the person you decide to be.",
        "Success is not the absence of failure; it's the persistence through failure.",
        "The biggest risk is not taking any risk.",
        "Believe in the beauty of your dreams and never give up.",
    ];

    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
};

export function isEmpty(obj) {
    if (!obj) return true;
    if (Array.isArray(obj)) {
        // Check if array is empty
        if (obj.length === 0) {
            return true;
        }
        // Check if any object in the array has empty keys and values
        return obj.some((item) => Object.values(item).every((value) => value === ""));
    } else if (typeof obj === "object") {
        // Check if object is empty
        if (Object.keys(obj).length === 0) {
            return true;
        }
        // Check if the object has empty keys and values
        return Object.values(obj).every((value) => value === "");
    } else {
        // Not an object or array, return false
        return false;
    }
}

// import.meta.env.VITE_API_ENDPOINT
function isWebCryptoSupported() {
    return "crypto" in window && "subtle" in window.crypto;
}
// Use only if you need a new encryption key - all the old data will LOST if you change the key
// export async function generateEncryptionKey() {
//     return await crypto.subtle.generateKey(
//         {
//             name: "AES-GCM",
//             length: 256, // Key size in bits (128, 192, or 256)
//         },
//         true, // Extractable (you can export the key)
//         ["encrypt", "decrypt"] // Key usages
//     );
// }
// export async function exportEncryptionKey(encryptionKey) {
//     const _exportedKey = await crypto.subtle.exportKey("raw", encryptionKey);
//     const _exportedKeyBase64Key = window.btoa(String.fromCharCode.apply(null, new Uint8Array(_exportedKey)));
//     return _exportedKeyBase64Key;
// }
function toBase64FromBinaryBuffer(binaryBufferKey) {
    return window.btoa(String.fromCharCode.apply(null, new Uint8Array(binaryBufferKey)));
}
function fromBase64ToBinaryBuffer(base64Key) {
    return new Uint8Array(
        window
            .atob(base64Key)
            .split("")
            .map((c) => c.charCodeAt(0))
    );
}

async function getEncryptionKey() {
    const _key = import.meta.env.VITE_ENCRYPTION_KEY_COOKIES;
    const _encryptionKey = fromBase64ToBinaryBuffer(_key); //new Uint8Array(window.atob(_key).split('').map((c) => c.charCodeAt(0)));

    return await crypto.subtle.importKey(
        "raw",
        _encryptionKey,
        {
            name: "AES-GCM",
            length: 256,
        },
        true,
        ["encrypt", "decrypt"]
    );
}

export async function encryptData(data) {
    if (isWebCryptoSupported()) {
        const encryptionKey = await getEncryptionKey();

        const dataBuffer = new TextEncoder().encode(data);
        const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization Vector (IV)

        const encryptedData = await crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            encryptionKey,
            dataBuffer
        );

        return toBase64FromBinaryBuffer(iv) + "|" + toBase64FromBinaryBuffer(encryptedData);
    } else {
        return null;
    }
}
export async function decryptData(encryptedData_) {
    if (isWebCryptoSupported()) {
        const encryptionKey = await getEncryptionKey();

        const _data = encryptedData_.split("|");
        const _iv = fromBase64ToBinaryBuffer(_data[0]);
        const _encryptedData = fromBase64ToBinaryBuffer(_data[1]);

        const decryptedData = await crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: _iv,
            },
            encryptionKey,
            _encryptedData
        );


        return new TextDecoder().decode(decryptedData);
    } else {
        return null;
    }
}

export function getCookieValue(key) {
    let _value = Cookies.get(key);
    if (isValidJSON(_value)) {
        _value = JSON.parse(_value);
    }
    return _value;
}
export function setCookieValue(key, value, expiration = 365) {
    let _value = value;
    if (!isCookieSizeWithinLimit(key, _value)) return;
    if (isValidJSON(JSON.stringify(_value))) {
        _value = JSON.stringify(_value);
    }
    Cookies.set(key, _value, { expires: expiration, secure: true, sameSite: "strict", path: "/" });
}
function getStringSizeInBytes(str) {
    // Approximate the size by converting the string to UTF-8 bytes
    return encodeURI(str).split(/%..|./).length - 1;
}
function isCookieSizeWithinLimit(cookieName, cookieValue) {
    // Calculate the size of the cookie value in bytes
    const valueSizeInBytes = getStringSizeInBytes(cookieValue);

    // Check if the size is within the limit
    if (valueSizeInBytes <= 4096) {
        return true;
    } else {
        console.warn(`Cookie '${cookieName}' size exceeds the maximum limit (4096 bytes).`);
        return false;
    }
}
function isValidJSON(value) {
    try {
        JSON.parse(value);
        return true;
    } catch (error) {
        return false;
    }
}

export async function shareCurrentPage(title, body = null) {
    // Check if the Web Share API is supported
    if (navigator.share) {
        try {
            await navigator.share({
                title: title,
                text: body,
                url: window.location.href,
            });
            console.log("Content shared successfully");
        } catch (err) {
            console.error("There was an error sharing:", err);
        }
    } else {
        // Fallback for browsers that do not support the Web Share API
        // Here, we're simply copying the URL to the clipboard
        try {
            await navigator.clipboard.writeText(window.location.href);
            notify("URL copied to clipboard");
        } catch (err) {
            notify("Error copying the current URL to the clipboard", "warn");
            console.error("Failed to copy URL:", err);
        }
    }
}

// export const BNConverter = () => {
//     var Units = {};

//     var rawUnits = {
//         wei: "1",
//         kwei: "1000",
//         Kwei: "1000",
//         babbage: "1000",
//         femtoether: "1000",
//         mwei: "1000000",
//         Mwei: "1000000",
//         lovelace: "1000000",
//         picoether: "1000000",
//         gwei: "1000000000",
//         Gwei: "1000000000",
//         shannon: "1000000000",
//         nanoether: "1000000000",
//         nano: "1000000000",
//         szabo: "1000000000000",
//         microether: "1000000000000",
//         micro: "1000000000000",
//         finney: "1000000000000000",
//         milliether: "1000000000000000",
//         milli: "1000000000000000",
//         ether: "1000000000000000000",
//         eth: "1000000000000000000",
//         kether: "1000000000000000000000",
//         grand: "1000000000000000000000",
//         mether: "1000000000000000000000000",
//         gether: "1000000000000000000000000000",
//         tether: "1000000000000000000000000000000",
//     };

//     var units = {};

//     Object.keys(rawUnits).map(function (unit) {
//         units[unit] = new BigNumber(rawUnits[unit], 10);
//     });

//     Units.units = rawUnits;

//     var re = RegExp(/^[0-9]+\.?[0-9]*$/);

//     Units.convert = function (value, from, to) {
//         console.log(value, from, to);
//         if (!re.test(value)) {
//             throw new Error("Unsupported value");
//         }

//         from = from.toLowerCase();
//         if (!units[from]) {
//             throw new Error("Unsupported input unit");
//         }

//         to = to.toLowerCase();
//         if (!units[to]) {
//             throw new Error("Unsupported output unit");
//         }

//         return new BigNumber(value, 10).multipliedBy(units[from]).decimalPlaces(0, BigNumber.ROUND_DOWN).div(units[to]).toString(10);
//     };
// };
