// import prettier from 'prettier'; // Check vite.config.js for the alias to make it build
// import solidityPlugin from 'prettier-plugin-solidity'; // Check vite.config.js for the alias to make it build

import prettier from "prettier/standalone";
import solidityPlugin from "prettier-plugin-solidity/standalone";

export const CodeEditorUtil = {
    calculateLineColumn: (sourceCode, start, end) => {
        let startLine = 1;
        let startColumn = 1;
        let charIndex = 0;

        while (charIndex < start) {
            if (sourceCode[charIndex] === "\n") {
                startLine++;
                startColumn = 1;
            } else {
                startColumn++;
            }
            charIndex++;
        }

        let endLine = startLine;
        let endColumn = startColumn;

        while (charIndex < end) {
            if (sourceCode[charIndex] === "\n") {
                endLine++;
                endColumn = 1;
            } else {
                endColumn++;
            }
            charIndex++;
        }

        return {
            startLine,
            startColumn,
            endLine,
            endColumn,
        };
    },
    highlightCode: (setCurrentCodeDebugDecoration, currentCodeDebugDecoration, sourceCode, start, end) => {
        try {
            // Calculate the line and column for start and end
            const range = CodeEditorUtil.calculateLineColumn(sourceCode, start, end);

            // get editor instance
            const _editor = window.monaco.editor.getEditors()[0];

            // Remove previous highlight
            if (currentCodeDebugDecoration) setCurrentCodeDebugDecoration(_editor.deltaDecorations(currentCodeDebugDecoration, [])); // update with new decoration

            // Add new highlight
            const _debugCurrentCodeDecoration = [
                {
                    id: "debugCode",
                    range: new monaco.Range(range.startLine, range.startColumn, range.endLine, range.endColumn),
                    options: {
                        isWholeLine: false,
                        linesDecorationsClassName: "debugCurrentCodeLine",
                        className: "debugCurrentCode",
                    },
                },
            ];
            setCurrentCodeDebugDecoration(_editor.deltaDecorations([], _debugCurrentCodeDecoration)); // update with new decoration
        } catch (error) {
            console.warn("Exception executing highlightCode", error);
        }
    },
    highlightErrorCode: (setCurrentCodeDecoration, currentCodeDecoration, highlightList_) => {
        try {
            // get editor instance
            const _editor = window.monaco.editor.getEditors()[0];
            setCurrentCodeDecoration(_editor.deltaDecorations(currentCodeDecoration, highlightList_)); // update with new decoration
        } catch (error) {
            console.warn("Exception executing highlightErrorCode", error);
        }
    },
    addErrorMarker: (markersList_) => {
        const _currentEditor = window.monaco.editor.getEditors()[0];
        window.monaco.editor.setModelMarkers(_currentEditor.getModel(), "compilationErrors", markersList_);
    },
    // REF: https://ohdarling88.medium.com/4-steps-to-add-custom-language-support-to-monaco-editor-5075eafa156d
    solidityConfig: {
        defaultToken: "invalid",

        keywords1: ["pragma", "returns", "require", "event", "emit", "return", "assert", "revert", "selfdestruct"],

        keywords2: [
            "solidity",
            "contract",
            "library",
            "interface",
            "function",
            "modifier",
            "enum",
            "struct",
            "mapping",
            "fixed",
            "ufixed",
            "mapping",
            "public",
            "private",
            "internal",
            "external",
            "pure",
            "view",
            "payable",
            "constructor",
            "fallback",
            "memory",
            "storage",
            "calldata",
            "delegatecall",
            "call",
            "this",
        ],

        typeKeywords: [
            "address",
            "bool",
            "bytes",
            "string",
            "uint",
            "uint8",
            "uint16",
            "uint24",
            "uint32",
            "uint40",
            "uint48",
            "uint56",
            "uint64",
            "uint72",
            "uint80",
            "uint88",
            "uint96",
            "uint104",
            "uint112",
            "uint120",
            "uint128",
            "uint136",
            "uint144",
            "uint152",
            "uint160",
            "uint168",
            "uint176",
            "uint184",
            "uint192",
            "uint200",
            "uint208",
            "uint216",
            "uint224",
            "uint232",
            "uint240",
            "uint248",
            "uint256",
            "int",
            "int8",
            "int16",
            "int24",
            "int32",
            "int40",
            "int48",
            "int56",
            "int64",
            "int72",
            "int80",
            "int88",
            "int96",
            "int104",
            "int112",
            "int120",
            "int128",
            "int136",
            "int144",
            "int152",
            "int160",
            "int168",
            "int176",
            "int184",
            "int192",
            "int200",
            "int208",
            "int216",
            "int224",
            "int232",
            "int240",
            "int248",
            "int256",
            "byte",
            "bytes",
            "enum",
            "struct",
            "mapping",
            "array",
            "modifier",
        ],

        operators: [
            "=",
            ">",
            "<",
            "!",
            "~",
            "?",
            ":",
            "==",
            "<=",
            ">=",
            "!=",
            "&&",
            "||",
            "++",
            "--",
            "+",
            "-",
            "*",
            "/",
            "&",
            "|",
            "^",
            "%",
            "<<",
            ">>",
            ">>>",
            "+=",
            "-=",
            "*=",
            "/=",
            "&=",
            "|=",
            "^=",
            "%=",
            "<<=",
            ">>=",
            ">>>=",
        ],

        symbols: /[=><!~?:&|+\-*\/\^%]+/,
        escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
        digits: /\d+(_+\d+)*/,
        octaldigits: /[0-7]+(_+[0-7]+)*/,
        binarydigits: /[0-1]+(_+[0-1]+)*/,
        hexdigits: /[[0-9a-fA-F]+(_+[0-9a-fA-F]+)*/,

        regexpctl: /[(){}\[\]\$\^|\-*+?\.]/,
        regexpesc: /\\(?:[bBdDfnrstvwWn0\\\/]|@regexpctl|c[A-Z]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4})/,

        tokenizer: {
            root: [[/[{}]/, "delimiter.bracket"], { include: "common" }],
            common: [
                // identifiers and keywords
                [
                    /[a-z_$][\w$]*/,
                    {
                        cases: {
                            "@typeKeywords": "variable",
                            "@operators": "operator",
                            "@keywords1": "keyword1",
                            "@keywords2": "keyword2",
                            "@default": "identifier",
                        },
                    },
                ],
                [/[A-Z][\w\$]*/, "type.identifier"], // to show class names nicely
                // [/[A-Z][\w\$]*/, 'identifier'],

                // TODO: Find the correct regex/way to find parameters name / function name
                // [/\s+\w+\s*\(([^)]+)\)/, "parameter"],
                // ref: https://microsoft.github.io/monaco-editor/playground.html - Custom Languages

                // Function name - not working
                // [/function\s+(\w+)\s*\(?/, "type.function"],
                [/\bfunction\s+(\w+)\s*\(/, ["type.function", "identifier"]], // Works on regex101 but not in Monaco

                // whitespace
                { include: "@whitespace" },

                // regular expression: ensure it is terminated before beginning (otherwise it is an opeator)
                [/\/(?=([^\\\/]|\\.)+\/([gimsuy]*)(\s*)(\.|;|\/|,|\)|\]|\}|$))/, { token: "regexp", bracket: "@open", next: "@regexp" }],

                // delimiters and operators
                [/[()\[\]]/, "@brackets"],
                [/[<>](?!@symbols)/, "@brackets"],
                [
                    /@symbols/,
                    {
                        cases: {
                            "@operators": "delimiter",
                            "@default": "",
                        },
                    },
                ],

                // numbers
                [/(@digits)[eE]([\-+]?(@digits))?/, "number.float"],
                [/(@digits)\.(@digits)([eE][\-+]?(@digits))?/, "number.float"],
                [/0[xX](@hexdigits)/, "number.hex"],
                [/0[oO]?(@octaldigits)/, "number.octal"],
                [/0[bB](@binarydigits)/, "number.binary"],
                [/(@digits)/, "number"],

                // delimiter: after number because of .\d floats
                [/[;,.]/, "delimiter"],

                // strings
                [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
                [/'([^'\\]|\\.)*$/, "string.invalid"], // non-teminated string
                [/"/, "string", "@string_double"],
                [/'/, "string", "@string_single"],
                [/`/, "string", "@string_backtick"],
            ],

            whitespace: [
                [/[ \t\r\n]+/, ""],
                [/\/\*\*(?!\/)/, "comment.doc", "@jsdoc"],
                [/\/\*/, "comment", "@comment"],
                [/\/\/.*$/, "comment"],
            ],

            comment: [
                [/[^\/*]+/, "comment"],
                [/\*\//, "comment", "@pop"],
                [/[\/*]/, "comment"],
            ],

            jsdoc: [
                [/[^\/*]+/, "comment.doc"],
                [/\*\//, "comment.doc", "@pop"],
                [/[\/*]/, "comment.doc"],
            ],

            regexp: [
                [/(\{)(\d+(?:,\d*)?)(\})/, ["regexp.escape.control", "regexp.escape.control", "regexp.escape.control"]],
                [/(\[)(\^?)(?=(?:[^\]\\\/]|\\.)+)/, ["regexp.escape.control", { token: "regexp.escape.control", next: "@regexrange" }]],
                [/(\()(\?:|\?=|\?!)/, ["regexp.escape.control", "regexp.escape.control"]],
                [/[()]/, "regexp.escape.control"],
                [/@regexpctl/, "regexp.escape.control"],
                [/[^\\\/]/, "regexp"],
                [/@regexpesc/, "regexp.escape"],
                [/\\\./, "regexp.invalid"],
                [/(\/)([gimsuy]*)/, [{ token: "regexp", bracket: "@close", next: "@pop" }, "keyword.other"]],
            ],

            regexrange: [
                [/-/, "regexp.escape.control"],
                [/\^/, "regexp.invalid"],
                [/@regexpesc/, "regexp.escape"],
                [/[^\]]/, "regexp"],
                [/\]/, { token: "regexp.escape.control", next: "@pop", bracket: "@close" }],
            ],

            string_double: [
                [/[^\\"]+/, "string"],
                [/@escapes/, "string.escape"],
                [/\\./, "string.escape.invalid"],
                [/"/, "string", "@pop"],
            ],

            string_single: [
                [/[^\\']+/, "string"],
                [/@escapes/, "string.escape"],
                [/\\./, "string.escape.invalid"],
                [/'/, "string", "@pop"],
            ],

            string_backtick: [
                [/\$\{/, { token: "delimiter.bracket", next: "@bracketCounting" }],
                [/[^\\`$]+/, "string"],
                [/@escapes/, "string.escape"],
                [/\\./, "string.escape.invalid"],
                [/`/, "string", "@pop"],
            ],

            bracketCounting: [[/\{/, "delimiter.bracket", "@bracketCounting"], [/\}/, "delimiter.bracket", "@pop"], { include: "common" }],
        },
    },
    addSolidity: (monaco, callbackExecuteFunction) => {
        console.debug("Adding Monaco Configuration - if see this message more than once, we have an issue!");
        // ref: https://ohdarling88.medium.com/4-steps-to-add-custom-language-support-to-monaco-editor-5075eafa156d
        //const _editor = window.monaco.editor.getEditors()[0];
        // const _monacoInstance = window.monaco;
        const _monacoInstance = monaco;
        // console.log("_monacoInstance", _monacoInstance);
        if (_monacoInstance) {
            // console.log("_editorInstances ", _monacoInstance.editor.getEditors());

            _monacoInstance.languages.register({ id: "solidity" });
            // console.log("_monacoInstance.languages.getLanguages", _monacoInstance.languages.getLanguages());

            _monacoInstance.languages.setMonarchTokensProvider("solidity", CodeEditorUtil.solidityConfig);

            // console.log("_monacoInstance.languages.getLanguages", _monacoInstance.languages.getLanguages());

            // TODO: Function name
            // TODO: parameters
            const theme1 = {
                base: "vs-dark",
                inherit: true,
                rules: [
                    // { token: "comment", foreground: "74B418" }, // green (needs to be lighter) - use theme default
                    { token: "variable", foreground: "7BEA8A" }, // light green
                    { token: "number", foreground: "86A6E9" }, // blue
                    { token: "keyword1", foreground: "B668B2" }, // purple C559BF
                    { token: "keyword2", foreground: "86A6E9" }, // blue
                    { token: "identifier", foreground: "c3c3c3" }, // gray
                    { token: "string", foreground: "EFB371" }, // light orange
                    { token: "delimiter", foreground: "c3c3c3" }, // gray
                    { token: "delimiter.bracket", foreground: "C0C856" }, // light yellow
                    { token: "entity.name.function", foreground: "ff2ee1" }, //
                    { token: "type.function", foreground: "ff2ee1" }, //
                    // TODO: { token: "parameter", foreground: "C0C856" }, // DOESNT WORK
                    // TODO: { token: "operator", foreground: "ff2ee1" }, // magenta // DOESNT WORK
                ],
                colors: {
                    // ref: https://github.com/microsoft/monaco-editor/issues/1631 - See the JS to run in playground and get all the options
                    "editor.foreground": "#ffffff", // change
                    "editor.background": "#1a1511",
                    "editor.selectionBackground": "#4e2b13",
                    "editor.inactiveSelectionBackground": "#4e2b13",
                    "editor.selectionHighlight": "#00ff0066",
                    "editor.selectionHighlightBorder": "#122d42",
                    "editor.lineHighlightBackground": "#3A312C",
                    "editorCursor.foreground": "#ffb564",
                    "editorWhitespace.foreground": "#BFBFBF",
                    "editorIndentGuide.background": "#666666",
                    "editorIndentGuide.activeBackground": "#86A6E9",
                    "editor.highlightBackground": "#9a4a9c88",
                    "editorOverviewRuler.selectionHighlightForeground": "#C0C856",
                },
            };

            const _editor = window.monaco.editor;
            _editor.defineTheme("solidityTheme", theme1);
            _editor.setTheme("solidityTheme");

            _monacoInstance.languages.registerCompletionItemProvider("solidity", {
                provideCompletionItems: (model, position) => {
                    let completionItems;
                    try {
                        // TODO: not 100% accurate but works ok, issue getting variable names inside a function
                        const textUntilPosition = model.getValueInRange({
                            startLineNumber: 1,
                            startColumn: 1,
                            endLineNumber: position.lineNumber,
                            endColumn: position.column,
                        });
                        console.log(textUntilPosition);
                        const variableRegex = new RegExp(`\\b(${CodeEditorUtil.solidityConfig.typeKeywords.join("|")})\\s+(public|private|internal)?\\s*(?!is\\b)([a-zA-Z_][a-zA-Z0-9_]*)\\b`, "g");
                        const localVarRegex = new RegExp(`\\b(${CodeEditorUtil.solidityConfig.typeKeywords.join("|")})\\s+(?!is\\b)([a-zA-Z_][a-zA-Z0-9_]*)\\b`, "g");

                        const lines = textUntilPosition.split("\n");
                        const variables = new Set();

                        for (const line of lines) {
                            let match;
                            if (!line.includes("(") && !line.includes(")")) {
                                while ((match = variableRegex.exec(line))) {
                                    const varName = match[3];
                                    if (varName && !line.trim().startsWith("event")) {
                                        variables.add(varName);
                                    }
                                }
                            } else if (line.includes("=") && !line.includes("function")) {
                                while ((match = localVarRegex.exec(line))) {
                                    const localVarName = match[2];
                                    if (localVarName) {
                                        variables.add(localVarName);
                                    }
                                }
                            }
                        }

                        const _variables = Array.from(variables);
                        completionItems = _variables.map((varName) => {
                            return {
                                label: varName,
                                kind: monaco.languages.CompletionItemKind.Variable,
                                insertText: varName,
                            };
                        });
                    } catch (error) {
                        console.log(error);
                        //return { suggestions: [] };
                    }
                    // Test: getting variable names in the code

                    const word = model.getWordUntilPosition(position);
                    const range = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endColumn: word.endColumn,
                    };
                    const suggestions = [
                        {
                            label: "ifelse",
                            kind: monaco.languages.CompletionItemKind.Snippet,
                            insertText: ["if (${1:condition}) {", "\t$0", "} else if {", "\t", "}"].join("\n"),
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: "If-Else Statement",
                            range: range,
                        },
                        {
                            label: "if",
                            kind: monaco.languages.CompletionItemKind.Snippet,
                            insertText: ["if (${1:condition}) {", "\t$0", "}"].join("\n"),
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: "If Statement",
                            range: range,
                        },
                        {
                            label: "function",
                            kind: monaco.languages.CompletionItemKind.Snippet,
                            insertText: ["function ${1:name} (${2:datatype} ${3:paramaterName}) {", "\t$0", "}"].join("\n"),
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: "Function Statement",
                            range: range,
                        },
                        {
                            label: "require",
                            kind: monaco.languages.CompletionItemKind.Snippet,
                            insertText: ['require(${1:what} ${2:operator} ${3:value}, "${4:revertMessage}");'].join("\n"),
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: "Require Statement",
                            range: range,
                        },
                        {
                            label: "mapping",
                            kind: monaco.languages.CompletionItemKind.Snippet,
                            insertText: ["mapping(${1:dataType} => ${2:dataType}) ${3:visibility} ${4:name};"].join("\n"),
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            documentation: "Mapping Statement",
                            range: range,
                        },
                        ...CodeEditorUtil.solidityConfig.keywords1.map((k) => {
                            return {
                                label: k,
                                kind: monaco.languages.CompletionItemKind.Keyword,
                                insertText: k,
                            };
                        }),
                        ...CodeEditorUtil.solidityConfig.keywords2.map((k) => {
                            return {
                                label: k,
                                kind: monaco.languages.CompletionItemKind.Keyword,
                                insertText: k,
                            };
                        }),
                        ...CodeEditorUtil.solidityConfig.typeKeywords.map((k) => {
                            return {
                                label: k,
                                kind: monaco.languages.CompletionItemKind.Text,
                                insertText: k,
                            };
                        }),
                    ];

                    const allSuggestions = [...completionItems, ...suggestions];

                    return { suggestions: allSuggestions };
                },
            });

            // auto format
            _monacoInstance.languages.registerDocumentFormattingEditProvider("solidity", {
                async provideDocumentFormattingEdits(model) {
                    const formattedCode = await prettier.format(model.getValue(), {
                        parser: "solidity-parse",
                        plugins: [solidityPlugin],
                        singleQuote: false,
                        printWidth: 200,
                        tabWidth: 4,
                        useTabs: false,
                        bracketSpacing: false,
                    });

                    return [
                        {
                            range: model.getFullModelRange(),
                            text: formattedCode,
                        },
                    ];
                },
            });
        }
    },
    // monacoEditorRetryCount: 0,
    // monacoEditorMaxRetries: 3,
    addActions: (callbackExecuteFunction) => {
        let _currentEditor = window.monaco.editor.getEditors()[0];
        // Assuming you have a reference to the editor instance
        // - read-only readonly mode - _currentEditor.updateOptions({ readOnly: true }); // WORKS !
        // if (!_currentEditor) {
        //     if (CodeEditorUtil.monacoEditorRetryCount < CodeEditorUtil.monacoEditorMaxRetries) {
        //         console.warn("Issue loading editor, retrying... monacoEditorRetryCount", monacoEditorRetryCount);
        //         CodeEditorUtil.monacoEditorRetryCount++;
        //         setTimeout(CodeEditorUtil.addActions(callbackExecuteFunction), 10000); // Retry after 1 second
        //     } else {
        //         console.warn("Failed to get editor instance after 3 retries.");
        //     }
        // } else {
        // Success, you have the editor instance
        // console.log("Editor instance found after", CodeEditorUtil.monacoEditorRetryCount, "tentatives :", _currentEditor);

        _currentEditor.addAction({
            // An unique identifier of the contributed action.
            id: "contractExecuteFunction",

            // A label of the action that will be presented to the user.
            label: "Execute function",

            // An optional array of keybindings for the action.
            keybindings: [
                // monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10,
                monaco.KeyCode.F8,
                // chord
                // monaco.KeyMod.chord(
                //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK,
                //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM
                // ),
            ],

            // A precondition for this action.
            precondition: null,

            // A rule to evaluate on top of the precondition in order to dispatch the keybindings.
            keybindingContext: null,

            contextMenuGroupId: "actions",

            contextMenuOrder: 1.1,

            run: function (editor) {
                console.log("Finding my precious funtion, I am at => " + editor.getPosition());

                const _function = findNearestFunctionName(editor.getPosition(), editor.getValue());
                console.log("Function Found", _function);

                callbackExecuteFunction("execution", _function.functionName);
            },
        });
        _currentEditor.addAction({
            id: "contractSimulateFunction",
            label: "Simulate function",
            keybindings: [monaco.KeyCode.F2],
            precondition: null,
            keybindingContext: null,
            contextMenuGroupId: "actions",
            contextMenuOrder: 1.2,
            run: function (editor) {
                const _function = findNearestFunctionName(editor.getPosition(), editor.getValue());
                callbackExecuteFunction("simulation", _function.functionName);
            },
        });
        _currentEditor.addAction({
            id: "contractCompile",
            label: "Compile",
            keybindings: [monaco.KeyCode.F9],
            precondition: null,
            keybindingContext: null,
            contextMenuGroupId: "actions",
            contextMenuOrder: 1.3,
            run: function (editor) {
                callbackExecuteFunction("compile", null);
            },
        });
        _currentEditor.addAction({
            id: "contractDeploy",
            label: "Deploy",
            keybindings: [monaco.KeyCode.F10],
            precondition: null,
            keybindingContext: null,
            contextMenuGroupId: "actions",
            contextMenuOrder: 1.4,
            run: function (editor) {
                callbackExecuteFunction("deploy", null);
            },
        });
        // _currentEditor.addAction({
        //     id: "contractVerify",
        //     label: "Verify",
        //     keybindings: [monaco.KeyCode.F12],
        //     precondition: null,
        //     keybindingContext: null,
        //     contextMenuGroupId: "actions",
        //     contextMenuOrder: 1.5,
        //     run: function (editor) {
        //         alert("Not implemented yet");
        //         //callbackExecuteFunction("verify", null);
        //     },
        // });

        // TODO: Functions declarations in more than line are not considered
        function findNearestFunctionName(cursorPosition, code) {
            const { lineNumber, columnNumber } = cursorPosition;

            // Split the code into lines
            const lines = code.split("\n");

            // Iterate over each line to find the nearest function
            for (let i = lineNumber - 1; i >= 0; i--) {
                const lineText = lines[i];

                // Use regular expression to match the function declaration and extract the function name, parameters, and parameter data types
                const regex = /function\s+(\w+)\s*\((.*?)\)/;
                const match = lineText.match(regex);

                // If a match is found, extract the function name, parameters, and parameter data types
                // TODO: Maybe parameters are not necessary, since we can have it later using the ABI
                if (match) {
                    const functionName = match[1];
                    const parametersText = match[2];

                    if (parametersText) {
                        const paramStrings = parametersText.split(",");
                        const parameterList = paramStrings.map((paramString) => {
                            const paramParts = paramString.trim().split(/\s+/);
                            const paramTypeIndex = paramParts.findIndex(isSpecialData);

                            let paramName = null;
                            let paramType = null;
                            let dataLocation = null;

                            if (paramTypeIndex > -1) {
                                paramName = paramParts.slice(paramTypeIndex + 1).join(" ");
                                paramType = paramParts.slice(0, paramTypeIndex).join(" ");
                                dataLocation = paramParts[paramTypeIndex];
                            } else {
                                paramName = paramParts.pop();
                                paramType = paramParts.join(" ");
                            }

                            return { name: paramName, type: paramType, dataLocation };
                        });

                        return { functionName, parameters: parameterList };
                    } else {
                        return { functionName, parameters: null };
                    }
                }
            }

            // No function found
            return null;
        }
        // Helper function to check if a data type is a special data type
        function isSpecialData(dataType) {
            const specialDataTypes = ["calldata", "memory", "storage", "internal", "external", "public"];
            return specialDataTypes.includes(dataType);
        }
        // }
    },
};
