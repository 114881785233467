import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import PopupNetworkSearchNetwork from "./PopupNetworkAppSearchNetwork";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const PopupNetworkApp = () => {
    const { networks, setCurrentNetwork, networkNames, getNetwork, currentNetworkInput, setCurrentNetworkInput, clearExecuteForm, currentNetwork } = useContext(AppContext);
    return (
        <dialog id="popupNetworkApp" className="modal rounded-lg bg-transparent">
            <form method="dialog" className="modal-box text-light w-[500px] min-h-[650px] flex flex-col justify-between">
                <div>
                    <h3 className="font-bold text-lg text-white">Network</h3>
                    <div className="pt-4">
                        <p className="text-secondary">Current Network</p>
                        <p>
                            {currentNetwork && (
                                <span>
                                    {currentNetwork.name} ({currentNetwork.id})
                                </span>
                            )}
                            {!currentNetwork && <span>&nbsp;</span>}
                        </p>
                        <p className="pt-4 text-secondary">Select a New Network</p>
                        <PopupNetworkSearchNetwork />
                    </div>
                </div>
                <div className="modal-action flex justify-end pt-20">
                    <button className="btn-default mt-2 justify-end">
                        Close
                        <CloseOutlined className="pl-2 text-red" />
                    </button>
                </div>
            </form>
        </dialog>
    );
};

export default PopupNetworkApp;
