import { useEffect, useState, useContext } from "react";
import PopupHistory from "./PopupHistory";
import PopupNetwork from "./PopupNetwork";
import PopupWallet from "./PopupWallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faFloppyDisk, faKey, faNetworkWired, faShare, faWallet } from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { shareCurrentPage } from "@util/util";
import { Web3util } from "@util/web3util";
import { FileUtil } from "@util/fileUtil";
import { notify } from "@util/component";
import Accordion from "@components/shared/Accordion/Accordion";

import { AppContext } from "@context/AppContext";

const NetworkPanel = ({
    tabsNetworkContractsSourceCode,
    contractsInfoEditor,
    activeTab,
    setActiveTab,
    rootContract,
    contractsNetworkEditor,
    setContractsNetworkEditor,
    setContractsWalletEditor,
    contractsWalletEditor,
}) => {
    const [currentDirectory, setCurrentDirectory] = useState();
    const [currentContractImplementationList, setCurrentContractImplementationList] = useState();
    const [currentContractProxyList, setCurrentContractProxyList] = useState();

    // - TODO BUG proxy should show read-only, implementation should show editable
    // tabsNetworkContractsSourceCode has one index and then the setCurrentContractImplementationList and setCurrentContractProxy has a different one
    // Need save the index in the new lists to match when the user clicks on the contract
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    function sortContractsByImports(currentContractImplementationList, rootContractName) {
        const rootContract = currentContractImplementationList.find((contract) => contract.contract === rootContractName);
        const sortedList = [rootContract];

        function sortContracts(contractList, sortedList) {
            contractList.forEach((contract) => {
                const imports = rootContract.code.match(/import\s+.*;/g) || [];
                imports.forEach((importStatement) => {
                    const importPath = importStatement.match(/import\s+(.*);/)[1].replace(/"/g, "");
                    const importedContract = currentContractImplementationList.find((contract) => contract.path === importPath);
                    if (importedContract && !sortedList.includes(importedContract)) {
                        sortedList.push(importedContract);
                        sortContracts([importedContract], sortedList);
                    }
                });
            });
        }

        sortContracts(
            currentContractImplementationList.filter((contract) => contract !== rootContract),
            sortedList
        );

        const unsortedContracts = currentContractImplementationList.filter((contract) => !sortedList.includes(contract));
        sortedList.push(...unsortedContracts);

        return sortedList;
    }

    // TODO: Copied to the CodeEditor.jsx to use control + S (move a proper place to use it in the future)
    async function saveFiles() {
        let _directory = currentDirectory;
        if (!currentDirectory) {
            _directory = await FileUtil.getDirectory();
            setCurrentDirectory(_directory);
        }
        for (let contract = 0; contract < tabsNetworkContractsSourceCode.length; contract++) {
            const _contract = tabsNetworkContractsSourceCode[contract];
            const _newFile = await FileUtil.createNewFile(_contract.contract + ".sol", _directory);
        }
        notify("Files saved successfully", "info");
    }

    useEffect(() => {
        try {
            // Save the original index of the contract in the array tabsNetworkContractsSourceCode
            for (let idx = 0; idx < tabsNetworkContractsSourceCode.length; idx++) {
                tabsNetworkContractsSourceCode[idx].index = idx;
            }
            if (contractsInfoEditor.isProxy) {
                let _listImplementation = tabsNetworkContractsSourceCode.filter((contract) => contract.type === "implementation");
                try {
                    _listImplementation = sortContractsByImports(_listImplementation, rootContract);
                } catch (err) {
                    _listImplementation = tabsNetworkContractsSourceCode.filter((contract) => contract.type === "implementation");
                }
                setCurrentContractImplementationList(_listImplementation);
                const _listProxy = tabsNetworkContractsSourceCode.filter((contract) => contract.type === "proxy");
                setCurrentContractProxyList(_listProxy);

                const _rootContract = _listImplementation.find((contract) => contract.contract === rootContract);
                setActiveTab(_rootContract.index);
            } else {
                let _list = tabsNetworkContractsSourceCode;
                try {
                    _list = sortContractsByImports(_list, rootContract);
                } catch (err) {
                    _list = tabsNetworkContractsSourceCode;
                }
                setCurrentContractImplementationList(_list);

                const _rootContract = tabsNetworkContractsSourceCode.find((contract) => contract.contract.toLowerCase() === rootContract.toLowerCase());
                setActiveTab(_rootContract.index);
            }
        } catch (error) {
            setActiveTab(0);
        }
    }, [tabsNetworkContractsSourceCode]);

    return (
        <div className="">
            <div className="py-2 flex items-center justify-between text-white">
                <p className="px-2 font-semibold text-md">Contract Editor</p>
                <span className="ml-auto flex items-center">
                    <button
                        className="cursor-pointer"
                        onClick={async () => {
                            saveFiles();
                        }}>
                        <FontAwesomeIcon icon={faFloppyDisk} className="text-light pr-2 h-4" />
                    </button>
                    <button
                        className="cursor-pointer"
                        onClick={() => {
                            shareCurrentPage(
                                "Smartcontract: " + rootContract + " in " + contractsNetworkEditor.name + " network",
                                "Open the link to see the code and execute functions using DebWeb3 platform.",
                                false
                            );
                        }}>
                        <FontAwesomeIcon icon={faShare} className="text-light pr-2 h-4" />
                    </button>
                    <button
                        className="cursor-pointer"
                        onClick={() => {
                            window.popupHistory.showModal();
                        }}>
                        <FontAwesomeIcon icon={faClockRotateLeft} className="text-light pr-2 h-4" />
                    </button>
                    <button
                        className="cursor-pointer"
                        onClick={() => {
                            if (contractsInfoEditor.isProxy) notify("Network change is disabled for proxies contracts", "info");
                            else window.popupNetwork.showModal();
                        }}>
                        <FontAwesomeIcon icon={faNetworkWired} className="text-light pr-2 h-4" />
                    </button>
                    <button
                        className="cursor-pointer"
                        onClick={() => {
                            window.popupWallet.showModal();
                        }}>
                        <FontAwesomeIcon icon={faWallet} className="text-light h-4" />
                    </button>
                </span>
            </div>
            {/* // - Created to show the source code of a contract in the Execute Page */}
            {!contractsInfoEditor.isProxy && (
                <div className="py-2 flex items-center justify-between">
                    <p className="px-2 text-white font-semibold text-md">Explorer</p>
                </div>
            )}

            {/* // - Disabled to only show the source code of a contract in the Execute Page */}
            {/* <div className="flex items-center justify-center py-2 w-full">
                <button
                    className="btn btn-default"
                    onClick={async () => {
                        alert("Code me!");
                    }}>
                    Use Selected
                    <FontAwesomeIcon icon={faFileArrowDown} className="pl-2" />
                </button>
                <button
                    className="btn btn-default ml-4"
                    onClick={async () => {
                        alert("Code me!");
                    }}>
                    Select a contract
                    <FontAwesomeIcon icon={faEthereum} className="pl-2" />
                </button>
            </div> */}
            {/* <div className="contract-items py-2 unselectable">
                {currentContractList &&
                    currentContractList.map((tab, index) => (
                        <div key={tab.contract} className={`contract-item ${activeTab === index ? "selected-item" : ""}`} onClick={() => handleTabClick(index)}>
                            <FontAwesomeIcon icon={faEthereum} className="icon pr-[5px]" />
                            {rootContract === tab.contract && <FontAwesomeIcon icon={faKey} className="icon px-1" alt="Root Contract" />}
                            {tab.type === "implementation" && <FontAwesomeIcon icon={faIdBadge} className="icon px-1" alt="Implementation Contract" />}
                            <span className="file-name">{tab.contract}</span>
                        </div>
                    ))}
            </div> */}
            {/* Use file-name_disabled to proxies contracts */}

            {contractsInfoEditor.isProxy && currentContractImplementationList && (
                <div className="py-1 unselectable">
                    <Accordion
                        id="NetworkPanelAccordionImplementationWithProxyList"
                        name="NetworkPanelAccordionImplementationWithProxyList"
                        title={"Implementation (" + currentContractImplementationList.length + ")"}
                        titleTooltip={contractsInfoEditor.implementation.address}
                        subtitle={Web3util.shortAddress(contractsInfoEditor.implementation.address, 4, 6)}
                        alertMessage={
                            contractsInfoEditor.implementation &&
                            contractsInfoEditor.implementation.address &&
                            contractsInfoEditor.implementation.address.toLowerCase() !== contractsInfoEditor.implementation.addressFromNetwork.toLowerCase() &&
                            "The registred implementation address in the Network " +
                                contractsInfoEditor.implementationFromNetwork +
                                " is different from the Block Explorer. Submit a new contract verification of the new implementation contract in the Block Explorer. If you already did, wait at least 15 minutes and check it again."
                        }
                        opened={true}
                        topBorder={false}
                        compactMode={true}
                        items={currentContractImplementationList.map((tab, index) => (
                            <div
                                id="NetworkPanelContractImplementationWithProxyListItemSelect"
                                name="NetworkPanelContractImplementationWithProxyListItemSelect"
                                key={tab.contract}
                                className={`contract-item ${activeTab === tab.index ? "selected-item" : ""}`}
                                onClick={() => handleTabClick(tab.index)}>
                                <FontAwesomeIcon
                                    id="NetworkPanelContractImplementationWithProxyListItemSelectIcon"
                                    name="NetworkPanelContractImplementationWithProxyListItemSelect"
                                    icon={faEthereum}
                                    className="icon pr-[5px]"
                                />
                                {/* {rootContract !== tab.contract && <FontAwesomeIcon icon={faSitemap} className="icon px-1" alt="Depedency Contract" />} */}
                                {rootContract === tab.contract && (
                                    <FontAwesomeIcon
                                        id="NetworkPanelContractImplementationWithProxyListItemSelectKey"
                                        name="NetworkPanelContractImplementationWithProxyListItemSelectKey"
                                        icon={faKey}
                                        className="icon px-1"
                                        alt="Root Contract"
                                    />
                                )}
                                {/* {tab.type === "implementation" && <FontAwesomeIcon icon={faIdBadge} className="icon px-1" alt="Implementation Contract" />} */}
                                <span id="NetworkPanelContractImplementationWithProxyListItemSelectText" name="NetworkPanelContractImplementationWithProxyListItemSelectText" className="file-name">
                                    {tab.contract}
                                </span>
                            </div>
                        ))}
                    />
                </div>
            )}
            {contractsInfoEditor.isProxy && currentContractProxyList && (
                <div className="unselectable">
                    <Accordion
                        id="NetworkPanelAccordionProxyList"
                        name="NetworkPanelAccordionProxyList"
                        title={"Proxy (" + currentContractProxyList.length + ")"}
                        titleTooltip={contractsInfoEditor.baseContractAddress}
                        subtitle={Web3util.shortAddress(contractsInfoEditor.baseContractAddress, 4, 6)}
                        opened={false}
                        topBorder={false}
                        compactMode={true}
                        items={currentContractProxyList.map((tab, index) => (
                            <div
                                id="NetworkPanelContractProxyListItemSelect"
                                name="NetworkPanelContractProxyListItemSelect"
                                key={tab.contract}
                                className={`contract-item ${activeTab === tab.index ? "selected-item" : ""}`}
                                onClick={() => handleTabClick(tab.index)}>
                                <FontAwesomeIcon id="NetworkPanelContractProxyListItemSelectIcon" name="NetworkPanelContractProxyListItemSelectIcon" icon={faEthereum} className="icon pr-[5px]" />
                                {/* {rootContract !== tab.contract && <FontAwesomeIcon icon={faSitemap} className="icon px-1" alt="Depedency Contract" />} */}
                                {rootContract === tab.contract && <FontAwesomeIcon icon={faKey} className="icon px-1" alt="Root Contract" />}
                                {/* {tab.type === "implementation" && <FontAwesomeIcon icon={faIdBadge} className="icon px-1" alt="Implementation Contract" />} */}
                                <span id="NetworkPanelContractProxyListItemSelectText" name="NetworkPanelContractProxyListItemSelectText" className="file-name-disabled">
                                    {tab.contract}
                                </span>
                            </div>
                        ))}
                    />
                </div>
            )}
            {!contractsInfoEditor.isProxy && currentContractImplementationList && (
                <div className="contract-items py-1 unselectable">
                    {currentContractImplementationList.map((tab, index) => (
                        <div
                            id="NetworkPanelContractImplementationListItemSelect"
                            name="NetworkPanelContractImplementationListItemSelect"
                            key={tab.contract}
                            className={`contract-item ${activeTab === tab.index ? "selected-item" : ""}`}
                            onClick={() => handleTabClick(tab.index)}>
                            <FontAwesomeIcon
                                id="NetworkPanelContractImplementationListItemSelectIcon"
                                name="NetworkPanelContractImplementationListItemSelectIcon"
                                icon={faEthereum}
                                className="icon pr-[5px]"
                            />
                            {rootContract === tab.contract && <FontAwesomeIcon icon={faKey} className="icon px-1" alt="Root Contract" />}
                            {/* {tab.type === "implementation" && <FontAwesomeIcon icon={faIdBadge} className="icon px-1" alt="Implementation Contract" />} */}
                            <span id="NetworkPanelContractImplementationListItemSelectText" name="NetworkPanelContractImplementationListItemSelectText" data-info={tab.contract} className="file-name">
                                {tab.contract}
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <PopupHistory />
            <PopupNetwork currentEditorNetwork={contractsNetworkEditor} setCurrentEditorNetwork={setContractsNetworkEditor} />
            <PopupWallet setParentWallet={setContractsWalletEditor} contractsNetworkEditor={contractsNetworkEditor} />
        </div>
    );
};

export default NetworkPanel;
