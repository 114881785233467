import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";

const PopupConfirmation = ({ mainMessage, message, proceedMessage, onSelection }) => {
    const handleSelection = (option) => {
        onSelection(option);
    };

    return (
        <>
            <dialog id="popupConfirmation" className="modal rounded-lg bg-transparent">
                <form method="dialog" className="modal-box min-w-fit">
                    {/* <input type="checkbox" id="popupConfirmation" className="modal-toggle" />
            <div className="modal"> */}
                    {/* <div className="modal-box min-w-fit"> */}
                    <p className="text-white font-bold text-lg">{mainMessage}</p>
                    {message && <p className="text-white pt-4">{message}</p>}
                    {proceedMessage && <p className="text-white pt-10 font-bold text-lg">{proceedMessage}</p>}
                    <div className="flex justify-end pt-10">
                        <label htmlFor="popupConfirmation" className="btn-default mt-2 justify-end" onClick={() => handleSelection(true)}>
                            Yes
                            <FontAwesomeIcon icon={faCheck} className="pl-2 text-green" />
                        </label>
                        <label htmlFor="popupConfirmation" className="btn-default mt-2 ml-5 justify-end" onClick={() => handleSelection(false)}>
                            No
                            <FontAwesomeIcon icon={faXmark} className="pl-2 text-red" />
                        </label>
                    </div>
                    {/* </div>
            </div> */}
                </form>
            </dialog>
        </>
    );
};

export default PopupConfirmation;
