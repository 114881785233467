import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import { Web3util } from "@util/web3util";
import { notify } from "@util/component";
import { getCookieValue, setCookieValue } from "@util/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faCube, faFileCircleCheck, faCopy, faRotate, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Accordion from "@components/shared/Accordion/Accordion";
import PopupDeployParameters from "./PopupDeployParameters";
import PopupDeploymentFinished from "./PopupDeploymentFinished";
import Tooltip from "@components/shared/Tooltip/Tooltip";
import { HistoryCookieKey } from "@util/enum";
import PopupConfirmation from "@components/modals/PopupConfirmation";

const DeploymentPanel = ({
    activeTabLocalNetwork,
    rootContract,
    compilationResult,
    contractSourceCode,
    contractContructorArguments = "",
    compilerVersion,
    optimizationUsed,
    optimizationRuns,
    contractsInfo,
    contractsNetworkEditor,
    contractsWalletEditor,
    callDeployment,
    setCallDeployment,
}) => {
    const { deployContract, verifyContract, loadCompilerVersions, verifyContractStatus, executeFunctionEditor } = useContext(AppContext);
    const [lastDeploymentInfo, setLastDeploymentInfo] = useState(null);
    const [lastVerificationInfo, setLastVerificationInfo] = useState(null);
    const [lastDeploymentDateTime, setLastDeploymentDateTime] = useState();
    // const [deploymentNetwork, setDeploymentNetwork] = useState();
    const [deploying, setDeploying] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState();
    const [verificationStatusMessage, setVerificationStatusMessage] = useState();
    const [autoVerify, setAutoVerify] = useState(true);
    const [autoVerifyStatus, setAutoVerifyStatus] = useState();
    const [deploymentPanelWasOpen, setDeploymentPanelWasOpen] = useState(false);
    const [deploymentParameters, setDeploymentParameters] = useState();
    const [currentABI, setCurrentABI] = useState(); // sucks but I need to use it pass back on the popup
    const [currentBytecode, setCurrentBytecode] = useState(); // sucks but I need to use it pass back on the popup
    const [constructorParameters, setConstructorParameters] = useState(); // sucks but I need to use it pass back on the popup
    const [initializeStatus, setInitializeStatus] = useState("Not started");
    const [proxyUpgradeToStatus, setProxyUpgradeToStatus] = useState("Not set");
    const [popupMessage1, setPopupMessage1] = useState(null);
    const [popupMessage2, setPopupMessage2] = useState(null);
    const [popupMessage3, setPopupMessage3] = useState(null);
    const [popupCallback, setPopupCallback] = useState(null);

    useEffect(() => {
        if (callDeployment) {
            deploy();
        }
        setCallDeployment(false);
    }, [callDeployment]);

    function onInputChange(event) {
        if (event.target.name === "autoverify") {
            setAutoVerify(event.target.checked);
        }
    }

    async function validateDeployment() {
        if (activeTabLocalNetwork === "network") {
            if (!compilationResult || !rootContract || !contractsInfo) {
                notify("You have to compile before deploying the contract", "warn");
                return false;
            }
            if (!contractsWalletEditor) {
                window.popupWallet.showModal();
                // notify("Select a wallet on Contract Editor icon to deploy the contract", "warn");
                return false;
            }
        }
        if (activeTabLocalNetwork === "local")
            if (!compilationResult || !rootContract || !contractsWalletEditor) {
                notify("Compile and select a wallet to deploy the contract", "warn");
                return false;
            }

        const { numErrors, numWarnings } = Web3util.countErrorsAndWarnings(compilationResult);
        if (numErrors > 0) {
            notify("Review and fix the compilation errors before deploy the contract", "warn");
            return false;
        }
        return true;
    }

    async function confirmDeployment() {
        const _validation = await validateDeployment();
        if (_validation) {
            setPopupMessage1("We DO NOT validate changes in the Storage Layout during the deployment process. Be aware that can cause data loss during the upgrade process.");
            setPopupMessage2("Also, your wallet should have proper permissions to execute the upgrade process.");
            setPopupMessage3("Do you want to proceed?");
            setPopupCallback("deployment");
            window.popupConfirmation.showModal();
            return true;
        } else return false;
    }

    const handleConfirmations = (option) => {
        window.popupConfirmation.close();
        console.log("handleConfirmation - User selected:", option);
        if (popupCallback === "deployment" && option) {
            console.log("deployment accepted");
            // DEPLOY
            deploy();
        }
    };

    function manageAutoVerify(deploymentInfo_, parameters_, _lastDeploymentDateTime) {
        if (autoVerify) {
            // Wait for 30 seconds and then start the verification process
            setAutoVerifyStatus("Waiting until the deployed source code is available to be verified");
            setTimeout(() => {
                setAutoVerifyStatus();
                verify(deploymentInfo_, parameters_, _lastDeploymentDateTime);
            }, 30000);
        }
    }

    async function deploy() {
        try {
            setLastVerificationInfo(null);
            setVerificationStatusMessage(null);
            setVerificationStatus(null);
            let abi;
            let bytecode;
            console.log("rootContract", rootContract);
            // console.log("rootcompilationResult.output.contracts", rootcompilationResult.output.contracts["contracts/" + rootContract + ".sol"][rootContract]Contract);
            if (activeTabLocalNetwork === "network") {
                abi = compilationResult.output.contracts["contracts/" + rootContract + ".sol"][rootContract].abi; // contracts/[contractname.sol]
                bytecode = compilationResult.output.contracts["contracts/" + rootContract + ".sol"][rootContract].evm.bytecode.object;
            } else {
                abi = compilationResult.output.contracts[rootContract + ".sol"][rootContract].abi;
                bytecode = compilationResult.output.contracts[rootContract + ".sol"][rootContract].evm.bytecode.object;
            }

            // TODO: offer this option to the user -> initilize is optional for proxy contracts with implementation that cointains a initialize function, show the popup
            // Constructor with parameters (arguments)
            let _constructorParameters = abi.find((_function) => _function.type === "constructor"); //type: 'constructor',
            console.log("_constructorParameters abi", abi);
            console.log("_constructorParameters", _constructorParameters);
            if (_constructorParameters && _constructorParameters.inputs && _constructorParameters.inputs.length > 0) {
                // Call popup to enter parameters
                setCurrentBytecode(bytecode);
                setCurrentABI(abi);
                setDeploymentParameters(_constructorParameters);
                window.popupDeployParameters.showModal();
                return;
            }

            await executeDeployment(null, abi, bytecode);
        } catch (error) {
            setDeploying(false);
            console.error("Error deploying a contract. Check if you have enough funds to deploy this contract.", error);
        }
    }

    async function setupNewImplementation(contractDeployedAddress, waitToExecute = true) {
        let _contractDeployedAddress;
        if (!contractDeployedAddress) {
            _contractDeployedAddress = lastDeploymentInfo.contractAddress;
        } else _contractDeployedAddress = contractDeployedAddress;

        let _delay = 30000;
        if (!waitToExecute) _delay = 0;

        // TODO: Create a new modal or use the same to get the initialize parameters
        // const _parametersImplementationTest = [
        //     {
        //         internalType: "address",
        //         name: "owner",
        //         type: "address",
        //         value: "0x0420e01EE4E177A8Aa088406c91B6841E3d5bEC7",
        //     },
        //     {
        //         internalType: "uint256",
        //         name: "ethAmount",
        //         type: "uint256",
        //         value: "1",
        //     },
        // ];

        // UUPS - you have to call upgradeTo IN THE NEW IMPLEMENTATION CONTRACT AFTER BEING DEPLOYED ! NOT IN THE PROXY CONTRACT
        if (contractsInfo.isProxy) {
            console.log("isProxy - Calling upgradeTo - Proxy:", contractsInfo.baseContractAddress, "New Implementation", _contractDeployedAddress);
            // - UUPS Proxy Implementation Update Process
            // - Call the upgradeTo via the Proxy, not the Implementation:
            // - Instead of calling upgradeTo on the current implementation contract directly, you should call it on the proxy's address but
            // - with the ABI of the implementation contract. This is the essence of proxy patterns: invoking functions on the proxy, which
            // - then delegates the call to the implementation.

            // - Only working for Proxy UUPS and Transparent
            // TODO: Beacon

            setProxyUpgradeToStatus("Waiting to upgrade implementation address (" + _delay / 1000 + " secs)");
            setTimeout(async () => {
                try {
                    const _implementationABI = JSON.parse(contractsInfo.implementation.details.ABI);
                    let _proxyAdminABI;
                    if (contractsInfo.proxyType === "transparent") _proxyAdminABI = JSON.parse(contractsInfo.proxyAdmin.details.ABI);
                    console.log("setupNewImplementation _implementationABI", _implementationABI);
                    console.log("setupNewImplementation _proxyAdminABI", _proxyAdminABI);
                    console.log("setupNewImplementation contractsWalletEditor", contractsWalletEditor);

                    // Prepare the function info and parameters to call the upgrade functions
                    // TODO: Improve getting info from the ABIs (use _implementationABI and _proxyAdminABI above)
                    let _functionUpgrade;
                    let _parameterUpgrade;
                    let _functionUpgradeTo;
                    let _parameterUpgradeTo;
                    if (contractsInfo.proxyType === "transparent") {
                        _functionUpgrade = {
                            inputs: [
                                { internalType: "contract ITransparentUpgradeableProxy", name: "proxy", type: "address" },
                                { internalType: "address", name: "implementation", type: "address" },
                            ],
                            name: "upgrade",
                            outputs: [],
                            stateMutability: "nonpayable",
                            type: "function",
                        };
                        _parameterUpgrade = [
                            { internalType: "contract ITransparentUpgradeableProxy", name: "proxy", type: "address", value: contractsInfo.baseContractAddress },
                            { internalType: "address", name: "implementation", type: "address", value: _contractDeployedAddress },
                        ];
                    } else if (contractsInfo.proxyType === "uups") {
                        _functionUpgradeTo = {
                            inputs: [{ internalType: "address", name: "newImplementation", type: "address" }],
                            name: "upgradeTo",
                            outputs: [],
                            stateMutability: "nonpayable",
                            type: "function",
                        };
                        _parameterUpgradeTo = [
                            {
                                internalType: "address",
                                name: "newImplementation",
                                type: "address",
                                value: _contractDeployedAddress,
                            },
                        ];
                    }

                    let result;
                    if (contractsWalletEditor.type === "provider") {
                        // Metamask
                        if (contractsInfo.proxyType === "uups") {
                            setProxyUpgradeToStatus("Executing upgradeTo function");
                            result = await executeFunctionEditor(
                                "execution",
                                contractsNetworkEditor.id,
                                null,
                                contractsInfo.baseContractAddress,
                                _implementationABI,
                                _functionUpgradeTo, //"upgradeTo",
                                _parameterUpgradeTo,
                                contractsWalletEditor.address,
                                null,
                                true
                            );
                            console.log("upgradeTo provider result", result);
                            if (!result.success) {
                                setProxyUpgradeToStatus("Error setting the new implementation contract in the Proxy. Click to retry.");
                                return;
                            }
                        } else if (contractsInfo.proxyType === "transparent") {
                            // TRANSPARENT
                            setProxyUpgradeToStatus("Executing upgrade function");
                            result = await executeFunctionEditor(
                                "execution",
                                contractsNetworkEditor.id,
                                null,
                                contractsInfo.proxyAdmin.addressFromNetwork,
                                _proxyAdminABI,
                                _functionUpgrade,
                                _parameterUpgrade,
                                contractsWalletEditor.address,
                                null,
                                true
                            );
                            console.log("upgradeTo provider result", result);
                            if (!result.success) {
                                setProxyUpgradeToStatus("Error setting the new implementation contract in the Admin Proxy. Click to retry.");
                                return;
                            }
                        }
                    } else {
                        if (contractsInfo.proxyType === "uups") {
                            setProxyUpgradeToStatus("Executing upgradeTo function");
                            result = await executeFunctionEditor(
                                "execution",
                                contractsNetworkEditor.id,
                                null,
                                contractsInfo.baseContractAddress,
                                _implementationABI,
                                "upgradeTo",
                                _contractDeployedAddress,
                                contractsWalletEditor.pk,
                                null
                            );
                            console.log("upgradeTo NOT provider result", result);
                            if (!result.data.success) {
                                setProxyUpgradeToStatus("Error setting the new implementation contract in the Proxy. Click to retry.");
                                return;
                            }
                        } else if (contractsInfo.proxyType === "transparent") {
                            setProxyUpgradeToStatus("Executing upgrade function");
                            result = await executeFunctionEditor(
                                "execution",
                                contractsNetworkEditor.id,
                                null,
                                contractsInfo.proxyAdmin.addressFromNetwork,
                                _proxyAdminABI,
                                "upgrade",
                                [contractsInfo.baseContractAddress, _contractDeployedAddress],
                                contractsWalletEditor.pk,
                                null
                            );
                            console.log("upgradeTo NOT provider result", result);
                            if (!result.data.success) {
                                setProxyUpgradeToStatus("Error setting the new implementation contract in the Admin Proxy. Click to retry.");
                                return;
                            }
                        }
                    }
                    setProxyUpgradeToStatus("Executed with success");
                } catch (error) {
                    console.error("Error executing initialize function", error);
                    setProxyUpgradeToStatus("Error setting the new implementation contract in the Proxy. Click to retry.");
                }
            }, _delay);

            // - Initialize is optional
            // - TODO: NEW POPUP!!! to collect the parameters to call initialize
            // - TODO: NEW POPUP!!! to collect the parameters to call initialize
            // - TODO: NEW POPUP!!! to collect the parameters to call initialize
            // - TODO: NEW POPUP!!! to collect the parameters to call initialize

            // const _parametersImplementationTest = null;//[contractDeployedAddress, 1];
            // // TODO: Run initialize using MetaMask
            // setInitializeStatus("Waiting to execute initialize");
            // setTimeout(async () => {
            //     try {
            //         setInitializeStatus("Executing initialize");
            //         const result = await executeFunctionEditor(
            //             "execution",
            //             contractsNetworkEditor.id,
            //             null,
            //             _contractDeployedAddress,
            //             null,
            //             "initialize",
            //             _parametersImplementationTest,
            //             contractsWalletEditor.pk,
            //             null
            //         );
            //         console.log("initialize result", result);
            //         setInitializeStatus("Executed with success");
            //     } catch (error) {
            //         console.error("Error executing initialize function", error);
            //         setInitializeStatus("Error executing initialize function. Click to retry.");
            //     }
            // }, 40000);
        }
    }

    // Add value into the constructor parameters
    function prepareConstructorParameters(parametersValues, parameters) {
        console.log("prepareConstructorParameters parametersValues", parametersValues);
        console.log("prepareConstructorParameters parameters", parameters);
        let _constructorParameters = [];
        for (let index = 0; index < parameters.length; index++) {
            const input = parameters[index];
            const parameterValue = parametersValues[index];
            input.value = parameterValue;
            _constructorParameters.push(input);
        }

        console.debug("prepareConstructorParameters - Parameters to call the function (in expected order by the contract)", _constructorParameters);
        return _constructorParameters;
    }

    async function executeDeployment(parameters_ = null, abi, bytecode) {
        try {
            setDeploying(true);
            let _parametersConstructor = null;
            console.log("parameters_", parameters_);
            if (parameters_) {
                // Constructor parameters for contract verification
                _parametersConstructor = prepareConstructorParameters(parameters_, deploymentParameters.inputs);
                setConstructorParameters(_parametersConstructor);
            }

            let deployment;
            console.log("executeDeployment contractsInfo", contractsInfo);
            console.log("executeDeployment contractsInfo", contractsInfo.isProxy);
            if (contractsWalletEditor.type && contractsWalletEditor.type === "provider") {
                deployment = await deployContract(contractsNetworkEditor.id, null, _parametersConstructor, abi, bytecode, contractsWalletEditor, contractsInfo.isProxy, null, null, null);
                // Adjust to be same format as deployment wihtout allet provider
                deployment.contractAddress = deployment._address;

                // // - MetaMask
                // let web3;
                // const { ethereum } = window;
                // if (ethereum && ethereum.isMetaMask) {
                //     web3 = new Web3(ethereum);
                // } else {
                //     notify("You need install MetaMask to deploy using it.", "warn");
                //     return null;
                // }
                // const _contract = new web3.eth.Contract(abi);
                // const _deployContract = await _contract.deploy({
                //     data: "0x" + bytecode,
                //     ...(parameters_ !== null ? { arguments: _parametersConstructor } : {}),
                // });
                // const _rawTransaction = {
                //     from: contractsWalletEditor.address,
                //     gas: 8000000,
                //     data: _deployContract.encodeABI(),
                // };
                // deployment = await _deployContract.send(_rawTransaction);
                // console.log("MetaMask Deployment Response - deployment", deployment);
            } else {
                deployment = await deployContract(
                    contractsNetworkEditor.id,
                    contractsNetworkEditor.RPCs[0].URL,
                    parameters_,
                    abi,
                    bytecode,
                    contractsWalletEditor,
                    contractsInfo.isProxy,
                    compilerVersion, // why?
                    optimizationUsed, // why?
                    optimizationRuns // why?
                );
            }
            console.debug("DeploymentPanel is done", deployment);
            setLastDeploymentInfo(deployment);

            // TODO: Add in the project contracts list if user is logged in or another way to save this in a log

            // Add this deployment to the cookies
            addDeploymentHistory(deployment);

            const _lastDeploymentDateTime = new Date();
            setLastDeploymentDateTime(_lastDeploymentDateTime);
            setDeploying(false);

            window.popupDeploymentFinished.showModal();

            manageAutoVerify(deployment, _parametersConstructor, _lastDeploymentDateTime);

            console.log("Running upgradeto and initialize");
            if (contractsInfo.isProxy) {
                setupNewImplementation(deployment.contractAddress);
            }
        } catch (error) {
            setDeploying(false);
            notify("Error deploying a contract. Check if you have enough funds to deploy this contract.", "error");
            console.error("Error deploying the contract", error);
        }
    }

    async function verify(deploymentInfo_, constructorParameters_, lastDeploymentDateTime_) {
        console.debug("Deployement Panel - Contract Verification Started", deploymentInfo_, constructorParameters_, lastDeploymentDateTime_);
        if (activeTabLocalNetwork === "network")
            if ((!lastDeploymentInfo && !deploymentInfo_) || !contractsInfo) {
                notify("You need compile and deploy the contract before validating it", "warn");
                return;
            }
        if (activeTabLocalNetwork === "local")
            if (!lastDeploymentInfo) {
                notify("You need compile and deploy the contract before validating it", "warn");
                return;
            }

        try {
            let _deploymentInfo = null;
            if (!lastDeploymentInfo) _deploymentInfo = deploymentInfo_;
            else _deploymentInfo = lastDeploymentInfo;
            let _lastDeploymentDateTime = null;
            if (!lastDeploymentDateTime) _lastDeploymentDateTime = lastDeploymentDateTime_;
            else _lastDeploymentDateTime = lastDeploymentDateTime;
            let _constructorParameters = null;
            if (!constructorParameters) _constructorParameters = constructorParameters_;
            else _constructorParameters = constructorParameters;
            // Verify if the verification was started after at least 30 seconds
            const thirtySecondsAgo = new Date(_lastDeploymentDateTime.getTime() + 30 * 1000);
            // console.log("Comparing the dates - last deployment + 30 secs", thirtySecondsAgo, "now", new Date());
            if (new Date() <= thirtySecondsAgo) {
                notify("You have to wait at least 30 seconds to your code being published to the network before trying to validate it.");
                return;
            }

            setVerifying(true);
            if (activeTabLocalNetwork === "network") {
                setVerificationStatusMessage("Submitting Code...");
                const _result = await verifyContract(
                    contractsNetworkEditor.id,
                    _deploymentInfo.contractAddress,
                    rootContract,
                    compilationResult.input, // - using solidity-standard-json-input in the verification, the "source code" should be input used to compile it
                    _constructorParameters,
                    contractsInfo.CompilerVersion,
                    contractsInfo.OptimizationUsed,
                    contractsInfo.Runs
                );
                console.debug("Contract verification is started", _result);
                setLastVerificationInfo(_result);
                const guidIsValid = validateGuid(_result.result);
                if ((_result.status === "1" && _result.message === "OK" && guidIsValid) || (_result.status = "0" && _result.message === "NOTOK" && _result.result === "Pending in queue")) {
                    //_result.result === "Pending in queue") {
                    verifyStatus(_result);
                } else {
                    setVerifying(false);
                    if (_result.message === "OK") {
                        setVerificationStatusMessage("Verified");
                        setVerificationStatus(true);
                    } else {
                        setVerificationStatusMessage("Warning - " + _result.result);
                        setVerificationStatus(false);
                    }
                }
            } else {
                let _compilerVersion;
                if (!compilerVersion) {
                    const _versions = await loadCompilerVersions();
                    _compilerVersion = _versions[0].version;
                }
                const _result = await verifyContract(
                    contractsNetworkEditor.id, // - TODO: TEST IT ! NEW PARAM NOT TESTED FOR LOCAL !!!
                    _deploymentInfo.contractAddress,
                    rootContract,
                    contractSourceCode,
                    contractContructorArguments,
                    compilerVersion ?? _compilerVersion,
                    optimizationUsed,
                    optimizationRuns
                );
                console.log("local verify", _result);
                // TODO: Verify automatically after a few seconds the status using the API call below
                if (_result.status === "1") {
                    notify("Verification started. Use the icon to check the status in the Block Explorer.");
                }
                setLastVerificationInfo(_result);
                setVerifying(false);
            }
        } catch (error) {
            setVerifying(false);
            setVerificationStatusMessage("Error verifying contract");
            setVerificationStatus(false);
            console.error("Error executing contract verification on the Block Explorer", error);
            notify("Error veryfing the contract. Please try again.", "error");
        }
    }

    async function verifyStatus(lastVerificationResult) {
        try {
            setVerificationStatusMessage("Checking Status...");

            let guid;
            if (lastVerificationResult) guid = lastVerificationResult.result;
            else guid = lastVerificationInfo.result;

            // call verifyContractStatus
            const _result = await verifyContractStatus(contractsNetworkEditor.id, guid);
            if (_result.status === "0" && _result.message === "NOTOK" && _result.result === "Pending in queue") {
                // Retry after 2 seconds calling API again
                setTimeout(() => {
                    verifyStatus(lastVerificationResult);
                }, 2000);
            } else {
                // it means the verify was ok or not, but not pending anymore
                setVerifying(false);
                if (_result.message === "OK") {
                    console.debug("Contract verification is done.");
                    setVerificationStatusMessage("Verified");
                    setVerificationStatus(true);
                } else {
                    setVerificationStatusMessage("Warning - " + _result.result);
                    setVerificationStatus(false);
                }
            }
        } catch (error) {
            setVerifying(false);
            setVerificationStatusMessage("Error verifying contract");
            setVerificationStatus(false);
            notify("Error check the verification status the contract. Please check it anually.", "error");
        }
    }

    function validateGuid(guid) {
        if (guid.length !== 50) return false;
        const regex = /^[a-zA-Z0-9]+$/;
        return regex.test(guid);
    }

    async function addDeploymentHistory(deployment) {
        let _history = getCookieValue(HistoryCookieKey) ?? [];

        const newItem = {
            network: contractsNetworkEditor.id,
            from: deployment.from,
            address: deployment.contractAddress,
            transaction: deployment.transactionHash,
            status: deployment.status,
            date: new Date(),
        };

        // If history has 10 items, remove the oldest one before adding a new item
        if (_history.length >= 10) {
            _history = _history.slice(1);
        }

        _history.push(newItem);

        setCookieValue(HistoryCookieKey, _history);
    }

    useEffect(() => {
        if (deploying) setDeploymentPanelWasOpen(true);
    }, [deploying]);

    return (
        <div className="py-1">
            <Accordion
                id="DeploymentPanelAccordion"
                name="DeploymentPanelAccordion"
                title={"Deployment"}
                opened={deploying || deploymentPanelWasOpen}
                items={[
                    deploying ? (
                        <p className="text-secondary font-semibold text-md">
                            <FontAwesomeIcon icon={faTriangleExclamation} className="text-secondary pr-2" fade />
                            Deploying contract...
                            <FontAwesomeIcon icon={faRotate} spin={deploying} className="text-secondary ml-2" />
                        </p>
                    ) : null,
                    <div className="items-center mt-1 text-light font-normal">
                        <p className="h-6">
                            <span className="text-secondary">Deployed at: </span>
                            {lastDeploymentInfo ? (
                                <>
                                    <span>{Web3util.shortAddress(lastDeploymentInfo.contractAddress, 6, 10)}</span>
                                    <FontAwesomeIcon
                                        id="DeploymentPanelContractAddressCopy"
                                        name="DeploymentPanelContractAddressCopy"
                                        icon={faCopy}
                                        className="text-secondary ml-2 cursor-pointer"
                                        onClick={() => {
                                            navigator.clipboard.writeText(lastDeploymentInfo.contractAddress);
                                        }}
                                    />
                                    <a href={contractsNetworkEditor.blockExplorerURL + "address/" + lastDeploymentInfo.contractAddress} target="_blank">
                                        <FontAwesomeIcon icon={faCube} className="text-secondary ml-2 cursor-pointer" />
                                    </a>
                                </>
                            ) : (
                                <span>N/A</span>
                            )}
                        </p>
                        <p className="h-6 mt-1">
                            <span className="text-secondary">Tx: </span>
                            {lastDeploymentInfo ? (
                                <>
                                    <span>{Web3util.shortAddress(lastDeploymentInfo.transactionHash, 10, 14)}</span>
                                    <FontAwesomeIcon
                                        id="DeploymentPanelTransactionHashCopy"
                                        name="DeploymentPanelTransactionHashCopy"
                                        icon={faCopy}
                                        className="text-secondary ml-2 cursor-pointer"
                                        onClick={() => {
                                            navigator.clipboard.writeText(lastDeploymentInfo.transactionHash);
                                        }}
                                    />
                                    <a
                                        href={contractsNetworkEditor.blockExplorerURL + "tx/" + lastDeploymentInfo.transactionHash}
                                        target="_blank"
                                        id="DeploymentPanelTransactionHashBlockExplorer"
                                        name="DeploymentPanelTransactionHashBlockExplorer">
                                        <FontAwesomeIcon icon={faCube} className="text-secondary ml-2" />
                                    </a>
                                </>
                            ) : (
                                <span>N/A</span>
                            )}
                        </p>
                        {lastDeploymentInfo && (
                            <div className="flex justify-center pt-4">
                                <button
                                    id="DeploymentPanelLastDeploymentInfoButton"
                                    name="DeploymentPanelLastDeploymentInfoButton"
                                    className="btn-default"
                                    onClick={() => {
                                        window.popupDeploymentFinished.showModal();
                                    }}>
                                    Last Deployment Info
                                    <FontAwesomeIcon id="DeploymentPanelLastDeploymentInfoButtonIcon" name="DeploymentPanelLastDeploymentInfoButtonIcon" icon={faRocket} className="pl-3" />
                                </button>
                            </div>
                        )}
                    </div>,
                    <div className="items-center text-light font-normal pt-4">
                        <div className="flex flex-row items-center">
                            <p className="font-semibold text-white">Verification</p>
                            <input id="autoverify" name="autoverify" type="checkbox" checked={autoVerify} className="checkbox checkbox-xs px-0 ml-4" onChange={onInputChange} />
                            <span className="pl-2">Autoverify</span>
                        </div>
                        {verifying && (
                            <p className="text-secondary font-semibold text-md pt-2">
                                <FontAwesomeIcon
                                    id="DeploymentPanelTriangleExclamationVerifing"
                                    name="DeploymentPanelTriangleExclamationVerifing"
                                    icon={faTriangleExclamation}
                                    className="text-secondary pr-2"
                                    fade
                                />
                                Verifying contract...
                                <FontAwesomeIcon icon={faRotate} spin={verifying} className="text-secondary ml-2" />
                            </p>
                        )}
                        {autoVerifyStatus && (
                            <div className="flex items-center pt-2">
                                <p>
                                    <FontAwesomeIcon
                                        id="DeploymentPanelTriangleExclamationVerifyStatus"
                                        name="DeploymentPanelTriangleExclamationVerifyStatus"
                                        icon={faTriangleExclamation}
                                        className="text-secondary pr-2"
                                        fade
                                    />
                                    {autoVerifyStatus}
                                </p>
                            </div>
                        )}
                        <div className="pt-2 text-left">
                            <span className="text-secondary">Verification: </span>
                            {verificationStatusMessage && !lastVerificationInfo && <span>{verificationStatusMessage}</span>}
                            {!lastVerificationInfo && !verificationStatusMessage && <span>Not started</span>}
                            {lastVerificationInfo && contractsNetworkEditor && verificationStatusMessage && (
                                // lastVerificationInfo.status === "0" ? (
                                //     <>
                                //         <span
                                //             className="tooltip cursor-help inline"
                                //             data-tip='Error "Unable to locate ContractCode at" means you have to wait until the blockchain process you code to be verified. Usually take less than a minute.'>
                                //             <span className="text-red">Error</span> <span className="text-light">{lastVerificationInfo.result}</span>
                                //         </span>
                                //     </>
                                // ) :
                                <Tooltip
                                    position="top"
                                    text='Error "Unable to locate ContractCode at" means you have to wait until the blockchain process you code to be verified. Wait an press Verify button.'>
                                    <span>{verificationStatusMessage}</span>
                                    <a
                                        href={contractsNetworkEditor.blockExplorerURL + "address/" + lastDeploymentInfo.contractAddress}
                                        target="_blank"
                                        id="DeploymentPanelVerificationStatusBlockExplorer"
                                        name="DeploymentPanelVerificationStatusBlockExplorer">
                                        <FontAwesomeIcon icon={faCube} className="text-secondary ml-2 cursor-pointer" />
                                    </a>
                                </Tooltip>
                            )}
                        </div>
                    </div>,
                    <div className="flex items-center justify-center pt-6" id="DeploymentPanelDeployPopupButtonDiv" name="DeploymentPanelDeployPopupButtonDiv">
                        <button
                            id="DeploymentPanelDeployPopupButton"
                            name="DeploymentPanelDeployPopupButton"
                            className="btn-default"
                            onClick={() => {
                                confirmDeployment();
                            }}>
                            Deploy
                            <FontAwesomeIcon id="DeploymentPanelDeployPopupButtonIcon" name="DeploymentPanelDeployPopupButtonIcon" icon={faRocket} className="pl-3" />
                        </button>
                        {/* 
                        <button
                            className="btn-default"
                            onClick={() => {
                                deploy();
                            }}>
                            Deploy
                            <FontAwesomeIcon icon={faRocket} className="pl-3" />
                        </button> */}
                        <button
                            id="DeploymentPanelVerifyButton"
                            name="DeploymentPanelVerifyButton"
                            className="btn-default ml-4"
                            onClick={() => {
                                verify();
                            }}>
                            Verify
                            <FontAwesomeIcon id="DeploymentPanelVerifyButtonIcon" name="DeploymentPanelVerifyButtonIcon" icon={faFileCircleCheck} className="pl-3" />
                        </button>
                        {/* <button
                            className="btn-default ml-4"
                            onClick={() => {
                                verifyStatus();
                            }}>
                            Check Status
                            <FontAwesomeIcon icon={faFileCircleCheck} className="pl-3" />
                        </button> */}
                        {/* <p>TODO: Checkbox: Also add in the Contracts List dropdown</p> */}
                    </div>,
                ]}
            />
            <PopupDeployParameters
                network={contractsNetworkEditor}
                contract={contractsInfo && contractsInfo.address}
                wallet={contractsWalletEditor}
                deploymentParameters={deploymentParameters}
                deploymentFunction={executeDeployment}
                abi={currentABI}
                bytecode={currentBytecode}
            />
            <PopupDeploymentFinished
                lastDeploymentInfo={lastDeploymentInfo}
                contractsNetworkEditor={contractsNetworkEditor}
                contractsInfo={contractsInfo}
                lastDeploymentDateTime={lastDeploymentDateTime}
                verifying={verifying}
                autoVerifyStatus={autoVerifyStatus}
                verificationStatusMessage={verificationStatusMessage}
                initializeStatus={initializeStatus}
                proxyUpgradeToStatus={proxyUpgradeToStatus}
                upgradeToFunctionRetry={setupNewImplementation}
            />
            <PopupConfirmation mainMessage={popupMessage1} message={popupMessage2} proceedMessage={popupMessage3} onSelection={handleConfirmations} />
        </div>
    );
};

export default DeploymentPanel;
