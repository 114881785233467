import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";

const Settings = () => {
    const { dataLoaded, nonceSetting, setNonceSetting, setGasSetting, setGasPriceSetting, overrideNonceSetting, setOverrideNonceSetting, currentNetwork, getUserGasPrice } = useContext(AppContext);
    const [inputValues, setInputValues] = useState({});
    const [_nonce, set_nonce] = useState(null);
    const [gasPricePage, setGasPricePage] = useState(null);

    useEffect(() => {
        if (dataLoaded) getGasPrice();
        inputValues["gas"] = 8000000;
    }, [currentNetwork, dataLoaded]);

    useEffect(() => {
        //console.log("useEffect nonce", nonceSetting);
        if (nonceSetting) set_nonce(nonceSetting);
    }, [nonceSetting]);

    // ! BUG - connectedaccount is not loaded yet, so this request is not being sent to the API....
    async function getGasPrice() {
        //console.log("changed network - getGasPrice", currentNetwork.id);
        if (currentNetwork) {
            const _gasPrice = await getUserGasPrice(currentNetwork.id);
            setGasPricePage(_gasPrice);
        } else {
            setGasPricePage("");
        }
    }

    const handleInputChange = (event) => {
        const _input = event.target.value.replace(/[^0-9]/g, "");
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: _input,
            };
        });

        if (event.target.name === "nonce") {
            set_nonce(_input);
            setNonceSetting(_input);
        } else if (event.target.name === "gas") {
            setGasSetting(_input);
        } else if (event.target.name === "gasPrice") {
            setGasPriceSetting(_input);
            setGasPricePage(_input);
        }

        if (event.target.name === "overrideNonce") {
            setOverrideNonceSetting(event.target.checked);
        }
    };

    return (
        <div className="collapse collapse-arrow mt-5 bg-[#1a1511]">
            <input type="checkbox" className="peer" />
            <div className="collapse-title text-white font-semibold text-md pt-4">Advanced Settings</div>
            <div className="collapse-content">
                {/* <div className="w-full">
                    <label className="label cursor-pointer pl-5">
                        <span className="label-text">Override Nonce (when is marked, enable the field)</span>
                        <input type="checkbox" name="overrideNonce" checked={overrideNonceSetting} className="checkbox ml-4" onChange={handleInputChange} />
                    </label>
                </div> */}
                <div className="pt-4">
                    <div className="flex items-center w-32 text-white">
                        Nonce
                        <span className="pl-20 text-light">override</span>
                        <input type="checkbox" name="overrideNonce" checked={overrideNonceSetting} className="checkbox ml-4 bg-[#4e2b13]" onChange={handleInputChange} />
                    </div>
                    <div className="flex items-center pt-2">
                        <input
                            type="text"
                            name="nonce"
                            placeholder={"Set manually the nonce and click override"}
                            autoComplete="new-password"
                            spellCheck="false"
                            onChange={handleInputChange}
                            value={_nonce || ""}
                        />
                    </div>
                </div>
                <div className="pt-4">
                    <p className="w-32 text-white">Gas</p>
                    <div className="flex items-center pt-2">
                        <input type="text" name="gas" onChange={handleInputChange} autoComplete="new-password" spellCheck="false" value={inputValues["gas"] || ""} />
                    </div>
                </div>
                <div className="pt-4">
                    <p className="w-32 text-white">Gas Price (Gwei)</p>
                    <div className="flex items-center pt-2">
                        <input type="text" name="gasPrice" placeholder={"Type the gas price in Gwei"} onChange={handleInputChange} autoComplete="new-password" spellCheck="false" value={gasPricePage || ""} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
