import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { dateDefaultFormat } from "../../util/util";
import { ModalTheme, FunctionGasType } from "../../util/enum";
import ReactJson from "react-json-view";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";

function ExecutionDetail() {
    const { executionQueueDetail, getFunctionGasType } = useContext(AppContext);

    // console.log("ExecutionDetail render");

    if (executionQueueDetail)
        return (
            <>
                <input type="checkbox" id="executionDetail" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box min-w-[85%] max-h-[85%]">
                        <div>
                            <p className="text-white font-semibold text-xl">
                                Result
                                <ContentCopyOutlinedIcon
                                    onClick={() => {
                                        navigator.clipboard.writeText(JSON.stringify(executionQueueDetail));
                                    }}
                                    className="pl-2 cursor-pointer max-h-4 min-h-4"
                                />
                            </p>
                            <div className="bg-[#1A1511] p-2 mt-2 rounded-lg">
                                <p className="text-secondary">
                                    Network:<span className="font-semibold text-light pl-2">{executionQueueDetail.network.name}</span>
                                </p>
                                {executionQueueDetail.type != "validation" && (
                                    <p className="text-secondary">
                                        Function:<span className="font-semibold text-light pl-2">{executionQueueDetail && executionQueueDetail.function && executionQueueDetail.function.name}</span>
                                    </p>
                                )}
                                <p className="text-secondary">
                                    Timestamp:
                                    <span className="font-semibold text-light pl-2">{dateDefaultFormat(new Date())}</span>
                                </p>
                                {executionQueueDetail.type != "validation" && (
                                    <p className="text-secondary">
                                        Receiver:<span className="font-semibold text-light pl-2">{executionQueueDetail && executionQueueDetail.contract && executionQueueDetail.contract.proxy}</span>
                                    </p>
                                )}
                                {executionQueueDetail.result && typeof executionQueueDetail.result === "object" && (
                                    <div className="py-1">
                                        <p className="text-secondary">Result</p>
                                        <div className="ReactJson-container">
                                            <ReactJson src={executionQueueDetail.result} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                                        </div>
                                    </div>
                                )}
                                {executionQueueDetail.result && typeof executionQueueDetail.result != "object" && (
                                    <p className="text-secondary font-semibold">
                                        Result:<span className="text-white text-lg pl-2">{executionQueueDetail.result}</span>
                                    </p>
                                )}
                                {executionQueueDetail.function && getFunctionGasType(executionQueueDetail.function.stateMutability) === FunctionGasType.PayGasFee && executionQueueDetail.error && (
                                    <p className="text-secondary font-semibold">
                                        Result:<span className="text-red text-lg pl-2">Failed</span>
                                        {/* {executionQueueDetail.receipt && (
                                            <a
                                                href={executionQueueDetail.network.blockExplorerURL + "/vmtrace?txhash=" + executionQueueDetail.receipt.transactionHash + "&type=gethtrace2"}
                                                target="_blank">
                                                <BugReportOutlinedIcon className="cursor-pointer max-h-5 min-h-5" />
                                            </a>
                                        )} */}
                                    </p>
                                )}
                                {executionQueueDetail.revertReason && (
                                    <p className="text-secondary">
                                        Revert Reason:
                                        <span className="font-semibold text-light pl-2 break-all text-red">{executionQueueDetail.revertReason.toString()}</span>
                                    </p>
                                )}
                                {executionQueueDetail.error && (
                                    <p className="text-secondary">
                                        Error:
                                        <span className="font-semibold text-light pl-2 break-all">{executionQueueDetail.error.error}</span>
                                    </p>
                                )}
                                {executionQueueDetail.type != "validation" && (
                                    <div className="text-light">
                                        <p className="text-secondary">Input{Object.keys(executionQueueDetail.parameters).length == 0 && <span className="pl-2 text-light">none</span>}</p>
                                        {Object.keys(executionQueueDetail.parameters).length > 0 && (
                                            <div className="bg-black py-1 ReactJson-container">
                                                <ReactJson src={executionQueueDetail.parameters} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />

                                                {/* <pre className="bg-black flex-wrap break-all text-light">{JSON.stringify(executionQueueDetail.parameters, null, 4)}</pre> */}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <p className="text-secondary pt-2">
                                    Output
                                    <ContentCopyOutlinedIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(JSON.stringify(executionQueueDetail, null, 4));
                                        }}
                                        className="pl-2 cursor-pointer max-h-4 min-h-4"
                                    />
                                </p>
                                <div className="py-1 ReactJson-container">
                                    <ReactJson src={executionQueueDetail} iconStyle="triangle" collapsed={1} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-action gap-4">
                            <label htmlFor="executionDetail" className="btn-default">
                                CLOSE
                                <CloseOutlined className="pl-2" />
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
}

export default ExecutionDetail;
