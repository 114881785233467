import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { timeSince, isObjEmpty } from "../../util/util";
import { ModalTheme } from "../../util/enum";
import ReactJson from "react-json-view";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { getImageUrl, getVideoUrl } from "@util/util";

const PopupVideo = ({ videoName, videoFileName }) => {
    return (
        <>
            <input type="checkbox" id="popupVideo" className="modal-toggle" />
            <label htmlFor="popupVideo" className="modal cursor-pointer">
                {/* <div className="modal"> */}
                    <div className="modal-box min-w-[850px] max-h-[80%] min-h-[500px]">
                        {/* <p className="text-white font-bold text-lg">{videoName}</p> */}
                        {/* <video controls autoPlay>
                            <source src={`${getVideoUrl(videoFileName)}`} type="video/mp4" className="" />
                        </video> */}

                        {/* <iframe width="800" height="450" src="https://www.youtube.com/embed/PjrfFV8Kjxk" title="DevWeb3 Executor" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                         */}

                        {/* <div className="flex justify-end">
                        <label htmlFor="popupVideo" className="btn-default mt-2 justify-end">
                            Close
                            <CloseOutlined className="pl-2 text-red" />
                        </label>
                    </div> */}
                    </div>
                {/* </div> */}
            </label>
        </>
    );
};

export default PopupVideo;
