import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { timeSince, isObjEmpty } from "../../util/util";
import { ModalTheme } from "../../util/enum";
import ReactJson from "react-json-view";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";

const QueueListItemRemoval = () => {
    const { currentHistoryItem, removeQueueListItem } = useContext(AppContext);

    // console.log("QueueListItemRemoval - currentHistoryItem", currentHistoryItem);
    if (!isObjEmpty(currentHistoryItem)) {
        return (
            <>
                <input type="checkbox" id="queueListItemRemoval" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box min-w-fit">
                        <p className="text-white font-bold text-lg">Delete Queue {currentHistoryItem.name}</p>
                        <p className="pb-1 pt-4 text-light">
                            TODO: Show the Queue Json for now
                        </p>
                        {/* {currentHistoryItem.parameters && Object.keys(currentHistoryItem.parameters).length > 0 && (
                            <>
                                <p className="py-1 text-secondary">Parameters: </p>
                                <div className="bg-black py-1 rounded-lg ReactJson-container">
                                    <ReactJson src={currentHistoryItem.parameters} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                            </>
                        )}

                        <p className="py-1 text-secondary">
                            Network: <span className="text-light">{currentHistoryItem.network.name}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Network RPC: <span className="text-light">{currentHistoryItem.network.RPC}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Contract: <span className="text-light">{currentHistoryItem.contract.name}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Contract Proxy: <span className="text-light">{currentHistoryItem.contract.proxy}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Contract Implementation: <span className="text-light">{currentHistoryItem.contract.implementation}</span>
                        </p>
                        {currentHistoryItem.wallet && currentHistoryItem.wallet.name && (
                            <>
                                <p className="py-1 text-secondary">
                                    Wallet: <span className="text-light">{currentHistoryItem.wallet.name}</span>
                                </p>
                                <p className="py-1 text-secondary">
                                    Wallet Address: <span className="text-light">{currentHistoryItem.wallet.address}</span>
                                </p>
                            </>
                        )}
                        <p className="py-1 text-secondary">
                            Executed <span className="text-light">{timeSince(Date.parse(currentHistoryItem.timestamp))}</span>
                        </p> */}
                        <label
                            htmlFor="queueListItemRemoval"
                            className="btn-default mt-8"
                            onClick={() => {
                                removeQueueListItem(currentHistoryItem);
                            }}>
                            YES
                            <CheckIcon className="pl-2 text-green" />
                        </label>
                        <label htmlFor="queueListItemRemoval" className="btn-default ml-5">
                            NO
                            <CloseOutlined className="pl-2 text-red" />
                        </label>
                    </div>
                </div>
            </>
        );
    } else return null;
};

export default QueueListItemRemoval;
