import { useEffect, useRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { getImageUrl, getImageUrlWithExt } from "../../util/util";
import { saveDataStorage, getDataStorage } from "../../util/util";
import { LocalStorage } from "@util/enum";

function PopupNetworkSearchbarDropdownNetwork(props) {
    const { networks, clearExecuteForm } = useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions, currentNetworkInput, setCurrentNetworkInput, setCurrentNetwork } = props;
    const ulRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });

        // If the user reloads the page, get the last network
        // if (networks) {
        //     if (getDataStorage(LocalStorage.NetworkEditorSelected)) {
        //         const _network = getDataStorage(LocalStorage.NetworkEditorSelected);
        //         setOptions(networks.filter((_option) => _option.name.toLocaleLowerCase().includes(_network.name.toLocaleLowerCase())));
        //         setCurrentNetwork(_network);
        //         setCurrentNetworkInput(_network.name);
        //     }
        // }
    }, [networks]);

    return (
        <div className="dropdown dropdown-bottom w-full">
            <div className="flex flex-row items-center justify-center">
                <span className="text-secondary">Network:</span>
                <input
                    className="input-image ml-1 border-black h-8 pl-1 cursor-pointer"
                    type="text"
                    // style={{ backgroundImage: `${getImageUrlWithExt(currentNetwork?.logo, "webp", true)}` }}
                    style={{ backgroundColor: "#1f1c1a", border: "none" }}
                    placeholder={placeholder}
                    ref={inputRef}
                    onChange={onInputChange}
                    autoComplete="new-password"
                    value={currentNetworkInput || ""}
                    spellCheck="false"
                    // onMouseOver={(event) => (event.target.style.backgroundColor = "#3d2413")}
                    // onMouseOut={(event) => (event.target.style.backgroundColor = "#1f1c1a")}
                />
            </div>
            <ul id="results" className="ul-main" ref={ulRef}>
                {options &&
                    options.map((option, index) => {
                        return (
                            <button
                                id="PopupNetworkSearchbarDropdownNetworkSelectItem"
                                name="PopupNetworkSearchbarDropdownNetworkSelectItem"
                                data-info={option.name}
                                type="button"
                                key={index}
                                onClick={(e) => {
                                    clearExecuteForm("network");
                                    setCurrentNetworkInput(option.name);
                                    ulRef.current.style.display = "none";
                                    setCurrentNetwork(option);
                                    // saveDataStorage(LocalStorage.NetworkEditorSelected, option);
                                    setOptions(networks.filter((_option) => _option.name.toLocaleLowerCase().includes(option.name.toLocaleLowerCase())));
                                    console.debug("Current network set to:", option);
                                }}
                                className="ul-item h-6">
                                <img className="h-5 inline pr-2 " src={`${getImageUrlWithExt(option.logo)}`} alt={option.name} />
                                {option.name}
                            </button>
                        );
                    })}
            </ul>
        </div>
    );
}

export default PopupNetworkSearchbarDropdownNetwork;
