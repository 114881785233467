import { useContext, useState } from "react";
import { AppContext } from "@context/AppContext";
import { timeSince, isObjEmpty } from "@util/util";
import { ModalTheme } from "@util/enum";
import ReactJson from "react-json-view";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ValidationMessage from "@components/shared/ValidationMessage";

const HistoryConfirmation = () => {
    const { currentHistoryItem, reexecuteFunction, setCurrentHistoryItem, validateParameter, setLastExecution } = useContext(AppContext);
    const [validationMessages, setValidationMessages] = useState();

    const handleInputChange = (event) => {
        // TODO: Validate the values with the input type (executionQueueDetail.parameters = values, xecutionQueueDetail.function.inputs = types)
        const _currentHistoryItem = structuredClone(currentHistoryItem);
        // see validateExecution in context
        const paramName = event.target.name;
        const paramValue = event.target.value;
        let parameters = _currentHistoryItem.parameters;
        // console.log("handleInputChange executionQueueDetail", executionQueueDetail);
        if (parameters) {
            // update the parameter value to be executed later
            const _parametersEventUpdate = parameters.map((param) => (param.name === paramName ? { ...param, value: paramValue } : param));
            console.log("HistoryConfirmation - handleInputChange _parametersEventUpdate", _parametersEventUpdate);
            _currentHistoryItem.parameters = _parametersEventUpdate;

            // Update the input value in the page
            const index = _currentHistoryItem.function.inputs.findIndex((input) => input.name === paramName);
            if (index !== -1) {
                // Update the executionQueueDetail state
                _currentHistoryItem.function.inputs[index].value = paramValue;
                //setExecutionQueueDetail({ ...currentHistoryItem });
                // TODO: Use setCurrentHistoryItem
                const _valid = validateParam(paramName, _currentHistoryItem.function.inputs[index].type, paramValue);
                console.log("_valid", _valid);

                // Update the executionQueue state
                setCurrentHistoryItem(_currentHistoryItem);
                // TODO: Use setCurrentHistoryItem
                // for (let index = 0; index < executionQueue.length; index++) {
                //     if (executionQueue[index].uuid === executionQueueDetail.uuid) {
                //         let executionQueueItem = executionQueue[index];
                //         executionQueueItem = executionQueueDetail;
                //         executionQueue[index] = { ...executionQueue[index], ...executionQueueItem };
                //         setExecutionQueue([...executionQueue]);
                //     }
                // }
            }
            console.log("executionQueueDetail.function.inputs", currentHistoryItem.function.inputs);
        } else {
            // Update the executionQueueDetail state
            _currentHistoryItem.parameters = [{ name: paramName, value: paramValue }];
            //setExecutionQueueDetail({ ..._currentHistoryItem });
            // TODO: Use setCurrentHistoryItem

            // Update the executionQueue state
            setCurrentHistoryItem(_currentHistoryItem);
            // TODO: Use setCurrentHistoryItem
            // for (let index = 0; index < executionQueue.length; index++) {
            //     if (executionQueue[index].uuid === executionQueueDetail.uuid) {
            //         let executionQueueItem = executionQueue[index];
            //         executionQueueItem = executionQueueDetail;
            //         executionQueue[index] = { ...executionQueue[index], ...executionQueueItem };
            //         setExecutionQueue([...executionQueue]);
            //     }
            // }
        }
    };

    const updateValidationMessages = (name, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [name]: message,
            };
        });
    };

    function validateParam(name, type, value) {
        console.log("validateParam name", name, "type", type, "value", value);
        if (!validateParameter(type, value)) {
            updateValidationMessages(name, name + " is invalid");
            return false;
        } else {
            updateValidationMessages(name, null);
            return true;
        }
    }

    function executeFunction(executionHistoryItem) {
        reexecuteFunction(executionHistoryItem, true);
    }

    //console.log("currentHistoryItem", currentHistoryItem);
    if (!isObjEmpty(currentHistoryItem)) {
        return (
            <>
                <input type="checkbox" id="historyConfirmation" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box min-w-fit">
                        <p className="text-white font-bold text-lg">Execute {currentHistoryItem.function.name}</p>
                        <p className="pb-1 pt-4 text-secondary">
                            Function Type: <span className="text-light">{currentHistoryItem.function.stateMutability}</span>
                        </p>
                        {/* {currentHistoryItem.parameters && Object.keys(currentHistoryItem.parameters).length > 0 && (
                            <>
                                <p className="py-1 text-secondary">Parameters: </p>
                                <div className="bg-black py-1 rounded-lg ReactJson-container">
                                    <ReactJson src={currentHistoryItem.parameters} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                            </>
                        )} */}

                        <p className="py-1 text-secondary">
                            Network: <span className="text-light">{currentHistoryItem.network.name}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Network RPC: <span className="text-light">{currentHistoryItem.network.RPC}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Contract: <span className="text-light">{currentHistoryItem.contract.name}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Contract Proxy: <span className="text-light">{currentHistoryItem.contract.proxy}</span>
                        </p>
                        <p className="py-1 text-secondary">
                            Contract Implementation: <span className="text-light">{currentHistoryItem.contract.implementation}</span>
                        </p>
                        {currentHistoryItem.wallet && currentHistoryItem.wallet.name && (
                            <>
                                <p className="py-1 text-secondary">
                                    Wallet: <span className="text-light">{currentHistoryItem.wallet.name}</span>
                                </p>
                                <p className="py-1 text-secondary">
                                    Wallet Address: <span className="text-light">{currentHistoryItem.wallet.address}</span>
                                </p>
                            </>
                        )}
                        <p className="py-1 text-secondary">
                            Executed <span className="text-light">{timeSince(Date.parse(currentHistoryItem.timestamp))}</span>
                        </p>
                        <div>
                            {currentHistoryItem && currentHistoryItem.function && currentHistoryItem.function.name && currentHistoryItem.function.inputs.length > 0 && (
                                <div className="pt-4 items-center">
                                    <p className="text-white">Parameters</p>
                                    {currentHistoryItem.function.inputs.map((input, index) => {
                                        return (
                                            <div key={index}>
                                                <p className="text-secondary pt-2">{input.name}</p>
                                                <div className="flex items-center pt-2">
                                                    <input
                                                        type="text"
                                                        name={input.name}
                                                        onChange={handleInputChange}
                                                        placeholder={input.type}
                                                        autoComplete="new-password"
                                                        spellCheck="false"
                                                        value={input.value || ""}
                                                    />
                                                </div>
                                                {validationMessages && validationMessages[input.name] && <ValidationMessage message={validationMessages[input.name]} />}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        <label
                            id="ExecuteHistoryConfirmationYes"
                            name="ExecuteHistoryConfirmationYes"
                            htmlFor="historyConfirmation"
                            className="btn-default mt-8"
                            onClick={() => {
                                executeFunction(currentHistoryItem);
                            }}>
                            YES
                            <CheckIcon className="pl-2 text-green" />
                        </label>
                        <label
                            id="ExecuteHistoryConfirmationNo"
                            name="ExecuteHistoryConfirmationNo"
                            htmlFor="historyConfirmation"
                            className="btn-default ml-5"
                            // onClick={() => {
                            //     console.log("closing modal");
                            // }}
                        >
                            NO
                            <CloseOutlined className="pl-2 text-red" />
                        </label>
                    </div>
                </div>
            </>
        );
    } else return null;
};

export default HistoryConfirmation;
