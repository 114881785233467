import { useEffect, useState } from "react";
import { getCookieValue, setCookieValue, dateDefaultFormat } from "@util/util";
import { HistoryCookieKey } from "@util/enum";
import PopupHistorySearchbarDropdownHistory from "./PopupHistorySearchbarDropdownHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faRefresh } from "@fortawesome/free-solid-svg-icons";

function PopupHistorySearchHistory({ setCurrentHistory, currentHistory }) {
    const [history, setHistory] = useState([]);
    const [historyOptions, setHistoryOptions] = useState();
    const [currentHistoryInput, setCurrentHistoryInput] = useState();

    useEffect(() => {
        getHistory();
    }, []);

    async function getHistory() {
        setHistory(getCookieValue(HistoryCookieKey));
        setHistoryOptions(getCookieValue(HistoryCookieKey));
    }

    const onInputChange = (event) => {
        if (event.target.value) {
            setHistoryOptions(history.filter((option) => option.address.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentHistoryInput(event.target.value);
        } else {
            setCurrentHistoryInput("");
            setHistoryOptions(history);
        }
    };

    return (
        <>
            <div>
                <p className="text-secondary">
                    Last Deployements
                    <FontAwesomeIcon
                        id="PopupHistorySearchHistoryRefreshButton"
                        name="PopupHistorySearchHistoryRefreshButton"
                        icon={faRefresh}
                        className="pl-1 text-secondary cursor-pointer"
                        onClick={() => {
                            getHistory();
                        }}
                    />
                </p>
                <PopupHistorySearchbarDropdownHistory
                    options={historyOptions}
                    onInputChange={onInputChange}
                    placeholder="Contract Address"
                    setOptions={setHistoryOptions}
                    currentHistoryInput={currentHistoryInput}
                    setCurrentHistoryInput={setCurrentHistoryInput}
                    setCurrentHistory={setCurrentHistory}
                />
                <p className="pt-6 text-secondary">Current Contract Deployed Info</p>

                {currentHistory && currentHistory.address && (
                    <div>
                        <p>Network: {currentHistory.network}</p>
                        <p>
                            Contract Address: {currentHistory.address}
                            <FontAwesomeIcon
                                id="PopupHistorySearchHistoryCopyAddressButton"
                                name="PopupHistorySearchHistoryCopyAddressButton"
                                data-info={currentHistory.address}
                                icon={faCopy}
                                className="text-secondary ml-2 cursor-pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(currentHistory.address);
                                }}
                            />
                        </p>
                        <p>
                            Executor: {currentHistory.from}
                            <FontAwesomeIcon
                                id="PopupHistorySearchHistoryCopyExecutorButton"
                                name="PopupHistorySearchHistoryCopyExecutorButton"
                                data-info={currentHistory.from}
                                icon={faCopy}
                                className="text-secondary ml-2 cursor-pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(currentHistory.from);
                                }}
                            />
                        </p>
                        <p>
                            Transaction: {currentHistory.transaction}
                            <FontAwesomeIcon
                                id="PopupHistorySearchHistoryCopyTransactionButton"
                                name="PopupHistorySearchHistoryCopyTransactionButton"
                                data-info={currentHistory.transaction}
                                icon={faCopy}
                                className="text-secondary ml-2 cursor-pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(currentHistory.transaction);
                                }}
                            />
                        </p>
                        <p>Status: {currentHistory.status.toString()}</p>
                        <p>Date: {dateDefaultFormat(currentHistory.date)}</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default PopupHistorySearchHistory;
