import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { dateDefaultFormat } from "../../util/util";
import { ModalTheme, FunctionGasType } from "../../util/enum";
import ReactJson from "react-json-view";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import ValidationMessage from "@components/shared/ValidationMessage";

function PopupQueueItemSettings() {
    const { executionQueueDetail, setExecutionQueueDetail, validateParameter, executionQueue, setExecutionQueue } = useContext(AppContext);
    const [validationMessages, setValidationMessages] = useState();

    // console.log("PopupQueueItemSettings render", executionQueue, executionQueueDetail);

    useEffect(() => {
        //loadInputValues();
    }, []);

    // TODO: Update the executionQueueDetail state (Check other may have to be updated and synced using API)
    // ! BUG: updating the executionQueueDetail is not enough. We should update all the items of the list (which state? -> executionQueue)
    // Find the item to update and them setExecutionQueue({ ...executionQueue });
    //             const index = executionQueueDetail.function.inputs.findIndex((input) => input.name === paramName);

    const handleInputChange = (event) => {
        // TODO: Validate the values with the input type (executionQueueDetail.parameters = values, xecutionQueueDetail.function.inputs = types)
        // see validateExecution in context
        const paramName = event.target.name;
        const paramValue = event.target.value;
        let parameters = executionQueueDetail.parameters;
        // console.log("handleInputChange executionQueueDetail", executionQueueDetail);
        if (parameters) {
            // update the parameter value to be executed later
            const _parametersEventUpdate = parameters.map((param) => (param.name === paramName ? { ...param, value: paramValue } : param));
            // console.log("Parameters - handleInputChange _parametersEventUpdate", _parametersEventUpdate);
            executionQueueDetail.parameters = _parametersEventUpdate;

            // Update the input value in the page
            const index = executionQueueDetail.function.inputs.findIndex((input) => input.name === paramName);
            if (index !== -1) {
                // Update the executionQueueDetail state
                executionQueueDetail.function.inputs[index].value = paramValue;
                setExecutionQueueDetail({ ...executionQueueDetail });
                const _valid = validateParam(paramName, executionQueueDetail.function.inputs[index].type, paramValue);
                console.log("_valid", _valid);

                // Update the executionQueue state
                updateexecutionQueue(executionQueueDetail);
                // for (let index = 0; index < executionQueue.length; index++) {
                //     if (executionQueue[index].uuid === executionQueueDetail.uuid) {
                //         let executionQueueItem = executionQueue[index];
                //         executionQueueItem = executionQueueDetail;
                //         executionQueue[index] = { ...executionQueue[index], ...executionQueueItem };
                //         setExecutionQueue([...executionQueue]);
                //     }
                // }
            }
            console.log("executionQueueDetail.function.inputs", executionQueueDetail.function.inputs);
        } else {
            // Update the executionQueueDetail state
            executionQueueDetail.parameters = [{ name: paramName, value: paramValue }];
            setExecutionQueueDetail({ ...executionQueueDetail });

            // Update the executionQueue state
            updateexecutionQueue(executionQueueDetail);
            // for (let index = 0; index < executionQueue.length; index++) {
            //     if (executionQueue[index].uuid === executionQueueDetail.uuid) {
            //         let executionQueueItem = executionQueue[index];
            //         executionQueueItem = executionQueueDetail;
            //         executionQueue[index] = { ...executionQueue[index], ...executionQueueItem };
            //         setExecutionQueue([...executionQueue]);
            //     }
            // }
        }
    };
    function updateexecutionQueue(executionQueueDetail_) {
        for (let index = 0; index < executionQueue.length; index++) {
            if (executionQueue[index].uuid === executionQueueDetail.uuid) {
                let executionQueueItem = executionQueue[index];
                executionQueueItem = executionQueueDetail_;
                executionQueue[index] = { ...executionQueue[index], ...executionQueueItem };
                setExecutionQueue([...executionQueue]);
            }
        }
    }

    const updateValidationMessages = (name, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [name]: message,
            };
        });
    };

    function validateParam(name, type, value) {
        console.log("validateParam name", name, "type", type, "value", value);
        if (!validateParameter(type, value)) {
            updateValidationMessages(name, name + " is invalid");
            return false;
        } else {
            updateValidationMessages(name, null);
            return true;
        }
    }

    if (executionQueueDetail)
        return (
            <>
                <input type="checkbox" id="popupQueueItemSettings" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box min-w-[75%] max-h-[85%]">
                        <div>
                            <p className="text-white font-semibold text-xl">
                                Detail
                            </p>
                            <div className="bg-[#1A1511] p-2 mt-2 rounded-lg">
                                <p className="text-secondary">
                                    Function:<span className="font-semibold text-light pl-2">{executionQueueDetail && executionQueueDetail.function && executionQueueDetail.function.name}</span>
                                </p>
                                <p className="text-secondary">
                                    Contract Name:<span className="text-light pl-2">
                                        {executionQueueDetail && executionQueueDetail.contract && executionQueueDetail.contract.name}
                                        </span>
                                </p>
                                <p className="text-secondary">
                                    Proxy Contract:<span className="text-light pl-2">
                                        {executionQueueDetail && executionQueueDetail.contract && executionQueueDetail.contract.proxy}
                                        </span>
                                </p>
                                <p className="text-secondary">
                                    Implementation Contract:<span className="text-light pl-2">{executionQueueDetail && executionQueueDetail.contract && executionQueueDetail.contract.implementation}</span>
                                </p>
                                <p className="text-secondary">
                                    Wallet Name:<span className="text-light pl-2">{executionQueueDetail && executionQueueDetail.wallet && executionQueueDetail.wallet.name}</span>
                                </p>
                                <p className="text-secondary">
                                    Wallet Address:<span className="text-light pl-2">{executionQueueDetail && executionQueueDetail.wallet && executionQueueDetail.wallet.address}</span>
                                </p>
                                <div>
                                    {executionQueueDetail && executionQueueDetail.function && executionQueueDetail.function.name && executionQueueDetail.function.inputs.length > 0 && (
                                        <div className="pt-4 items-center">
                                            <p className="text-white">Parameters</p>
                                            {executionQueueDetail.function.inputs.map((input, index) => {
                                                return (
                                                    <div key={index}>
                                                        <p className="text-secondary pt-2">{input.name}</p>
                                                        <div className="flex items-center pt-2">
                                                            <input
                                                                type="text"
                                                                name={input.name}
                                                                onChange={handleInputChange}
                                                                placeholder={input.type}
                                                                autoComplete="new-password"
                                                                spellCheck="false"
                                                                value={input.value || ""}
                                                            />
                                                            {validationMessages && validationMessages[input.name] && <ValidationMessage message={validationMessages[input.name]} />}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-action gap-4">
                            <label htmlFor="popupQueueItemSettings" className="btn-default">
                                CLOSE
                                <CloseOutlined className="pl-2" />
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
}

export default PopupQueueItemSettings;
