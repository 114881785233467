import React from "react";
import "./ResizeHandle.css";

const ResizeHandle = ({ onMouseDown }) => {
    return (
        <div className="hidden lg:flex justify-center resize-handle" onMouseDown={onMouseDown}>
            {/* <div className="line"></div> */}
        </div>
    );
};

export default ResizeHandle;
