import { useEffect, useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { notify, NotifyContainer } from "@util/component";
import SideMenu from "@components/sidemenu/SideMenu";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
// import Editor from "@monaco-editor/react";
import Content from "./Content";
// import { reverseArray } from "@util/util";
import { useSearchParams } from "react-router-dom";

const CodeEditor = () => {
    // const { contractsSourceCode } = useContext(AppContext);
    // const [tabsContent, setTabsContent] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    let _mode;
    let _tx;
    let _contract;
    let _network;
    // if (searchParams && searchParams.has("mode")) _mode = searchParams.get("mode");
    // if (searchParams && searchParams.has("tx")) _tx = searchParams.get("tx");
    if (searchParams && searchParams.has("contract")) _contract = searchParams.get("contract");
    if (searchParams && searchParams.has("network")) _network = searchParams.get("network");

    // console.log("CodeEditor params", _contract, _network);

    useEffect(() => {
        // if (contractsSourceCode) {
        // const _contracts = reverseArray(transformArray(contractsSourceCode));
        // setTabsContent(_contracts);
        // }
    }, []);

    return (
        <div className="master">
            <NavBar />
            <div className="flex flex-row main-container bg-[#1a1511]">
                <div>
                    <SideMenu />
                </div>
                <div className="pb-4 w-full">
                    <Content contract={_contract} network={_network} />
                </div>
            </div>
            <Footer />
            <NotifyContainer />
        </div>
    );
};

export default CodeEditor;
