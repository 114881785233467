import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { notify, NotifyContainer } from "../../util/component";
import { newUUID, generateRandomName } from "../../util/util";
import SideMenu from "../../components/sidemenu/SideMenu";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";
import QueueListItemRemoval from "../../components/modals/QueueLisItemRemoval";
import HistoryItem from "../../components/history/HistoryItem";
import AddIcon from "@mui/icons-material/Add";

const QueueList = () => {
    const { connectedAccount, dataLoaded, queueList, setQueueList, executionQueue, currentNetwork, addQueueListItem } = useContext(AppContext);
    const [userLogged, setUserLogged] = useState("loading");
    const [queueListName, setQueueListName] = useState();
    const [_queueList, _setQueueListName] = useState();
    const inputRef = useRef();

    useEffect(() => {
        // console.log("QueueList useEffect userLogged", userLogged);
    }, []);

    useEffect(() => {
        if (userLogged === true) {
            inputRef.current.addEventListener("focus", (event) => {
                if (inputRef.current.value) inputRef.current.select();
            });
            setQueueListName(generateRandomName());
        }
    }, [userLogged]);

    useEffect(() => {
        if (queueList && currentNetwork) _setQueueListName(queueList.filter((queueListItem) => queueListItem.networkId === currentNetwork.id));
    }, [queueList, currentNetwork]);

    function onInputChange(e) {
        setQueueListName(e.target.value);
    }

    function addQueueList() {
        addQueueListItem({
            id: newUUID(),
            name: queueListName,
            networkId: currentNetwork.id,
            queue: null,
        });
        setQueueListName(generateRandomName());
    }

    useEffect(() => {
        if (connectedAccount && !dataLoaded) {
            setUserLogged("loading");
        }
        if (!connectedAccount) {
            setUserLogged(false);
        }
        if (connectedAccount && dataLoaded) {
            setUserLogged(true);
        }
    }, [connectedAccount, dataLoaded]);

    if (userLogged === "loading") {
        return (
            <div className="container">
                <nav className="navbar">
                    <NavBar />
                </nav>
                <aside className="sidebar">
                    <SideMenu />
                </aside>
                <div className="container content-full text-lg text-secondary">
                    <p>Loading Page...</p>
                    <p className="loaderSpinner mt-4"></p>
                </div>
                <footer className="footer">
                    <Footer />
                </footer>
                <NotifyContainer />
            </div>
        );
    }

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            {userLogged === true && (
                <main className="content-grid">
                    <div className="2xl:col-span-6 col-span-12 p-2">
                        <p className="text-white font-semibold text-xl">Execution Queue</p>
                        <div className="pt-4">
                            <p className="text-white">New Queue</p>
                            <p className="pt-2 pb-1 text-secondary">Name</p>
                            <input type="text" name="name" maxLength={45} autoComplete="new-password" spellCheck="false" onChange={onInputChange} value={queueListName || ""} ref={inputRef} />
                            <div className="pt-4">
                                <button
                                    className="btn-default"
                                    onClick={() => {
                                        addQueueList();
                                    }}>
                                    Create New Queue
                                    <AddIcon className="pl-2" />
                                </button>
                            </div>
                        </div>
                        <div className="pt-6">
                            <p className="text-white">Queues Available</p>
                            <ul className="mt-4">
                                {_queueList &&
                                    _queueList.map((item, index_) => {
                                        return (
                                            <li className="bg-black" key={item.id}>
                                                <HistoryItem historyItem={item} behavior={"queueListItem"} />
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>
                </main>
            )}
            {userLogged === false && (
                <main className="content-full px-4">
                    <p className="text-lg text-secondary">You need to be authenticated to use this page.</p>
                    <p className="text-lg text-light pt-2">Click on the top right button to go to the Login page.</p>
                </main>
            )}
            <footer className="footer">
                <Footer />
            </footer>
            <QueueListItemRemoval />
            <NotifyContainer />
        </div>
    );
};

export default QueueList;
