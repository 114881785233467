import { v4 as uuidv4 } from "uuid";

function addUUIDsToList(itemsList) {
    return itemsList.map((item) => {
        if (item.type === "directory" && item.children) {
            const newItem = { ...item, uuid: uuidv4() };
            newItem.children = addUUIDsToList(item.children);
            return newItem;
        } else {
            return {
                ...item,
                uuid: uuidv4(),
            };
        }
    });
}

async function getDirectoryContents(dirRoot, isRoot = true, recursive = false) {
    const content = [];

    if (dirRoot.values().length === 0) {
        // console.log("dirRoot.values().length", dirRoot.values().length);
        return;
    }
    for await (const entry of dirRoot.values()) {
        // console.log("getDirectoryContents entry found", entry);
        if (entry.kind === "file") {
            const file = await entry.getFile();
            const extension = file.name.slice(file.name.lastIndexOf(".") + 1);
            content.push({ type: "file", name: file.name, extension: extension, size: file.size, handle: entry });
        } else {
            if (recursive) {
                const children = await getDirectoryContents(entry, false, recursive);
                content.push({ type: "directory", name: entry.name, handle: entry, children: children });
            } else {
                content.push({ type: "directory", name: entry.name, handle: entry, children: [] });
            }
        }
    }
    console.log("getDirectoryContents content", content);
    if (isRoot) {
        return { name: dirRoot.name, children: content };
    } else {
        return content;
    }
}

export const FileUtil = {
    getDirectory: async () => {
        const directory = await window.showDirectoryPicker();
        return directory;
    },
    ListFilesDirectory: async (popup = true, dirRoot_, isRoot = true, recursive = false) => {
        // ! BUG : handle (dirRoot_) is null
        console.log("ListFilesDirectory popup", popup, "dirRoot_", dirRoot_, "isRoot", isRoot, "recursive", recursive);
        // TODO: Limit the number of files
        // console.log("recursive", recursive);
        let dirRoot = dirRoot_;
        if (popup) dirRoot = await window.showDirectoryPicker();
        console.log("FileUtil ListFilesDirectory dirRoot", dirRoot);
        const result = await getDirectoryContents(dirRoot, isRoot, recursive);
        console.log("result", result);

        // Add UUIDs to the items
        const _result = addUUIDsToList(result);

        return [_result, dirRoot];
    },
    getFileType: (extension) => {
        console.log("getFileType extension", extension);
        const _extension = extension.slice(extension.lastIndexOf(".") + 1);
        if (_extension !== "") extension = _extension;

        const fileTypes = {
            js: "JavaScript",
            jsx: "JavaScript XML",
            json: "JSON",
            html: "HTML",
            css: "CSS",
            scss: "Sass",
            less: "Less",
            md: "Markdown",
            txt: "Text",
            jpg: "JPEG image",
            jpeg: "JPEG image",
            png: "PNG image",
            gif: "GIF image",
            svg: "SVG image",
            webp: "WebBP image",
            ico: "Icon",
            ttf: "TrueType Font",
            otf: "OpenType Font",
            woff: "Web Open Font Format",
            woff2: "Web Open Font Format 2",
            eot: "Embedded OpenType Font",
            sol: "Solidity Contract",
            gitignore: "Git Ignore",
            gitattributes: "Git Attributes",
            lock: "Yarn or NPM lock",
            yml: "YAML",
            yaml: "YAML",
            xml: "XML",
            ini: "INI",
            toml: "TOML",
            csv: "CSV",
            ts: "TypeScript",
            tsx: "TypeScript XML",
        };

        return fileTypes[extension] || "Unknown";
    },
    convertFileSize: (sizeInBytes) => {
        const units = ["Bytes", "KB", "MB", "GB", "TB"];
        let unitIndex = 0;

        if (!sizeInBytes || sizeInBytes === 0) return "0"; // Fix for new files

        while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
            sizeInBytes /= 1024;
            unitIndex++;
        }

        return `${sizeInBytes.toFixed(2)} ${units[unitIndex]}`;
    },
    createNewFile: async () => {
        const options = {
            types: [
                {
                    description: "Solidity Files",
                    accept: {
                        "solidity/*": [".sol"],
                    },
                },
            ],
            excludeAcceptAllOption: false,
            multiple: false,
        };
        const handle = await window.showSaveFilePicker(options);
        console.log("new file handle", handle);
        return handle;
    },
    createNewFile: async (filename, directory) => {
        const fileHandle = await directory.getFileHandle(filename, { create: true });
        return fileHandle;
    },
    writeToFile: async (fileHandle, content) => {
        const writable = await fileHandle.createWritable();
        await writable.write(content);
        await writable.close();
    },
    addUUIDsToList: addUUIDsToList,
    extractImports: (contractCode) => {
        const importRegex = /import\s+"([^"]+)"\s*;/g;
        const importPaths = [];

        let match;
        while ((match = importRegex.exec(contractCode)) !== null) {
            importPaths.push(match[1]);
        }

        return importPaths;
    },
    // SHOULD HAVE NODE_MODULES CONCATENATED
    searchFile: (path, files) => {
        const segments = path.split("/");
        let current = files;
        for (const segment of segments) {
            const match = current.find((file) => file.name === segment);
            if (!match) {
                return null;
            }
            if (match.type === "file" && segment === segments[segments.length - 1]) {
                return match;
            }
            current = match.children;
        }
        return null;
    },
    searchFileByImport: (importPath, files) => {
        const segments = importPath.split("/");
        let current = files;
        for (const segment of segments) {
            const match = current.find((file) => {
                const fileName = file.name.split(".")[0];
                return fileName === segment || file.name === segment;
            });
            if (!match) {
                return null;
            }
            if (match.type === "file" && segment === segments[segments.length - 1]) {
                return match;
            }
            current = match.children;
        }
        return null;
    },
    getPathFromUUID: (uuid, fileList) => {
        function searchUUIDInFiles(uuid, files, path = "") {
            for (const file of files) {
                if (file.uuid === uuid) {
                    return path;
                } else if (file.type === "directory") {
                    const result = searchUUIDInFiles(uuid, file.children, path + file.name + "/");
                    if (result !== null) {
                        return result;
                    }
                }
            }
            return null;
        }

        return searchUUIDInFiles(uuid, fileList);
    },
    resolveImportPath: (currentPath, importPath) => {
        const segments = currentPath.split("/");
        const importSegments = importPath.split("/");
        const absoluteSegments = [...segments.slice(0, -1), ...importSegments];

        const resolvedSegments = [];
        for (const segment of absoluteSegments) {
            if (segment === ".") {
                // Skip current directory segment
                continue;
            } else if (segment === "..") {
                // Go up one directory level
                resolvedSegments.pop();
            } else {
                resolvedSegments.push(segment);
            }
        }

        return resolvedSegments.join("/");
    },
    flattenDependencies: (dependencies) => {
        let flattened = [];

        for (const dependencyObj of dependencies) {
            flattened.push({
                dependency: dependencyObj.dependency,
                path: dependencyObj.path,
                code: dependencyObj.code,
            });

            if (dependencyObj.importedDependencies) {
                const importedFlattened = FileUtil.flattenDependencies(dependencyObj.importedDependencies);
                flattened = flattened.concat(importedFlattened);
            }
        }

        return flattened;
    },

    // searchFile("node_modules/libraryA/contracts", "file.txt", files) to search for a file with name "file.txt" in the specikfic path.
    // searchFile("file.txt", files) to search for a file with name "file.txt" in all paths.
    // searchFile("./file.txt", files, "path/to/directory") to search for a file with name "file.txt" in the "path/to/directory" directory.
    // searchFile: (path, files, currentPath = "") => {
    //     const segments = path.split("/");
    //     console.log("searchFile segments", segments);
    //     let current = files;

    //     // If path starts with "./", use currentPath as the starting point
    //     if (segments[0] === ".") {
    //         console.log("trying to find", path, "in the", currentPath);
    //         const _test = files.find((file) => file.name === currentPath);
    //         console.log("TEST ", _test);
    //         current = files.find((file) => file.name === currentPath)?.children;
    //         segments.shift(); // remove the first segment "./"
    //     }

    //     for (const segment of segments) {
    //         const match = current.find((file) => file.name === segment);
    //         console.log("searchFile match", match, "segment", segment, "current", current);
    //         if (!match) {
    //             return null;
    //         }
    //         if (match.type === "file" && segment === segments[segments.length - 1]) {
    //             return match;
    //         }
    //         current = match.children;
    //     }
    //     return null;
    // },
};

//     ListFilesDirectory: async (recursive = false) => {
//         // const root = await navigator.storage.getDirectory();
//         // console.log(root);
//         const dirHandle = await window.showDirectoryPicker();
//         console.log(dirHandle);
//         const promises = [];
//         for await (const entry of dirHandle.values()) {
//             // console.log(entry);
//             // const path = await dirHandle.resolve(entry);
//             // console.log("path", path);
//             if (entry.kind === "file")
//                 promises.push(
//                     entry.getFile().then((file) => {
//                         const extension = file.name.slice(file.name.lastIndexOf(".") + 1);
//                         return { type: "file", name: file.name, extension: extension, size: file.size };
//                     })
//                 );
//             else {
//                 promises.push({ type: "directory", name: entry.name });
//             }
//         }
//         const _list = await Promise.all(promises);
//         // the output should be
//         // name: "",
//         // children: [
//         //     {
//         //         name: "src",
//         //         children: [{ name: "index.js" }, { name: "styles.css" }],
//         //     },

//         console.log(_list);
//         return _list;
//     },
// };

// async function ListFilesDirectory() {
//     const dirHandle = await window.showDirectoryPicker();
//     const promises = [];
//     for await (const entry of dirHandle.values()) {
//         if (entry.kind !== "file") {
//             continue;
//         }
//         promises.push(entry.getFile().then((file) => `${file.name} (${file.size})`));
//     }
//     console.log(await Promise.all(promises));
// }

// async function loadfile() {
//     // ref: https://developer.chrome.com/articles/file-system-access/
//     // directory/file tree https://dgreene1.github.io/react-accessible-treeview/docs/examples-Basic
//     // ! gets a file and read it content
//     const pickerOpts = {
//         types: [
//             {
//                 description: "Solidity Files",
//                 accept: {
//                     "solidity/*": [".sol"],
//                 },
//             },
//         ],
//         excludeAcceptAllOption: false,
//         multiple: false,
//     };
//     const [fileHandle] = await window.showOpenFilePicker(pickerOpts);
//     console.log(fileHandle);
//     const file = await fileHandle.getFile(fileHandle);
//     console.log(file);
//     const contents = await file.text();
//     console.log(contents);
// }

// async function savefile() {
//     // ! saves a file - WORKING!
//     // SAVEAS?: const draftHandle = await root.getFileHandle("draft.txt", { create: true });

//     // create a new handle
//     // const newHandle = await window.showSaveFilePicker();
//     // // create a FileSystemWritableFileStream to write to
//     // const writableStream = await newHandle.createWritable();
//     // // write our file
//     // await writableStream.write("saving data inside your file");
//     // // close the file and write the contents to disk.
//     // await writableStream.close();

//     alert("thie method is disabled to not destroy the contents a file");
// }
