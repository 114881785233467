import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import SearchbarDropdown from "./SearchbarDropdown";
import { getRandomMotivationalMessage } from "@util/util";
import AddIcon from "@mui/icons-material/Add";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShower, faCube, faCode, faCopy, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// ref: https://github.com/HuakunShen/demo/blob/master/React/search-bar/src/App.jsx

function SearchContract() {
    const { contracts, contractNames, currentContract, currentNetwork, setCurrentContract, currentContractInput, setCurrentContractInput, contractOptions, setContractOptions } =
        useContext(AppContext);

    const [placeholderText, setPlaceholderText] = useState("Search Contract");

    const navigate = useNavigate();

    console.log("SearchContract currentContract", currentContract);

    useEffect(() => {
        if (currentNetwork && contracts) setContractOptions(contracts.filter((option) => option.networkId === currentNetwork.id));
        console.log("SearchContract", contracts);
        console.log("SearchContract currentNetwork", currentNetwork);
    }, [contracts, currentNetwork]);

    useEffect(() => {
        if (contractOptions.length === 0) setPlaceholderText("Add a new contract clicking on the + button");
        else setPlaceholderText("Search Contract");
    }, [contractOptions]);

    const onInputChange = (event) => {
        setContractOptions(
            contracts.filter((option) => {
                if (option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) && option.networkId === currentNetwork.id) return true;
                else return false;
            })
        );
        setCurrentContractInput(event.target.value);
    };

    function clearContract() {
        setCurrentContract();
        setContractOptions(contracts.filter((option) => option.networkId === currentNetwork.id));
        setCurrentContractInput("");
    }

    function showSourcecode(type) {
        const currentProtocol = window.location.protocol;
        const currentDomain = window.location.hostname;
        const currentPort = window.location.port;
        let url;
        url = currentProtocol + "//" + currentDomain + ":" + currentPort + "/editor?network=" + currentNetwork.id + "&contract=";
        const contract = type === "implementation" ? currentContract.implementation : currentContract.proxy;
        url = url + contract;
        // console.log("showSourcecode URL", url);
        window.open(url, "_blank");
    }

    return (
        <div className="pt-4 items-center">
            <p className="w-32 text-white">Contract</p>
            <div className="flex items-center pt-2">
                <SearchbarDropdown options={contractOptions} onInputChange={onInputChange} placeholder={placeholderText} setOptions={setContractOptions} />
                <FontAwesomeIcon
                    id="ExecuteContractSearchContractClearButton"
                    name="ExecuteContractSearchContractClearButton"
                    icon={faShower}
                    className="text-secondary ml-2 cursor-pointer max-h-5 min-h-5"
                    onClick={() => {
                        clearContract();
                    }}
                />
                <label htmlFor="manageContract" className="btn-icon">
                    <FontAwesomeIcon
                        id="ExecuteContractSearchContractManageContractButton"
                        name="ExecuteContractSearchContractManageContractButton"
                        icon={faPlus}
                        className="ml-2 text-secondary cursor-pointer"
                        htmlFor="manageContract"
                    />
                </label>
            </div>
            {currentNetwork && currentContract && Object.keys(currentContract).length !== 0 && (
                <>
                    <p className="text-secondary pt-2">Proxy</p>
                    <div className="flex items-center pt-2">
                        <input type="text" placeholder="Proxy" name="proxy" maxLength={42} autoComplete="new-password" spellCheck="false" value={currentContract.proxy || ""} disabled />
                        <FontAwesomeIcon
                            id="ExecuteContractSearchContractProxyCopyButton"
                            name="ExecuteContractSearchContractProxyCopyButton"
                            icon={faCopy}
                            onClick={() => {
                                if (currentContract.implementation) navigator.clipboard.writeText(currentContract.proxy);
                                else navigator.clipboard.writeText(getRandomMotivationalMessage());
                            }}
                            className="pl-2 text-secondary cursor-pointer max-h-4 min-h-4"
                        />
                        {/* <a href={currentNetwork.blockExplorerURL + "/address/" + currentContract.proxy + "#code"} target="_blank">
                                <FontAwesomeIcon icon={faCode} className="ml-2 text-secondary cursor-pointer" />
                            </a> */}
                        <FontAwesomeIcon
                            id="ExecuteContractSearchContractProxyCodeButton"
                            name="ExecuteContractSearchContractProxyCodeButton"
                            icon={faCode}
                            className="ml-2 text-secondary cursor-pointer"
                            onClick={() => {
                                showSourcecode("proxy");
                            }}
                        />
                        <a
                            id="ExecuteContractSearchContractProxyBlockExplorer"
                            name="ExecuteContractSearchContractProxyBlockExplorer"
                            href={currentNetwork.blockExplorerURL + "/address/" + currentContract.proxy}
                            target="_blank">
                            <FontAwesomeIcon
                                id="ExecuteContractSearchContractProxyBlockExplorerIcon"
                                name="ExecuteContractSearchContractProxyBlockExplorerIcon"
                                icon={faCube}
                                className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5"
                            />
                        </a>
                    </div>
                    <p className="text-secondary pt-2">Implementation</p>
                    <div className="flex items-center pt-2">
                        <input type="text" placeholder="Implementation" name="implementation" maxLength={42} autoComplete="new-password" value={currentContract.implementation || ""} disabled />
                        <FontAwesomeIcon
                            id="ExecuteContractSearchContractImplementationCopyButton"
                            name="ExecuteContractSearchContractImplementationCopyButton"
                            icon={faCopy}
                            onClick={() => {
                                if (currentContract.implementation) navigator.clipboard.writeText(currentContract.implementation);
                                else navigator.clipboard.writeText(getRandomMotivationalMessage());
                            }}
                            className="pl-2 text-secondary cursor-pointer max-h-4 min-h-4"
                        />
                        {/* <a href={currentNetwork.blockExplorerURL + "/address/" + currentContract.implementation + "#code"} target="_blank">
                                <FontAwesomeIcon icon={faCode} className="ml-2 text-secondary cursor-pointer" />
                            </a> */}
                        <FontAwesomeIcon
                            id="ExecuteContractSearchContractImplementationCodeButton"
                            name="ExecuteContractSearchContractImplementationCodeButton"
                            icon={faCode}
                            className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5"
                            onClick={() => {
                                showSourcecode("implementation");
                            }}
                        />
                        <a href={currentNetwork.blockExplorerURL + "/address/" + currentContract.implementation} target="_blank">
                            <FontAwesomeIcon
                                id="ExecuteContractSearchContractImplementationCodeIcon"
                                name="ExecuteContractSearchContractImplementationCodeIcon"
                                icon={faCube}
                                className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5"
                            />
                        </a>
                    </div>
                </>
            )}
        </div>
    );
}

export default SearchContract;
