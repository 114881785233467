import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import DeploymentPanelSearchNetwork from "./PopupNetworkSearchNetwork";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faBolt, faBoxOpen, faCode, faCopy, faCube, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Web3 from "web3";
import { extendedMoneyNumberFormatter, timeSince } from "@util/util";
import { dateDefaultFormat } from "../../util/util";

const PopupDeploymentFinished = ({
    lastDeploymentInfo,
    contractsNetworkEditor,
    contractsInfo,
    lastDeploymentDateTime,
    verifying,
    autoVerifyStatus,
    verificationStatusMessage,
    initializeStatus,
    proxyUpgradeToStatus,
    upgradeToFunctionRetry,
}) => {
    const { getCoinPrice } = useContext(AppContext);
    const [transactionCostWei, setTransactionCostWei] = useState();
    const [coinPrice, setCoinPrice] = useState();
    const [transactionCostUSD, setTransactionCostUSD] = useState();

    useEffect(() => {
        if (lastDeploymentInfo) calculateTransactionCost();
    }, [lastDeploymentInfo]);

    async function calculateTransactionCost() {
        let _transactionCostWei = null;
        if (lastDeploymentInfo.gasUsed && lastDeploymentInfo.effectiveGasPrice) {
            let _feeEtherAmount = lastDeploymentInfo.gasUsed * lastDeploymentInfo.effectiveGasPrice;
            _transactionCostWei = _feeEtherAmount.toString();
        }

        let _coinPrice = null;
        _coinPrice = await getCoinPrice(contractsNetworkEditor.coin);

        let _transactionCostUSD;
        if (_coinPrice && lastDeploymentInfo.gasUsed && lastDeploymentInfo.effectiveGasPrice) {
            _transactionCostUSD = await getTransactionCostUSD(lastDeploymentInfo.gasUsed, lastDeploymentInfo.effectiveGasPrice, _coinPrice);
        }

        setTransactionCostWei(_transactionCostWei);
        setCoinPrice(_coinPrice);
        setTransactionCostUSD(_transactionCostUSD);
    }

    async function getTransactionCostUSD(gasUsed, gasPrice, coinPrice) {
        let _feeUSD;
        let _feeEtherAmount = gasUsed * gasPrice;
        let _feeEther = Web3.utils.fromWei(_feeEtherAmount.toString(), "ether");
        _feeUSD = extendedMoneyNumberFormatter().format(parseFloat(_feeEther) * parseFloat(coinPrice));
        if (isNaN(_feeUSD)) return null;
        return _feeUSD;
    }

    return (
        <dialog id="popupDeploymentFinished" className="modal rounded-lg bg-transparent">
            <div method="dialog" className="modal-box text-light min-w-[780px]">
                <h1 className="font-bold text-lg text-white">Deployment Result</h1>
                {lastDeploymentInfo && (
                    <div className="pt-3">
                        <h2 className="text-secondary">
                            {contractsInfo.isProxy && (
                                <>
                                    🎉Implementation Contract deployed with <span className="text-green">success 🎉</span>
                                </>
                            )}
                            {!contractsInfo.isProxy && (
                                <>
                                    🎉Contract deployed with <span className="text-green">success 🎉</span>
                                </>
                            )}
                        </h2>
                        <p className="text-secondary pt-2">
                            Name: <span className="text-light">{contractsInfo.ContractName}</span>
                        </p>
                        <p className="text-secondary">
                            Contract Address:
                            <span className="text-light pl-2">
                                {lastDeploymentInfo.contractAddress ?? lastDeploymentInfo._address}
                                <FontAwesomeIcon
                                    id="PopupDeploymentFinishedContractAddressCopy"
                                    name="PopupDeploymentFinishedContractAddressCopy"
                                    icon={faCopy}
                                    className="text-secondary ml-2 cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(lastDeploymentInfo.contractAddress);
                                    }}
                                />
                                <a
                                    id="PopupDeploymentFinishedContractAddressBlockExplorer"
                                    name="PopupDeploymentFinishedContractAddressBlockExplorer"
                                    href={contractsNetworkEditor.blockExplorerURL + "address/" + (lastDeploymentInfo.contractAddress || lastDeploymentInfo._address)}
                                    target="_blank">
                                    <FontAwesomeIcon icon={faCube} className="text-secondary ml-2" />
                                </a>
                            </span>
                        </p>
                        {lastDeploymentInfo.transactionHash && (
                            <p className="text-secondary">
                                Transaction:
                                <span className="text-light pl-2">
                                    {lastDeploymentInfo.transactionHash}
                                    <FontAwesomeIcon
                                        id="PopupDeploymentFinishedContractTransactionCopy"
                                        name="PopupDeploymentFinishedContractTransactionCopy"
                                        icon={faCopy}
                                        className="text-secondary ml-2 cursor-pointer"
                                        onClick={() => {
                                            navigator.clipboard.writeText(lastDeploymentInfo.transactionHash);
                                        }}
                                    />
                                    <a
                                        id="PopupDeploymentFinishedTransactionBlockExplorer"
                                        name="PopupDeploymentFinishedTransactionBlockExplorer"
                                        href={contractsNetworkEditor.blockExplorerURL + "tx/" + lastDeploymentInfo.transactionHash}
                                        target="_blank">
                                        <FontAwesomeIcon icon={faCube} className="text-secondary ml-2" />
                                    </a>
                                </span>
                            </p>
                        )}
                        {lastDeploymentInfo.gasUsed && (
                            <p className="text-secondary">
                                Gas Used:<span className="text-light pl-2">{lastDeploymentInfo.gasUsed} Wei</span>
                            </p>
                        )}
                        {lastDeploymentInfo.effectiveGasPrice && (
                            <p className="text-secondary">
                                Gas Price:<span className="text-light pl-2">{Web3.utils.fromWei(lastDeploymentInfo.effectiveGasPrice.toString(), "gwei")} Gwei</span>
                            </p>
                        )}
                        {transactionCostUSD && transactionCostWei && contractsNetworkEditor && (
                            <p className="text-secondary">
                                Deployment Cost:
                                <span className="text-light pl-2">
                                    {transactionCostUSD} USD - {Web3.utils.fromWei(transactionCostWei.toString(), "ether")} {contractsNetworkEditor.coin}
                                </span>
                            </p>
                        )}
                        <p className="text-secondary">
                            Deployment Time:<span className="text-light pl-2">{dateDefaultFormat(lastDeploymentDateTime)}</span>
                        </p>
                        {contractsInfo.isProxy && (
                            <p className="text-secondary pt-4">
                                Setting new Implemenetation Address:<span className="text-light pl-2">{proxyUpgradeToStatus}</span>
                                {proxyUpgradeToStatus === "Error setting the new implementation contract in the Proxy. Click to retry." && (
                                    <span id="PopupDeploymentFinishedUpgradeRetry" name="PopupDeploymentFinishedUpgradeRetry">
                                        <FontAwesomeIcon
                                            id="PopupDeploymentFinishedUpgradeRetryIcon"
                                            name="PopupDeploymentFinishedUpgradeRetryIcon"
                                            icon={faArrowsRotate}
                                            className="text-secondary ml-2 cursor-pointer"
                                            onClick={() => {
                                                upgradeToFunctionRetry(null, false);
                                            }}></FontAwesomeIcon>
                                    </span>
                                )}
                            </p>
                        )}
                        <p className="text-secondary pt-4">
                            Verification Status:<span className="text-light pl-2">{autoVerifyStatus || verificationStatusMessage}</span>
                        </p>
                        {(verifying || autoVerifyStatus) && (
                            <p className="text-secondary pt-4">
                                <FontAwesomeIcon icon={faTriangleExclamation} className="pr-2" fade />
                                It will take a few seconds until the new contract code is available in the network.
                            </p>
                        )}
                        {/* {contractsInfo.isProxy && (
                            <>
                                <p className="text-secondary pt-4">
                                    Initialize Status:<span className="text-light pl-2">Not Executed</span> */}
                        {/* // TODO */}
                        {/* <span className="text-light pl-2">{initializeStatus}</span>
                                    {initializeStatus === "Error executing initialize function. Click to retry." && (
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faArrowsRotate}
                                                className="text-secondary ml-2 cursor-pointer"
                                                onClick={() => {
                                                    alert("TODO");
                                                }}></FontAwesomeIcon>
                                        </span>
                                    )} */}
                        {/* </p>
                            </>
                        )} */}
                    </div>
                )}
                {!lastDeploymentInfo && (
                    <div className="pt-3">
                        <h2 className="text-secondary">Deploy a contract to see the details here</h2>
                    </div>
                )}
                <div className="flex justify-end pt-10">
                    {lastDeploymentInfo && !contractsInfo.isProxy && (
                        <button
                            id="PopupDeploymentFinishedOpenNewContractButton"
                            name="PopupDeploymentFinishedOpenNewContractButton"
                            className="btn-default justify-end ml-4"
                            onClick={() => {
                                window.open(window.location.origin + "/editor?network=" + contractsNetworkEditor.id + "&contract=" + lastDeploymentInfo.contractAddress, "_blank");
                            }}>
                            open new contract
                            <FontAwesomeIcon id="PopupDeploymentFinishedOpenNewContractButtonIcon" name="PopupDeploymentFinishedOpenNewContractButtonIcon" icon={faCode} className="pl-2" />
                        </button>
                    )}
                    {lastDeploymentInfo && contractsInfo.isProxy && (
                        <>
                            {/* <button
                                className="btn-default"
                                onClick={() => {
                                    alert("TODO");
                                }}>
                                Execute Initialize
                            </button> */}
                            <button
                                id="PopupDeploymentFinishedLoadNewContractButton"
                                name="PopupDeploymentFinishedLoadNewContractButton"
                                className="btn-default justify-end ml-4"
                                onClick={() => {
                                    window.open(window.location.origin + "/editor?network=" + contractsNetworkEditor.id + "&contract=" + contractsInfo.baseContractAddress);
                                }}>
                                load new contract
                                <FontAwesomeIcon id="PopupDeploymentFinishedLoadNewContractButtonIcon" name="PopupDeploymentFinishedLoadNewContractButtonIcon" icon={faCode} className="pl-2" />
                            </button>
                        </>
                    )}
                    <button
                        id="PopupDeploymentFinishedCloseButton"
                        name="PopupDeploymentFinishedCloseButton"
                        className="btn-default justify-end ml-4"
                        onClick={() => {
                            window.popupDeploymentFinished.close();
                        }}>
                        Close
                        <CloseOutlined className="pl-2 text-red" />
                    </button>
                </div>
            </div>
        </dialog>
    );
};

export default PopupDeploymentFinished;
