import { useEffect, useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import PopupNetworkSearchbarDropdownNetwork from "./PopupNetworkAppSearchbarDropdownNetwork";
import AddIcon from "@mui/icons-material/Add";

// ref: https://github.com/HuakunShen/demo/blob/master/React/search-bar/src/App.jsx

function PopupNetworkSearchNetwork() {
    const { networks } = useContext(AppContext);
    const [ networkOptions, setNetworkOptions ] = useState();
    const [ currentNetworkInput, setCurrentNetworkInput ] = useState();
    // const [ currentNetwork, setCurrentNetwork ] = useState();

    useEffect(() => {
        setNetworkOptions(networks);
    }, [networks]);

    // console.log("SearchNetwork Networks", networks);
    // console.log("SearchNetwork NetworksOptions", networkOptions);

    const onInputChange = (event) => {
        if (event.target.value) {
            setNetworkOptions(networks.filter((option) => option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentNetworkInput(event.target.value);
        } else {
            console.log("PopupNetworkSearchNetwork No network selected")
            setCurrentNetworkInput("");
            setNetworkOptions(networks);
        }
    };

    return (
        <>
            <div>
                <PopupNetworkSearchbarDropdownNetwork options={networkOptions} onInputChange={onInputChange} placeholder="Select a Network" setOptions={setNetworkOptions} currentNetworkInput={currentNetworkInput} setCurrentNetworkInput={setCurrentNetworkInput} />
            </div>
        </>
    );
}

export default PopupNetworkSearchNetwork;
