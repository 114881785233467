import { useEffect, useRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { getImageUrl, getImageUrlWithExt } from "../../util/util";
import { saveDataStorage, getDataStorage } from "../../util/util";
import { LocalStorage } from "../../util/enum";

function SearchbarDropdown(props) {
    const { networks, currentNetworkInput, currentNetworkSettings, setCurrentNetworkSettings } = useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions, currentNetworkSearchInput, setCurrentNetworkSearchInput } = props;
    const ulRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });
    }, []);

    return (
        <div className="dropdown dropdown-bottom w-full">
            <input
                className="input-image pl-10"
                style={currentNetworkSettings && { backgroundImage: `${getImageUrlWithExt(currentNetworkSettings.logo, "webp", true)}` }}
                type="text"
                placeholder={placeholder}
                ref={inputRef}
                onChange={onInputChange}
                autoComplete="new-password"
                spellCheck="false"
                value={currentNetworkSearchInput || ""}
            />
            <ul id="results" className="ul-main" ref={ulRef}>
                {options && options.map((option, index) => {
                    return (
                        <button
                            type="button"
                            key={index}
                            onClick={(e) => {
                                ulRef.current.style.display = "none";
                                setCurrentNetworkSettings(option);
                                setCurrentNetworkSearchInput(option.name);
                                setOptions(networks.filter((_option) => _option.name.toLocaleLowerCase().includes(option.name.toLocaleLowerCase())));
                                console.debug("Current network in Settings page is set to:", option);
                            }}
                            className="ul-item">
                            <img className="h-5 inline pr-2 " src={`${getImageUrlWithExt(option.logo)}`} alt={option.name} />
                            {option.name}
                        </button>
                    );
                })}
            </ul>
        </div>
    );
}

export default SearchbarDropdown;
