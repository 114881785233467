import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import ValidationMessage from "@components/shared/ValidationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { notify } from "@util/component";
import { FunctionGasType } from "@util/enum";

const PopupDeployParameters = ({ network, contract, wallet, deploymentParameters, deploymentFunction, abi, bytecode }) => {
    const { validateParameter } = useContext(AppContext);
    const [validationMessages, setValidationMessages] = useState();
    const [parameters, setParameters] = useState({});

    const handleInputChange = (event) => {
        const paramName = event.target.name;
        const paramValue = event.target.value;

        setParameters((prevValues) => {
            return {
                ...prevValues,
                [paramName]: paramValue,
            };
        });

        const index = deploymentParameters.inputs.findIndex((input) => input.name === paramName);
        if (index !== -1) {
            validateParam(paramName, deploymentParameters.inputs[index].type, paramValue);
        }
    };

    function validateParam(name, type, value) {
        if (!validateParameter(type, value)) {
            updateValidationMessages(name, name + " is invalid");
            return false;
        } else {
            updateValidationMessages(name, null);
            return true;
        }
    }

    const updateValidationMessages = (name, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [name]: message,
            };
        });
    };

    async function deployContract() {
        // Validate if there are validations errors
        if (validationMessages && validationMessages && typeof validationMessages === "object") {
            const functionErrors = validationMessages;

            // Loop through the properties of functionErrors object
            for (const param in functionErrors) {
                if (functionErrors[param] !== null) {
                    notify("Please review the validations messages and try again", "error");
                    return;
                }
            }
        }
        const _paramValues = Object.values(parameters);
        console.log("deployContract _paramValues", _paramValues);
        deploymentFunction(_paramValues, abi, bytecode);
    }

    return (
        <dialog id="popupDeployParameters" className="rounded-lg bg-transparent w-[50%]">
            {/* <form method="dialog" className="modal-box text-light"> */}
            <form method="dialog" className="modal-box w-full h-full max-w-full max-h-full text-light">
                <div>
                    <h3 className="font-bold text-lg text-white">Deploy Parameters</h3>
                    <div className="pt-4 text-secondary">
                        <p>
                            Network:
                            <span className="pl-1 text-light">
                                {network && network.name} ({network && network.id})
                            </span>
                        </p>
                        <p>
                            Contract: <span className="text-light">{contract}</span>
                        </p>
                        {wallet && (
                            <p>
                                Wallet:
                                <span className="pl-1 text-light">
                                    {wallet && wallet.name} ({wallet && wallet.address})
                                </span>
                            </p>
                        )}
                    </div>
                    {deploymentParameters && deploymentParameters.inputs && deploymentParameters.inputs.length > 0 && (
                        <div className="pt-4 items-center">
                            <p className="text-light">Parameters</p>
                            {deploymentParameters.inputs.map((input, index) => {
                                return (
                                    <div key={index}>
                                        <p className="text-secondary pt-2">{input.name}</p>
                                        <div className="flex items-center pt-2">
                                            <input
                                                type="text"
                                                name={input.name}
                                                onChange={handleInputChange}
                                                placeholder={input.type}
                                                autoComplete="new-password"
                                                spellCheck="false"
                                                value={(parameters && parameters[input.name]) || ""}
                                            />
                                        </div>
                                        {validationMessages && validationMessages[input.name] && <ValidationMessage message={validationMessages[input.name]} />}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className="modal-action pt-6">
                    <button
                        id="PopupDeployParametersDeployButton"
                        name="PopupDeployParametersDeployButton"
                        className="btn-default"
                        onClick={() => {
                            deployContract();
                        }}>
                        Deploy
                    </button>
                    <button id="PopupDeployParametersCancelButton" name="PopupDeployParametersCancelButton" className="btn-default" onClick={() => {}}>
                        Cancel
                    </button>
                </div>
            </form>
        </dialog>
    );
};

export default PopupDeployParameters;
