// Season enums can be grouped as static members of a class
// export class LocalStorage {
//     static Network = new LocalStorage("network");
//     static Contract = new LocalStorage("contract");
//     static Wallet = new LocalStorage("wallet");

//     constructor(key) {
//         this.key = key;
//     }
// }

export const LocalStorage = {
    SideMenuState: "sidemenu_state",
    NetworkList: "network_list",
    ContractList: "contract_list",
    WalletList: "wallet_list",
    ExecutionHistory: "execution_history",
    ExecutionQueue: "execution_queue",
    ExecutionQueueResults: "execution_queue_results",
    NetworkSelected: "network_selected",
    EditorWallet: "editor_wallet",
};

export const FunctionGasType = {
    PayGasFee: "gas",
    NoGasFee: "nogas",
};

export const ModalTheme = {
    base00: "black",
    base01: "#1A1511",
    base02: "#322B26",
    base03: "#11452C",
    base04: "#4e2b13",
    base05: "#b3b3b3",
    base06: "#b3b3b3",
    base07: "#ffb564",
    base08: "#b3b3b3",
    base09: "#b3b3b3",
    base0A: "#b3b3b3",
    base0B: "#b3b3b3",
    base0C: "#ffb564",
    base0D: "#ef7308",
    base0E: "#ffb564",
    base0F: "#b3b3b3",
};

export const ValidationStepOperator = {
    equal: "equal",
    less: "less",
    greater: "greater",
    equalOrLess: "equalorless",
    equalOrGreater: "equalorgreater",
    notEqual: "notequal",
};

export const HistoryCookieKey = "HISTORY_DEPLOYMENT_COOKIE_KEY";
