import { useState, useEffect, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { dateDefaultFormat, defaultNumberFormatter, moneyNumberFormatter, ether, getImageUrl, capitalize, extractJSON } from "@util/util";
import { ModalTheme } from "@util/enum";
import { FunctionGasType } from "@util/enum";
import ReactJson from "react-json-view";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBugSlash, faCubes, faXmark, faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import Tabs from "@components/tabs/Tabs";

const ResultPayable = ({ currentFunction, lastExecution, network }) => {
    const { getCoinPrice, getFunctionGasType } = useContext(AppContext);
    const [changed, setChanged] = useState(false);
    const [functionType, setFunctionType] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        setChanged(true);
    }, [currentFunction]);

    useEffect(() => {
        setChanged(false);
        if (lastExecution) {
            const _functionGasType = getFunctionGasType(lastExecution.function.stateMutability);
            if (_functionGasType === FunctionGasType.NoGasFee) setFunctionType("View or Pure (No Gas Used)");
            if (_functionGasType === FunctionGasType.PayGasFee) setFunctionType("Payable or Non-Payable");
        }
    }, [lastExecution]);

    // console.log("ResultPayalable - lastExecution", lastExecution);

    function debugTransaction() {
        navigate("/editor?mode=debug");
    }

    function getFeeUSD(receipt) {
        let _feeUSD;
        if (receipt) _feeUSD = moneyNumberFormatter().format(parseFloat(ether(lastExecution.receipt.gasUsed * lastExecution.gasPrice)) * parseFloat(lastExecution.coinPrice));
        else _feeUSD = moneyNumberFormatter().format(parseFloat(ether(lastExecution.gas * lastExecution.gasPrice)) * parseFloat(lastExecution.coinPrice));
        if (isNaN(_feeUSD)) return "<0.01";
        return _feeUSD;
    }

    function openTrace() {
        // ! MAINNET ONLY !
        notify("Only for mainnets...");
        // TODO: Add the network names in the DB
        let _networkName;
        if (lastExecution.network.id === 80001) _networkName = "polygon";
        let _traceLink = "";
        window.open(currentNetwork.faucetURL, "_blank");
    }

    console.log("ResultPayable lastExecution", lastExecution);

    const tabsData = [
        {
            title: (
                <span>
                    Result
                    {!lastExecution.error && <FontAwesomeIcon icon={faCheck} className="pl-1 text-green" />}
                    {lastExecution.error && <FontAwesomeIcon icon={faXmark} className="pl-1 text-red" />}
                </span>
            ),
            content: (
                <div>
                    {!lastExecution.result && (
                        <p className="text-secondary">
                            Result:<span className="text-light pl-2">N/A</span>
                        </p>
                    )}
                    {lastExecution.result && typeof lastExecution.result != "object" && (
                        <p className="text-secondary">
                            Result:<span className="text-light pl-2">{lastExecution.result.toString()}</span>
                            <FontAwesomeIcon
                                id="ResultPayableResultCopyButtonIcon"
                                name="ResultPayableResultCopyButtonIcon"
                                icon={faCopy}
                                onClick={() => {
                                    navigator.clipboard.writeText(lastExecution.result.toString());
                                }}
                                className="ml-2 text-secondary cursor-pointer"
                            />
                        </p>
                    )}
                    {lastExecution.result && typeof lastExecution.result === "object" && (
                        <div>
                            <p className="text-secondary">
                                Result
                                <FontAwesomeIcon
                                    id="ResultPayableResultCopyButtonIcon"
                                    name="ResultPayableResultCopyButtonIcon"
                                    icon={faCopy}
                                    onClick={() => {
                                        navigator.clipboard.writeText(lastExecution.result);
                                    }}
                                    className="ml-2 text-secondary cursor-pointer"
                                />
                            </p>
                            <div className="ReactJson-container">
                                <ReactJson src={lastExecution.result} iconStyle="triangle" collapsed={1} displayDataTypes={false} theme={ModalTheme} />
                            </div>
                        </div>
                    )}
                    {lastExecution.error && lastExecution.error.revertReason && (
                        <p className="text-secondary">
                            Revert Reason:
                            <span className="text-light pl-2 text-red">{lastExecution.error.revertReason}</span>
                        </p>
                    )}
                </div>
            ),
        },
        {
            title: "Detail",
            content: (
                <div>
                    {/* <div className="text-secondary">
                        <div className="flex items-center">
                            Status:
                            {lastExecution.receipt && lastExecution.receipt.status && <span className="font-semibold text-light pl-2 text-green">Success</span>}
                            {!lastExecution.receipt && <span className="font-semibold text-light pl-2 text-red">Failed</span>}
                            {lastExecution.receipt && !lastExecution.receipt.status && (
                                <>
                                    <span className="text-light pl-2 text-red">Failed</span> */}
                    {/* {lastExecution.type === "execution" && (
                                                                <a href={lastExecution.network.blockExplorerURL + "/vmtrace?txhash=" + lastExecution.receipt.transactionHash + "&type=gethtrace2"} target="_blank">
                                                                    <img className="h-4 pl-2" src={`${getImageUrl("redpill")}`} alt="Trace" />
                                                                </a>
                                                            )} */}
                    {/* </>
                            )}
                        </div>
                    </div> */}
                    {/* <p className="text-secondary">
                        Network:
                        <span className="text-light pl-2">
                            {network.name} ({network.id})
                        </span>
                    </p>
                    <p className="text-secondary">
                        Function:<span className="text-light pl-2">{lastExecution.function.name}</span>
                    </p> */}
                    <p className="text-secondary">
                        Function Type:<span className="text-light pl-2">{functionType}</span>
                    </p>
                    {lastExecution.input.type === "execution" && (
                        <p className="text-secondary">
                            Transaction Id:
                            <span className="text-sm text-light pl-2">
                                {(lastExecution.receipt && lastExecution.receipt.transactionHash) || lastExecution.transactionHash}
                                {!lastExecution.receipt && !lastExecution.transactionHash && "Not Executed"}
                            </span>
                            {lastExecution.receipt && (
                                <>
                                    <a
                                        id="ResultPayableResultTransactionBlockExplorer"
                                        name="ResultPayableResultTransactionBlockExplorer"
                                        href={network.blockExplorerURL + "/tx/" + lastExecution.receipt.transactionHash}
                                        target="_blank">
                                        {/* <ReorderOutlinedIcon className="ml-1 text-secondary cursor-pointer" /> */}
                                        <FontAwesomeIcon icon={faCubes} className="ml-1 text-secondary cursor-pointer" />
                                    </a>
                                    <FontAwesomeIcon
                                        id="ResultPayableResultTransactionCopyIcon"
                                        name="ResultPayableResultTransactionCopyIcon"
                                        icon={faCopy}
                                        onClick={() => {
                                            navigator.clipboard.writeText(lastExecution.receipt.transactionHash);
                                        }}
                                        className="ml-2 text-secondary cursor-pointer"
                                    />
                                    {/* <FontAwesomeIcon
                                            icon={faBugSlash}
                                            className="ml-3 text-secondary cursor-pointer"
                                            onClick={() => {
                                                debugTransaction();
                                            }}
                                        /> */}
                                </>
                            )}
                        </p>
                    )}
                    {/* <p className="text-secondary">
                        Timestamp:<span className="text-light pl-2">{dateDefaultFormat(lastExecution.timestamp)}</span>
                    </p>
                    <p className="text-secondary">
                        Sender:
                        {lastExecution.input.type === "execution" && <span className="text-light pl-2">{lastExecution.wallet.address}</span>}
                        {lastExecution.input.type === "simulation" && <span className="text-light pl-2">N/A</span>}
                    </p>
                    <p className="text-secondary">
                        Receiver:
                        {lastExecution.contract.implementation && <span className="text-light pl-2">{lastExecution.contract.implementation}</span>}
                        {!lastExecution.contract.implementation && <span className="text-light pl-2">N/A</span>} */}
                    {/* {lastExecution.input.type === "simulation" && <span className="text-light pl-2">{lastExecution.contract.implementation}</span>}
                        {!lastExecution.receipt && <span className="text-light pl-2">N/A</span>}
                        {lastExecution.receipt && <span className="text-light pl-2">{lastExecution && lastExecution.contract.implementation}</span>} */}
                    {/* </p> */}
                    {lastExecution.input.type === "execution" && (
                        <p className="text-secondary">
                            {!lastExecution.receipt && <span>Gas Used (Estimated):</span>}
                            {lastExecution.receipt && <span>Gas Used:</span>}
                            {/* Gas Used: */}
                            {!lastExecution.receipt && lastExecution.gas && <span className="text-light pl-2">{lastExecution.gas}</span>}
                            {!lastExecution.receipt && !lastExecution.gas && <span className="text-light pl-2">N/A</span>}
                            {lastExecution.receipt && <span className="text-light pl-2">{lastExecution.receipt.gasUsed}</span>}
                        </p>
                    )}
                    {lastExecution.input.type === "execution" && (
                        <p className="text-secondary">
                            Gas Price:
                            {lastExecution.gasPrice && (
                                <span className="text-light pl-2">
                                    {lastExecution.gasPrice} Wei ({moneyNumberFormatter().format(Web3.utils.fromWei(lastExecution.gasPrice.toString(), "gwei"))} Gwei)
                                </span>
                            )}
                            {!lastExecution.gasPrice && <span className="text-light pl-2">N/A</span>}
                        </p>
                    )}
                    {lastExecution.input.type === "execution" && (
                        <p className="text-secondary">
                            {!lastExecution.receipt && <span>Fee (Estimated):</span>}
                            {lastExecution.receipt && <span>Fee:</span>}
                            {/* Fee: */}
                            {/* {!lastExecution.receipt && <span className="font-semibold text-light pl-2">{lastExecution.gasFee} Wei (Estimated)</span>} */}
                            {lastExecution.receipt && !lastExecution.provider && (
                                <span className="text-light pl-2">
                                    {defaultNumberFormatter().format(ether(lastExecution.receipt.gasUsed * lastExecution.gasPrice))} {network.coin} (USD {getFeeUSD(true)})
                                </span>
                            )}
                            {!lastExecution.receipt && !lastExecution.provider && (
                                <span className="text-light pl-2">
                                    {defaultNumberFormatter().format(ether(lastExecution.gas * lastExecution.gasPrice))} {network.coin} (USD {getFeeUSD(false)})
                                </span>
                            )}
                            {lastExecution.provider && <span className="text-light pl-2">N/A</span>}
                        </p>
                    )}
                </div>
            ),
        },
        {
            title: "Events/Logs",
            content: (
                <div className="text-light">
                    {!lastExecution.events && (
                        <p className="text-secondary">
                            Events/Logs:<span className="pl-2 text-light">none</span>
                        </p>
                    )}
                    {lastExecution.events && (
                        <div className="py-1 ReactJson-container">
                            <ReactJson src={lastExecution.events} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: "Inputs",
            content: (
                <div className="text-light">
                    {Object.keys(currentFunction.inputs).length === 0 && (
                        <p className="text-secondary">
                            Parameters <span className="pl-2 text-light">none</span>
                        </p>
                    )}
                    {lastExecution.function.stateMutability === "payable" && (
                        <div>
                            <p className="text-secondary">
                                Value <span className="pl-2 text-light">{lastExecution.input.value}</span>
                            </p>
                        </div>
                    )}
                    {lastExecution.function.parameters && lastExecution.function.parameters.length > 0 && (
                        <div className="py-1 ReactJson-container">
                            {/* <p>{lastExecution.input.parameters}</p> */}
                            <ReactJson src={lastExecution.function.parameters} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                        </div>
                    )}
                </div>
            ),
        },
        // {
        //     title: "Trace",
        //     content: (
        //         <div className="text-light">
        //             {lastExecution.type === "execution" && (
        //                 <p className="flex w-full justify-center items-center p-5">
        //                     <button className="btn-default" onClick={() => {openTrace();}}>View Trace Information</button>
        //                 </p>
        //             )}
        //         </div>
        //     ),
        // },
        {
            title: "Errors",
            content: (
                <div className="text-light">
                    {!lastExecution.error && (
                        <p className="text-secondary">
                            Error:<span className="pl-2 text-light">none</span>
                        </p>
                    )}
                    {lastExecution.error && lastExecution.error.error && typeof lastExecution.error.error === "object" && (
                        <div>
                            <div className="text-secondary">
                                Error:
                                <span className="text-light pl-2">{extractJSON(lastExecution.error.error).errorMessageBefore}</span>
                                <div className="ReactJson-container">
                                    <ReactJson src={extractJSON(lastExecution.error.error).json} iconStyle="triangle" collapsed={false} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                                <span>{extractJSON(lastExecution.error.error).errorMessageAfter}</span>
                            </div>
                            {/* <div className="pt-4">
                                <span className="text-secondary">StackTrace</span>
                                <span className="text-light pl-2">{extractJSON(lastExecution.error.stackTrace).errorMessageBefore}</span>
                                <div className="ReactJson-container">
                                    <ReactJson src={extractJSON(lastExecution.error.stackTrace).json} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />
                                </div>
                                <p className="text-light pl-2">{extractJSON(lastExecution.error.stackTrace).errorMessageAfter}</p>
                            </div> */}
                        </div>
                    )}
                    {lastExecution.error && lastExecution.error.error && typeof lastExecution.error.error != "object" && (
                        <div className="text-secondary">
                            Error:<span className="pl-2 text-light">{lastExecution.error.error}</span>
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: "Output (JSON Data)",
            content: (
                <div>
                    {/* <p className="text-secondary">
                        Output JSON
                        <ContentCopyOutlinedIcon
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(lastExecution, null, 4));
                            }}
                            className="pl-2 cursor-pointer max-h-4 min-h-4"
                        />
                    </p> */}
                    <div className="py-1 ReactJson-container">
                        <ReactJson src={lastExecution} iconStyle="triangle" collapsed={1} displayDataTypes={false} theme={ModalTheme} />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            {!changed && (
                <div>
                    <Tabs tabsData={tabsData} />
                </div>
            )}
        </>
    );
};

export default ResultPayable;
