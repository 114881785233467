import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import ValidationMessage from "@components/shared/ValidationMessage";
import SearchbarDropdown from "@pages/networks/SearchbarDropdown";
import { timeSince } from "@util/util";
import { Web3util } from "@util/web3util";
import { notify, NotifyContainer } from "@util/component";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCubes, faTrash } from "@fortawesome/free-solid-svg-icons";

const RunnerAPIPage = () => {
    const { getAPIKeysList, createNewAPIKey, deleteAPIKey, connectedAccount, dataLoaded } = useContext(AppContext);
    const [keyList, setKeyList] = useState();
    const [keyName, setKeyName] = useState();
    const [userLogged, setUserLogged] = useState("loading");
    // const navigate = useNavigate();

    useEffect(() => {
        getKeysList();
    }, []);

    useEffect(() => {
        if (connectedAccount && !dataLoaded) {
            setUserLogged("loading");
        }
        if (!connectedAccount) {
            setUserLogged(false);
        }
        if (connectedAccount && dataLoaded) {
            setUserLogged(true);
        }
    }, [connectedAccount, dataLoaded]);

    if (userLogged === "loading") {
        return (
            <div className="container">
                <nav className="navbar">
                    <NavBar />
                </nav>
                <aside className="sidebar">
                    <SideMenu />
                </aside>
                <div className="container content-full text-lg text-secondary">
                    <p>Loading Page...</p>
                    <p className="loaderSpinner mt-4"></p>
                    {/* <p className="pt-10">Don't forget to select a network to start</p> */}
                </div>
                <footer className="footer">
                    <Footer />
                </footer>
                <NotifyContainer />
            </div>
        );
    }

    async function getKeysList() {
        const _keyList = await getAPIKeysList();
        console.log("RunnerAPIPage getKeysList keyList", _keyList);
        setKeyList(_keyList);
    }

    async function createNewKey() {
        const _newKey = await createNewAPIKey(keyName);
        setKeyName(null);
        await getKeysList();
    }

    async function deleteKey(key) {
        await deleteAPIKey(key);
        await getKeysList();
    }

    function handleInput(event) {
        if (event.target.name === "keyName") {
            setKeyName(event.target.value);
        }
    }

    console.log("RunnerAPIPage keyList", keyList);

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            {userLogged === true && (
                <main className="content-grid">
                    <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                        <h3 className="font-bold text-lg text-white">Runner API</h3>
                        <div className="flex-row pt-2 items-center">
                            <p className="font-semibold py-2 text-white">Get your API Key</p>
                            <p className="pt-2 pb-1 text-secondary">Current Active keys</p>
                            <p>You can create up to 3 keys</p>
                            <ul className="">
                                {keyList &&
                                    keyList.map((key, index) => (
                                        <li key={key.key} className="py-2">
                                            <div className="flex flex-row items-center">
                                                <p>
                                                    <button
                                                        id="RunnerAPIPageDeleteKeyButton"
                                                        name="RunnerAPIPageDeleteKeyButton"
                                                        className="btn-default"
                                                        onClick={() => {
                                                            deleteKey(key.key);
                                                        }}>
                                                        <FontAwesomeIcon
                                                            id="RunnerAPIPageDeleteKeyButtonIcon"
                                                            name="RunnerAPIPageDeleteKeyButtonIcon"
                                                            icon={faTrash}
                                                            className="text-secondary cursor-pointer max-h-5 min-h-5"
                                                        />
                                                    </button>
                                                </p>
                                                <div className="pl-2">
                                                    <p>
                                                        Name: <span className="text-secondary">{key.name}</span>
                                                    </p>
                                                    <p className="">
                                                        Key: <span className="text-secondary">{key.key}</span>
                                                    </p>
                                                    <p className="">
                                                        Created: <span className="text-secondary">{timeSince(Date.parse(key.created))}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                            <button
                                id="RunnerAPIPageRefreshKeyListButton"
                                name="RunnerAPIPageRefreshKeyListButton"
                                className="btn-default mt-4"
                                onClick={() => {
                                    getKeysList();
                                }}>
                                Refresh Key List
                                <FontAwesomeIcon id="RunnerAPIPageRefreshKeyListButtonIcon" name="RunnerAPIPageRefreshKeyListButtonIcon" icon={faCode} className="pl-2" />
                            </button>
                            <p className="pt-10 pb-1 text-secondary">Request a new key</p>
                            <p>Key Name</p>
                            <input type="text" name="keyName" placeholder="Key Name" maxLength={30} autoComplete="new-password" value={keyName || ""} onChange={handleInput} />
                            <button
                                id="RunnerAPIPageCreateNewKeyButton"
                                name="RunnerAPIPageCreateNewKeyButton"
                                className="btn-default mt-10"
                                onClick={() => {
                                    createNewKey();
                                }}>
                                Create New Key
                                <FontAwesomeIcon id="RunnerAPIPageCreateNewKeyButtonIcon" name="RunnerAPIPageCreateNewKeyButtonIcon" icon={faCode} className="pl-2" />
                            </button>
                        </div>
                    </div>
                </main>
            )}
            {userLogged === false && (
                <main className="content-full px-4">
                    <p className="text-lg text-secondary">You need to be authenticated to use this page.</p>
                    <p className="text-lg text-light pt-2">Click on the top right button to go to the Login page.</p>
                </main>
            )}
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default RunnerAPIPage;
