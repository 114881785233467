import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import { callAfterDelay, cancelCallAfterDelay } from "@util/util";
import { notify } from "@util/component";
import SideMenu from "@components/sidemenu/SideMenu";
import { NotifyContainer } from "@util/component";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LeakAddOutlinedIcon from "@mui/icons-material/LeakAddOutlined";
import Editor from "react-simple-code-editor";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css"; // green and yellow (match the style) !!!!

const Import = () => {
    const { syncUserData, getUserData, connectedAccount } = useContext(AppContext);
    const [contractsJson, setContractsJson] = useState(null);
    const [walletsJson, setWalletsJson] = useState(null);
    // const [timeoutIds, setTimeoutIds] = useState(null);
    const [contractValidationMessage, setContractValidationMessage] = useState();
    const [walletValidationMessage, setWalletValidationMessage] = useState();

    useEffect(() => {
        getUserInfo();
    }, [connectedAccount]);

    async function getUserInfo() {
        const _contractsData = await getUserData("contracts");
        const _contractsJson = JSON.stringify(_contractsData, null, 4);
        console.log("Import contracts", _contractsJson);
        setContractsJson(_contractsJson);

        const walletsData = await getUserData("wallets");
        const _walletsJson = JSON.stringify(walletsData, null, 4);
        console.log("Import wallets", _walletsJson);
        setWalletsJson(_walletsJson);
    }

    function syncData(type) {
        try {
            if (type === "contracts") {
                syncUserData(type, JSON.parse(contractsJson));
            } else if (type === "wallets") {
                syncUserData(type, JSON.parse(walletsJson));
            }
        } catch (e) {
            notify("Error synchronizing data. Check the JSON and try again.", "error");
        }
    }

    function formatJSON(type) {
        try {
            if (type === "contracts") {
                const _formattedJson = JSON.parse(contractsJson);
                setContractsJson(JSON.stringify(_formattedJson, null, 4));
            } else if (type === "wallets") {
                const _formattedJson = JSON.parse(walletsJson);
                setWalletsJson(JSON.stringify(_formattedJson, null, 4));
            }
            notify(`JSON is valid for ${type}`, "info");
            validateJSON(type);
        } catch (e) {
            notify(`Invalid JSON for ${type}. Check for any commas at the end of list and try to ident the code.`, "warn");
            console.log("Invalid JSON", type);
        }
    }

    // nice try! STATES SUCKS !
    function codeChangeManager(type, code) {
        if (type === "contracts") setContractsJson(code);
        if (type === "wallets") setWalletsJson(code);
    }

    function validateJSON(type) {
        let _validation = [];
        if (type === "contracts") {
            const _formattedJson = JSON.parse(contractsJson);
            _formattedJson.forEach((contract, index) => {
                if (!contract.hasOwnProperty("name")) _validation.push({ prop: "name", index: index });
                if (!contract.hasOwnProperty("implementation")) _validation.push({ prop: "implementation", index: index });
                if (!contract.hasOwnProperty("proxy")) _validation.push({ prop: "proxy", index: index });
            });
            if (_validation.length > 0) {
                const _validationMessage = "JSON Validation Failed. The folowing contracts and props have issues: \n";
                let _validationMessageDetail = "";
                _validation.forEach((issue) => {
                    _validationMessageDetail += `Contract position ${issue.index + 1} prop ${issue.prop} not found \n`;
                });
                console.log("_validationMessage", _validationMessage);
                console.log("_validationMessageDetail", _validationMessageDetail);
                setContractValidationMessage(_validationMessage + _validationMessageDetail);
            } else {
                setContractValidationMessage();
            }
        } else if (type === "wallets") {
            const _formattedJson = JSON.parse(walletsJson);
            _formattedJson.forEach((wallet, index) => {
                if (!wallet.hasOwnProperty("name")) _validation.push({ prop: "name", index: index });
                if (!wallet.hasOwnProperty("address")) _validation.push({ prop: "address", index: index });
                if (!wallet.hasOwnProperty("pk")) _validation.push({ prop: "pk", index: index });
            });
            if (_validation.length > 0) {
                const _validationMessage = "JSON Validation Failed. The folowing wallets and props have issues: \n";
                let _validationMessageDetail = "";
                _validation.forEach((issue) => {
                    _validationMessageDetail += `Wallet position ${issue.index + 1} prop ${issue.prop} not found \n`;
                });
                console.log("_validationMessage", _validationMessage);
                console.log("_validationMessageDetail", _validationMessageDetail);
                setWalletValidationMessage(_validationMessage + _validationMessageDetail);
            } else {
                setWalletValidationMessage();
            }
        }
    }

    const contractExample = {
        name: "Token BacaDex - Testnet",
        implementation: "0x0000000000000000000000000000000000000000",
        proxy: "0x0000000000000000000000000000000000000000",
        networkId: 80001,
    };
    const walletExample = {
        name: "Test Wallet BacaDex",
        address: "0x0000000000000000000000000000000000000000",
        pk: "0000000000000000000000000000000000000000000000000000000000000000",
    };

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="2xl:col-span-6 col-span-12 p-2">
                    {/* <div className="w-full px-2 2xl:col-span-6 col-span-12"> */}
                    <p className="text-white font-semibold text-xl">Contracts</p>
                    {contractsJson && (
                        <div className="overflow-y-auto h-[650px] bg-black">
                            <Editor
                                value={contractsJson}
                                onValueChange={(code) => codeChangeManager("contracts", code)}
                                highlight={(json) => hljs.highlight(json, { language: "JSON" }).value}
                                padding={10}
                                tabSize={4}
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 14,
                                }}
                                // readOnly // READ ONLY!
                                className="text-light bg-black"
                            />
                        </div>
                    )}
                    <div className="py-2">
                        <pre className="text-red break-words whitespace-pre-wrap">{contractValidationMessage}</pre>
                    </div>
                    <div>
                        <p>Contract example</p>
                        <pre>{`${JSON.stringify(contractExample, null, 4)}`}</pre>
                    </div>
                    <div className="py-2">
                        <button
                            className="btn-default"
                            onClick={() => {
                                formatJSON("contracts");
                            }}>
                            Format and Validate JSON
                            <CheckCircleOutlineOutlinedIcon className="pl-2" />
                        </button>
                        <button
                            className="btn-default ml-4"
                            onClick={() => {
                                syncData("contracts");
                            }}>
                            Sync Data
                            <LeakAddOutlinedIcon className="pl-2" />
                        </button>
                    </div>
                </div>
                <div className="2xl:col-span-6 col-span-12 p-2">
                    {/* <div className="w-full px-2 2xl:col-span-6 col-span-12"> */}
                    <p className="text-white font-semibold text-xl">Wallets</p>
                    {walletsJson && (
                        <div className="overflow-y-auto h-[650px] bg-black">
                            <Editor
                                value={walletsJson}
                                onValueChange={(code) => codeChangeManager("wallets", code)}
                                highlight={(json) => hljs.highlight(json, { language: "JSON" }).value}
                                padding={10}
                                tabSize={4}
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 14,
                                }}
                                className="text-light bg-black"
                            />
                        </div>
                    )}
                    <div className="py-2">
                        <pre className="text-red break-words whitespace-pre-wrap">{walletValidationMessage}</pre>
                    </div>
                    <div>
                        <p>Wallet example</p>
                        <pre>{`${JSON.stringify(walletExample, null, 4)}`}</pre>
                    </div>
                    <div className="py-2">
                        <button
                            className="btn-default"
                            onClick={() => {
                                formatJSON("wallets");
                            }}>
                            Format and Validate JSON
                            <CheckCircleOutlineOutlinedIcon className="pl-2" />
                        </button>
                        <button
                            className="btn-default ml-4"
                            onClick={() => {
                                syncData("wallets");
                            }}>
                            Sync Data
                            <LeakAddOutlinedIcon className="pl-2" />
                        </button>
                    </div>
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default Import;
