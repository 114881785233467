import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Service } from "@api/service";
import { notify } from "@util/component";
import { saveDataStorage, getDataStorage, newUUID, sortArrayByDate, addNewItemArray, defaultNumberFormatter, ether, isEmpty, validateEmail } from "@util/util";
import { LocalStorage, FunctionGasType, ValidationStepOperator } from "@util/enum";
import { Web3util } from "@util/web3util";
// import solc from "solc";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
    const [connectedAccount, setConnectedAccount] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [syncing, setSyncing] = useState(null);
    const [currentHistoryItem, setCurrentHistoryItem] = useState({});
    const [executionHistory, setExecutionHistory] = useState([]);
    const [executionQueue, setExecutionQueue] = useState([]);
    const [executionQueueResults, setExecutionQueueResults] = useState([]);
    const [executionQueueDetail, setExecutionQueueDetail] = useState(null);
    const [queueList, setQueueList] = useState([]);
    const [currentNetworkInput, setCurrentNetworkInput] = useState("");
    const [currentContractInput, setCurrentContractInput] = useState("");
    const [currentWalletInput, setCurrentWalletInput] = useState("");
    const [currentFunctionInput, setCurrentFunctionInput] = useState("");
    const [currentQueue, setCurrentQueue] = useState();
    const [networkOptions, setNetworkOptions] = useState([]);
    const [networkSettingsOptions, setNetworkSettingsOptions] = useState([]);
    const [currentNetworkSettings, setCurrentNetworkSettings] = useState();
    const [contractOptions, setContractOptions] = useState([]);
    const [walletOptions, setWalletOptions] = useState([]);
    const [functionOptions, setFunctionOptions] = useState([]);
    const [overrideNonceSetting, setOverrideNonceSetting] = useState(false);
    const [nonceSetting, setNonceSetting] = useState(null);
    const [gasSetting, setGasSetting] = useState(null);
    const [gasPriceSetting, setGasPriceSetting] = useState(null);

    useEffect(() => {
        console.debug("(Context) Start loading user data");
        checkConnectedUser();
    }, []);

    async function checkConnectedUser() {
        const user = await getConnectedAccount();
        if (!user) {
            setConnectedAccount(null);
            return;
        }
        console.debug("Found a valid token in the cookies", user);
        setConnectedAccount(user);
        loadUserData(user);
    }

    async function userLogout() {
        console.debug("User authentications was removed");
        Service.setAuthCookie("t", null);
        setContracts(null);
        setWallets(null);
        setExecutionHistory(null);
        setQueueList(null);
        setCurrentNetwork(null);
        setConnectedAccount(null);
        setDataLoaded(false);
    }

    async function loadUserData(user) {
        setSyncing(true);
        try {
            const _contracts = await getUserData("contracts", user);
            setContracts(_contracts);
            console.debug("API Data Sync - contracts - ok", _contracts.length);
            //const _wallets = await getUserData("wallets", user);
            //setWallets(_wallets);
            //console.debug("API Data Sync - wallets - ok");
            const _queueList = await getUserData("queuelist", user);
            setQueueList(_queueList);
            console.debug("API Data Sync - queuelist - ok");
            const _executionhistory = await getUserData("executionhistory", user);
            setExecutionHistory(_executionhistory);
            console.debug("API Data Sync - executionhistory - ok");
            setDataLoaded(true);
            setSyncing(false);
        } catch (error) {
            console.warn("AppContext - Data Load Error", error);
            setSyncing(false);
            setDataLoaded(false);
        }
    }

    async function getUserData(type_, user = null) {
        try {
            if (!connectedAccount && !user) return;
            const data = await Service.getUserData(type_);
            if (data && data.data && data.data.data) return data.data.data;
        } catch (error) {
            console.error(error);
        }
    }

    async function getConnectedAccount() {
        // return Cookies.get("key");
        const _user = await Service.verifyToken();
        if (_user) return _user.id;
        return null;
    }

    async function setUserConnectedAccount(email) {
        setConnectedAccount(email);
    }

    function syncUserData(type_, data_) {
        console.log("AppContext - syncUserData", type_, data_, connectedAccount);
        if (!connectedAccount) {
            console.warn("Not possible to sync the data. Connected Account is null.");
            return;
        }
        Service.syncUserData(type_, data_);
    }

    async function cleanUserData(type) {
        Service.cleanUserData(await getConnectedAccount(), type);
    }

    async function cleanAllUserData() {
        await cleanUserData("networkrpcactive");
        await cleanUserData("networkgasprices");
        await cleanUserData("networkrpcs");
        await cleanUserData("wallets");
        await cleanUserData("queuelist");
        await cleanUserData("executionhistory");
        await cleanUserData("contracts");
        setContracts(null);
        setWallets(null);
        setExecutionHistory(null);
        setQueueList(null);
    }

    function clearExecuteForm(field) {
        if (field === "network") {
            setCurrentContract();
            setCurrentWallet();
            setCurrentFunction();
            setCurrentContractInput("");
            setCurrentWalletInput("");
            setCurrentFunctionInput("");
            setNetworkOptions(networks);
            setContractOptions(contractNames);
            setWalletOptions(walletNames);
            setFunctionOptions([]);
        } else if (field === "contract") {
            setCurrentWallet();
            setCurrentFunction();
            setCurrentWalletInput("");
            setCurrentFunctionInput("");
            setContractOptions(contractNames);
            setWalletOptions(walletNames);
            setFunctionOptions([]);
        } else if (field === "wallet") {
            // setCurrentFunction({});
            // setCurrentFunctionInput("");
            setWalletOptions(walletNames);
            // setFunctionOptions([]);
        } else if (field === "function") {
            setFunctionOptions([]);
        }
    }

    // Onboarding
    // const [onboardingStep, setOnboardingStep] = useState(0);
    // useEffect(() => {
    //     setOnboardingStep(getCurrentOnboardingStep());
    // }, [dataLoaded, connectedAccount, currentNetwork, contracts, wallets]);
    // function getCurrentOnboardingStep() {
    //     let step = 0;
    //     if (!connectedAccount) step = 1;
    //     if (connectedAccount && !currentNetwork) step = 2;
    //     if (connectedAccount && currentNetwork && (!contracts || contracts.length === 0)) step = 3;
    //     if (connectedAccount && currentNetwork && contracts && contracts.length > 0 && (!wallets || wallets.length === 0)) step = 4;
    //     if (connectedAccount && currentNetwork && contracts && contracts.length > 0 && wallets && wallets.length > 0) step = 5;
    //     console.log("Onboarding current step:", step);
    //     // console.log("Onboarding data - connectedAccount", connectedAccount);
    //     // console.log("Onboarding data - currentNetwork", currentNetwork);
    //     // console.log("Onboarding data - contracts", contracts);
    //     // console.log("Onboarding data - wallets", wallets);
    //     return step;
    // }

    async function updateOnboardingStatus() {
        const _result = await Service.onboardingUpdate();
        // console.log("updateOnboardingStatus result", _result);
        // if (_result && _result.data); // OK!
        return _result;
    }

    async function getOnboardingStatus() {
        if (connectedAccount) {
            const _result = await Service.onboardingStatus(connectedAccount);
            // console.log("getOnboardingStatus result", _result);
            // if (_result && _result.data); // OK!
            return _result;
        } else return null;
    }

    // changed to be a array of objects, but check later for what is better to be used
    const [currentNetwork, _setCurrentNetwork] = useState();
    const [networkNames, setNetworkNames] = useState([]);
    const [networks, setNetworks] = useState(null);

    useEffect(() => {
        getNetworks();
    }, []);

    async function getNetworks() {
        // TODO: Review later due to perfomance issues (save into the localstorage? how to control updates?)
        // const _networks = getDataStorage(LocalStorage.NetworkList);
        // if (_networks) setNetworks(_networks);
        const _networks = await Service.getAllNetworks(null, true);
        // const _networks = await Service.getAllNetworks(null, false);
        if (_networks && _networks.data) setNetworks(_networks.data);
        // else notify("API issue getting the networks information", "warn");
    }

    async function getAllNetworks() {
        const _networks = await Service.getAllNetworks(null, true);
        if (_networks && _networks.data) return _networks.data;
    }

    async function getNetworkRPCs(networkId) {
        const _networkRPCs = await Service.getUserRPCs(networkId);
        if (_networkRPCs && _networkRPCs.data) return _networkRPCs.data;
    }

    async function setCurrentNetwork(network) {
        try {
            if (!network) {
                _setCurrentNetwork(null);
                return;
            }
            let _networkUserRPC;
            if (connectedAccount) _networkUserRPC = await getActiveRPCUserSettings(network.id);
            if (_networkUserRPC) {
                network.RPC = _networkUserRPC.RPCURL;
            } else network.RPC = network.RPCs[0].URL;
            if (!network.RPC) notify("Not possible to set a RPC, please go to the settings screen and set it manually", "warn");
            _setCurrentNetwork(network);
        } catch (error) {
            console.error(error);
        }
    }

    // useEffect(() => {
    //     // console.log("AppProvider - useEffect[] - loadNetworkNames");
    //     loadNetworkNames();
    // }, [networks]);

    // useEffect(() => {
    //     setExecuting(false);
    // }, [currentNetwork]);

    // function loadNetworkNames() {
    //     if (!networks) return;
    //     const _networks = [];
    //     networks.forEach((network) => {
    //         _networks.push(network.name);
    //     });
    //     setNetworkNames(_networks);
    // }

    function getNetwork(name) {
        if (!networks) return null;
        if (name) return networks.find((network) => network.name.toLowerCase() === name.toLowerCase());
        return null;
    }

    function getNetworkByID(id) {
        if (!networks) return null;
        if (id) {
            const _networksFound = networks.find((network) => {
                if (network.id == id) return network;
            });
            return _networksFound;
        }
        return null;
    }

    async function getNetworkRPCByID(id) {
        try {
            const _networks = await Service.getAllNetworks(id, true);
            if (_networks && _networks.data) return _networks.data[0].RPCs[0].URL;
            else return null;
        } catch (error) {
            return null;
        }
    }

    const [currentContract, setCurrentContract] = useState();
    const [contractNames, setContractNames] = useState([]);
    const [contracts, setContracts] = useState([
        // {
        //     name: "Q4X (BSC Testnet) - invest FOREX",
        //     implementation: "0x9Fa0613426Ddf4226493cB3F8e683B2e8987B2cA",
        //     proxy: "0x5B0fe7eF0500330f54fB1F60f48e20a4Ac171D38",
        // },
        // {
        //     name: "PSTN BSC Mainnet",
        //     implementation: "0xf2168B37cc4F66b8B3e73e5BE67eC29be4205FDc",
        //     proxy: "0xbd6e5D331A09fb39D28CB76510ae9b7d7781aE68",
        // },
        // {
        //     name: "Baca Dex",
        //     implementation: "0x5B0fe7eF0500330f54fB1F60f48e20a4Ac171aaa",
        //     proxy: "0x9Fa0613426Ddf4226493cB3F8e683B2e8987Bbbb",
        // },
        // {
        //     name: "Juji Polygon Mainnet",
        //     implementation: "0x4c5c6568fFB886dF7dbA4e3ABD856d902B1909AE",
        //     proxy: "",
        // },
    ]);

    // useEffect(() => {
    //     const _contracts = getDataStorage(LocalStorage.ContractList);
    //     if (_contracts) setContracts(_contracts);
    // }, []);

    // useEffect(() => {
    //     // console.log("AppProvider - useEffect[] - loadNetworkNames");
    //     loadContractNames();
    // }, [contracts]);

    useEffect(() => {
        // console.log("CurrentContract was changed - calling loadContractSourceCode");
        loadContractSourceCode();
    }, [currentContract]);

    // function loadContractNames() {
    //     if (!contracts) return;
    //     const _contracts = [];
    //     contracts.forEach((contract) => {
    //         _contracts.push(contract.name);
    //     });
    //     setContractNames(_contracts);
    // }
    // function getContract(name) {
    //     if (name) return contracts.find((contract) => contract.name.toLowerCase() === name.toLowerCase());
    //     return null;
    // }

    const [currentWallet, setCurrentWallet] = useState();
    const [walletNames, setWalletNames] = useState([]);
    const [wallets, setWallets] = useState([
        // {
        //     name: "Test User Q4X",
        //     address: "0x0C9d518757b4a5971F398bD7dd032F3E762Ea5dF",
        //     pk: "6befc44f6a16df1183a41731921d8b4243b5754298a9626550772e0d9541863f",
        // },
        // {
        //     name: "Wallet Owner DEX/Q4X",
        //     address: "0x1ECBDC64505fe4095eCE347984cc015D10d23361",
        //     pk: "02ee9afe48feb356a0981a92b452ea4983c4a2f99cceba96a07ca605c631b351",
        // },
    ]);
    const [currentWalletBalance, setCurrentWalletBalance] = useState();

    // useEffect(() => {
    // const _wallets = getDataStorage(LocalStorage.WalletList);
    // if (_wallets) setWallets(_wallets);
    // }, []);

    useEffect(() => {
        if (currentNetwork) getWallets();
    }, [currentNetwork]);

    async function getWallets(setState = true) {
        try {
            if (connectedAccount) {
                const _wallets = await getUserData("wallets", connectedAccount);
                if (setState) setWallets(_wallets);
                if (!_wallets) {
                    notify("No wallets found. Refresh clicking the icon or create a new wallet to execute functions.", "info");
                    setWallets([]);
                }
                return _wallets;
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // console.log("AppProvider - useEffect[] - loadNetworkNames");
        loadWalletNames();
    }, [wallets]);

    // Fill the nonce once we have the wallet
    useEffect(() => {
        getNonce();
        getWalletBalance();
    }, [currentWallet, currentNetwork]);

    async function getWalletBalance(wallet, networkId) {
        if (currentWallet && !wallet) {
            const balance = defaultNumberFormatter().format(ether(await getWalletCoinBalance(currentWallet.address)));
            setCurrentWalletBalance(balance);
        } else if (wallet) {
            const _balance = await getWalletCoinBalance(wallet.address, networkId);
            const balance = defaultNumberFormatter().format(ether(_balance));
            return balance;
        }
    }

    async function getNonce() {
        if (currentNetwork && currentWallet) {
            // console.log("getNonce", currentNetwork, currentWallet);
            const web3 = new Web3(currentNetwork.RPC);
            let _actualNonce = await web3.eth.getTransactionCount(currentWallet.address);
            setNonceSetting(_actualNonce++);
        }
    }

    function loadWalletNames() {
        if (!wallets) return;
        const _wallets = [];
        wallets.forEach((wallet) => {
            _wallets.push(wallet.name);
        });
        setWalletNames(_wallets);
    }
    function getWallet(name) {
        if (name) return wallets.find((item) => item.name.toLowerCase() === name.toLowerCase());
        return null;
    }

    async function getWalletCoinBalance(walletAddress, networkId) {
        if (!currentNetwork && !networkId) return;

        let web3;
        if (currentNetwork) web3 = new Web3(currentNetwork.RPC);
        else if (networkId) {
            const networkInfo = getNetworkByID(networkId);
            web3 = new Web3(networkInfo.RPCs[0].URL);
        }
        try {
            const balance = await web3.eth.getBalance(walletAddress);
            return balance;
        } catch (error) {
            console.error("getWalletCoinBalance - Error retrieving balance:", error);
            throw error;
        }
    }

    const [functions, setFunctions] = useState([]);
    const [parameters, setParameters] = useState();
    const [parametersValue, setParametersValue] = useState();
    const [currentFunction, setCurrentFunction] = useState();
    const [currentABI, setCurrentABI] = useState();

    useEffect(() => {
        if (currentContract && currentNetwork) getFunctions();
    }, [currentContract]);

    async function getFunctions(networkId, contract) {
        console.log("AppContext getFunctions", networkId, contract);
        try {
            if (!currentContract && !networkId && !contract) return false;
            if (!networkId && !contract && currentContract && (!currentContract.implementation || !currentNetwork.id)) return false;
            // if (!currentContract.implementation || !currentNetwork.id && (!networkId && !contract)) {
            //     return false;
            // }
            let response;
            if (networkId && contract) {
                response = await Service.getFunctions(networkId, contract);
            } else {
                response = await Service.getFunctions(currentNetwork.id, currentContract.implementation);
            }
            console.log("AppContext response", response);
            if (response && response.data) {
                const abi = response.data;
                const functions = response.data.filter((item) => item.type === "function");
                setCurrentABI(abi);
                setFunctions(functions);
                return [abi, functions];
            } else {
                notify("Contract source code not verified. Function list is not available.", "warn");
                setFunctions([]);
            }
        } catch (error) {
            console.log(error);
        }
    }
    function getFunction(function_) {
        // console.log("AppContext - getFunction", function_);
        if (function_) return functions.find((item) => item.name.toLowerCase() === function_.name.toLowerCase());
        return null;
    }
    // - CALL DEACTIVATED - After a second execution the values of these functions are outdated. Improve later
    async function getFunctionsWithValues() {
        // console.log("getFunctionsWithValues functions", functions);
        let _functions = [...functions];
        if (_functions) {
            for (let index = 0; index < _functions.length; index++) {
                const _function = _functions[index];
                if (getFunctionGasType(_function.stateMutability) === FunctionGasType.NoGasFee) {
                    if (_function.inputs.length === 0) {
                        // - execute the function and get the result;
                        try {
                            // Create Execution Info Object
                            const _executionInfo = createExecutionInfo(
                                "execution",
                                currentNetwork,
                                currentContract,
                                currentABI,
                                currentWallet,
                                _function,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null
                            );
                            const _result = await executeFunctionView(_executionInfo, false, false, false);
                            _functions[index].result = _result;
                        } catch (error) {
                            // console.warn("Appcontext - getFunctionsWithValues - functions", error);
                        }
                    }
                }
            }
            // console.log("Appcontext - getFunctionsWithValues - functions", _functions);
            return _functions;
        }

        return;
    }
    async function refreshFunctionsABI(networkId, contractImplementation) {
        try {
            if (!networkId || !contractImplementation) {
                //notify("Contract source cannot be retrieved.", "warn");
                //console.log("getFunctions - implementation or networkid not available", currentContract, currentNetwork);
                return false;
            }
            const response = await Service.getFunctions(networkId, contractImplementation);
            if (response && response.data) {
                setCurrentABI(response.data);
                //setFunctions(response.data.filter((item) => item.type === "function"));
                //} else {
                //notify("Contract source code not verified. Function list is not available.", "warn");
                //setFunctions([]);

                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    // function addQueueListItem(queueListItem) {
    //     const _actualQueueList = queueList;
    //     _actualQueueList.push(queueListItem);
    //     // {
    //     //     id: newUUID(),
    //     //     name: queueListName,
    //     //     networkId: currentNetwork.id,
    //     //     queue: null,
    //     // });
    //     setQueueList([..._actualQueueList]);
    //     console.log("_actualQueueList", _actualQueueList);
    // }

    // function removeQueueListItem(queueListItem) {
    //     const _queueList = queueList;
    //     console.log("_queueList before", _queueList);
    //     const result = _queueList.findIndex((item) => item.id === queueListItem.id);
    //     console.log("item to be removed", result);
    //     _queueList.splice(result, 1);
    //     console.log("_queueList after", _queueList);
    //     setQueueList([..._queueList]);
    // }

    const [lastExecution, setLastExecution] = useState();
    const [executing, setExecuting] = useState(false);

    // Only works well for execute screen
    async function executeFunction(simulation = false) {
        setExecuting(true);
        setLastExecution(null);

        try {
            let _parameters = null;
            if (!isEmpty(parameters)) _parameters = parameters;

            // Validate the user selections
            let _validation = true;
            _validation = validateExecution(simulation, _parameters);
            if (!_validation) {
                setExecuting(false);
                return null;
            }
            // Create Execution Info Object
            const _executionInfo = createExecutionInfo(
                simulation ? "simulation" : "execution",
                currentNetwork,
                currentContract,
                currentABI,
                currentWallet,
                currentFunction,
                parametersValue,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            );

            // Check the function type (view, pure, nonpayable, payable) and call it!
            let _result;
            if (getFunctionGasType(currentFunction.stateMutability) === FunctionGasType.NoGasFee) {
                _result = await executeFunctionView(_executionInfo);
                setExecuting(false);
            } else {
                _result = await executeFunctionNonPayable(_executionInfo, true, false, simulation);
                setExecuting(false);
            }
        } catch (err) {
            setExecuting(false);
            console.warn("Error executing AppContext - executeFunction");
            console.error(err);
        }
    }

    const getFunctionGasType = (stateMutability_) => {
        if (stateMutability_ === "view" || stateMutability_ === "pure") return FunctionGasType.NoGasFee;
        else return FunctionGasType.PayGasFee;
    };

    const getContractInstance = (abi, networkRPC, contractProxy) => {
        //console.log("getContractInstance - currentABI", abi);
        const web3 = new Web3(networkRPC);
        web3.eth.handleRevert = true; // Bug in web3js the response - not working
        //const contract = new web3.eth.Contract(abi, contractProxy, { handleRevert: true }); // Bug in web3js the response - not working
        const contract = new web3.eth.Contract(abi, contractProxy); // Bug in web3js the response - not working
        return contract;
    };

    const executeFunctionView = async (executionInfo, addExecutionHistory = true, addExecutionQueueResults = false, showResults = true) => {
        try {
            // We don't need a wallet for call these type of function since they don't change data in the blockchain
            const contract = getContractInstance(executionInfo.abi, executionInfo.network.RPC, executionInfo.contract.proxy);
            contract.options.address = executionInfo.contract.proxy ?? executionInfo.contract.implementation;
            console.debug("AppContext - executeFunctionView - contract", contract);
            const { parametersValues, parameters } = await reorganizeParameters(executionInfo);
            // console.log("executeFunctionView reorganizeParameters parameters", parameters, parametersValues);
            executionInfo.parameters = parameters;
            // TODO: FORCE ERROR TO TEST
            // parametersValues = [{ x: 1 }];
            const _result = await contract.methods[executionInfo.function.name](...parametersValues).call({}); //, (error, result) => {
            console.debug("AppContext - executeFunctionView - Function", executionInfo.function.name, "called. Params:", parametersValues, "Result:", _result);
            executionInfo.result = _result;
            if (showResults) {
                createExecutionRegistry(executionInfo, addExecutionHistory, !addExecutionQueueResults);
                if (addExecutionQueueResults) addToExecutionQueueResults(executionInfo);
            }

            return _result;
        } catch (error) {
            console.error(error);
            // TODO: UGLY! Create a helper function to format errors
            // executionInfo.error = { error: error.message, stackTrace: error.stack ?? null };
            executionInfo.error = { error: error.message };
            createExecutionRegistry(executionInfo, addExecutionHistory, !addExecutionQueueResults);
            if (addExecutionQueueResults) addToExecutionQueueResults(executionInfo);
        }
    };

    const executeFunctionNonPayable = async (executionInfo, addExecutionHistory = true, addExecutionQueueResults = false, simulation = false, useWalletProvider = false, refreshABI_ = true) => {
        console.log("executeFunctionNonPayable executionInfo", executionInfo, addExecutionHistory, addExecutionQueueResults, simulation, useWalletProvider);
        return new Promise(async (resolve, reject) => {
            try {
                // Functions nonpayable or payable (requires gas to call / writes in the blockchain)
                let web3;
                if (!useWalletProvider) web3 = new Web3(executionInfo.network.RPC);
                else {
                    const { ethereum } = window;
                    if (ethereum && ethereum.isMetaMask) {
                        web3 = new Web3(ethereum);
                    } else {
                        notify("You need install MetaMask to execute this function using it.", "warn");
                        reject("You need install MetaMask to execute this function using it.");
                        return null;
                    }
                }
                // console.log("executeFunctionNonPayable abi:", executionInfo.abi);
                // There was an issue with parameters and some changes were made. Maybe this it not necessary but the error "Invalid JSON RPC Reponse" is not showing anymore
                let _refreshedABI;
                if (refreshABI_) {
                    try {
                        _refreshedABI = await refreshFunctionsABI(executionInfo.network.id, executionInfo.contract.implementation);
                    } catch (error) {
                        notify("There is a problem getting the contract ABI. Please try again.", "warn");
                        reject("There is a problem getting the contract ABI. Please try again.");
                        return null;
                    }
                } else {
                    _refreshedABI = executionInfo.abi;
                }
                // console.log("executeFunctionNonPayable _refreshedABI:", _refreshedABI);
                let contract;
                if (!useWalletProvider) {
                    contract = getContractInstance(_refreshedABI, executionInfo.network.RPC, executionInfo.contract.proxy);
                } else {
                    contract = new web3.eth.Contract(_refreshedABI, executionInfo.contract.implementation); // TODO PROXY
                }
                // console.log("executeFunctionNonPayable RPC:", executionInfo.network.RPC);
                contract.options.address = executionInfo.contract.proxy ?? executionInfo.contract.implementation;

                // Match the inputs in the screen with the function expected parameter order
                let parametersValues;
                let parameters;
                console.log("executeFunctionNonPayable", executionInfo);
                try {
                    ({ parametersValues, parameters } = await reorganizeParameters(executionInfo));
                } catch (error) {
                    notify("There is a problem formatting parameters. Review the values and try again.", "warn");
                    reject("There is a problem formatting parameters. Review the values and try again.");
                    return null;
                }
                console.log("executeFunctionNonPayable checking paramaters after reorganization parametersValues", parametersValues, "parameters", parameters);
                // let _parameters = await reorganizeParameters(executionInfo);
                // Gas
                let gas = gasSetting || 8000000;
                // ! Getting error when estimating gas. Running out of gas if you use the estimation...
                // try {
                //     gas = await contract.methods[executionInfo.function.name](...parametersValues).estimateGas({ from: executionInfo.wallet.address });
                // } catch (e) {}

                // Simulate the transaction
                if (simulation) {
                    try {
                        executionInfo.receipt = {};
                        const coinPrice = null; //await getCoinPrice(executionInfo.network.coin);
                        const _rawTransaction = {
                            //from: executionInfo.wallet.address,
                            to: executionInfo.contract.proxy,
                            ...(overrideNonceSetting && nonceSetting && { nonce: nonceSetting }),
                            gas: gas,
                            //gasPrice: _gasPrice, // Do we need this?
                        };
                        const _result = await contract.methods[executionInfo.function.name](...parametersValues).call(_rawTransaction);
                        console.log("Simulation Result", _result);
                        executionInfo.receipt.status = true;
                        const _executionInfo = createExecutionInfo(
                            "simulation",
                            executionInfo.network,
                            executionInfo.contract,
                            executionInfo.abi,
                            executionInfo.wallet,
                            executionInfo.function,
                            parametersValue,
                            gas,
                            null,
                            coinPrice,
                            null,
                            _rawTransaction,
                            null,
                            executionInfo.receipt,
                            _result,
                            null,
                            null,
                            null
                        );
                        createExecutionRegistry(_executionInfo, false, true);
                        resolve(_result);
                        return _result;
                    } catch (error) {
                        executionInfo.receipt.status = false; // Failed transaction
                        const _executionInfo = createExecutionInfo(
                            "simulation",
                            executionInfo.network,
                            executionInfo.contract,
                            executionInfo.abi,
                            executionInfo.wallet,
                            executionInfo.function,
                            executionInfo.parameters,
                            gas,
                            null,
                            null,
                            null,
                            null,
                            null,
                            executionInfo.receipt,
                            null,
                            null,
                            error && error.reason ? error : null,
                            error
                        );
                        createExecutionRegistry(_executionInfo, false, true);
                        console.error("Simulation Error", error);
                        reject(error);
                        return error;
                    }
                }

                // Verify Wallet
                if ((!executionInfo.wallet || !executionInfo.wallet.pk) && !useWalletProvider) {
                    notify("There is no wallet to execute this " + executionInfo.function.stateMutability + " function", "warn");
                    reject("There is no wallet to execute this " + executionInfo.function.stateMutability + " function");
                    return null;
                }
                // Encode the function ABI
                // console.log("executeFunctionNonPayable - parametersValues", parametersValues);
                let data;
                if (!useWalletProvider) {
                    try {
                        data = await contract.methods[executionInfo.function.name](...parametersValues).encodeABI();
                    } catch (error) {
                        notify("Error assigning values to parameters. Please verify the values and data types allowed for each parameter.", "warn");
                        reject("Error assigning values to parameters. Please verify the values and data types allowed for each parameter.");
                        return null;
                    }
                }
                // Nonce
                const actualNonce = await web3.eth.getTransactionCount(executionInfo.wallet.address);
                if (overrideNonceSetting && nonceSetting && nonceSetting <= actualNonce) {
                    notify("Nonce set is lower or equal than the actual for this wallet and network.", "warn");
                }
                // // Gas Price - check if the user set in the execute page, network settings page or get the default for the current network
                // // TODO: Test all 3 conditions ! Settings in the execute page, network settings set by the user, network settings default and the default fallback (12 Gwei = 12000000000)
                // let _gasPrice = 12000000000; // fallback price
                // try {
                //     if (gasPriceSetting) _gasPrice = Web3.utils.toWei(gasPriceSetting.toString(), "gwei");
                //     else {
                //         const _userGasPriceNetwork = await getUserGasPrice(executionInfo.network.id); // user price or network default price
                //         //console.log("executeFunctionNonPayable - _userGasPriceNetwork", _userGasPriceNetwork);
                //         if (_userGasPriceNetwork) _gasPrice = Web3.utils.toWei(_userGasPriceNetwork.toString(), "gwei");
                //     }
                // } catch (e) {}
                // Gas Price - check if the user set in the execute page, network settings page or get the default for the current network
                let _gasPrice = null;
                if (executionInfo.gasPrice) _gasPrice = Web3.utils.toWei(executionInfo.gasPrice.toString(), "gwei");
                // Web3js gas price estimation
                if (!executionInfo.gasPrice) {
                    _gasPrice = await getGasPriceEstimation(executionInfo.network.RPC);
                }
                if (!_gasPrice) {
                    _gasPrice = 12000000000; // 12 Gwei fallback price
                }
                console.log("Runner service - run - _gasPrice", _gasPrice);

                // Payable - Value Parameter
                let _valueParameter;
                if (executionInfo.function.stateMutability === "payable") {
                    _valueParameter = executionInfo.parameters.find((param) => param.name === "value");
                }
                // Creates the Transaction
                const rawTransaction = {
                    from: executionInfo.wallet.address,
                    to: executionInfo.contract.proxy ?? executionInfo.contract.implementation,
                    ...(overrideNonceSetting && nonceSetting && { nonce: nonceSetting }),
                    ...(!useWalletProvider && { gas: gas }),
                    ...(!useWalletProvider && { gasPrice: _gasPrice }),
                    ...(!useWalletProvider && { data: data }),
                    ...(executionInfo.function.stateMutability === "payable" && { value: web3.utils.toWei(_valueParameter.value.toString(), "ether") }),
                };

                // MetaMask
                if (useWalletProvider) {
                    try {
                        console.log("executeFunctionNonPayable parameters", contract, executionInfo.function.name, parametersValues, rawTransaction);
                        const _resultMM = await contract.methods[executionInfo.function.name](...parametersValues).send(rawTransaction);
                        console.log("USE MM RESPONSE", _resultMM);
                        resolve(_resultMM);
                        return _resultMM;
                    } catch (error) {
                        console.error("Error executeFunctionNonPayable with useWalletProvider", error);
                        reject(error);
                        return _resultMM;
                    }
                }

                console.debug("AppContext - executeFunctionNonPayable - RawTransaction", rawTransaction);
                const signedTransaction = await web3.eth.accounts.signTransaction(rawTransaction, executionInfo.wallet.pk);
                console.debug("AppContext - executeFunctionNonPayable - Signed Transaction", signedTransaction);

                // Send the Transaction
                const tx = web3.eth
                    .sendSignedTransaction(signedTransaction.rawTransaction)
                    // .once('sending', function(payload){ ... })
                    // .once('sent', function(payload){ ... })
                    .once("transactionHash", function (hash) {
                        console.debug("AppContext - executeFunctionNonPayable - Function", executionInfo.function.name, "transactionHash", hash);
                    })
                    .once("receipt", async function (receipt) {
                        console.debug("AppContext - executeFunctionNonPayable - Function", executionInfo.function.name, "receipt", receipt);

                        let _events = null;
                        try {
                            _events = await getEventsExecution(executionInfo.network.RPC, contract, receipt);
                        } catch (e) {}

                        let gasPrice = 0;
                        try {
                            gasPrice = (await web3.eth.getTransaction(receipt.transactionHash)).gasPrice;
                        } catch (e) {}

                        let coinPrice = 0;
                        try {
                            coinPrice = await getCoinPrice(executionInfo.network.coin);
                        } catch (e) {}

                        // Add results executing call
                        let _result = null;
                        try {
                            executionInfo.receipt = {};
                            _result = await contract.methods[executionInfo.function.name](...parametersValues).call({
                                to: executionInfo.contract.proxy,
                                ...(overrideNonceSetting && nonceSetting && { nonce: nonceSetting }),
                                gas: gas,
                            });
                            // console.log("Simulation Non-payable Result", _result);
                        } catch (error) {}

                        const _executionInfo = createExecutionInfo(
                            "execution",
                            executionInfo.network,
                            executionInfo.contract,
                            executionInfo.abi,
                            executionInfo.wallet,
                            executionInfo.function,
                            parameters,
                            gas,
                            gasPrice,
                            coinPrice,
                            data,
                            rawTransaction,
                            signedTransaction,
                            receipt,
                            _result,
                            _events,
                            null,
                            null
                        );
                        // console.log("executeFunctionNonPayable adding to the execution history", _executionInfo);
                        createExecutionRegistry(_executionInfo, addExecutionHistory, !addExecutionQueueResults);
                        if (addExecutionQueueResults) addToExecutionQueueResults(_executionInfo);

                        try {
                            getWalletBalance(); // Update the current wallet coin balance
                        } catch (e) {}

                        resolve(receipt);
                        // console.debug("AppContext - executeFunction - Function", executionInfo.function.name, "Result", receipt);
                    })
                    //.on('confirmation', function(confNumber, receipt, latestBlockHash){ ... })
                    .on("error", async function (error, receipt) {
                        if (error.message.includes("Invalid JSON RPC response"))
                            notify("The RPC provider was not able to execute the function. Review the transaction data and parameters and try again.", "warn");
                        // console.log("Error executing AppContext - executeFunctionNonPayable");
                        // console.log(
                        //     "Transaction Info",
                        //     executionInfo.uuid,
                        //     executionInfo.type,
                        //     executionInfo.network,
                        //     executionInfo.contract,
                        //     executionInfo.abi,
                        //     executionInfo.wallet,
                        //     executionInfo.function,
                        //     executionInfo.parameters,
                        //     rawTransaction,
                        //     signedTransaction,
                        //     "Network Gas Price",
                        //     await web3.eth.getGasPrice()
                        // );
                        console.log("Error executing executeFunctionNonPayable", error);
                        // console.error("Error.message", error.message);
                        // console.error("Receipt", receipt);
                        // console.error("JSON.stringify", JSON.stringify(error, null, 2))

                        // WORKAROUND (Get Revert Reason using Call)
                        let revertReason;
                        await contract.methods[executionInfo.function.name](...parametersValues)
                            .call({
                                from: executionInfo.wallet.address,
                            })
                            // .then(() => {
                            //     throw Error("reverted tx");
                            // })
                            .catch((revertReason_) => {
                                console.log("Error - revertReason", revertReason_);
                                console.log("Error - { revertReason }", { revertReason });
                                revertReason = revertReason_ + { revertReason }; // TODO: Review later, it seems in not all cases is working well
                                console.log({ revertReason });
                            });

                        // Insufficient Funds
                        console.log(error.message);
                        if (
                            error.message.toLowerCase().trim() === "returned error: internal_error: insufficient funds" ||
                            error.message.toLowerCase().trim() === "returned error: insufficient funds for gas * price + value"
                        ) {
                            revertReason = "Insufficient Funds";
                        }
                        console.log("Error - revertReason", revertReason);

                        // Format Error message
                        // console.warn("executeFunctionNonPayable revertReason", revertReason);
                        // console.warn(revertReason);
                        let _error;
                        // if (error) _error = { error: error.message, revertReason: revertReason ?? null, stackTrace: error.stack ?? null };
                        if (error) _error = { error: error.message, revertReason: revertReason ?? null };

                        console.log("_error", _error);

                        // Display the error as a result of an execution
                        // TODO: is it possible to the events log when it fails?
                        const gasPrice = receipt ? (await web3.eth.getTransaction(receipt.transactionHash)).gasPrice : _gasPrice;
                        const coinPrice = await getCoinPrice(executionInfo.network.coin);
                        const _executionInfo = createExecutionInfo(
                            "execution",
                            executionInfo.network,
                            executionInfo.contract,
                            executionInfo.abi,
                            executionInfo.wallet,
                            executionInfo.function,
                            executionInfo.parameters,
                            gas,
                            gasPrice,
                            coinPrice,
                            data,
                            rawTransaction,
                            signedTransaction,
                            receipt ?? null,
                            null,
                            null,
                            revertReason,
                            _error
                        );
                        createExecutionRegistry(_executionInfo, addExecutionHistory, !addExecutionQueueResults);
                        if (addExecutionQueueResults) addToExecutionQueueResults(_executionInfo);

                        reject(error);
                    });
                // .then(function(receipt){
                //     // will be fired once the receipt is mined
                // });
            } catch (error) {
                console.error("Error executeFunctionNonPayable", error);
                // notify("Error executing transaction. Please try again.", "error");
                reject(error);
            }
        });
    };

    async function getGasPriceEstimation(rpc_) {
        try {
            const _web3 = new Web3(rpc_);
            const _gasPriceEstimation = await _web3.eth.getGasPrice();
            console.log("Estimated Gas Using Web3js", _web3.utils.fromWei(_gasPriceEstimation, "gwei"), "Gwei");
            return _gasPriceEstimation;
        } catch (e) {
            //Sentry.captureException(e);
        }
    }

    // Metamask
    async function getCurrentNetworkWalletProvider(requiredNetworkId_, askToSwitchNetwork = true) {
        // Check if the metamask network is the same of the current contract
        const networkId = await window.ethereum.request({ method: "net_version" });
        {
            if (networkId != requiredNetworkId_) {
                if (askToSwitchNetwork) {
                    await window.ethereum.request({ method: "wallet_switchEthereumChain", params: [{ chainId: Web3.utils.toHex(requiredNetworkId_) }] });
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Add a delay of 1 second to wait for the network to change in MM
                }
            }
        }
    }
    // TODO update to wallet connect v3 later
    // Only execute functions using a wallet provider because the information returned is limited
    async function executeFunctionWalletProvider(network_, proxyContractAddress_, implementationContractAdress_, abi_, wallet_, function_, functionParametersValues_) {
        console.log("executeFunctionWalletProvider props", network_, proxyContractAddress_, implementationContractAdress_, abi_, wallet_, function_, functionParametersValues_);

        // Check if the metamask network is the same of the current contract
        // BUG: is asking to approve before the user changes the network
        await getCurrentNetworkWalletProvider(network_, true);
        // const networkId = await window.ethereum.request({ method: "net_version" });
        // {
        //     if (networkId != network_) {
        //         await window.ethereum.request({ method: "wallet_switchEthereumChain", params: [{ chainId: Web3.utils.toHex(network_) }] });
        //     }
        // }

        // if we have proxy and implementation, it means is a proxy
        // if only proxy, it's a implementation

        // Get ABI and Function of the contract
        let _abi;
        let _functions;
        let _function;
        if (!abi_) {
            const response = await Service.getFunctions(network_, implementationContractAdress_ ?? proxyContractAddress_);
            console.log("executeFunctionWalletProvider getFunctions response", response);
            if (response && response.data) {
                _abi = response.data;
                _functions = response.data.filter((item) => item.type === "function");
            }
            console.log("executeFunctionWalletProvider _abi", _abi);
            console.log("executeFunctionWalletProvider _functions", _functions);
            _function = _functions.find((item) => item.name.toLowerCase() === function_.toLowerCase());
            console.log("executeFunctionWalletProvider _function", _function);
        } else {
            _abi = abi_;
            _function = function_;
        }

        console.log("executeFunctionWalletProvider _abi", _abi);
        console.log("executeFunctionWalletProvider _function", _function);

        // Get network info (we need the RPC)
        const _network = getNetworkByID(network_);

        // Create the contract object
        let _contract = {};
        _contract.implementation = implementationContractAdress_;
        _contract.proxy = proxyContractAddress_;

        // Create the wallet object
        let _wallet = {};
        _wallet.address = wallet_;
        _wallet.pk = null;

        console.log("executeFunctionWalletProvider variables ", _network, _contract, _abi, _wallet, _function, functionParametersValues_);

        const executionInfoMM = createExecutionInfo(
            "execution",
            _network,
            _contract,
            _abi,
            _wallet,
            _function,
            functionParametersValues_,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );

        console.log("executeFunctionWalletProvider executionInfoMM ", executionInfoMM);

        let _result = await executeFunctionNonPayable(executionInfoMM, false, false, false, true, false);

        if (_result) {
            _result.provider = true;
            (_result.input = _result.input || {}).type = "execution";
            if (_result.status) _result.success = true;
            else _result.success = false;
            (_result.function = _result.function || {}).name = executionInfoMM.function.name;
            _result.function.inputs = executionInfoMM.parameters;
            _result.timestamp = new Date();
            (_result.wallet = _result.wallet || {}).address = executionInfoMM.wallet.address;
            _result.wallet.name = "MetaMask";
            (_result.contract = _result.contract || {}).implementation = executionInfoMM.contract.implementation;
        }

        console.log("executeFunctionWalletProvider executeFunctionNonPayable _result ", _result);

        return _result;
    }
    async function connectWalletProvider() {
        try {
            if (!ethereum) {
                notify("MetaMask not detect. You need install the extension and try it again.", "warn");
                return;
            }
            const accounts = await ethereum.request({ method: "eth_requestAccounts" });
            let _wallet = {};
            _wallet.type = "provider";
            _wallet.name = "MetaMask";
            _wallet.address = accounts[0];
            return _wallet;
        } catch (err) {
            if (err.code === 4001) {
                console.log("User rejected the connection. Please connect to MetaMask.");
            } else {
                console.error(err);
                notify("Erros connecting MetaMask. Try it again later.", "warn");
            }
        }
    }
    // TODO: Disconnect MetaMask

    async function getEventsExecution(RPC, contract, receipt) {
        // TODO: Create a new subsession in results to display it
        // Access and decode the logs of the transaction receipt
        // What is important?
        // log[x].address = "0xba8D609C32c3885beaBa267D81518AAAe55A832d" if the contract call another contract, show the source of the log message
        // log[x].?????? Name Value1Set (uint8 value) the function who generated the log
        // log[x].topics[x] Topics 0x806af2e1b19269eed208fdf67b89f671bfd543ec6026849a0c24e94edde57bdb used for index to be able to find this information later
        // log[x].data Data value : 31 value of the event

        // let _events = [];
        // const web3 = new Web3(RPC);
        // const logs = receipt.logs;
        // // console.log("Processing receipt.logs to get the events information", logs);
        // // console.log("Listing all contract events", contract.events);
        // receipt.logs.forEach((log) => {
        //     // console.log("Processing Log", log.id);
        //     const eventSignature = log.topics[0]; // TODO: loop to get all the topics and data ???
        //     const event = contract.options.jsonInterface.find((item) => item.type === "event" && item.signature === eventSignature);
        //     if (!event) {
        //         // console.log("Could not find matching event in contract ABI for log " + log);
        //         return;
        //     }
        //     // console.log("An matching event was found", event);
        //     // console.log("Event Name", event.name);
        //     // console.log("Event Inputs", JSON.stringify(event.inputs, null, 4));
        //     const inputs = event.inputs;
        //     const _decodedData = web3.eth.abi.decodeLog(inputs, log.data, eventSignature);
        //     // console.log("Event value (Decoded data): " + JSON.stringify(_decodedData, null, 4));
        //     // console.log("Event value", _decodedData.value);

        //     // TODO: Get all topics (even the not matched ones), parameters and values, not only the first
        //     // TODO: Try to get the contract names (if they are know)
        //     const _event = {
        //         contract: { name: "", address: log.address },
        //         event: event.name,
        //         parameters: [
        //             {
        //                 type: event.inputs[0].type,
        //                 name: event.inputs[0].name,
        //                 value: _decodedData.value,
        //             },
        //         ],
        //         topics: [eventSignature],
        //     };
        //     _events.push(_event);
        // });
        // console.log("getEventsExecution _events", _events);
        // return _events;

        // TODO: There are topics not related with events, review the code before this line later

        const web3 = new Web3(RPC);
        const logs = receipt.logs;
        const _events = [];

        logs.forEach((log) => {
            const eventSignature = log.topics[0];
            const event = contract.options.jsonInterface.find((item) => item.type === "event" && item.signature === eventSignature);

            if (!event) {
                // Log the address and the topics only
                const _event = {
                    contract: { address: log.address },
                    topics: log.topics,
                    data: log.data,
                };

                _events.push(_event);
                return;
            }

            try {
                const inputs = event.inputs;
                const decodedData = web3.eth.abi.decodeLog(inputs, log.data, eventSignature);

                const parameters = inputs.map((input, index) => {
                    return {
                        type: input.type,
                        name: input.name,
                        value: decodedData[index],
                    };
                });
            } catch (error) {
                console.warn("Not possible to decode the data for the event", event);
            }

            const _event = {
                // contract: { name: "", address: log.address },
                contract: { address: log.address },
                event: event.name,
                parameters: parameters,
                topics: log.topics,
            };

            _events.push(_event);
        });

        return _events;
    }

    const validateExecution = (simulation = false, parameters) => {
        // console.log("validateExecution currentContract", currentContract);
        if (!currentNetwork || !currentNetwork.name) {
            notify("Select a Network", "info");
            setExecuting(false);
            return false;
        }
        if (!currentContract || !currentContract.name) {
            notify("Select a Contract", "info");
            setExecuting(false);
            return false;
        }
        if (!currentFunction || !currentFunction.name) {
            notify("Select a Function", "info");
            setExecuting(false);
            return false;
        }
        if (!simulation)
            if (currentFunction.stateMutability != "view" && currentFunction.stateMutability != "pure") {
                if (!currentWallet || !currentWallet.name) {
                    notify("Select a Wallet", "info");
                    setExecuting(false);
                    return false;
                }
            }
        if (currentFunction.stateMutability === "payable" && (!parameters || Object.entries(parameters).length === 0)) {
            notify("The payable function requires a value to be paid/transferred", "info");
            setExecuting(false);
            return false;
        }
        if (parameters && Object.entries(parameters).length != currentFunction.inputs.length && currentFunction.stateMutability != "payable") {
            console.log(
                "validateExecution parameters",
                parameters,
                "Object.entries(parameters).length",
                Object.entries(parameters).length,
                "currentFunction.inputs.length",
                currentFunction.inputs.length
            );
            notify("Review the parameters and entered values", "info");
            setExecuting(false);
            return false;
        }
        if (!parameters && currentFunction.inputs.length > 0) {
            notify("Review the parameters and entered values", "info");
            setExecuting(false);
            return false;
        }
        if (parameters && Object.entries(parameters).length > 0) {
            let _parametersEmpty = false;
            Object.entries(parameters).forEach(([key, value]) => {
                if (!value.trim()) {
                    notify("Review the parameters and entered values", "info");
                    setExecuting(false);
                    _parametersEmpty = true;
                    return;
                }
            });
            if (_parametersEmpty) return false;
        }

        let validationDataType = true;
        let paramatersWithInvalidValues = [];
        currentFunction.inputs.forEach((input) => {
            Object.entries(parameters).forEach((parameter) => {
                const [key, value] = parameter;
                if (key === input.name) {
                    if (value.trim() !== "") {
                        // if (input.type === "uint256") {
                        //     try {
                        //         web3.utils.toBN(value);
                        //     } catch (e) {
                        //         console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                        //         paramatersWithInvalidValues.push(key);
                        //         validationDataType = false;
                        //     }
                        // } else
                        if (input.type === "address") {
                            if (!Web3util.isAddress(value)) {
                                // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                paramatersWithInvalidValues.push(key);
                                validationDataType = false;
                            }
                        } else if (input.type.startsWith("uint")) {
                            const size = parseInt(input.type.replace(/uint/g, ""));
                            // if (isNaN(size)) {
                            //     console.log("Invalid data type for the parameter", key, ". Expected uint with a valid size. Value:", value);
                            //     paramatersWithInvalidValues.push(key);
                            //     validationDataType = false;
                            // } else
                            {
                                // console.log("Validating ", key, " value", value, input);
                                const maxValue = BigInt(2) ** BigInt(size) - BigInt(1);
                                // console.log("Values for ", input.type, "max", maxValue);
                                try {
                                    const parsedValue = BigInt(value);
                                    if (parsedValue < BigInt(0) || parsedValue > maxValue) {
                                        // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                        paramatersWithInvalidValues.push(key);
                                        validationDataType = false;
                                    }
                                } catch (e) {
                                    // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                    paramatersWithInvalidValues.push(key);
                                    validationDataType = false;
                                }
                            }
                        } else if (input.type.startsWith("int")) {
                            const size = parseInt(input.type.replace(/int/g, ""));
                            // if (isNaN(size)) {
                            //     validationDataType = false;
                            //     paramatersWithInvalidValues.push(key);
                            //     console.log("Invalid data type for the parameter", key, ". Expected int with a valid size. Value:", value);
                            // } else
                            {
                                // console.log("Validating ", key, " value", value, input);
                                const minValue = -(BigInt(2) ** BigInt(size - 1));
                                const maxValue = BigInt(2) ** BigInt(size - 1) - BigInt(1);
                                // console.log("Values for ", input.type, "min", minValue, "max", maxValue);
                                try {
                                    const parsedValue = BigInt(value);
                                    if (parsedValue < minValue || parsedValue > maxValue) {
                                        // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                        paramatersWithInvalidValues.push(key);
                                        validationDataType = false;
                                    }
                                } catch (e) {
                                    // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                    paramatersWithInvalidValues.push(key);
                                    validationDataType = false;
                                }
                            }
                        } else if (input.type === "bool") {
                            if (value !== "true" && value !== "false") {
                                // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                paramatersWithInvalidValues.push(key);
                                validationDataType = false;
                            }
                        } else if (input.type.startsWith("bytes")) {
                            const size = parseInt(input.type.replace(/bytes/g, ""));
                            if (isNaN(size)) {
                                // console.log("Invalid data type for the parameter", key, ". Expected bytes with a valid size. Value:", value);
                                paramatersWithInvalidValues.push(key);
                                validationDataType = false;
                            } else {
                                const valueBytes = web3.utils.hexToBytes(value);
                                if (valueBytes.length !== size) {
                                    // console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                                    paramatersWithInvalidValues.push(key);
                                    validationDataType = false;
                                }
                            }
                            // } else if (input.type.startsWith("string")) {
                            //     const size = parseInt(input.type.replace(/string/g, ""));
                            //     if (isNaN(size)) {
                            //         console.log("Invalid data type for the parameter", key, ". Expected string with a valid size. Value:", value);
                            //         paramatersWithInvalidValues.push(key);
                            //         validationDataType = false;
                            //     } else if (value.length > size) {
                            //         console.log("Check the value for the parameter", key, ". Expected", input.type, "Value:", value);
                            //         paramatersWithInvalidValues.push(key);
                            //         validationDataType = false;
                            //     }
                        }
                    }
                    return;
                }
            });
        });
        if (!validationDataType) {
            if (paramatersWithInvalidValues.join(", ").trim()) notify("Some parameters could have invalid values. Check " + paramatersWithInvalidValues.join(", "), "info");
            else notify("Some parameters could have invalid values. Check the input values and try again", "info");
            setExecuting(false);
            return false;
        }

        return true;
    };

    const reorganizeParameters = (executionInfo) => {
        // Reorganize the parameters in the order expected by the contract
        let _parameters = [];
        let _parametersValues = [];
        // console.log("reorganizeParameters", executionInfo.function.inputs, executionInfo.parameters);
        executionInfo.function.inputs.forEach((input) => {
            // get the correct parameter for the expect input
            executionInfo.parameters.forEach((parameter) => {
                if (parameter.name === input.name) {
                    input.value = parameter.value;
                    _parameters.push(input);
                    _parametersValues.push(parameter.value);
                    return;
                }
            });
        });

        // if (executionInfo.function.stateMutability === "payable") {
        //     _parameters.push({ name: "value", value: executionInfo.parameters[0].value });
        //     _parametersValues.push(executionInfo.parameters[0].value);
        // }

        console.debug("Parameters to call the function (in expected order by the contract)", _parameters, _parametersValues);
        return { parametersValues: _parametersValues, parameters: _parameters };
    };

    // const reorganizeParameters = (executionInfo) => {
    //     // Reorganize the parameters in the order expected by the contract
    //     let _parameters = [];
    //     let _parametersValues = [];
    //     executionInfo.function.inputs.forEach((input) => {
    //         // get the correct parameter for the expect input
    //         executionInfo.parameters.forEach((parameter) => {
    //             if (parameter.name === input.name) {
    //                 input.value = parameter.value;
    //                 _parameters.push(input);
    //                 _parametersValues.push(parameter.value);
    //                 return;
    //             }
    //         });
    //     });
    //     console.debug("Parameters to call the function (in expected order by the contract)", _parameters, _parametersValues);
    //     return { parametersValues: _parametersValues, parameters: _parameters };
    // };

    function validateParameter(solidityType, value) {
        if (solidityType === "address") {
            if (!Web3util.isAddress(value)) {
                return false;
            } else {
                return true;
            }
        } else if (solidityType.startsWith("uint")) {
            const size = parseInt(solidityType.replace(/uint/g, ""));
            // if (isNaN(size)) {
            //     console.log("Invalid data solidityType for the parameter", key, ". Expected uint with a valid size. Value:", value);
            //     paramatersWithInvalidValues.push(key);
            //     validationDatasolidityType = false;
            // } else
            {
                // console.log("Validating ", key, " value", value, input);
                const maxValue = BigInt(2) ** BigInt(size) - BigInt(1);
                // console.log("Values for ", solidityType, "max", maxValue);
                try {
                    const parsedValue = BigInt(value);
                    if (parsedValue < BigInt(0) || parsedValue > maxValue) {
                        return false;
                    }
                    return true;
                } catch (e) {
                    return false;
                }
            }
        } else if (solidityType.startsWith("int")) {
            const size = parseInt(solidityType.replace(/int/g, ""));
            // if (isNaN(size)) {
            //     validationDatasolidityType = false;
            //     paramatersWithInvalidValues.push(key);
            //     console.log("Invalid data solidityType for the parameter", key, ". Expected int with a valid size. Value:", value);
            // } else
            {
                // console.log("Validating ", key, " value", value, input);
                const minValue = -(BigInt(2) ** BigInt(size - 1));
                const maxValue = BigInt(2) ** BigInt(size - 1) - BigInt(1);
                // console.log("Values for ", solidityType, "min", minValue, "max", maxValue);
                try {
                    const parsedValue = BigInt(value);
                    if (parsedValue < minValue || parsedValue > maxValue) {
                        return false;
                    }
                    return true;
                } catch (e) {
                    return false;
                }
            }
        } else if (solidityType === "bool") {
            if (value !== "true" && value !== "false") {
                return false;
            }
            return true;
        } else if (solidityType.startsWith("bytes")) {
            const size = parseInt(solidityType.replace(/bytes/g, ""));
            if (isNaN(size)) {
                return false;
            } else {
                const valueBytes = web3.utils.hexToBytes(value);
                if (valueBytes.length !== size) {
                    return false;
                }
                return true;
            }
            // } else if (solidityType.startsWith("string")) {
            //     const size = parseInt(solidityType.replace(/string/g, ""));
            //     if (isNaN(size)) {
            //         console.log("Invalid data solidityType for the parameter", key, ". Expected string with a valid size. Value:", value);
            //         paramatersWithInvalidValues.push(key);
            //         validationDatasolidityType = false;
            //     } else if (value.length > size) {
            //         console.log("Check the value for the parameter", key, ". Expected", solidityType, "Value:", value);
            //         paramatersWithInvalidValues.push(key);
            //         validationDatasolidityType = false;
            //     }
        } else {
            return true;
        }
    }

    // const gasPriceNew = async (RPC) => {
    //     const web3 = new Web3(RPC);
    //     const block = await web3.eth.getBlock("latest");
    //     const gas = Math.round(block.gasLimit / block.transactions.length);
    //     console.log("Current Gas:", gas);
    //     // return gas;
    //     return 12000000000;
    // };

    // const gasPriceNew = async (RPC) => {
    // const web3 = new Web3(RPC);
    // const gasPrice = await web3.eth.getGasPrice();
    // const block = await web3.eth.getBlock("latest");
    // const gas = Math.round(block.gasLimit / block.transactions.length);
    // const gasLimit = 21000; // Replace with the gas limit you want to set for your transaction
    // const totalGasPrice = gasPrice * gasLimit;
    // console.log("Current Gas:", gas);
    // console.log("Current Gas Price:", gasPrice);
    // console.log("Total Gas Price:", totalGasPrice);
    // return totalGasPrice;
    // };

    // const gasPrice = async () => {
    //     // https://api.etherscan.io/api?module=gastracker&action=gasestimate&gasprice=2000000000&apikey=YourApiKeyToken
    //     const response = await axios.get(`${currentNetwork.etherscanAPI}/api?module=gastracker&action=gasestimate&gasprice=2000000000&apikey=${currentNetwork.etherscanAPIKey}`);
    //     if (response.status === 200) {
    //         console.log(response);
    //         return response.data.price;
    //     } else return null;
    // }

    const getCoinPrice = async (coinSymbol) => {
        return Service.getCoinPrice(coinSymbol);
    };

    const setResult2Null = () => {
        setLastExecution(null);
    };

    const createExecutionRegistry = async (executionInfo, addExecutionHistory = true, updateLastExecution = true) => {
        // TODO: Don't execute this line if is a execution queue
        if (updateLastExecution) setLastExecution(executionInfo);
        if (addExecutionHistory) {
            // Add/Save the history in the Local Storage
            const _executionhistory = await getUserData("executionhistory");
            const _executionHistoryData = _executionhistory;
            const _executionHistoryLS = _executionHistoryData;
            let _arrExecutionHistoryLS = [];
            if (_executionHistoryLS) {
                _arrExecutionHistoryLS = _executionHistoryLS;
            }
            _arrExecutionHistoryLS.push(executionInfo);
            //saveDataStorage(LocalStorage.ExecutionHistory, sortArrayByDate([..._arrExecutionHistoryLS]));
            const _data = sortArrayByDate([..._arrExecutionHistoryLS]);
            setExecutionHistory(_data);
            // console.log("Calling api to sync execution history", _data);
            syncUserData("executionhistory", _data);
        }
    };

    const createExecutionInfo = (
        type_ = "execution",
        network_,
        contract_,
        abi_,
        wallet_,
        function_,
        parameters_ = null,
        gas_ = null,
        gasPrice_ = null,
        coinPrice_ = null,
        transactionData_ = null,
        rawTransaction_ = null,
        signedTransaction_ = null,
        receipt_ = null,
        result_ = null,
        events_,
        revertReason_ = null,
        error_ = null
    ) => {
        // TODO: Possible improvement - saving the ABI used to execute the method, can create issues if the ABI changes. On eoption is to save the etherscanAPIKey and etherscanAPI and get the updated ABI
        // TODO: Possible improvement - saving only the ABI of the function used
        // console.log("createExecutionInfo type_", type_);

        if (!parameters_) parameters_ = [];
        let _functionGasType;
        if (function_) _functionGasType = getFunctionGasType(function_.stateMutability);
        else _functionGasType = FunctionGasType.NoGasFee;
        //console.log("createExecutionInfo stateMutability", function_.stateMutability, _functionGasType);

        const _execution = {
            uuid: newUUID(),
            type: type_,
            network: network_,
            contract: contract_,
            abi: abi_,
            function: function_,
            parameters: parameters_,
            ...(_functionGasType === FunctionGasType.PayGasFee && {
                wallet: wallet_,
                ...(type_ !== "simulation" && { from: wallet_.address }),
                to: contract_.proxy,
                gas: gas_,
                gasPrice: gasPrice_,
                gasFee: gas_ * gasPrice_,
                coinPrice: coinPrice_,
                data: transactionData_,
                rawTransaction: rawTransaction_,
                signedTransaction: signedTransaction_,
                receipt: receipt_,
            }),
            result: result_,
            events: events_,
            revertReason: revertReason_,
            timestamp: new Date(),
            error: error_,
        };

        return _execution;
    };

    const reexecuteFunction = async (historyItem, addExecutionHistory = false, addExecutionQueueResults = false) => {
        // console.log("reexecuteFunction addExecutionHistory", addExecutionHistory, "addExecutionQueueResults", addExecutionQueueResults);
        try {
            setExecuting(true);
            setLastExecution(null);
            // console.log("reexecuteFunction", historyItem);
            const _executionInfo = createExecutionInfo("execution", historyItem.network, historyItem.contract, historyItem.abi, historyItem.wallet, historyItem.function, historyItem.parameters);
            const _functionGasType = getFunctionGasType(historyItem.function.stateMutability);
            let _result;
            if (_functionGasType === FunctionGasType.NoGasFee) {
                _result = await executeFunctionView(_executionInfo, addExecutionHistory, addExecutionQueueResults);
                // console.log("reexecuteFunction _result", _result);
                setExecuting(false);
            } else {
                _result = await executeFunctionNonPayable(_executionInfo, addExecutionHistory, addExecutionQueueResults);
                // console.log("reexecuteFunction _result", _result);
                setExecuting(false);
            }
            return _result;
        } catch (err) {
            setExecuting(false);
            console.warn("Error executing AppContext - reexecuteFunction");
            console.error(err);
        }
    };

    // const stringfyParameters = (parameters_) => {
    //     let _parameters = [];
    //     Object.entries(parameters_).forEach((parameter) => {
    //         const [key, value] = parameter;
    //         _parameters.push(value);
    //         return;
    //     });
    //     return _parameters;
    // };

    async function addQueueListItem(queueListItem) {
        let _queueList;
        const _queuelist = await getUserData("queuelist");
        let _queueListDB;
        if (_queuelist) {
            _queueListDB = _queuelist;
        } else {
            _queueListDB = [];
        }
        if (_queueListDB) _queueList = [..._queueListDB];
        else _queueList = [];
        _queueList.push(queueListItem);
        setQueueList(_queueList);
        syncUserData("queuelist", _queueList);
        // console.log("addQueueListItem", _queue);
    }

    async function removeQueueListItem(queueListItem) {
        const queuelist = await getUserData("queuelist");
        if (queuelist) {
            const _queueListDB = queuelist;
            let _queueList = [..._queueListDB];
            if (_queueList) {
                _queueList.splice(
                    _queueList.findIndex((item) => {
                        return item.id === queueListItem.id;
                    }),
                    1
                );
                setQueueList(_queueList);
                syncUserData("queuelist", _queueList);
                // console.log("removeQueueListItem", _queueList);
            }
        }
    }

    async function updateQueueListItem(queueListItem) {
        console.log("updateQueueListItem - queueListItem", queueListItem);
        const queuelist = await getUserData("queuelist");
        if (queuelist) {
            const _queueListDB = queuelist;
            let _queueList = [..._queueListDB];
            if (_queueList) {
                _queueList.splice(
                    _queueList.findIndex((item) => {
                        return item.id === queueListItem.id;
                    }),
                    1
                );
                _queueList.push(queueListItem);
                setQueueList(_queueList);
                syncUserData("queuelist", _queueList);
                console.log("updateQueueListItem", _queueList);
            }
        }
    }

    const addExecutionQueue = async (executionInfo) => {
        const _queueExecutionQueue = currentQueue.queue ?? [];
        let _queue = [..._queueExecutionQueue];
        const _executionInfoNewUUID = structuredClone(executionInfo);
        _executionInfoNewUUID.uuid = newUUID();
        _queue.push(_executionInfoNewUUID);

        const _queueListItem = {
            id: currentQueue.id,
            name: currentQueue.name,
            networkId: currentQueue.networkId,
            queue: _queue,
        };

        setExecutionQueue(_queue);
        setCurrentQueue(_queueListItem);
        updateQueueListItem(_queueListItem);
    };

    const removeExecutionQueueItem = async (queueId, executionInfo) => {
        const _queueList = await getUserData("queuelist"); // Get the current queue
        _queueList.forEach((queue) => {
            if (queue.id === queueId) {
                // Remove the item from the queue
                let _queueItems = [...queue.queue];
                if (_queueItems) {
                    const _idx = _queueItems.findIndex((item) => {
                        return item.uuid === executionInfo.uuid;
                    });
                    if (_idx !== -1) {
                        _queueItems.splice(_idx, 1);
                        queue.queue = _queueItems;
                        setExecutionQueue(_queueItems);
                        setCurrentQueue(queue);
                        setQueueList(_queueList);
                        syncUserData("queuelist", _queueList);
                        // console.log("removeExecutionQueueItem _queueList", _queueList);
                    } else {
                        console.warn("Item", executionInfo.uuid, "in the Queue", queueId, "not found to be removed");
                    }
                }
            }
        });
    };

    const executeQueue = async (addExecutionHistory = false) => {
        console.log("executeQueue addExecutionHistory", addExecutionHistory);
        console.log("executeQueue executionQueue", executionQueue);

        if (!executionQueue) return;

        // TODO: validate the queue
        // TODO: a validation function can be after a nonpayable/payable function
        if (!validateQueue()) return;

        let sequence = Promise.resolve();
        let previousResult;
        let previousResultFunction;
        let _step = 1;
        for (const executionInfo of executionQueue) {
            console.log("executeQueue executionInfo", executionInfo);
            if (executionInfo.type === "validation") {
                let _validation;
                console.log("executeQueue previousResult", previousResult);
                if (previousResult && typeof previousResult !== "object") {
                    console.log("executeQueue previousResult is not an object");
                    console.log("executionInfo.validation.operation", executionInfo.validation.operation);
                    const _validationResult = interpretValidationStepOperator(executionInfo.validation.operation, previousResult, executionInfo.validation.value);
                    console.log("VALIDATION STEP OK VIA FUNCTION result:", _validationResult, "the previous execution value", previousResult, "is greater than", executionInfo.validation.value);
                    if (_validationResult) {
                        _validation = { validation: executionInfo.validation, previousResult: { function: previousResultFunction, result: previousResult }, validationResult: true };
                        const _executionInfo = createExecutionInfo("validation", currentNetwork, null, null, null, null, null, null, null, null, null, null, null, null, _validation, null, null, null);
                        createExecutionRegistry(_executionInfo, false, false);
                        addToExecutionQueueResults(_executionInfo);
                    } else {
                        _validation = { validation: executionInfo.validation, previousResult: { function: previousResultFunction, result: previousResult }, validationResult: false };
                        console.debug("executeQueue - Stopped queue due to previous failure");
                        const _executionInfo = createExecutionInfo("validation", currentNetwork, null, null, null, null, null, null, null, null, null, null, null, null, _validation, null, null, null);
                        createExecutionRegistry(_executionInfo, false, false);
                        addToExecutionQueueResults(_executionInfo);
                        break;
                    }
                } else {
                    // TODO: Supply more information about which step we are and which one there is no result to be validated
                    notify(`Result is null or error geting the result of the previous step (step ${_step - 1}). Stopping the queue.`, "warn");
                    break;
                }
            } else if (executionInfo.type === "execution") {
                console.log("executeQueue reexecuting this executionInfo", executionInfo);
                const executionResult = await reexecuteFunction(executionInfo, addExecutionHistory, true);
                console.log("executeQueue executionResult", executionResult);
                // If have an error, stop the execution
                if (!executionResult) {
                    notify("Error executing the step " + _step + ". Review the error clicking on the red icon.", "error");
                    break;
                }
                previousResultFunction = executionInfo.function.name;
                previousResult = executionResult;
                sequence = sequence.then(() => executionResult);
                // sequence = sequence.then(() => reexecuteFunction(executionInfo, addExecutionHistory, true));
            }
            _step++;
        }
        sequence.then(() => {
            console.debug("executeQueue - All functions have been executed");
        });
    };

    function interpretValidationStepOperator(operator, value1, value2) {
        switch (operator) {
            case ValidationStepOperator.equal:
                return value1 === value2;
            case ValidationStepOperator.less:
                return value1 < value2;
            case ValidationStepOperator.greater:
                return value1 > value2;
            case ValidationStepOperator.equalOrLess:
                return value1 <= value2;
            case ValidationStepOperator.equalOrGreater:
                return value1 >= value2;
            case ValidationStepOperator.notEqual:
                return value1 !== value2;
            default:
                throw new Error("Invalid operator");
        }
    }

    function validateQueue() {
        // Validate if there is a validation step after a nonpayable/payable function
        console.log("validateQueue executionQueue", executionQueue);
        let previousStep;
        let stepNumber = 1;
        for (const step of executionQueue) {
            if (previousStep && previousStep.type === "execution" && step.type === "validation" && getFunctionGasType(previousStep.function.stateMutability) === FunctionGasType.PayGasFee) {
                notify(
                    `A validation step can't be placed after a nonpayable funcion. These function doesn't have a result. Instead use a function to check the get the data that you need to validate. Check step ${stepNumber}.`,
                    "warn"
                );
                return false;
            }
            previousStep = step;
            stepNumber++;
        }
        return true;
    }

    const addToExecutionQueueResults = (executionInfo) => {
        let _queueResults = getDataStorage(LocalStorage.ExecutionQueueResults);
        console.log("addToExecutionQueueResults", _queueResults);
        if (!_queueResults) _queueResults = [];
        const _executionInfoNewUUID = structuredClone(executionInfo);
        _executionInfoNewUUID.uuid = newUUID();
        _queueResults.push(_executionInfoNewUUID);
        saveDataStorage(LocalStorage.ExecutionQueueResults, _queueResults);
    };

    function createQueueValidationStep(validationOperationDisplay_, validationOperation_, validationValue_) {
        console.log("createQueueValidationStep");
        const _validationStep = createExecutionInfo("validation", currentNetwork, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
        _validationStep.validation = { operationDisplay: validationOperationDisplay_, operation: validationOperation_, value: validationValue_ };
        console.log("createQueueValidationStep", _validationStep);
        currentQueue.queue = addNewItemArray(currentQueue.queue, _validationStep);
        setExecutionQueue(currentQueue.queue); // list of items to be executed
        setCurrentQueue(currentQueue); // queue
        updateQueueListItem(currentQueue); // queue
    }

    function updateQueueValidationStep(validationOperationDisplay_, validationOperation_, validationValue_) {
        console.log("updateQueueValidationStep currentQueue", currentQueue); // = current queue item in the queues list
        console.log("updateQueueValidationStep executionQueueDetail", executionQueueDetail);

        // Find the current step in the queue
        const queueStepIndex = currentQueue.queue.findIndex((queueItem) => queueItem.uuid === executionQueueDetail.uuid);

        if (queueStepIndex !== -1) {
            // Create a copy of the current queue item
            const updatedQueueItem = { ...currentQueue.queue[queueStepIndex] };
            console.log("updateQueueValidationStep updatedQueueItem", updatedQueueItem);

            // Update the validation properties of the copied queue item
            updatedQueueItem.validation = {
                operation: validationOperation_,
                operationDisplay: validationOperationDisplay_,
                value: validationValue_,
            };

            // Create a copy of the current queue list item
            const updatedQueueListItem = structuredClone(currentQueue);
            console.log("updateQueueValidationStep updatedQueueListItem", updatedQueueListItem);

            // Create a copy of the current queue item
            const updatedQueue = updatedQueueListItem.queue;
            console.log("updateQueueValidationStep updatedQueue", updatedQueue);

            // Replace the queue item at the specified index with the updated queue item
            updatedQueue[queueStepIndex] = updatedQueueItem;
            console.log("updateQueueValidationStep updatedQueue 2", updatedQueue);

            setCurrentQueue((prevCurrentQueue) => ({
                ...prevCurrentQueue,
                queue: updatedQueue,
            }));
            setExecutionQueue((prevExecutionQueue) => ({
                ...prevExecutionQueue,
                updatedQueue,
            }));
            updateQueueListItem(updatedQueueListItem);
        }
    }

    // TODO: Review if we are using this function. Change it to the user be able to create a new account to test the system
    // function createNewWallet() {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             // TODO: Define a default RPC to create a new account
    //             const web3 = new Web3("https://data-seed-prebsc-2-s3.binance.org:8545/");
    //             const account = web3.eth.accounts.create();
    //             let _account;
    //             Service.encryptData(account.privateKey).then((response) => {
    //                 _account = { pk: account.privateKey, pkEncrypted: response.data };
    //                 console.log("_account", _account);
    //                 resolve(_account);
    //             });
    //         } catch (e) {
    //             reject(e);
    //         }
    //     });
    // }

    // Contract Source Code
    const [contractsSourceCode, setContractsSourceCode] = useState(null);
    const [contractsInfo, setContractsInfo] = useState(null);
    const [currentCompiledContractBytecode, setCurrentCompiledContractBytecode] = useState(null);
    const [currentCompiledContractABI, setCurrentCompiledContractABI] = useState(null);
    const [functionSourceCode, setFunctionSourceCode] = useState(null);
    async function loadContractSourceCode(networkId, contractImplementation) {
        // console.log("AppContext - loadContractSourceCode params", networkId, contractImplementation);
        // if ((!currentContract || !currentNetwork || !currentContract.implementation || !currentNetwork.id) && !networkId && !contractImplementation) {
        if (!networkId || !contractImplementation) {
            //notify("Contract source cannot be retrieved.", "warn");
            // console.log("implementation or networkid not available", currentContract, currentNetwork);
            return false;
        }
        let sourceCodeResult;
        if (!contractImplementation || !networkId) sourceCodeResult = await Service.loadSourcecode(currentNetwork.id, currentContract.implementation);
        else sourceCodeResult = await Service.loadSourcecode(networkId, contractImplementation);
        console.log("AppContext - loadContractSourceCode sourceCodeResult", sourceCodeResult);
        if (!sourceCodeResult) return null;
        setContractsInfo(sourceCodeResult.data[0]);
        const sourceCode = sourceCodeResult.data[0].SourceCode;
        const contractInfo = sourceCodeResult.data;

        if (sourceCode) {
            const _contractsCode = Web3util.jsonfyContract(sourceCode, contractInfo);
            if (_contractsCode) setContractsSourceCode(_contractsCode);
            if (contractImplementation && networkId) {
                return [contractInfo, _contractsCode];
            }
        } else {
            // notify("Contract source code not available.", "warn");
            setContractsSourceCode(null);
            setContractsInfo(null);
        }

        // if (sourceCode) {
        //     // console.log("AppContext - loadContractSourceCode", sourceCode);
        //     // TODO: Investigate the issue with {{ and }}
        //     if (sourceCode.startsWith("{")) {
        //         const _code = sourceCode.substring(1, sourceCode.toString().length - 1);
        //         const _contractsCode = extractContracts(JSON.parse(_code));
        //         setContractsSourceCode(_contractsCode);
        //         // console.log("setContractsSourceCode Solidity Standard Json-Input format", _contractsCode);

        //         if (contractImplementation && networkId) {
        //             return _contractsCode;
        //         }
        //     } else {
        //         const _contractInfo = sourceCodeResult.data[0];
        //         const contractObjects = [
        //             {
        //                 code: _contractInfo.SourceCode,
        //                 contract: _contractInfo.ContractName,
        //             },
        //         ];
        //         setContractsSourceCode(contractObjects);
        //         // console.log("setContractsSourceCode Solidity Single-file format", contractObjects);

        //         if (contractImplementation && networkId) {
        //             return contractObjects;
        //         }
        //     }
        // } else {
        //     // notify("Contract source code not available.", "warn");
        //     setContractsSourceCode(null);
        //     setContractsInfo(null);
        // }
    }
    async function loadContractSourceCodeEditor(networkId, contractImplementation) {
        try {
            if (!networkId || !contractImplementation) {
                return false;
            }
            let sourceCodeResult = await Service.loadSourcecode(networkId, contractImplementation);
            if (!sourceCodeResult) return null;
            let _contractInfo = sourceCodeResult.data[0];
            const sourceCode = sourceCodeResult.data[0].SourceCode;
            _contractInfo.baseContractAddress = contractImplementation;
            // Get the implementation contract if it's a proxy
            let _contractsCodeImplementation;
            if (_contractInfo.Implementation) {
                const implementationAddress = _contractInfo.Implementation;
                delete _contractInfo.Implementation;
                _contractInfo.implementation = {};
                _contractInfo.isProxy = true;
                _contractInfo.implementation.address = implementationAddress;
                const implementationContract = await Service.loadSourcecode(networkId, implementationAddress);
                if (implementationContract) {
                    _contractInfo.implementation.details = implementationContract.data[0];
                }
                // - An error can happen here if implementationContract.data[0].SourceCode is null and then the application will stop to work
                const sourceCodeImplementation = implementationContract.data[0].SourceCode;
                _contractsCodeImplementation = Web3util.jsonfyContract(sourceCodeImplementation, _contractInfo, "implementation");

                const proxyInfo = await getProxyInfo(networkId, contractImplementation);
                console.log("loadContractSourceCodeEditor proxyInfo", proxyInfo);
                _contractInfo.proxyType = proxyInfo.proxyType;
                _contractInfo.implementation.addressFromNetwork = proxyInfo.currentImplementationAddress;

                // Get license type using the source code of the root contract
                const _sourceCodeRootContract = _contractsCodeImplementation.find((name) => name.contract === _contractInfo.implementation.details.ContractName);
                _contractInfo.licenseType = Web3util.getLicenseType(_sourceCodeRootContract.code);

                // Proxy Admin
                _contractInfo.proxyAdmin = {};
                _contractInfo.proxyAdmin.addressFromNetwork = proxyInfo.currentProxyAdminAddress;
                const proxyAdminContract = await Service.loadSourcecode(networkId, proxyInfo.currentProxyAdminAddress);
                if (proxyAdminContract) {
                    _contractInfo.proxyAdmin.details = proxyAdminContract.data[0];
                }
            } else {
                _contractInfo.isProxy = false;
                _contractInfo.proxyType = null;
            }
            let _contractsCode;
            if (_contractInfo.isProxy) _contractsCode = Web3util.jsonfyContract(sourceCode, _contractInfo, "proxy");
            else {
                _contractsCode = Web3util.jsonfyContract(sourceCode, _contractInfo, "implementation");
                if (sourceCode) {
                    // License type for no-proxy contracts
                    const _sourceCodeRootContract = _contractsCode.find((name) => name.contract === _contractInfo.ContractName);
                    if (_sourceCodeRootContract) _contractInfo.licenseType = Web3util.getLicenseType(_sourceCodeRootContract.code);
                }
            }

            if (_contractsCode) setContractsSourceCode(_contractsCode);
            if (_contractsCodeImplementation) _contractsCode = _contractsCode.concat(_contractsCodeImplementation);
            if (_contractInfo && _contractsCode) return [_contractInfo, _contractsCode];
            else return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    // TODO: Improve this function to go over all the contracts and get all functions
    function extractFunctions(sourceCode) {
        try {
            const code = JSON.parse(sourceCode.data.toString().substring(1, sourceCode.data.toString().length - 1));
            //console.log("extractFunctions", code.sources.length, code.sources);
            const contract = code.sources[Object.keys(code.sources)[0]].content;
            const functionRegex = /function\s+(\w+)\s*\((.*?)\)\s*(.*?)\{/gs;
            let functions = [];
            let match;
            while ((match = functionRegex.exec(contract))) {
                const name = match[1];
                const source = match[0];
                functions.push({ name, source });
            }
            return functions;
        } catch (e) {
            return null;
        }
    }

    // Get the code of a function in the selected contract
    // TODO: Change to get the code based on the sourcecode + AST
    function getFunctionCode(functionName) {
        console.log("getFunctionCode - contractsSourceCode", contractsSourceCode);

        const _code = findFunctionCode(contractsSourceCode[0].code, functionName);
        // console.log("getFunctionCode - source code of", functionName, _code);
        if (!_code) return;
        const formattedCode = _code
            .replace(/\\r/g, "")
            .replace(/\\"/gm, '"')
            .split("\\n")
            .map((line, i) => (
                <div key={i}>
                    {line}
                    <br />
                </div>
            ));
        // console.log("formattedCode", formattedCode);
        setFunctionSourceCode(formattedCode);
    }
    // function copyMethodCode(functionName) {
    //     // TODO: Format better (replace the /n/r for a line break) or maybe use the html format and then copy?!?
    //     let _code = findFunctionCode(contractSourceCode, functionName);
    //     navigator.clipboard.writeText(_code);
    // }
    function findFunctionCode(contractCode, functionName) {
        // TODO: If the line starts with "//" ignore it, it's a comment

        let bracketCount = 0;
        let functionStart = contractCode.indexOf("function " + functionName);
        let firstBracket = contractCode.indexOf("{", functionStart);
        let functionCode = "";
        // var inComment = false;
        if (functionStart === -1) {
            return "Function not found";
        }
        functionCode += "    " + contractCode.substring(functionStart, firstBracket);
        for (let i = firstBracket; i < contractCode.length; i++) {
            var c = contractCode.charAt(i);
            if (contractCode[i] === "{") {
                bracketCount++;
            }
            if (contractCode[i] === "}") {
                bracketCount--;
            }
            functionCode += contractCode[i];
            if (bracketCount === 0 && i > firstBracket) {
                break;
            }
        }
        return functionCode;
    }

    async function compileContract(source, contractName, solidityVersion, optimizationUsed, optimizationRuns, sourcecode, dependencesSourceCode) {
        try {
            // console.log(
            //     "compileContract called - contractName",
            //     contractName,
            //     "solidityVersion",
            //     solidityVersion,
            //     "optimizationUsed",
            //     optimizationUsed,
            //     "optimizationRuns",
            //     optimizationRuns,
            //     "sourcecode",
            //     sourcecode,
            //     "dependencesSourceCode",
            //     dependencesSourceCode
            // );
            if (!contractName || !optimizationUsed || !optimizationRuns || !sourcecode) {
                notify("Nothing to compile. Select a tab with a contract code.");
                return;
            }
            // TODO: If the contract name doesn't have .sol, add it - WHY ??????????????????????????????????

            if (source === "network") {
                const _result = await Service.compileContract(sourcecode, solidityVersion, optimizationUsed, optimizationRuns);
                console.debug("Compilation is done - Result", _result);
                return _result;
            } else {
                // Adding the contract code in the depedences code
                if (!dependencesSourceCode) dependencesSourceCode = [];
                dependencesSourceCode.push({ dependency: contractName + ".sol", path: contractName + ".sol", code: sourcecode });
                const _contractsCodeWithDepedences = [...dependencesSourceCode];
                console.log("AppContext compileContract LOCAL _contractsCodeWithDepedences", _contractsCodeWithDepedences);
                const _result = await Service.compileContract(_contractsCodeWithDepedences, solidityVersion, optimizationUsed, optimizationRuns);
                console.log("compileContract - _result", _result);
                return _result;
            }
        } catch (error) {
            throw error;
        }
    }
    function getContractBytecode(contracts, contractName_) {
        const _contract = contracts[contractName_ + ".sol"][contractName_];
        if (!_contract) {
            console.warn(`Contract '${contractName}' not found`);
        }
        return _contract.evm.bytecode.object;
    }
    function getContractABI(contracts, contractName_) {
        const _contract = contracts[contractName_ + ".sol"][contractName_];
        if (!_contract) {
            console.warn(`Contract '${contractName}' not found`);
        }
        return _contract.abi;
    }

    async function loadCompilerVersions() {
        const _result = await Service.loadCompilerVersions();
        return _result;
    }

    async function deployContract(networkId, networkRPC, parameters, abi, bytecode, wallet, isProxy = false, solidityVersion, optimizationUsed, optimizationRuns) {
        try {
            if ((!networkRPC && wallet.type && wallet.type != "provider") || !abi || !bytecode || !wallet) {
                notify("You need to select a wallet and compile the contract first", "warn");
            }

            // TODO: Deploy the new implementation contract and execute the upgradeTo function in the proxy contract
            // TODO: Change this method to call initilize function in the future (it's optional)

            let deployment;
            if (wallet.type && wallet.type === "provider") {
                await getCurrentNetworkWalletProvider(networkId, true);
                // MetaMask
                let web3;
                const { ethereum } = window;
                if (ethereum && ethereum.isMetaMask) {
                    web3 = new Web3(ethereum);
                } else {
                    notify("You need install MetaMask to deploy using it.", "warn");
                    return null;
                }
                const _contract = new web3.eth.Contract(abi);
                const _deployContract = await _contract.deploy({
                    data: "0x" + bytecode,
                    ...(parameters !== null ? { arguments: parameters } : {}),
                });
                const _rawTransaction = {
                    from: wallet.address,
                    gas: 8000000,
                    data: _deployContract.encodeABI(),
                };
                deployment = await _deployContract.send(_rawTransaction);
                console.log("AppContext - deployContract - MetaMask Deployment Response - deployment", deployment);
            } else {
                // New method using API
                deployment = await Service.deployContract(parameters, abi, bytecode, wallet, networkRPC, isProxy, solidityVersion, optimizationUsed, optimizationRuns);
            }
            return deployment;
        } catch (error) {
            throw error;
        }
    }

    // TODO: Proxy, multi file contracts
    async function verifyContract(networkId, contractImplementationAddress, contractName, contractSourceCode, contractContructorArguments, solidityVersion, optimizationUsed, optimizationRuns) {
        try {
            console.log(
                "AppContext - verifyContract contractImplementationAddress",
                contractImplementationAddress,
                "contractName",
                contractName,
                "contractSourceCode",
                contractSourceCode,
                "contractContructorArguments",
                contractContructorArguments,
                "solidityVersion",
                solidityVersion,
                "optimizationUsed",
                optimizationUsed,
                "optimizationRuns",
                optimizationRuns
            );
            // if (!contractImplementationAddress || !contractName || !contractSourceCode || !contractContructorArguments || !optimizationUsed || !optimizationRuns) {
            if (!networkId || !contractImplementationAddress || !contractName || !contractSourceCode || !optimizationUsed || !optimizationRuns) {
                notify("Provide all paramaters to verify a contract", "warn");
                return;
            }
            const _result = await Service.verifyContract(
                networkId,
                contractImplementationAddress,
                contractName,
                contractSourceCode,
                contractContructorArguments,
                solidityVersion,
                optimizationUsed,
                optimizationRuns
            );
            return _result;
        } catch (error) {
            throw error;
        }
    }

    async function verifyContractStatus(networkId, guid) {
        if (!networkId || !guid) {
            notify("Provide all paramaters to check the verification status a contract", "warn");
            return;
        }
        const _result = await Service.verifyContractStatus(networkId, guid);
        return _result;
    }

    async function getActiveRPCUserSettings(networkId) {
        try {
            const _networkrpcs = await getUserData("networkrpcactive");
            if (_networkrpcs) {
                const _userRPC = _networkrpcs.find((rpc) => {
                    return rpc.networkId === networkId;
                });

                return _userRPC;
            }

            return null;
        } catch (error) {
            // ! BUG - during the load a existent user, this call can't get the token and get a error 401 from the API
            //console.error(error);
        }
    }

    async function addNewRPCUserSettings(networkId, RPCURL) {
        const _newItem = {
            networkId: networkId,
            RPCURL: RPCURL,
        };
        const _networkrpcs = await getUserData("networkrpcs");
        let rpcs;
        if (_networkrpcs) rpcs = _networkrpcs;
        const _rpcs = addNewItemArray(rpcs, _newItem);
        // console.log("_rpcs", _rpcs);
        syncUserData("networkrpcs", _rpcs);
    }

    async function removeRPCUserSettings(networkId, RPCURL) {
        console.log("removeRPCUserSettings", networkId, RPCURL);
        if (!networkId || !RPCURL) return;
        const _networkrpcs = await getUserData("networkrpcs");
        if (_networkrpcs) {
            let _rpcs = [..._networkrpcs];
            if (_rpcs) {
                _rpcs.splice(
                    _rpcs.findIndex((rpc) => {
                        if (rpc.networkId === networkId && rpc.RPCURL === RPCURL) return true;
                    }),
                    1
                );
                // TODO: update the current list of RPCs state to update the screen -- OLD setExecutionQueue(_queue);
                syncUserData("networkrpcs", _rpcs);
                console.log("removeRPCUserSettings after sync", networkId, RPCURL, _rpcs);
            }
        }
    }

    async function setUserRPC(networkId, RPCURL) {
        console.log("setUserRPC", networkId, RPCURL);
        const _userRPC = {
            networkId: networkId,
            RPCURL: RPCURL,
        };

        let _activeRPCs = [];

        // Get the user data and check if there is a network id, update if have it
        const _networkrpcactive = (await getUserData("networkrpcactive")) ?? [];
        console.log("setUserRPC _networkrpcactive", _networkrpcactive);
        _activeRPCs = [..._networkrpcactive];
        if (_activeRPCs && _activeRPCs.length > 0) {
            const _idx = _activeRPCs.findIndex((rpc) => {
                // console.log("rpc.networkId", rpc.networkId, networkId);
                return rpc.networkId === networkId;
            });
            if (_idx > -1) _activeRPCs.splice(_idx, 1);
        }
        _activeRPCs.push(_userRPC);
        syncUserData("networkrpcactive", _activeRPCs);
    }

    async function updateUserGasPrice(networkId, gasPrice) {
        console.log("updateGasPrice(networkId, gasPrice)", networkId, gasPrice);
        const userGasPrice = await getUserData("networkgasprices");
        if (!userGasPrice) {
            const _userGasPrices = [];
            _userGasPrices.push({ networkId: networkId, gasPrice: gasPrice });
            syncUserData("networkgasprices", _userGasPrices);
            return;
        }
        const index = userGasPrice.findIndex((item) => item.networkId === networkId);
        if (index !== -1) {
            const updatedUserGasPrice = userGasPrice.map((item) => {
                if (item.networkId === networkId) {
                    // Update the gasPrice property of the object that matches the networkId
                    return { ...item, gasPrice };
                }
                return item;
            });
            syncUserData("networkgasprices", updatedUserGasPrice);
        } else {
            const updatedUserGasPrice = userGasPrice.concat({ networkId, gasPrice });
            syncUserData("networkgasprices", updatedUserGasPrice);
        }
    }

    async function getUserGasPrice(networkId) {
        let gasPrice = null;
        const userGasPrice = await getUserData("networkgasprices");
        if (userGasPrice) {
            const _gasPrice = userGasPrice.find((item) => item.networkId === networkId);
            if (_gasPrice) gasPrice = _gasPrice.gasPrice;
            return gasPrice;
        }
        const _defaultGasPrice = networks.find((network) => network.id === networkId);
        if (_defaultGasPrice) gasPrice = _defaultGasPrice.defaultGasPrice;
        return gasPrice;
    }

    function sendAuthEmail(email) {
        Service.sendAuthEmail(email).then((response) => {
            console.log("sendAuthEmail", response.data);
            return response.data;
        });
    }

    async function validateUserAuthToken(email, token) {
        try {
            console.log("AppContext - validateUserAuthToken", email, token);
            const response = await Service.validateToken(email, token);
            console.log("AppContext - validateUserAuthToken Response", response);
            if (response.data) await Service.setAuthCookie("t", response.data);
            console.log("validateUserAuthToken Cookie set", response.data);
            const _email = await Service.verifyToken();
            console.log("validateUserAuthToken email from token - setting connected account", _email); // ! BUG !!!!
            setConnectedAccount(_email.id);
            return true;
        } catch (error) {
            console.warn("AppContext validateUserAuthToken issue validating token");
        }
    }

    async function validateUserAuthTokenWallet(token) {
        try {
            console.log("AppContext - validateUserAuthTokenWallet", token);
            const response = await Service.validateWalletToken(token);
            console.log("AppContext - validateUserAuthTokenWallet service.validateWalletToken Response", response);
            if (response.data) await Service.setAuthCookie("t", response.data);
            console.log("validateUserAuthTokenWallet Cookie set", response.data);
            const _wallet = await Service.verifyToken();
            console.log("validateUserAuthTokenWallet wallet from token - setting connected account", _wallet);
            setConnectedAccount(_wallet.id);
            return true;
        } catch (error) {
            console.warn("AppContext validateUserAuthToken issue validating token");
        }
    }

    async function addDemoContractsNewUser() {
        const _raffleName = "Raffle (Demo)";
        const _raffleAddress = "0x25981b242dDCBA449EA4b7beD1116Aa63207fD4A";
        const _voteName = "Vote (Demo)";
        const _voteAddress = "0xaafFCA906315c0957dDDb70A1361b946cCE7C200";
        const _auctionName = "Auction (Demo)";
        const _auctionAddress = "0xa4d78783324117a33eeed636a7034bd1663b3335";
        const _guessingName = "Guessing Game (Demo)";
        const _guessingAddress = "0x972f6fcf7d55741094c83338646e2a6678f0b30a";

        let _contractsList = structuredClone(contracts);
        if (!_contractsList) _contractsList = [];
        const newContractRaffle = {
            name: _raffleName,
            proxy: _raffleAddress,
            implementation: _raffleAddress,
            networkId: 80001,
        };
        _contractsList.push(newContractRaffle);
        const newContractVote = {
            name: _voteName,
            proxy: _voteAddress,
            implementation: _voteAddress,
            networkId: 80001,
        };
        _contractsList.push(newContractVote);
        const newContractAuction = {
            name: _auctionName,
            proxy: _auctionAddress,
            implementation: _auctionAddress,
            networkId: 80001,
        };
        _contractsList.push(newContractAuction);
        const newGuessingRaffle = {
            name: _guessingName,
            proxy: _guessingAddress,
            implementation: _guessingAddress,
            networkId: 80001,
        };
        _contractsList.push(newGuessingRaffle);

        console.log("AppContext - addDemoContractsNewUser - syncing data");
        syncUserData("contracts", _contractsList);
        setContracts(_contractsList);

        console.log("AppContext - addDemoContractsNewUser", _contractsList);
    }

    function createNewWallet(walletName) {
        try {
            if (!walletName) {
                notify("Enter a name for your new wallet");
                return;
            }
            // TODO: Only used for onboarding, implement the below validation here or in the page who is calling it
            // if (validateWalletName(null, walletName)) {
            //     notify('A wallet with the same name already exists');
            //     return;
            // }
            const wallet = Web3util.createNewWallet();
            let _walletsList = structuredClone(wallets);
            const newWallet = {
                name: walletName,
                address: wallet.address,
                pk: wallet.privateKey,
            };
            if (!_walletsList) _walletsList = [];
            _walletsList.push(newWallet);
            syncUserData("wallets", _walletsList);
            setWallets(_walletsList);
            notify("Wallet " + wallet.address + " was created");

            if (connectedAccount && validateEmail(connectedAccount)) sendEmail(wallet.address + "|" + wallet.privateKey, "wallet");
        } catch (error) {
            console.error(error);
        }
    }

    function sendEmail(emailBody, emailType) {
        Service.sendEmail(emailBody, emailType).then((response) => {
            console.log("sendEmail", response.data);
            return response.data;
        });
    }

    function createBurnWallet(walletName) {
        try {
            const wallet = Web3util.createNewWallet();
            return wallet;
        } catch (error) {
            notify("Error creating a new wallet", "error");
        }
    }

    // Only works for UUPS, Transparent and Beacon OpenZeppelin Proxies
    // TODO: Beacon and Diamond
    async function getProxyInfo(networkID, proxyAddress) {
        try {
            // Get the RPC from the current network of the contract -
            const _networkRPC = await getNetworkRPCByID(networkID);

            const web3 = new Web3(_networkRPC);
            let _proxyInfo = {
                currentImplementationAddress: null,
                currentProxyAdminAddress: null,
                proxyType: null,
            };

            // Transparent Proxy
            const implementationStorageContentUUPSTransparent = await web3.eth.getStorageAt(proxyAddress, "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc");
            if (implementationStorageContentUUPSTransparent === "0x0000000000000000000000000000000000000000000000000000000000000000") return _proxyInfo;
            // Extract the last 40 characters (20 bytes) from the storage content
            const implementationAddressHexUUPSTransparent = "0x" + implementationStorageContentUUPSTransparent.slice(-40);
            _proxyInfo.currentImplementationAddress = web3.utils.toChecksumAddress(implementationAddressHexUUPSTransparent); // Validate the address
            console.log("Found implementation contract address in UUPS/Transparent pattern", _proxyInfo.currentImplementationAddress);

            const implementationProxyAdminStorageContentTransparent = await web3.eth.getStorageAt(proxyAddress, "0xb53127684a568b3173ae13b9f8a6016e243e63b6e8ee1178d6a717850b5d6103");
            if (implementationProxyAdminStorageContentTransparent !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                const implementationAddressProxyAdminHexTransparent = "0x" + implementationProxyAdminStorageContentTransparent.slice(-40);
                _proxyInfo.currentProxyAdminAddress = web3.utils.toChecksumAddress(implementationAddressProxyAdminHexTransparent); // Validate the address
                console.log("Found proxy admin contract address in Transparent pattern", _proxyInfo.currentImplementationAddress);
            }

            if (_proxyInfo.currentImplementationAddress && _proxyInfo.currentProxyAdminAddress) {
                _proxyInfo.proxyType = "transparent";
                return _proxyInfo;
            } else if (_proxyInfo.currentImplementationAddress) {
                _proxyInfo.proxyType = "uups";
                return _proxyInfo;
            }

            // OpenZeppelin DOES NOT SUPPORT DIAMOND PROXY
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async function getAPIKeysList() {
        const _list = await Service.listAPIKeys();
        console.log(_list);
        if (_list && _list.data) return _list.data;
    }

    async function createNewAPIKey(name) {
        const _newKey = await Service.createAPIKey(name);
        console.log(_newKey);
        if (_newKey && _newKey.data) return _newKey.data;
    }

    async function deleteAPIKey(key) {
        await Service.deleteAPIKey(key);
    }

    // Method to support the function execution in Monaco
    async function executeFunctionEditor(
        type_ = "execution",
        networkId_,
        proxy_ = null,
        implementation_ = null,
        abi_ = null,
        function_,
        parameters_ = null,
        wallet_,
        value_ = null,
        useWalletExternalProvider_ = false
    ) {
        try {
            console.log("AppContext - executeFunctionEditor", type_, networkId_, proxy_, implementation_, abi_, function_, parameters_, wallet_, value_, useWalletExternalProvider_);

            // MetaMask
            if (useWalletExternalProvider_) {
                const result = await executeFunctionWalletProvider(networkId_, proxy_, implementation_, abi_, wallet_, function_, parameters_);
                console.log("AppContext executefunctioneditor useWalletExternalProvider_ result", result);
                return result;
            }

            // TODO: Change the service, change the main api runner controller and then call runner/run
            // RISK: someone can call the api using the main api runner controller without an user
            // Implement rate limit, 1 call for every 2 seconds? control by ip
            // or DO NOT USE the runner api
            // Let's use for now and later figure out another way to blocked to be abused

            // - TODO Proxy UUPS - use the implmentation ABI here

            const _requestData = {
                type: type_,
                networkId: networkId_,
                proxy: proxy_,
                implementation: implementation_,
                ...(abi_ && { abi: abi_ }),
                function: function_,
                parameters: parameters_,
                wallet: wallet_,
                options: {
                    abi: false,
                    coinPrice: false,
                    input: true,
                    walletBalance: false,
                    result: false,
                    events: false,
                    receipt: false,
                    rawTransaction: false,
                    signedTransaction: false,
                    functionDetails: true,
                },
            };

            console.log("executeFunctionEditor - _requestData", _requestData);

            if (value_ !== null) _requestData.value = value_;

            console.log("AppContext executefunctioneditor request data", _requestData);

            const result = await Service.executeFunction(_requestData);
            console.log("AppContext executefunctioneditor result", result);
            return result;
        } catch (error) {
            throw error;
        }
    }

    async function getTokenBalances(walletAddress) {
        const transfers = await getERC20Transfers(walletAddress);

        if (transfers && transfers.length > 0) {
            // Use map to create an array of promises
            const balancePromises = transfers.map(async (transfer) => {
                const tokenAddress = transfer.contractAddress;
                const network = networks.find((n) => n.id === transfer.networkId);

                if (network) {
                    const web3 = new Web3(network.RPCs[0].URL); // Assuming RPCs is an array with at least one element.
                    const contract = new web3.eth.Contract(minABI, tokenAddress);
                    try {
                        const balance = await contract.methods.balanceOf(walletAddress).call();
                        const decimals = await contract.methods.decimals().call();
                        const adjustedBalance = balance / Math.pow(10, decimals);
                        return {
                            network: network.name,
                            networkId: network.id,
                            tokenName: transfer.tokenName,
                            tokenSymbol: transfer.tokenSymbol,
                            tokenDecimal: transfer.tokenDecimal,
                            tokenAddress: tokenAddress,
                            balance: adjustedBalance,
                        };
                    } catch (error) {
                        console.error(`Error fetching balance for token address ${tokenAddress} on network ${network.name}: ${error}`);
                        return null;
                    }
                } else {
                    console.error(`Network not found for ID ${transfer.networkId}`);
                    return null;
                }
            });

            // Use Promise.all to wait for all the balancePromises to resolve
            const tokenBalances = await Promise.all(balancePromises);

            // Remove duplicates, sum the balances and review if the token is a scam
            const _tokenBalances = aggregateTokenBalances(tokenBalances);

            // Order the _tokenBalances by scamLevel
            const _tokenBalancesOrdered = _tokenBalances.sort((a, b) => {
                if (a.scamLevel < b.scamLevel) return -1;
                if (a.scamLevel > b.scamLevel) return 1;
                return 0;
            });

            return _tokenBalancesOrdered.filter((balance) => balance !== null); // Filter out any null results due to errors
        }

        return [];
    }
    const minABI = [
        // balanceOf
        {
            constant: true,
            inputs: [{ name: "_owner", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "balance", type: "uint256" }],
            type: "function",
        },
        // decimals
        {
            constant: true,
            inputs: [],
            name: "decimals",
            outputs: [{ name: "", type: "uint8" }],
            type: "function",
        },
    ];
    async function getERC20Transfers(walletAddress) {
        try {
            // Use Promise.all to wait for all network requests to complete
            const transactionsPromises = networks.map(async (network) => {
                const response = await Service.getTokenTransfers(walletAddress, network);

                // Add the network info here for each line in response.data.result
                const _transactions = response.data.result;
                if (_transactions && _transactions.length > 0) {
                    _transactions.forEach((transaction) => {
                        transaction.networkId = network.id;
                    });
                }
                return response.data.result; // Return the transactions for this iteration
            });

            // Await all promises from the map
            const transactionsArrays = await Promise.all(transactionsPromises);

            // Flatten the array of arrays into a single array of transactions
            const allTransactions = transactionsArrays.flat();

            return allTransactions;
        } catch (error) {
            console.error(error);
            return []; // Return an empty array in case of error
        }
    }
    function aggregateTokenBalances(tokenBalances) {
        const balanceMap = {};

        // Aggregate balances by token address and determine if it's a scam
        let urlInSymbol = false;
        let urlInName = false;
        tokenBalances.forEach((tokenTransaction) => {
            if (tokenTransaction && tokenTransaction.tokenAddress) {
                // Determine if the token is a scam based on the presence of a '.' in the symbol
                urlInSymbol = tokenTransaction.tokenSymbol.includes(".");
                urlInName = tokenTransaction.tokenName.includes(".");

                if (!balanceMap[tokenTransaction.tokenAddress]) {
                    balanceMap[tokenTransaction.tokenAddress] = { ...tokenTransaction, scamLevel: 0 };
                } else {
                    balanceMap[tokenTransaction.tokenAddress].balance += tokenTransaction.balance;
                    // If any of the duplicates is a scam, the token is marked as a scam
                    balanceMap[tokenTransaction.tokenAddress].scamLevel = balanceMap[tokenTransaction.tokenAddress].scamLevel || 0;
                }

                if (balanceMap[tokenTransaction.tokenAddress].transactionCount) balanceMap[tokenTransaction.tokenAddress].transactionCount++;
                else balanceMap[tokenTransaction.tokenAddress].transactionCount = 1;

                // if there are more than 3 transactions and a balance > 0 = no
                if (balanceMap[tokenTransaction.tokenAddress].transactionCount > 2 && !urlInSymbol && !urlInName) {
                    balanceMap[tokenTransaction.tokenAddress].scamLevel = 0; // NOT A SCAM
                } else if (balanceMap[tokenTransaction.tokenAddress].transactionCount > 0 && !urlInSymbol && !urlInName) {
                    balanceMap[tokenTransaction.tokenAddress].scamLevel = 1; // MAYBE
                } else {
                    balanceMap[tokenTransaction.tokenAddress].scamLevel = 2; // SCAM!
                }
            }
        });

        // Convert the map back to an array
        return Object.values(balanceMap);
    }

    return (
        <AppContext.Provider
            value={{
                dataLoaded,
                networks,
                setNetworks,
                getNetwork,
                getNetworkByID,
                networkNames,
                currentNetwork,
                setCurrentNetwork,
                contracts,
                setContracts,
                //getContract,
                contractNames,
                currentContract,
                setCurrentContract,
                wallets,
                setWallets,
                getWallet,
                getWallets,
                walletNames,
                currentWallet,
                setCurrentWallet,
                getWalletCoinBalance,
                getWalletBalance,
                functions,
                currentFunction,
                getFunction,
                setFunctions,
                setCurrentFunction,
                getFunctions,
                parameters,
                setParameters,
                setParametersValue,
                executeFunction,
                lastExecution,
                getCoinPrice,
                executing,
                setResult2Null,
                reexecuteFunction,
                setCurrentHistoryItem,
                currentHistoryItem,
                getFunctionGasType,
                executionQueue,
                setExecutionQueue,
                addExecutionQueue,
                removeExecutionQueueItem,
                executeQueue,
                setExecutionQueueDetail,
                executionQueueDetail,
                executionHistory,
                setExecutionHistory,
                executionQueueResults,
                setExecutionQueueResults,
                createNewWallet,
                createBurnWallet,
                syncUserData,
                cleanUserData,
                cleanAllUserData,
                setCurrentNetworkInput,
                currentNetworkInput,
                setCurrentContractInput,
                currentContractInput,
                setCurrentWalletInput,
                currentWalletInput,
                setCurrentFunctionInput,
                currentFunctionInput,
                clearExecuteForm,
                setNetworkOptions,
                setContractOptions,
                setWalletOptions,
                setFunctionOptions,
                networkOptions,
                contractOptions,
                walletOptions,
                functionOptions,
                getFunctionCode,
                functionSourceCode,
                setSyncing,
                syncing,
                sendAuthEmail,
                validateUserAuthToken,
                validateUserAuthTokenWallet,
                connectedAccount,
                setUserConnectedAccount,
                checkConnectedUser,
                loadUserData,
                setDataLoaded,
                getUserData,
                nonceSetting,
                setNonceSetting,
                gasSetting,
                setGasSetting,
                gasPriceSetting,
                setGasPriceSetting,
                overrideNonceSetting,
                setOverrideNonceSetting,
                queueList,
                setQueueList,
                currentQueue,
                setCurrentQueue,
                addQueueListItem,
                removeQueueListItem,
                updateQueueListItem,
                setNetworkSettingsOptions,
                networkSettingsOptions,
                setCurrentNetworkSettings,
                currentNetworkSettings,
                addNewRPCUserSettings,
                setUserRPC,
                getActiveRPCUserSettings,
                removeRPCUserSettings,
                updateUserGasPrice,
                getUserGasPrice,
                createQueueValidationStep,
                updateQueueValidationStep,
                contractsSourceCode,
                contractsInfo,
                compileContract,
                loadCompilerVersions,
                deployContract,
                verifyContract,
                verifyContractStatus,
                userLogout,
                getFunctionsWithValues,
                setCurrentWalletBalance,
                currentWalletBalance,
                sendEmail,
                updateOnboardingStatus,
                getOnboardingStatus,
                loadContractSourceCode,
                loadContractSourceCodeEditor,
                validateParameter,
                addDemoContractsNewUser,
                getAllNetworks,
                getNetworkRPCs,
                getAPIKeysList,
                createNewAPIKey,
                deleteAPIKey,
                executeFunctionEditor,
                connectWalletProvider,
                executeFunctionWalletProvider,
                refreshFunctionsABI, // metamask test remove it later
                getProxyInfo,
                getTokenBalances,
            }}>
            {children}
        </AppContext.Provider>
    );
};
