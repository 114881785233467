import { useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { Web3util } from "@util/web3util";
import { notify } from "@util/component";
//import { saveDataStorage, getDataStorage } from "@util/util";
//import { LocalStorage } from "@util/enum";
import ValidationMessage from "@components/shared/ValidationMessage";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

/*
How to use: Add this code in the form that you want call this modal
Most important is the htmlFor="manageContract" prop.
<label htmlFor="manageContract" className="btn bg-transparent border-transparent m-0 p-0 hover:bg-transparent hover:border-transparent">
    <AddIcon className="ml-2 text-secondary cursor-pointer" htmlFor="manageContract" />
</label>
*/
function ManageContract() {
    // Get the current contracts in the appcontext
    const { contracts, getContractNames, setContracts, syncUserData, currentNetwork, getProxyInfo } = useContext(AppContext);

    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        // Name - At least 3 characters and check if the name already exists
        if (event.target.name == "name") {
            if (event.target.value.length < 3) {
                updateValidationMessages(event, "Contract name should have at least 3 characters");
                return;
            }
            const _exists = validateContractName(event);
            if (!_exists) updateValidationMessages(event, null);
        }

        if (event.target.name == "proxy" || event.target.name == "implementation") {
            if (event.target.value && !Web3util.isAddress(event.target.value)) {
                updateValidationMessages(event, "Address is invalid");
                return;
            } else {
                updateValidationMessages(event, null);
            }

            const _exists = validateContractAddress(event);
            if (!_exists) updateValidationMessages(event, null);
        }
    };

    const validateContractName = (event) => {
        if (!event.target.value) return;
        let _exists = false;
        contracts &&
            contracts.forEach((contract) => {
                const contractName = event.target.value.toLowerCase();
                if (contract.name.toLowerCase() === contractName) {
                    if (event) updateValidationMessages(event, "A Contract with this name already exists");
                    _exists = true;
                    return;
                }
            });
        return _exists;
    };

    const validateContractAddress = (event) => {
        if (!event.target.value) return;
        let _exists = false;
        contracts &&
            contracts.forEach((contract) => {
                const contractAddress = event.target.value.toLowerCase();
                if (event.target.name === "proxy") {
                    if (contract.proxy && contract.proxy.toLowerCase() === contractAddress) {
                        if (event) updateValidationMessages(event, "A Contract with this proxy address already exists");
                        _exists = true;
                        return;
                    }
                }
                if (event.target.name === "implementation") {
                    if (contract.implementation.toLowerCase() === contractAddress) {
                        if (event) updateValidationMessages(event, "A Contract with this implementation address already exists");
                        _exists = true;
                        return;
                    }
                }
            });
        return _exists;
    };

    const addContract = () => {
        if (!checkValidationMessagesIsEmpty()) {
            notify("Check the validations messages below and try again", "warn");
            return;
        }
        if (!inputValues["name"] || !inputValues["implementation"]) {
            notify("Name and Implementation are required", "warn");
            return;
        }
        let _contractsList = structuredClone(contracts);
        const newContract = {
            name: inputValues["name"],
            proxy: inputValues["proxy"],
            implementation: inputValues["implementation"],
            networkId: currentNetwork.id,
        };
        _contractsList.push(newContract);
        // saveDataStorage(LocalStorage.ContractList, _contractsList);
        syncUserData("contracts", _contractsList);
        setContracts(_contractsList);

        console.log("ManageContract popup _contractsList", _contractsList);

        resetInputValues();
    };

    const checkValidationMessagesIsEmpty = () => {
        let _isEmpty = true;
        Object.entries(validationMessages).forEach((_message) => {
            const [key, value] = _message;
            if (value != null) _isEmpty = false;
        });
        return _isEmpty;
    };

    const resetInputValues = () => {
        setInputValues({
            name: "",
            proxy: "",
            implementation: "",
        });
    };

    const removeContract = (name) => {
        let _contractsList = structuredClone(contracts);
        _contractsList = _contractsList.filter((contract) => contract.name !== name);
        //saveDataStorage(LocalStorage.ContractList, _contractsList);
        syncUserData("contracts", _contractsList);
        setContracts(_contractsList);
    };

    // TODO: use getProxyInfo
    async function getProxy() {
        if (inputValues["proxy"]) {
            let _proxyInfo = await getProxyInfo(currentNetwork.id, inputValues["proxy"]);
            console.log(_proxyInfo);
            if (_proxyInfo) {
                setInputValues((prevValues) => {
                    return {
                        ...prevValues,
                        ["implementation"]: _proxyInfo.currentImplementationAddress,
                    };
                });
                // }
                // let _implementation = await getImplementationFromProxy(inputValues["proxy"]);
                // if (_implementation) {
                //     _implementation = Web3util.removeLeadingZeros(_implementation);
                //     setInputValues((prevValues) => {
                //         return {
                //             ...prevValues,
                //             ["implementation"]: _implementation,
                //         };
                //     });
            } else {
                notify("Implementation contract was not found for this Proxy address");
            }
        }
    }

    return (
        <>
            <input type="checkbox" id="manageContract" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box min-w-fit">
                    <h3 className="font-bold text-lg text-white">Contracts</h3>
                    {/* <p className="py-4 text-white">Available Contracts</p>
                    {contracts && contracts.map((contract) => {
                        return (
                            <div key={contract.name}>
                                <p className="flex text-secondary items-center h-8">
                                    <LockOutlinedIcon className="max-h-4 mr-2" />
                                    {contract.name}
                                    <DeleteOutlinedIcon
                                        className="ml-2 max-h-5 text-secondary cursor-pointer"
                                        onClick={() => {
                                            removeContract(contract.name);
                                        }}
                                    />
                                </p>
                                <pre className="pt-1 px-2 text-sm bg-black text-light">{JSON.stringify(contract, null, "\t")}</pre>
                            </div>
                        );
                    })} */}
                    <div className="flex-row pt-4 items-center">
                        <p className="font-semibold py-2 text-white">Add a Contract</p>
                        <p className="pt-2 pb-1 text-secondary">Name</p>
                        <input type="text" name="name" maxLength={45} autoComplete="new-password" onChange={handleInputChange} value={inputValues["name"] || ""} />
                        {validationMessages["name"] && <ValidationMessage message={validationMessages["name"]} />}
                        <p className="pt-2 pb-1 text-secondary">Proxy Address</p>
                        <input type="text" name="proxy" maxLength={50} autoComplete="new-password" onChange={handleInputChange} value={inputValues["proxy"] || ""} />
                        {validationMessages["proxy"] && <ValidationMessage message={validationMessages["proxy"]} />}
                        <p className="pt-2 pb-1 text-secondary">
                            Implementation Address
                            <FontAwesomeIcon
                                icon={faDownload}
                                className="pl-2 cursor-pointer"
                                onClick={() => {
                                    getProxy();
                                }}
                            />
                        </p>
                        <input type="text" name="implementation" maxLength={50} autoComplete="new-password" onChange={handleInputChange} value={inputValues["implementation"] || ""} />
                        {validationMessages["implementation"] && <ValidationMessage message={validationMessages["implementation"]} />}
                    </div>
                    <div className="modal-action gap-4 pt-12">
                        <label htmlFor="manageContract" className="btn-default">
                            CLOSE
                            <CloseOutlined className="pl-2" />
                        </label>
                        {/* <label
                            className="btn-default"
                            onClick={() => {
                                addContract();
                            }}>
                            {" "}
                            ADD MORE
                            <AddTaskOutlinedIcon className="pl-2" />
                        </label> */}
                        <label
                            htmlFor={checkValidationMessagesIsEmpty() ? "manageContract" : undefined}
                            className="btn-default"
                            onClick={() => {
                                addContract();
                            }}>
                            ADD
                            <AddIcon className="pl-2" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageContract;
