import SearchFunction from "./SearchFunction";
import Parameters from "./parameter/Parameters";

const FunctionSection = () => {
    return (
        <>
            <SearchFunction />
            <Parameters />
        </>
    );
};

export default FunctionSection;
