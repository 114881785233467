import { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "@context/AppContext";
import SearchbarDropdown from "./SearchbarDropdown";
import AddIcon from "@mui/icons-material/Add";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import MoneyOffCsredOutlinedIcon from "@mui/icons-material/MoneyOffCsredOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// ref: https://github.com/HuakunShen/demo/blob/master/React/search-bar/src/App.jsx

function SearchFunction() {
    const { functions, setCurrentFunctionInput, currentContract, functionOptions, setFunctionOptions, getFunctionCode, functionSourceCode, getFunctionsWithValues, getFunctions } =
        useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (functions.length > 0) {
            setFunctionOptions(functions);
            // - Call getFunctionsWithValues
            //loadContractsWithValues(); // ISSUE: The values overtime are not updated - FIX IT LATER
            // getFunctionsWithValues();
        }
    }, [functions]);

    async function loadContractsWithValues() {
        const _functionsWithValues = await getFunctionsWithValues();
        setFunctionOptions(_functionsWithValues);
    }

    const onInputChange = (event) => {
        setFunctionOptions(functions.filter((option) => option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
        setCurrentFunctionInput(event.target.value);
    };

    function showSourcecode() {
        navigate("/editor?network=" + currentContract.networkId + "&contract=" + currentContract.proxy ?? currentContract.implementation);
    }

    function RefreshFunctionsList() {
        if (currentContract) getFunctions();
    }

    return (
        <>
            <div className="pt-4 items-center">
                <p className="text-white">Function</p>
                <div className="flex items-start pt-2">
                    <SearchbarDropdown
                        options={functionOptions}
                        onInputChange={onInputChange}
                        placeholder={functions && functions.length > 0 ? "Search Function" : "Select a published contract to list the functions"}
                        setOptions={setFunctionOptions}
                    />
                    <FontAwesomeIcon
                        id="ExecuteSearchFunctionShowSourceCodeButton"
                        name="ExecuteSearchFunctionShowSourceCodeButton"
                        icon={faCode}
                        className="ml-2 mt-3 text-secondary cursor-pointer max-h-5 min-h-5"
                        onClick={() => {
                            showSourcecode();
                        }}
                    />
                    {functions.length === 0 && currentContract && (
                        <FontAwesomeIcon
                            id="ExecuteSearchFunctionRefreshFunctionsListButton"
                            name="ExecuteSearchFunctionRefreshFunctionsListButton"
                            icon={faRefresh}
                            className="ml-2 mt-3 text-secondary cursor-pointer max-h-5 min-h-5"
                            onClick={() => {
                                RefreshFunctionsList();
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default SearchFunction;
