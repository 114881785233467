import { useEffect, useRef, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { getImageUrl, getImageUrlWithExt, timeSince, dateDefaultFormat } from "@util/util";
import { saveDataStorage, getDataStorage } from "@util/util";
import { LocalStorage } from "@util/enum";

function PopupHistorySearchbarDropdownHistory(props) {
    // const { networks, clearExecuteForm } = useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions, currentHistoryInput, setCurrentHistoryInput, setCurrentHistory } = props;
    const ulRef = useRef();
    const inputRef = useRef();

    // TODO: Check it later, legacy from network popup
    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });
    }, [options]);

    return (
        <div className="dropdown dropdown-bottom w-full">
            <div className="flex flex-row items-center justify-center">
                <span className="text-secondary">Search:</span>
                <input
                    className="input-image ml-1 border-black h-8 pl-1 cursor-pointer"
                    type="text"
                    // style={{ backgroundImage: `${getImageUrlWithExt(currentNetwork?.logo, "webp", true)}` }}
                    style={{ backgroundColor: "#1f1c1a", border: "none" }}
                    placeholder={placeholder}
                    ref={inputRef}
                    onChange={onInputChange}
                    autoComplete="new-password"
                    value={currentHistoryInput || ""}
                    spellCheck="false"
                    // onMouseOver={(event) => (event.target.style.backgroundColor = "#3d2413")}
                    // onMouseOut={(event) => (event.target.style.backgroundColor = "#1f1c1a")}
                />
            </div>
            <ul id="results" className="ul-main" ref={ulRef}>
                {options &&
                    options.map((option, index) => {
                        return (
                            <button
                                id="PopupHistorySearchbarDropdownHistorySelectItem"
                                name="PopupHistorySearchbarDropdownHistorySelectItem"
                                data-info={option.address}
                                type="button"
                                key={index}
                                onClick={(e) => {
                                    setCurrentHistoryInput(option.address);
                                    ulRef.current.style.display = "none";
                                    setCurrentHistory(option);
                                    // saveDataStorage(LocalStorage.NetworkEditorSelected, option);
                                    setOptions(options.filter((_option) => _option.address.toLocaleLowerCase().includes(option.address.toLocaleLowerCase())));
                                    console.debug("Current deployment info:", option);
                                }}
                                className="ul-item h-6">
                                {/* <img className="h-5 inline pr-2 " src={`${getImageUrlWithExt(option.logo)}`} alt={option.name} />  USE network in the cookie to show the logo*/}
                                {option.network} -{option.address} -{dateDefaultFormat(option.date)} ({timeSince(option.date)})
                            </button>
                        );
                    })}
            </ul>
        </div>
    );
}

export default PopupHistorySearchbarDropdownHistory;
