import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("t");
// console.log("Service render");

export const Service = {
    getUserData: async (type) => {
        const _token = Cookies.get("t");
        // console.log("Service.getUserData - type", type, "token", token);
        try {
            if (!type) {
                console.warn("getUserData - Can't get user data without account and data type");
                return;
            }

            const _requestData = {
                //service: "userData",
                type: type,
            };

            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "sync",
                params: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            // console.log("Service.getUserData - API Request", request);

            const response = await axios(request);
            return response;
        } catch (error) {
            console.warn("Exception calling API syncing user data", error);
        }
    },

    syncUserData: (type, data) => {
        console.log("Service.syncUserData", type, data);
        const _token = Cookies.get("t");
        console.log("Service.syncUserData _token", _token);
        if (!type || !data) {
            console.warn("syncUserData - Can't sync user data without account, data type and data");
            return;
        }

        const _requestData = {
            service: "userData",
            type: type,
            data: data,
        };

        const request = {
            method: "post",
            url: import.meta.env.VITE_API_ENDPOINT + "sync",
            data: _requestData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${_token}`,
            },
        };

        console.log("Service.syncUserData - API Request", request);

        axios(request)
            .then((response) => {
                console.debug("Syncing User Data (", type, ")");
            })
            .catch((error) => {
                console.warn("Exception calling API syncing user data", error);
            });
    },

    cleanUserData: (account, type) => {
        const _token = Cookies.get("t");
        if (!account || !type) {
            console.warn("cleanUserData - Can't clean user data without account and data type");
            return;
        }

        const _requestData = {
            service: "userDataClean",
            type: type,
        };

        const request = {
            method: "post",
            url: import.meta.env.VITE_API_ENDPOINT + "sync",
            data: _requestData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${_token}`,
            },
        };

        //console.log("Service.getUserData - API Request", request);

        axios(request)
            .then((response) => {
                // console.log("response", response);
                // console.log("response data", response.data);
                return response;
            })
            // .then((result) => console.log(result.data))
            .catch((error) => {
                console.warn("Exception calling API syncing user data", error);
            });
    },

    encryptData: (value) => {
        return new Promise(async (resolve, reject) => {
            if (!value) {
                console.warn("encryptData - Can't execute function without a value");
                resolve();
            }

            const _requestData = {
                value: value,
            };

            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "hash",
                params: _requestData,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            //console.log("Service.getUserData - API Request", request);

            axios(request)
                .then((response) => {
                    console.log("encryptData - response", response);
                    // console.log("response data", response.data);
                    // return response;
                    resolve(response);
                })
                // .then((result) => console.log(result.data))
                .catch((error) => {
                    console.warn("Exception calling API syncing user data", error);
                    reject();
                });
        });
    },

    sendAuthEmail: (email) => {
        return new Promise(async (resolve, reject) => {
            if (!email) {
                console.warn("sendAuthEmail - Can't execute function without an email");
                reject("sendAuthEmail - Can't execute function without an email");
            }

            const _requestData = {
                email: email,
            };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "auth",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            //console.log("Service.sendAuthEmail - API Request", request);

            axios(request)
                .then((response) => {
                    console.log("sendAuthEmail - response", response);
                    resolve(response);
                })
                .catch((error) => {
                    console.warn("Exception calling API sending auth email", error);
                    reject();
                });
        });
    },

    validateToken: async (email, token) => {
        console.log("Service validateToken", email, token);
        // return new Promise(async (resolve, reject) => {
        if (!email || !token) {
            console.warn("validateToken - Can't validate a token without an email and token");
            // resolve();
            return;
        }

        const _requestData = {
            t: token,
            e: email,
        };

        const request = {
            method: "GET",
            url: import.meta.env.VITE_API_ENDPOINT + "auth",
            params: _requestData,
            headers: {
                "Content-Type": "application/json",
            },
        };

        console.log("Service.validateToken - API Request", request);

        try {
            const response = await axios(request);
            console.log("Service.validateToken response", response);
            if (response) return response;
        } catch (error) {
            console.warn("Exception calling API verifing token", error);
            throw error;
        }
    },

    verifyToken: async (token_) => {
        let _token = Cookies.get("t");
        // console.log("Service verifyToken", _token, token_);
        if (!_token) _token = token_;
        // return new Promise(async (resolve, reject) => {
        if (!_token) {
            console.warn("verifyToken - Token not found - User not authenticated");
            // resolve();
            return;
        }

        const _requestData = {
            t: _token,
        };

        const request = {
            method: "GET",
            url: import.meta.env.VITE_API_ENDPOINT + "auth/verify",
            params: _requestData,
            headers: {
                "Content-Type": "application/json",
            },
        };

        // console.log("Service.verifyToken - API Request", request);

        try {
            const response = await axios(request);
            if (response && response.data) return response.data;
            //else throw new Error("Exception calling API verifing token");
        } catch (error) {
            console.warn("Exception calling API verifing token", error);
        }
    },

    validateWalletToken: async (token_) => {
        if (!token_) {
            console.log("validateWalletToken - Token not provided");
            return;
        }

        const _requestData = {
            token: token_,
        };

        const request = {
            method: "POST",
            url: import.meta.env.VITE_API_ENDPOINT + "auth/token",
            data: _requestData,
            headers: {
                "Content-Type": "application/json",
            },
        };

        console.log("Service.validateWalletToken - API Request", request);

        try {
            const response = await axios(request);
            console.log("Service.validateWalletToken response", response);
            if (response) return response;
        } catch (error) {
            console.warn("Exception calling API validating wallet token", error);
            throw error;
        }
    },

    setAuthCookie: async (key, value, expiration = 365) => {
        console.log("setAuthCookie", key, value, expiration);
        Cookies.set(key, value, { expires: expiration, secure: true, sameSite: "strict", path: "/" });
    },

    getAllNetworks: async (networkId, mainnet = false) => {
        try {
            const _token = Cookies.get("t");
            const _requestData = {
                networkId: networkId,
                includeMainnet: mainnet,
            };

            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/networks",
                ...(_requestData && { params: _requestData }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            //console.log("getAllNetworks - response", response);
            if (response.data) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: getAllNetworks", error);
        }
    },

    getUserRPCs: async (networkId) => {
        try {
            const _token = Cookies.get("t");
            const _requestData = {
                networkId: networkId,
            };

            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/networkRPCs",
                ...(_requestData && { params: _requestData }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            console.log("getUserRPCs - response", response);
            if (response.data) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: getUserRPCs", error);
        }
    },

    loadSourcecode: async (networkId, implementation) => {
        try {
            const _token = Cookies.get("t");
            if (!networkId || !implementation) {
                console.warn("loadSourcecode - Can't execute function without networkId and contract implementation address");
                return;
            }

            const _requestData = {
                networkId: networkId,
                implementation: implementation,
            };

            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/code",
                params: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            // console.log("SERVICE - loadSourcecode - request", request);

            const response = await axios(request);
            // console.log("SERVICE - loadSourcecode - response", response);
            if (response.data) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: loadSourcecode", error);
        }
    },

    getFunctions: async (networkId, implementation) => {
        // console.log("Service.getFunctions", networkId, implementation);
        try {
            const _token = Cookies.get("t");
            if (!networkId || !implementation) {
                console.warn("getFunctions - Can't execute function without networkId and contract implementation address");
                return;
            }

            const _requestData = {
                networkId: networkId,
                implementation: implementation,
            };

            //const token = Cookies.get("t");
            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/functions",
                params: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            // console.log("getFunctions - response", response);
            if (response.data) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: getFunctions", error);
        }
    },

    getCoinPrice: async (coinSymbol) => {
        try {
            // https://api.binance.us/api/v3/avgPrice?symbol=BNBUSD
            // https://api.binance.us/api/v3/avgPrice?symbol=MATICUSD

            //console.log("Getting prices for", coinSymbol);

            const response = await axios.get(`https://api.binance.us/api/v3/avgPrice?symbol=${coinSymbol}USD`);
            if (response.status === 200) {
                return response.data.price;
            } else return null;
        } catch (error) {
            return null;
        }
    },

    compileContract: async (contracts, solidityVersion, optimizationUsed, optimizationRuns) => {
        try {
            const _token = Cookies.get("t");
            if (!contracts || optimizationUsed === undefined || !optimizationRuns) {
                console.warn("compileContract - Can't compile without contract name, code and optimization parameters");
                return;
            }

            const _requestData = {
                contracts: contracts,
                solidityVersion: solidityVersion,
                optimizationUsed: optimizationUsed,
                optimizationRuns: optimizationRuns,
            };

            //const token = Cookies.get("t");
            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/compile",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: compileContract", error);
            throw error;
        }
    },

    deployContract: async (parameters, abi, bytecode, wallet, networkRPC, isProxy, solidityVersion, optimizationUsed, optimizationRuns) => {
        try {
            const _token = Cookies.get("t");
            // console.log("service deployContract", parameters, abi, bytecode, wallet, networkRPC, isProxy);
            if (!abi || !bytecode || !wallet || !networkRPC) {
                console.warn("deployContract - Can't deploy the contract without all data");
                return;
            }

            const _requestData = {
                parameters: parameters,
                abi: abi,
                bytecode: bytecode,
                wallet: wallet,
                networkRPC: networkRPC,
                isProxy: isProxy,
                solidityVersion: solidityVersion,
                optimizationUsed: optimizationUsed,
                optimizationRuns: optimizationRuns,
            };

            //const token = Cookies.get("t");
            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/deploy",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            // console.log("deployContract - response", response);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: deployContract", error);
            throw error;
        }
    },

    verifyContract: async (networkId, contractImplementationAddress, contractName, contractSourceCode, contractContructorArguments = "", solidityVersion, optimizationUsed, optimizationRuns) => {
        // console.log(
        //     "service - verifyContract",
        //     networkId,
        //     contractImplementationAddress,
        //     contractName,
        //     contractSourceCode,
        //     contractContructorArguments,
        //     solidityVersion,
        //     optimizationUsed,
        //     optimizationRuns
        // );
        try {
            const _token = Cookies.get("t");
            if (!networkId || !contractImplementationAddress || !contractSourceCode || !solidityVersion || optimizationUsed === undefined || !optimizationRuns) {
                console.warn("Service - verifyContract - Can't verify a contract without all parameters");
                return;
            }

            const _requestData = {
                networkId: networkId,
                contractImplementationAddress: contractImplementationAddress,
                contractName: contractName,
                contractSourceCode: contractSourceCode,
                contractContructorArguments: contractContructorArguments,
                solidityVersion: solidityVersion,
                optimizationUsed: optimizationUsed,
                optimizationRuns: optimizationRuns,
            };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/verify",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: verifyContract", error);
            throw error;
        }
    },

    verifyContractStatus: async (networkId, guid) => {
        try {
            const _token = Cookies.get("t");
            if (!networkId || !guid) {
                console.warn("Service - verifyContractStatus - Can't verify a contract without all parameters");
                return;
            }

            const _requestData = {
                networkId: networkId,
                guid: guid,
            };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/verifyStatus",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: verifyContractStatus", error);
        }
    },

    loadCompilerVersions: async () => {
        // console.log("service - loadCompilerVersions");
        try {
            const _token = Cookies.get("t");
            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "web3/compilerversions",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            const response = await axios(request);
            // console.log("loadCompilerVersions - response", response);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: loadCompilerVersions", error);
        }
    },

    sendEmail: async (emailBody, emailType) => {
        try {
            const _token = Cookies.get("t");
            console.log("service.sendEmail _token", _token);
            if (!emailBody || !emailType) {
                console.warn("sendEmail - Can't execute function without the correct parameters");
                reject("sendEmail - Can't execute function without the correct parameters");
            }

            const _requestData = {
                emailBody: emailBody,
                emailType: emailType,
            };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "email/send",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            console.log("Service.sendEmail - API Request", request);

            const response = await axios(request);
            console.log("sendEmail - response", response);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: sendEmail", error);
        }
    },
    onboardingUpdate: async () => {
        const _token = Cookies.get("t");
        try {
            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "sync/onboarding",
                // data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            //console.log("Service.onboardingUpdate - API Request", request);

            const response = await axios(request);
            // console.log("onboardingUpdate - response", response);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: onboardingUpdate", error);
        }
    },
    onboardingStatus: async (userEmail) => {
        try {
            const _requestData = {
                user: userEmail,
            };

            const request = {
                method: "GET",
                url: import.meta.env.VITE_API_ENDPOINT + "sync/onboarding",
                params: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: `bearer ${token}`,
                },
            };

            console.log("Service.onboardingUpdate - API Request", userEmail, request);

            const response = await axios(request);
            console.log("onboardingUpdate - response", response);
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.warn("Exception calling API method: onboardingStatus", error);
        }
    },
    listAPIKeys: async () => {
        const _token = Cookies.get("t");
        try {
            // const _requestData = {
            //     //service: "userData",
            // };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "runner/listkeys",
                //params: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            console.log("Service.listAPIKeys - API Request", request);

            const response = await axios(request);
            return response;
        } catch (error) {
            console.warn("Exception calling API listAPIKeys", error);
        }
    },
    createAPIKey: async (name) => {
        const _token = Cookies.get("t");
        try {
            const _requestData = {
                name: name,
            };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "runner/addkey",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            console.log("Service.createAPIKey - API Request", request);

            const response = await axios(request);
            return response;
        } catch (error) {
            console.warn("Exception calling API createAPIKey", error);
        }
    },
    deleteAPIKey: async (key) => {
        const _token = Cookies.get("t");
        try {
            const _requestData = {
                key: key,
            };

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "runner/deletekey",
                data: _requestData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${_token}`,
                },
            };

            console.log("Service.deleteAPIKey - API Request", request);

            const response = await axios(request);
            return response;
        } catch (error) {
            console.warn("Exception calling API deleteAPIKey", error);
        }
    },
    executeFunction: async (data_) => {
        // const _token = Cookies.get("t");
        try {
            // const _requestData = data_;

            const request = {
                method: "POST",
                url: import.meta.env.VITE_API_ENDPOINT + "runner/execute",
                data: data_,
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: `bearer ${_token}`,
                },
            };

            console.log("Service.executeFunction - API Request", request);

            const response = await axios(request);
            console.log("Service.executeFunction - API response", response);

            return response;
        } catch (error) {
            console.warn("Exception calling API executeFunction", error);
            throw error;
        }
    },
    getTokenTransfers: async (walletAddress, network) => {
        try {
            const url = `${network.blockExplorerAPIURL}/api?module=account&action=tokentx&address=${walletAddress}&startblock=0&endblock=999999999&sort=asc&apikey=${network.blockExplorerAPIKey}`;

            const request = {
                method: "GET",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            // console.log("Service.getTokenTransfers - API Request", request);

            const response = await axios(request);
            // console.log("Service.getTokenTransfers - API response", response);

            return response;
        } catch (error) {
            console.warn("Exception calling API getTokenTransfers", error);
            throw error;
        }
    },
};
