import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const MESSAGE_LETTER_DURATION_FACTOR = 40; // Average time to read a letter (word with 5 letters)

export const notify = (message, type = "info") => {
    const messageLength = message.length;
    let duration = messageLength * MESSAGE_LETTER_DURATION_FACTOR;
    if (duration < 3000) duration = 3000;
    if (type === "info") toast.info(message, { autoClose: duration });
    if (type === "warn") toast.warn(message, { autoClose: duration });
    if (type === "error") toast.error(message, { autoClose: duration });
};
export const NotifyContainer = () => {
    return <ToastContainer position="top-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} draggable theme="dark" />;
};
