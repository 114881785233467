import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import HistoryItem from "../../../components/history/HistoryItem";
//import { saveDataStorage, getDataStorage, removeDataStorage, sortArrayByDate } from "../../util/util";
import { FunctionGasType } from "../../../util/enum";
import { useFetcher, useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

const History = (props) => {
    const { behavior } = props;
    const { executionHistory, setExecutionHistory, cleanUserData, currentNetwork } = useContext(AppContext);
    const [historyList, setHistoryList] = useState([]);
    // let _history;// = getDataStorage(LocalStorage.ExecutionHistory);
    const navigate = useNavigate();

    // console.log("History render", currentNetwork);

    useEffect(() => {
        if (currentNetwork && executionHistory) setHistoryList(executionHistory.filter((option) => option.network.name === currentNetwork.name).slice(0, 10));
    }, [executionHistory]);

    // useEffect(() => {
    //     // TODO: Call api and get the data
    //     setExecutionHistory(getDataStorage(LocalStorage.ExecutionHistory));
    // }, [])

    useEffect(() => {
        if (currentNetwork && executionHistory) setHistoryList(executionHistory.filter((option) => option.network.name === currentNetwork.name).slice(0, 10));
    }, [currentNetwork]);

    function cleanHistory() {
        // _history = null;
        //removeDataStorage(LocalStorage.ExecutionHistory);
        // TODO: Call api and clear the history
        cleanUserData("executionhistory");
        setExecutionHistory([]);
    }

    return (
        <>
            <div className="text-white font-semibold text-xl">
                History
                <div className="pt-4 items-center">
                    <button
                        id="ExecuteHistoryQueueButton"
                        name="ExecuteHistoryQueueButton"
                        className="btn-default"
                        onClick={() => {
                            navigate("/queuelist");
                        }}>
                        Execution Queue
                        <FormatListNumberedIcon className="pl-2" />
                    </button>
                    <button
                        id="ExecuteHistoryCleanButton"
                        name="ExecuteHistoryCleanButton"
                        className="btn-default ml-4"
                        onClick={() => {
                            cleanHistory();
                        }}>
                        Clean History
                        <DeleteForeverIcon className="pl-2" />
                    </button>
                </div>
            </div>
            {executionHistory &&
                historyList.map((history_) => {
                    return (
                        <div key={history_.uuid} className="mt-4">
                            <HistoryItem historyItem={history_} behavior={behavior} />
                        </div>
                    );
                })}
        </>
    );
};

export default History;
