import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import ValidationMessage from "@components/shared/ValidationMessage";

const Parameters = () => {
    const { functions, functionNames, currentFunction, getFunctionSignature, currentNetwork, parameters, setParameters, setParametersValue, validateParameter } = useContext(AppContext);
    const [validationMessages, setValidationMessages] = useState();

    // TODO: Validation - Input data type
    // const updateValidationMessages = (event, message) => {
    //     setValidationMessages((prevValues) => {
    //         return {
    //             ...prevValues,
    //             [event.target.name]: message,
    //         };
    //     });
    // };

    const handleInputChange = (event) => {
        const paramName = event.target.name;
        const paramValue = event.target.value;
        if (parameters) {
            const _parameters = Object.entries(parameters).map(([name, value]) => ({ name, value }));
            // console.log("Parameters - handleInputChange _paramaters 1", _parameters);
            const _parametersEventUpdate = _parameters.map((param) => (param.name === paramName ? { ...param, value: paramValue } : param));
            // console.log("Parameters - handleInputChange _parametersEventUpdate", _parametersEventUpdate);
            setParametersValue(_parametersEventUpdate);
        } else {
            // console.log("Parameters - handleInputChange parameters is null");
            setParametersValue([{ name: paramName, value: paramValue }]);
        }

        setParameters((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });

        // console.log("Validating parameter", paramName, paramValue);
        const index = currentFunction.inputs.findIndex((input) => input.name === paramName);
        if (index !== -1) {
            validateParam(paramName, currentFunction.inputs[index].type, paramValue);
        }
    };

    function validateParam(name, type, value) {
        // console.log("validateParam name", name, "type", type, "value", value);
        if (!validateParameter(type, value)) {
            updateValidationMessages(name, name + " is invalid");
            return false;
        } else {
            updateValidationMessages(name, null);
            return true;
        }
    }

    const updateValidationMessages = (name, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [name]: message,
            };
        });
    };

    return (
        <div>
            {currentFunction && currentFunction.name && currentFunction.inputs.length > 0 && (
                <div className="pt-4 items-center">
                    <p className="text-light">Parameters</p>
                    {currentFunction.inputs.map((input, index) => {
                        return (
                            <div key={index}>
                                <p className="text-secondary pt-2">{input.name}</p>
                                <div className="flex items-center pt-2">
                                    <input type="text" name={input.name} onChange={handleInputChange} placeholder={input.type} autoComplete="new-password" spellCheck="false" />
                                </div>
                                {validationMessages && validationMessages[input.name] && <ValidationMessage message={validationMessages[input.name]} />}
                            </div>
                        );
                    })}
                </div>
            )}
            {currentFunction && currentFunction.stateMutability === "payable" && (
                <div className="pt-4 items-center">
                    {currentFunction.inputs.length === 0 && <p className="text-light">Parameters</p>}
                    <div>
                        <p className="text-secondary pt-2">{"value"}</p>
                        <div className="flex items-center pt-2">
                            <input type="text" name={"value"} onChange={handleInputChange} placeholder={"Value in Ether"} autoComplete="new-password" spellCheck="false" />
                            {validationMessages && validationMessages["value"] && <ValidationMessage message={validationMessages["value"]} />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Parameters;
