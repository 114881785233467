import { useEffect, useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import SearchbarDropdown from "./SearchbarDropdown";
import AddIcon from "@mui/icons-material/Add";

// ref: https://github.com/HuakunShen/demo/blob/master/React/search-bar/src/App.jsx

function SearchNetwork() {
    const { networks } = useContext(AppContext);
    const [networkOptions, setNetworkOptions] = useState();
    const [currentNetworkInput, setCurrentNetworkInput] = useState();

    useEffect(() => {
        setNetworkOptions(networks);
    }, [networks]);

    const onInputChange = (event) => {
        if (event.target.value) {
            setNetworkOptions(networks.filter((option) => option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentNetworkInput(event.target.value);
        } else {
            setCurrentNetworkInput("");
            setNetworkOptions(networks);
        }
    };

    return (
        <>
            <div className="pt-4 items-center">
                <p className="w-32 text-white">Network</p>
                <div className="flex items-center pt-2">
                    <SearchbarDropdown
                        options={networkOptions}
                        onInputChange={onInputChange}
                        placeholder="Search Network"
                        setOptions={setNetworkOptions}
                        currentNetworkInput={currentNetworkInput}
                        setCurrentNetworkInput={setCurrentNetworkInput}
                    />
                    {/* Disabled to be in the settings screen or centralized managed
                    <label htmlFor="manageNetwork" className="btn-icon">
                        <AddIcon className="ml-2 text-secondary cursor-pointer" htmlFor="manageNetwork" />
                    </label> */}
                </div>
            </div>
        </>
    );
}

export default SearchNetwork;
