import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import { notify, NotifyContainer } from "@util/component";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import ValidationMessage from "@components/shared/ValidationMessage";
import { validateUrl, copyArray } from "@util/util";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SearchNetwork from "./SearchNetwork";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const Networks = () => {
    const {
        connectedAccount,
        getActiveRPCUserSettings,
        currentNetworkSettings,
        addNewRPCUserSettings,
        setUserRPC,
        removeRPCUserSettings,
        setCurrentNetworkSettings,
        updateUserGasPrice,
        getUserGasPrice,
        getNetworkRPCs,
    } = useContext(AppContext);

    const [inputValues, setInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [gasPrice, setGasPrice] = useState();
    const [activeRPC, setActiveRPC] = useState();
    const [gasPriceTimeoutId, setGasPriceTimeoutId] = useState();
    const [RPCsList, setRPCSList] = useState();

    useEffect(() => {
        // console.log("useEffect currentNetworkSettings", currentNetworkSettings);
        if (currentNetworkSettings) {
            getUserGasPriceSettings();
            clearRPCField();
            findUserActiveRPC();
        }
    }, [currentNetworkSettings]);

    async function getUserGasPriceSettings() {
        let _gasPrice = await getUserGasPrice(currentNetworkSettings.id);
        setGasPrice(_gasPrice);
    }

    async function findUserActiveRPC() {
        const _userActiveRPC = await getActiveRPCUserSettings(currentNetworkSettings.id);
        if (_userActiveRPC) {
            setActiveRPC(_userActiveRPC.RPCURL);
        } else {
            setActiveRPC(currentNetworkSettings.RPCs[0].URL);
        }
    }

    function addNewRPCInUserSettings() {
        console.log("addNewRPCInUserSettings", currentNetworkSettings.RPCs);
        addNewRPCUserSettings(currentNetworkSettings.id, inputValues["newRPCURL"]);
        clearRPCField();
        let _rpcs = [];
        if (RPCsList) {
            if (_rpcs) _rpcs = [...RPCsList];
            _rpcs.push({
                URL: inputValues["newRPCURL"],
                type: "user",
            });

            let _current = copyArray(currentNetworkSettings);
            _current.RPCs = _rpcs;
            setRPCSList(_rpcs);
            // setCurrentNetworkSettings(_current);
            console.log("addNewRPCInUserSettings _current", _current);
        }
    }

    function removeRPCInUserSettings(selectedRPC) {
        console.log("removeRPCInUserSettings", currentNetworkSettings.id, selectedRPC.URL);
        console.log("removeRPCInUserSettings currentNetworkSettings", currentNetworkSettings);
        console.log("removeRPCInUserSettings selectedRPC", selectedRPC);
        removeRPCUserSettings(currentNetworkSettings.id, selectedRPC.URL);
        if (RPCsList) {
            let _rpcs = [...RPCsList];
            if (_rpcs) {
                const _idx = _rpcs.findIndex((rpc) => {
                    if (rpc.URL === selectedRPC.URL) return true;
                    console.log("removeRPCInUserSettings compare", rpc.URL, selectedRPC.URL);
                });
                console.log("removeRPCInUserSettings _idx", _idx);

                if (_idx !== -1) {
                    _rpcs.splice(_idx, 1);
                }
                // console.log("removeRPCInUserSettings _rpcs", _rpcs);
                let _current = structuredClone(currentNetworkSettings);
                _current.RPCs = _rpcs;
                // setCurrentNetworkSettings(_current);
                setRPCSList(_rpcs);
                console.log("removeRPCInUserSettings _current", _current);
            }
        }
    }

    function clearRPCField() {
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                newRPCURL: "",
            };
        });
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                newRPCURL: "",
            };
        });
    }

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: event.target.value,
            };
        });
        if (event.target.name == "gasPrice") {
            const _input = event.target.value.replace(/[^0-9]/g, "");
            setGasPrice(_input);
            if (currentNetworkSettings) {
                // Only submit the change after 3 seconds and clear all previous updates queued calls
                clearTimeout(gasPriceTimeoutId);
                const _gasPriceTimeoutId = setTimeout(() => {
                    updateUserGasPrice(currentNetworkSettings.id, _input);
                }, 3000);
                setGasPriceTimeoutId(_gasPriceTimeoutId);
            }
        }
        if (event.target.name === "rpc") {
            setUserRPC(currentNetworkSettings.id, event.target.value);
            setActiveRPC(event.target.value);
        }
        if (event.target.name == "newRPCURL" || event.target.name == "newRPCURL") {
            if (!validateUrl(event.target.value) && event.target.value) {
                updateValidationMessages(event, "RPC URL is invalid");
            } else {
                updateValidationMessages(event, null);
            }
        }
    };

    // TODO: combine user RPCs with the current System RPCs
    // currentNetworkSettings.RPCs
    // getNetworkRPCs
    useEffect(() => {
        getCurrentUserRPCs();
    }, [currentNetworkSettings]);

    async function getCurrentUserRPCs() {
        if (currentNetworkSettings) {
            const _userRPCs = await getNetworkRPCs(currentNetworkSettings.id);
            const _currentNetworkSettingsRPCs = structuredClone(currentNetworkSettings.RPCs);
            const _currentNetworkSettings = structuredClone(currentNetworkSettings);
            if (_userRPCs && _userRPCs.length > 0) {
                _userRPCs.forEach((userRPC) => {
                    _currentNetworkSettingsRPCs.push({ URL: userRPC.RPCURL, type: "user" });
                });
                _currentNetworkSettings.RPCs = [..._currentNetworkSettingsRPCs];
                setRPCSList(_currentNetworkSettings.RPCs);
            } else {
                setRPCSList(currentNetworkSettings.RPCs);
            }
        }
    }

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                {connectedAccount && (
                    <>
                        <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                            <h3 className="font-bold text-lg text-white">Networks</h3>
                            <div className="pt-4">
                                <p className="pb-1 text-white">Choose the Network</p>
                                <SearchNetwork />
                            </div>
                            <div className="pt-8">
                                <p className="pb-1 text-secondary">Network Id (Chain Id)</p>
                                <div className="flex items-center">
                                    <input type="text" name="id" spellCheck="false" value={(currentNetworkSettings && currentNetworkSettings.id) || ""} disabled />
                                    {currentNetworkSettings && currentNetworkSettings.id && (
                                        <a href={`https://chainlist.org/chain/${currentNetworkSettings.id}`} target="_blank">
                                            <LaunchOutlinedIcon className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5" />
                                        </a>
                                    )}
                                </div>
                                <p className="pt-2 pb-1 text-secondary">Name</p>
                                <input type="text" name="name" spellCheck="false" value={(currentNetworkSettings && currentNetworkSettings.name) || ""} disabled />
                                <p className="pt-2 text-secondary">Block Explorer URL</p>
                                <div className="flex items-center">
                                    <input type="text" name="id" value={(currentNetworkSettings && currentNetworkSettings.blockExplorerURL) || ""} disabled />
                                    {currentNetworkSettings && currentNetworkSettings.blockExplorerURL && (
                                        <a href={currentNetworkSettings.blockExplorerURL} target="_blank">
                                            <LaunchOutlinedIcon className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5" />
                                        </a>
                                    )}
                                </div>
                                <p className="pt-2 pb-1 text-secondary">Coin</p>
                                <input type="text" name="coin" spellCheck="false" value={(currentNetworkSettings && currentNetworkSettings.coin) || ""} disabled />
                                <p className="pt-2 pb-1 text-secondary">Type</p>
                                <input type="text" name="type" spellCheck="false" value={(currentNetworkSettings && currentNetworkSettings.type) || ""} disabled />
                                <p className="pt-2 pb-1 text-secondary">Related</p>
                                <input type="text" name="related" spellCheck="false" value={(currentNetworkSettings && currentNetworkSettings.related) || ""} disabled />
                                <p className="pt-2 text-secondary">Faucet URL</p>
                                <div className="flex items-center">
                                    <input type="text" name="faucetURL" spellCheck="false" value={(currentNetworkSettings && currentNetworkSettings.faucetURL) || ""} disabled />
                                    {currentNetworkSettings && currentNetworkSettings.faucetURL && (
                                        <a href={currentNetworkSettings.faucetURL} target="_blank">
                                            <LaunchOutlinedIcon className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5" />
                                        </a>
                                    )}
                                </div>
                                <p className="pt-2 pb-1 text-secondary">Gas Price in Gwei (Default)</p>
                                <input type="text" name="gasPrice" spellCheck="false" value={gasPrice || ""} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                            <div>
                                <p className="pt-10 pb-2 text-white">Available RPCs</p>
                                {
                                    // currentNetworkSettings &&
                                    //     activeRPC &&
                                    //     currentNetworkSettings.RPCs &&
                                    RPCsList &&
                                        // currentNetworkSettings.RPCs.map((rpc) => {
                                        RPCsList.map((rpc) => {
                                            const isChecked = rpc.URL === activeRPC;
                                            //console.log("isChecked", isChecked, rpc.URL, activeRPC);
                                            return (
                                                <label className="label cursor-pointer" key={rpc.URL}>
                                                    <input type="radio" name="rpc" className="radio checked:bg-[#ef7308]" onChange={handleInputChange} value={rpc.URL} checked={isChecked} />
                                                    {rpc.type === "system" && <span className="label-text w-full pl-4">System RPC</span>}
                                                    {rpc.type != "system" && <span className="label-text w-full pl-4">{rpc.URL}</span>}
                                                    {rpc.type === "user" && (
                                                        <DeleteOutlinedIcon
                                                            className="ml-2 max-h-5 text-secondary cursor-pointer"
                                                            onClick={() => {
                                                                removeRPCInUserSettings(rpc);
                                                            }}
                                                        />
                                                    )}
                                                    {rpc.type != "user" && <div></div>}
                                                </label>
                                            );
                                        })
                                }
                            </div>
                            <div className="gap-4 pt-8">
                                <p className="pt-2 pb-1 text-secondary">RPC URL</p>
                                <div>
                                    <input
                                        type="text"
                                        name="newRPCURL"
                                        maxLength={80}
                                        autoComplete="new-password"
                                        spellCheck="false"
                                        onChange={handleInputChange}
                                        value={inputValues["newRPCURL"] || ""}
                                    />
                                    {validationMessages["newRPCURL"] && <ValidationMessage message={validationMessages["newRPCURL"]} />}
                                </div>
                                <div className="pt-4">
                                    <label
                                        className="btn-default"
                                        onClick={() => {
                                            addNewRPCInUserSettings();
                                        }}>
                                        ADD
                                        <AddIcon className="pl-2" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};
export default Networks;
