import Web3 from "web3";

export const Web3util = {
    transformExecution2Queue: (executionInfo, id = 1, next = 2) => {
        // console.log("transformExecution2Queue executionInfo", executionInfo);
        if (executionInfo.type === "validation") return;
        try {
            if (!executionInfo) {
                console.warn("transformExecution2Queue - Invalid executionInfo");
                return;
            }

            const _output = {
                id: 1,
                type: "execution",
                contract: executionInfo.contract.name,
                proxy: executionInfo.contract.proxy, // optional
                implementation: executionInfo.contract.implementation,
                //networkId: executionInfo.network.id, // It will be the same for the entire queue
                function: executionInfo.function.name,
                abi: executionInfo.abi, // Why we need this? we can get this when we are executing the function or in the block explorer
                ...(executionInfo.function.inputs && { parameters: executionInfo.function.inputs }),
                ...(executionInfo.paramaters && { paramatersValues: executionInfo.paramaters }),
                ...(executionInfo.wallet && { wallet: executionInfo.wallet }),
                next: 2,
            };

            // TODO: Validation?

            // const _output = {
            //     id: 1,
            //     type: "execution",
            //     detail: {
            //         contract: executionInfo.contract.name,
            //         proxy: executionInfo.contract.proxy,
            //         implementation: executionInfo.contract.implementation,
            //         networkId: executionInfo.network.id,
            //         function: executionInfo.function.name,
            //         abi: executionInfo.abi,
            //         ...(executionInfo.function.inputs && { parameters: executionInfo.function.inputs }),
            //         ...(executionInfo.paramaters && { paramatersValues: executionInfo.paramaters }),
            //         ...(executionInfo.wallet && { wallet: executionInfo.wallet }),
            //     },
            //     next: 2,
            // };

            // console.log("transformExecution2Queue _output", _output);
            return _output;
        } catch (error) {
            console.warn("Exception executing transformExecution2Queue", error);
        }
    },
    cleanABIs: (queue) => {
        const cleanedSteps = queue.steps.map((step) => {
            if (step.detail.function) {
                // if a specific function is mentioned in the step, keep only the corresponding ABI
                const matchedABI = step.detail.abi.find((abi) => abi.name === step.detail.function);
                step.detail.abi = [matchedABI];
            } else {
                // if no specific function is mentioned, keep all ABIs
                step.detail.abi = step.detail.abi.filter((abi) => abi.type === "function");
            }
            return step;
        });

        return {
            ...queue,
            steps: cleanedSteps,
        };
    },
    shortAddress: (address, numberCharsStart = 2, numberCharsEnd = 4) => {
        if (!address) return;
        const prefix = address.slice(0, numberCharsStart);
        const suffix = address.slice(-numberCharsEnd);
        return `${prefix}...${suffix}`;
    },
    findContractName: (sourceCode) => {
        const regex = /contract\s+(\w+)(?:\s+is|)\s+.*?\{/s;
        const matches = sourceCode.match(regex);
        console.log("Matches:", matches);
        if (matches && matches.length > 1) {
            return matches[1];
        }
        return null;
    },
    countErrorsAndWarnings: (compilationOutput) => {
        let numErrors = 0;
        let numWarnings = 0;

        if (!compilationOutput || !compilationOutput.output || !compilationOutput.output.errors) {
            return { numErrors, numWarnings };
        }
        
        for (let index = 0; index < compilationOutput.output.errors.length; index++) {
            const error = compilationOutput.output.errors[index];
            if (error.severity.toLowerCase() === "warning") numWarnings++;
            if (error.severity.toLowerCase() === "error") numErrors++;
        }

        return { numErrors, numWarnings };
    },
    createNewWallet: () => {
        const web3 = new Web3();
        const wallet = web3.eth.accounts.create();
        return wallet;
    },
    isAddress: (address) => {
        return Web3.utils.isAddress(address);
    },
    validatePK: (PK) => {
        try {
            const web3 = new Web3();
            const account = web3.eth.accounts.privateKeyToAccount(PK);
            return account;
        } catch (error) {
            return false;
        }
    },
    removeLeadingZeros: (address) => {
        if (!address.startsWith("0x")) {
            console.log("removeLeadingZeros 0x");
            return null;
        }

        // Remove leading zeros
        const cleanedAddress = address.replace(/^0x0*/, "0x");

        // Calculate the required padding length
        const paddingLength = 42 - cleanedAddress.length;

        // Pad the cleaned address with leading zeros after the "0x" prefix
        const paddedAddress = cleanedAddress.replace(/^0x/, "0x" + "0".repeat(paddingLength));
        console.log("removeLeadingZeros paddedAddress", paddedAddress);

        // Validate the resulting address
        if (!Web3.utils.isAddress(paddedAddress)) {
            console.log("removeLeadingZeros not address");
            return null;
        }

        return paddedAddress;
    },
    extractImports: (contractCode) => {
        const importRegex = /import\s+"([^"]+)"\s*;/g;
        const importPaths = [];

        let match;
        while ((match = importRegex.exec(contractCode)) !== null) {
            importPaths.push(match[1]);
        }

        return importPaths;
    },
    flattenDependencies: (dependencies) => {
        let flattened = [];

        for (const dependencyObj of dependencies) {
            flattened.push({
                dependency: dependencyObj.dependency,
                path: dependencyObj.path,
                code: dependencyObj.code,
            });

            if (dependencyObj.importedDependencies) {
                const importedFlattened = Web3util.flattenDependencies(dependencyObj.importedDependencies);
                flattened = flattened.concat(importedFlattened);
            }
        }

        return flattened;
    },
    getImportsCode: (contractsCodeList) => {
        // console.log("getImportsCode contractsCodeList", contractsCodeList);
        let dependenciesCode = [];
        for (let index = 0; index < contractsCodeList.length; index++) {
            const dependency = contractsCodeList[index];
            dependenciesCode.push({
                path: [dependency.path],
                code: dependency.code,
            });
        }

        return dependenciesCode;
    },
    jsonfyContract: (sourceCode, contractInfo_, type = "") => {
        try {
            if (sourceCode) {
                // console.log("web3util - jsonfyContract", sourceCode, contractInfo_);
                // TODO: Investigate the issue with {{ and }}
                if (sourceCode.startsWith("{")) {
                    const _code = sourceCode.substring(1, sourceCode.toString().length - 1);
                    const _contractsCode = Web3util.extractContracts(JSON.parse(_code));
                    for (let index = 0; index < _contractsCode.length; index++) {
                        const _contract = _contractsCode[index];
                        _contract.type = type;
                    }

                    return _contractsCode;
                } else {
                    const _contractsCode = [
                        {
                            code: contractInfo_.SourceCode,
                            contract: contractInfo_.ContractName,
                            type: type,
                        },
                    ];

                    return _contractsCode;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.error("web3util - jsonsifyContract", error);
        }
    },
    extractContracts: (json) => {
        // console.log("extractContracts json.sources", json.sources);
        const contracts = [];
        for (const [key, value] of Object.entries(json.sources)) {
            const contractName = key.replace(/^.*[\\/]/, "").replace(".sol", "");
            const code = value.content;
            const path = key;
            // console.log("extractContracts key", key);
            contracts.push({ contract: contractName, code, path });
            // contracts.push({ label: contractName, content: <Editor height="90vh" defaultLanguage="sol" theme="vs-dark" defaultValue={code} /> });
        }
        return contracts;
    },
    getLicenseType: (sourceCode) => {
        const regex = /SPDX-License-Identifier:\s*([\w-]+?)(?=\s|;|$)/;
        const match = sourceCode.match(regex);
        return match ? match[1] : null;
    },
};
