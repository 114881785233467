import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import HistoryItem from "../../components/history/HistoryItem";
import { saveDataStorage, getDataStorage } from "../../util/util";
import { LocalStorage, FunctionGasType } from "../../util/enum";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";

function ExecutionQueueResults() {
    // const { executionQueueResults } = useContext(AppContext);
    const _queueResults = getDataStorage(LocalStorage.ExecutionQueueResults);

    // console.log("Execution Queue Results render", _queueResults);

    return (
        <div className="pt-4">
            {_queueResults &&
                _queueResults.map((history_) => {
                    return (
                        <div key={history_.uuid}>
                            <HistoryItem historyItem={history_} behavior={"queueResults"} />
                        </div>
                    );
                })}
        </div>
    );
}

export default ExecutionQueueResults;
