import { useEffect, useRef, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { getImageUrl } from "../../../util/util";

function SearchbarDropdown(props) {
    const { setCurrentContract, contracts, getContract, setFunctions, currentContractInput, setCurrentContractInput, setCurrentWallet, setCurrentFunction, clearExecuteForm, currentNetwork } =
        useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions } = props;
    const ulRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });
        inputRef.current.addEventListener("focusout", (event) => {
            if (!inputRef.current.value) {
                setCurrentContract({});
            }
        });
    }, []);

    return (
        <div className="dropdown dropdown-bottom w-full">
            <input type="text" placeholder={placeholder} ref={inputRef} onChange={onInputChange} autoComplete="new-password" spellCheck="false" value={currentContractInput || ""} />
            <ul id="results" className="ul-main" ref={ulRef}>
                {options.map((option, index) => {
                    return (
                        <button
                            id="ExecuteContractSearchbarDropdownItem"
                            name="ExecuteContractSearchbarDropdownItem"
                            type="button"
                            key={index}
                            onClick={(e) => {
                                clearExecuteForm("contract");
                                setCurrentContractInput(option.name);
                                ulRef.current.style.display = "none";
                                //inputRef.current.value = option;
                                setCurrentContract(option);
                                // setOptions(contracts.filter((_option) => _option.name.toLocaleLowerCase().includes(option.toLocaleLowerCase())));

                                setOptions(
                                    contracts.filter((_option) => {
                                        if (_option.name.toLocaleLowerCase().includes(option.name.toLocaleLowerCase()) && option.networkId === currentNetwork.id) return true;
                                        else return false;
                                    })
                                );

                                setFunctions([]);
                                console.debug("Current contract set to:", option);
                            }}
                            className="ul-item">
                            {option.name}
                        </button>
                    );
                })}
            </ul>
        </div>
    );
}

export default SearchbarDropdown;
