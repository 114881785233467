import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ValidationMessage from "@components/shared/ValidationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold, faBolt } from "@fortawesome/free-solid-svg-icons";
import { notify } from "@util/component";
import { FunctionGasType } from "@util/enum";

const PopupFunctionParameters = ({ network, contract, contractsInfoEditor, currentFunction, wallet, functionToExecuteType, setParametersValues, setExecutionResult, setExecutingFunction }) => {
    const { validateParameter, executeFunctionEditor, getFunctionGasType } = useContext(AppContext);
    const [validationMessages, setValidationMessages] = useState();
    // const [parametersValue, setParametersValue] = useState();
    const [parameters, setParameters] = useState({});

    const handleInputChange = (event) => {
        const paramName = event.target.name;
        const paramValue = event.target.value;
        const functionName = currentFunction.name;

        setParameters((prevValues) => {
            const functionParams = prevValues[functionName] || {};

            return {
                ...prevValues,
                [functionName]: {
                    ...functionParams,
                    [paramName]: paramValue,
                },
            };
        });

        const index = currentFunction.inputs.findIndex((input) => input.name === paramName);
        if (index !== -1) {
            validateParam(functionName, paramName, currentFunction.inputs[index].type, paramValue);
        }
    };

    function validateParam(functionName, name, type, value) {
        if (!validateParameter(type, value)) {
            updateValidationMessages(functionName, name, name + " is invalid");
            return false;
        } else {
            updateValidationMessages(functionName, name, null);
            return true;
        }
    }

    // const updateValidationMessages = (name, message) => {
    //     setValidationMessages((prevValues) => {
    //         return {
    //             ...prevValues,
    //             [name]: message,
    //         };
    //     });
    // };

    const updateValidationMessages = (functionName, name, message) => {
        setValidationMessages((prevValues) => {
            let functionMessages = {};
            if (prevValues) functionMessages = prevValues[functionName] || {};
            // if (prevValues) prevValues[currentFunction.name];

            return {
                ...prevValues,
                [functionName]: {
                    ...functionMessages,
                    [name]: message,
                },
            };
        });
    };

    async function executeContractFunction(functionToExecuteType = "execution") {
        try {
            // Validate if there are validations errors
            if (validationMessages && validationMessages[currentFunction.name] && typeof validationMessages[currentFunction.name] === "object") {
                const functionErrors = validationMessages[currentFunction.name];

                // Loop through the properties of functionErrors object
                for (const param in functionErrors) {
                    if (functionErrors[param] !== null) {
                        notify("Please review the validations messages and try again", "error");
                        return;
                    }
                }
            }

            setExecutingFunction(true);
            window.popupFunctionParameters.close();

            console.log("executeContractFunction parameters", parameters);
            // Payable
            let _value;
            if (functionToExecuteType === "execution" && currentFunction.stateMutability === "payable") {
                // ! BUG: Get the value from parameters and remove it from the array
                console.log("getting value", parameters[currentFunction.name].value);
                if (currentFunction.stateMutability === "payable") {
                    _value = parameters[currentFunction.name].value;
                    //_parametersValues = null;
                    delete parameters[currentFunction.name].value;
                }
            }

            let _parametersValues = null;
            if (parameters && parameters[currentFunction.name] && Object.keys(parameters[currentFunction.name]).length > 0) {
                _parametersValues = Object.values(parameters[currentFunction.name]);
            }

            // Look for the assigning process in the state...
            // - TODO: If it's a proxy, we call the proxy contract address using the ABI of the implementation contract
            let _result = null;
            let _functionType = getFunctionGasType(currentFunction.stateMutability);
            if (_functionType === FunctionGasType.PayGasFee && functionToExecuteType === "execution") {
                if (wallet.type === "provider") {
                    // For External Wallet Providers, we use an internal call to execute the funcion. The parameters format is differente e.g. [{ name: "value", value: 13 }]
                    _parametersValues = [];
                    for (let item in parameters[currentFunction.name]) {
                        _parametersValues.push({ name: item, value: parameters[currentFunction.name][item] });
                    }
                    _result = await executeFunctionEditor(
                        functionToExecuteType,
                        network.id,
                        contractsInfoEditor.isProxy ? contractsInfoEditor.baseContractAddress : null,
                        contractsInfoEditor.isProxy ? contractsInfoEditor.implementation.addressFromNetwork : contractsInfoEditor.baseContractAddress,
                        null,
                        currentFunction.name,
                        _parametersValues,
                        wallet.address,
                        _value,
                        true
                    );
                } else {
                    _result = await executeFunctionEditor(
                        functionToExecuteType,
                        network.id,
                        contractsInfoEditor.isProxy ? contractsInfoEditor.baseContractAddress : null,
                        contractsInfoEditor.isProxy ? contractsInfoEditor.implementation.addressFromNetwork : contractsInfoEditor.baseContractAddress,
                        null,
                        currentFunction.name,
                        _parametersValues,
                        wallet.pk,
                        _value
                    );
                }
            } else {
                console.log("executeContractFunction view function");
                _result = await executeFunctionEditor(
                    functionToExecuteType,
                    network.id,
                    contractsInfoEditor.isProxy ? contractsInfoEditor.baseContractAddress : null,
                    contractsInfoEditor.isProxy ? contractsInfoEditor.implementation.addressFromNetwork : contractsInfoEditor.baseContractAddress,
                    null,
                    currentFunction.name,
                    _parametersValues,
                    null,
                    null,
                    false
                );
            }
            console.log("executeContractFunction _result", _result);
            if (wallet && wallet.type && wallet.type === "provider" && _functionType === FunctionGasType.PayGasFee && functionToExecuteType === "execution") setExecutionResult(_result);
            else setExecutionResult(_result.data);
            setExecutingFunction(false);
            window.popupExecutionResult.showModal();
        } catch (error) {
            setExecutingFunction(false);
            notify("Error executing function. Please, try again.", "error");
            console.error(error);
        }
    }

    return (
        <dialog id="popupFunctionParameters" className="rounded-lg bg-transparent w-[50%]">
            {/* <form method="dialog" className="modal-box text-light"> */}
            {/* <form method="dialog" className="modal-box w-full h-full max-w-full max-h-full text-light"> */}
            <div className="modal-box w-full h-full max-w-full max-h-full text-light">
                <h3 className="font-bold text-lg text-white">Execute Function</h3>
                <div className="pt-4 text-secondary">
                    <p>
                        Network:
                        <span className="pl-1 text-light">
                            {network && network.name} ({network && network.id})
                        </span>
                    </p>
                    <p>
                        Contract: <span className="text-light">{contract}</span>
                    </p>
                    <p>
                        Name: <span className="text-light">{currentFunction && currentFunction.name}</span>
                    </p>
                    <p>
                        Type: <span className="text-light">{currentFunction && currentFunction.stateMutability}</span>
                    </p>
                    {wallet && (
                        <p>
                            Wallet:
                            <span className="pl-1 text-light">
                                {wallet && wallet.name} ({wallet && wallet.address})
                            </span>
                        </p>
                    )}
                </div>
                {currentFunction && currentFunction.name && currentFunction.inputs.length > 0 && (
                    <div className="pt-4 items-center">
                        <p className="text-light">Parameters</p>
                        {currentFunction.inputs.map((input, index) => {
                            return (
                                <div id="PopupFunctionParametersList" name="PopupFunctionParametersList" data-info={input.name} key={index}>
                                    <p className="text-secondary pt-2">{input.name}</p>
                                    <div className="flex items-center pt-2">
                                        <input
                                            type="text"
                                            name={input.name}
                                            onChange={handleInputChange}
                                            placeholder={input.type}
                                            autoComplete="new-password"
                                            spellCheck="false"
                                            value={(parameters && parameters[currentFunction.name] && parameters[currentFunction.name][input.name]) || ""}
                                        />
                                    </div>
                                    {validationMessages && validationMessages[currentFunction.name] && validationMessages[currentFunction.name][input.name] && (
                                        <ValidationMessage message={validationMessages[currentFunction.name][input.name]} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {currentFunction && currentFunction.stateMutability === "payable" && (
                    <div className="pt-4 items-center">
                        {currentFunction.inputs.length === 0 && <p className="text-light">Parameters</p>}
                        <div>
                            <p className="text-secondary pt-2">{"value"}</p>
                            <div className="flex items-center pt-2">
                                <input type="text" name={"value"} onChange={handleInputChange} placeholder={"Value in Ether"} autoComplete="new-password" spellCheck="false" />
                                {validationMessages && validationMessages["value"] && <ValidationMessage message={validationMessages["value"]} />}
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex justify-end pt-6">
                    {/* if there is a button in form, it will close the modal */}
                    <button
                        id="PopupFunctionParametersExecuteButton"
                        name="PopupFunctionParametersExecuteButton"
                        className="btn-default mr-4"
                        onClick={() => {
                            executeContractFunction(functionToExecuteType);
                        }}>
                        {functionToExecuteType === "execution" && "Execute"}
                        {functionToExecuteType === "simulation" && "Simulate"}
                        <FontAwesomeIcon icon={faBolt} className="pl-2" />
                    </button>
                    <button
                        id="PopupFunctionParametersCloseButton"
                        name="PopupFunctionParametersCloseButton"
                        className="btn-default"
                        onClick={() => {
                            const _paramValues = Object.values(parameters);
                            setParametersValues(_paramValues);
                            console.log("_paramValues", _paramValues);
                            window.popupFunctionParameters.close();
                        }}>
                        Close
                    </button>
                </div>
            </div>
            {/* </form> */}
        </dialog>
    );
};

export default PopupFunctionParameters;
