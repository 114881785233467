import { useState, useContext, useEffect } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCopy, faCube, faLocationDot, faLocationPinLock, faTriangleExclamation, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Web3util } from "@util/web3util";
import ValidationMessage from "@components/shared/ValidationMessage";
import SearchbarDropdown from "@pages/networks/SearchbarDropdown";
import { Helmet } from "react-helmet";

const GetImplementation = () => {
    const { getProxyInfo, networkSettingsOptions, setNetworkSettingsOptions, networks, getAllNetworks, currentNetworkSettings, loadContractSourceCodeEditor } = useContext(AppContext);
    const [currentNetworkSearchInput, setCurrentNetworkSearchInput] = useState();
    const [validationMessages, setValidationMessages] = useState();
    const [proxy, setProxy] = useState();
    const [proxyInfo, setProxyInfo] = useState();
    const [implementationAddressIsDifferent, setImplementationAddressIsDifferent] = useState();

    useEffect(() => {
        getAllNetworksList();
    }, [networks]);

    async function getAllNetworksList() {
        const _allNetworks = await getAllNetworks();
        console.log("SourceCodePage _allNetworks", _allNetworks);
        console.log("SourceCodePage networks", networks);
        setNetworkSettingsOptions(_allNetworks);
    }

    const handleInputChange = (event) => {
        const _proxy = event.target.value;
        if (_proxy) {
            let _valid;
            try {
                _valid = Web3util.isAddress(_proxy);
            } catch (error) {}
            console.log("valid", _valid);
            if (!_valid) {
                setValidationMessages("Proxy Adress is invalid");
            } else {
                setValidationMessages(null);
            }
        }
        setProxy(event.target.value);
    };

    const onInputChange = (event) => {
        if (event.target.value) {
            console.log("onInputChange - event.target.value", event.target.value);
            setNetworkSettingsOptions(networks.filter((option) => option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentNetworkSearchInput(event.target.value);
        } else {
            setCurrentNetworkSearchInput("");
            setNetworkSettingsOptions(networks);
            //setCurrentNetwork({});
        }
    };

    async function getImplementationAddressFromProxy() {
        const [_contractInfo, _contractsCode] = await loadContractSourceCodeEditor(currentNetworkSettings.id, proxy);
        console.log("_contractInfo", _contractInfo);
        setProxyInfo(_contractInfo);

        if (_contractInfo.implementation.addressFromNetwork === _contractInfo.implementation.address) {
            setImplementationAddressIsDifferent(true);
        } else {
            setImplementationAddressIsDifferent(false);
        }
    }

    return (
        <div className="container">
            <Helmet>
                <meta charset="UTF-8" />
                <title>Check the implementation logic address of your Proxy Solidity contract | DevWeb3</title>
                <meta name="description" content="Check the implementation logi contract address of your Web3 Proxy solidity contract address." />
                <meta property="og:title" content="Check the implementation logic address of your Proxy Solidity contract | DevWeb3" />
                <meta property="og:description" content="Check the implementation logi contract address of your Web3 Proxy solidity contract address." />
                <meta name="twitter:title" content="Check the implementation logic address of your Proxy Solidity contract | DevWeb3" />
                <meta name="twitter:description" content="Check the implementation logi contract address of your Web3 Proxy solidity contract address." />
            </Helmet>
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="col-span-full max-w-[1280px] min-w-[635px] p-2">
                    <h3 className="font-bold text-lg text-white">Get Implementation Address from a Proxy</h3>
                    <div className="flex-row pt-2 items-center">
                        <p className="font-semibold py-2 text-white">Network</p>
                        <p className="pt-2 pb-1 text-secondary">Name</p>
                        <div>
                            <SearchbarDropdown
                                options={networkSettingsOptions}
                                onInputChange={onInputChange}
                                placeholder="Select a Network"
                                setOptions={setNetworkSettingsOptions}
                                currentNetworkSearchInput={currentNetworkSearchInput}
                                setCurrentNetworkSearchInput={setCurrentNetworkSearchInput}
                            />
                        </div>
                        <p className="pt-2 pb-1 text-secondary">Proxy Address</p>
                        <input
                            type="text"
                            name="proxyInput"
                            maxLength={42}
                            autoComplete="new-password"
                            spellCheck="false"
                            placeholder="Enter the address of the proxy contract"
                            onChange={handleInputChange}
                            value={proxy || ""}
                        />
                        {validationMessages && <ValidationMessage message={validationMessages || ""} />}
                        <p>
                            <button
                                id="GetImplementationGetImplementationAddressButton"
                                name="GetImplementationGetImplementationAddressButton"
                                className="btn-default mt-2"
                                onClick={() => getImplementationAddressFromProxy()}>
                                Get Implementation Address
                                <FontAwesomeIcon
                                    id="GetImplementationGetImplementationAddressButtonIcon"
                                    name="GetImplementationGetImplementationAddressButtonIcon"
                                    icon={faLocationDot}
                                    className="text-secondary ml-2 cursor-pointer"
                                />
                            </button>
                        </p>
                        {proxyInfo && (
                            <div className="border-2 border-[#AA6840] rounded-lg mt-10 p-2">
                                <p className="font-semibold text-white">Implementation Contract Address Found</p>
                                <p className="mt-6 flex justify-between items-center">
                                    <div className="text-secondary">
                                        Proxy Address:
                                        <span id="GetImplementationProxyAddress" name="GetImplementationProxyAddress" className="pl-1 text-light">
                                            {proxyInfo.baseContractAddress}
                                        </span>
                                    </div>
                                    <div>
                                        <button
                                            id="GetImplementationCopyProxyAddressButton"
                                            name="GetImplementationCopyProxyAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                navigator.clipboard.writeText(proxyInfo.baseContractAddress);
                                            }}>
                                            Copy
                                            <FontAwesomeIcon
                                                id="GetImplementationCopyProxyAddressButtonIcon"
                                                name="GetImplementationCopyProxyAddressButtonIcon"
                                                icon={faCopy}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                        <button
                                            id="GetImplementationEditorProxyAddressButton"
                                            name="GetImplementationEditorProxyAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                window.open(window.location.origin + "/editor?network=" + currentNetworkSettings.id + "&contract=" + proxyInfo.baseContractAddress, "_blank");
                                            }}>
                                            View In Editor
                                            <FontAwesomeIcon
                                                id="GetImplementationEditorProxyAddressButtonIcon"
                                                name="GetImplementationEditorProxyAddressButtonIcon"
                                                icon={faCode}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                        <button
                                            id="GetImplementationBlockExplorerProxyAddressButton"
                                            name="GetImplementationBlockExplorerProxyAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                window.open(currentNetworkSettings.blockExplorerURL + "/address/" + proxyInfo.baseContractAddress, "_blank");
                                            }}>
                                            View In Block Explorer
                                            <FontAwesomeIcon icon={faCube} className="text-secondary ml-2 cursor-pointer" />
                                        </button>
                                    </div>
                                </p>
                                <p className="pt-6">
                                    <span className="text-secondary">Proxy Type:</span>
                                    <span id="GetImplementationProxyType" name="GetImplementationProxyType" className="text-light pl-1">
                                        {proxyInfo.proxyType}
                                    </span>
                                </p>
                                <p className="mt-6 flex justify-between items-center">
                                    <div className="text-secondary">
                                        Network Registered Address:
                                        <span id="GetImplementationNetworkImplementationAddress" name="GetImplementationNetworkImplementationAddress" className="pl-1 text-light">
                                            {proxyInfo.implementation.addressFromNetwork}
                                        </span>
                                    </div>
                                    <div>
                                        <button
                                            id="GetImplementationCopyNetworkImplementationAddressButton"
                                            name="GetImplementationCopyNetworkImplementationAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                navigator.clipboard.writeText(proxyInfo.implementation.addressFromNetwork);
                                            }}>
                                            Copy
                                            <FontAwesomeIcon
                                                id="GetImplementationCopyNetworkImplementationAddressButtonIcon"
                                                name="GetImplementationCopyNetworkImplementationAddressButtonIcon"
                                                icon={faCopy}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                        <button
                                            id="GetImplementationEditorNetworkImplementationAddressButton"
                                            name="GetImplementationEditorNetworkImplementationAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                window.open(
                                                    window.location.origin + "/editor?network=" + currentNetworkSettings.id + "&contract=" + proxyInfo.implementation.addressFromNetwork,
                                                    "_blank"
                                                );
                                            }}>
                                            View In Editor
                                            <FontAwesomeIcon
                                                id="GetImplementationEditorNetworkImplementationAddressButtonIcon"
                                                name="GetImplementationEditorNetworkImplementationAddressButtonIcon"
                                                icon={faCode}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                        <button
                                            id="GetImplementationBlockExplorerNetworkImplementationAddressButton"
                                            name="GetImplementationBlockExplorerNetworkImplementationAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                window.open(currentNetworkSettings.blockExplorerURL + "/address/" + proxyInfo.implementation.addressFromNetwork, "_blank");
                                            }}>
                                            View In Block Explorer
                                            <FontAwesomeIcon
                                                id="GetImplementationBlockExplorerNetworkImplementationAddressButtonIcon"
                                                name="GetImplementationBlockExplorerNetworkImplementationAddressButtonIcon"
                                                icon={faCube}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                    </div>
                                </p>
                                <p className="mt-6 flex justify-between items-center">
                                    <div className="text-secondary">
                                        Block Explorer Registered Address:
                                        <span id="GetImplementationImplementationAddress" name="GetImplementationImplementationAddress" className="pl-1 text-light">
                                            {proxyInfo.implementation.address}
                                        </span>
                                    </div>
                                    <div>
                                        <button
                                            id="GetImplementationCopyImplementationAddressButton"
                                            name="GetImplementationCopyImplementationAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                navigator.clipboard.writeText(proxyInfo.implementation.address);
                                            }}>
                                            Copy
                                            <FontAwesomeIcon
                                                id="GetImplementationCopyImplementationAddressButtonIcon"
                                                name="GetImplementationCopyImplementationAddressButtonIcon"
                                                icon={faCopy}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                        <button
                                            id="GetImplementationEditorImplementationAddressButton"
                                            name="GetImplementationEditorImplementationAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                window.open(window.location.origin + "/editor?network=" + currentNetworkSettings.id + "&contract=" + proxyInfo.implementation.address, "_blank");
                                            }}>
                                            View In Editor
                                            <FontAwesomeIcon
                                                id="GetImplementationEditorImplementationAddressButtonIcon"
                                                name="GetImplementationEditorImplementationAddressButtonIcon"
                                                icon={faCode}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                        <button
                                            id="GetImplementationBlockExplorerImplementationAddressButton"
                                            name="GetImplementationBlockExplorerImplementationAddressButton"
                                            className="btn-default ml-4"
                                            onClick={() => {
                                                window.open(currentNetworkSettings.blockExplorerURL + "/address/" + proxyInfo.implementation.address, "_blank");
                                            }}>
                                            View In Block Explorer
                                            <FontAwesomeIcon
                                                id="GetImplementationBlockExplorerImplementationAddressButtonIcon"
                                                name="GetImplementationBlockExplorerImplementationAddressButtonIcon"
                                                icon={faCube}
                                                className="text-secondary ml-2 cursor-pointer"
                                            />
                                        </button>
                                    </div>
                                </p>
                                {proxyInfo.proxyType.toLocaleLowerCase() === "transparent" && (
                                    <p className="mt-6 flex justify-between items-center">
                                        <div className="text-secondary">Proxy Admin Address: {proxyInfo.proxyAdmin.addressFromNetwork}</div>
                                        <div>
                                            <button
                                                id="GetImplementationCopyProxyAdminAddressButton"
                                                name="GetImplementationCopyProxyAdminAddressButton"
                                                className="btn-default ml-4"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(proxyInfo.proxyAdmin.addressFromNetworks);
                                                }}>
                                                Copy
                                                <FontAwesomeIcon
                                                    id="GetImplementationCopyProxyAdminAddressButtonIcon"
                                                    name="GetImplementationCopyProxyAdminAddressButtonIcon"
                                                    icon={faCopy}
                                                    className="text-secondary ml-2 cursor-pointer"
                                                />
                                            </button>
                                            <button
                                                id="GetImplementationEditorProxyAdminAddressButton"
                                                name="GetImplementationEditorProxyAdminAddressButton"
                                                className="btn-default ml-4"
                                                onClick={() => {
                                                    window.open(
                                                        window.location.origin + "/editor?network=" + currentNetworkSettings.id + "&contract=" + proxyInfo.proxyAdmin.addressFromNetwork,
                                                        "_blank"
                                                    );
                                                }}>
                                                View In Editor
                                                <FontAwesomeIcon
                                                    id="GetImplementationEditorProxyAdminAddressButtonIcon"
                                                    name="GetImplementationEditorProxyAdminAddressButtonIcon"
                                                    icon={faCode}
                                                    className="text-secondary ml-2 cursor-pointer"
                                                />
                                            </button>
                                            <button
                                                id="GetImplementationBlockExplorerProxyAdminAddressButton"
                                                name="GetImplementationBlockExplorerProxyAdminAddressButton"
                                                className="btn-default ml-4"
                                                onClick={() => {
                                                    window.open(currentNetworkSettings.blockExplorerURL + "/address/" + proxyInfo.proxyAdmin.addressFromNetwork, "_blank");
                                                }}>
                                                View In Block Explorer
                                                <FontAwesomeIcon
                                                    id="GetImplementationBlockExplorerProxyAdminAddressButtonIcon"
                                                    name="GetImplementationBlockExplorerProxyAdminAddressButtonIcon"
                                                    icon={faCube}
                                                    className="text-secondary ml-2 cursor-pointer"
                                                />
                                            </button>
                                        </div>
                                    </p>
                                )}
                                {implementationAddressIsDifferent && (
                                    <>
                                        <p className="font-semibold text-secondary pt-10">
                                            <FontAwesomeIcon icon={faTriangleExclamation} className="pr-2" />
                                            Your current implementation address in the network is different from the one registered in the block explorer.
                                        </p>
                                        <p className="font-semibold text-secondary">Go to the Block Explorer and verify the code of the curent network implementation address.</p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default GetImplementation;
