import { useEffect, useRef, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { getImageUrl, getImageUrlWithExt } from "@util/util";

function SearchbarDropdown(props) {
    const { networks, clearExecuteForm, setCurrentNetwork } = useContext(AppContext);
    const { options, onInputChange, placeholder, setOptions, currentNetworkInput, setCurrentNetworkInput } = props;
    const ulRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.addEventListener("focus", (event) => {
            ulRef.current.style.display = "flex";
            if (inputRef.current.value) inputRef.current.select();
        });
    }, []);

    return (
        <div className="dropdown dropdown-bottom w-full">
            <input type="text" placeholder={placeholder} ref={inputRef} onChange={onInputChange} autoComplete="new-password" spellCheck="false" value={currentNetworkInput || ""} />
            <ul id="results" className="ul-main" ref={ulRef}>
                {options &&
                    options.map((option, index) => {
                        return (
                            <button
                                id="ExecuteNetworkSearchbarDropdownItem"
                                name="ExecuteNetworkSearchbarDropdownItem"
                                type="button"
                                key={index}
                                onClick={(e) => {
                                    clearExecuteForm("network");
                                    setCurrentNetworkInput(option.name);
                                    ulRef.current.style.display = "none";
                                    setCurrentNetwork(option);
                                    // saveDataStorage(LocalStorage.NetworkEditorSelected, option);
                                    setOptions(networks.filter((_option) => _option.name.toLocaleLowerCase().includes(option.name.toLocaleLowerCase())));
                                    console.debug("Current network set to:", option);
                                }}
                                className="ul-item">
                                <img className="h-5 inline pr-2 " src={`${getImageUrlWithExt(option.logo)}`} alt={option.name} />
                                {option.name}
                            </button>
                        );
                    })}
            </ul>
        </div>
    );
}

export default SearchbarDropdown;
