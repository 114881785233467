import { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "@context/AppContext";
import { defaultNumberFormatter, ether } from "@util/util";
import SearchbarDropdown from "./SearchbarDropdown";
import AddIcon from "@mui/icons-material/Add";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaucet, faGasPump, faShower, faCube, faCopy, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";

// ref: https://github.com/HuakunShen/demo/blob/master/React/search-bar/src/App.jsx

function SearchWallet() {
    const { wallets, walletNames, currentWallet, setCurrentWallet, currentNetwork, setCurrentWalletInput, clearExecuteForm, walletOptions, setWalletOptions, currentWalletBalance, getWallets } =
        useContext(AppContext);
    // const [currentWalletBalance, setCurrentWalletBalance] = useState();
    const [placeholderText, setPlaceholderText] = useState("Search Wallet");
    const inputRef = useRef();

    useEffect(() => {
        setWalletOptions(walletNames);
    }, [walletNames]);

    const onInputChange = (event) => {
        if (walletNames) {
            setWalletOptions(walletNames.filter((option) => option.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentWalletInput(event.target.value);
        }
    };

    function clearWallet() {
        setCurrentWallet();
        setWalletOptions(walletNames);
        setCurrentWalletInput("");
        inputRef.current.value = "";
    }

    // useEffect(() => {
    //     getBalance();
    // }, [currentWallet]);
    // async function getBalance() {
    //     if (currentWallet) setCurrentWalletBalance(defaultNumberFormatter().format(ether(await getWalletCoinBalance(currentWallet.address))));
    // }

    useEffect(() => {
        if (walletOptions.length === 0) setPlaceholderText("Add a new wallet clicking on the + button");
        else setPlaceholderText("Search Wallet");
    }, [walletOptions]);

    function openFaucetPage() {
        try {
            navigator.clipboard.writeText(currentWallet.address);
        } catch (error) {}
        window.open(currentNetwork.faucetURL, "_blank");
    }

    function RefreshWalletsList() {
        getWallets();
    }

    return (
        <>
            <div className="pt-4 items-center">
                <p className="w-32 text-white">Wallet</p>
                <div className="flex items-center pt-2">
                    <SearchbarDropdown options={walletOptions} onInputChange={onInputChange} placeholder={placeholderText} setOptions={setWalletOptions} inputRef={inputRef} />
                    <FontAwesomeIcon
                        id="ExecuteSearchWalletClearButton"
                        name="ExecuteSearchWalletClearButton"
                        icon={faShower}
                        className="ml-2 text-secondary cursor-pointer"
                        onClick={() => {
                            clearWallet();
                        }}
                    />
                    <label htmlFor="manageWallet" className="btn-icon">
                        <FontAwesomeIcon
                            id="ExecuteSearchWalletManageWalletButton"
                            name="ExecuteSearchWalletManageWalletButton"
                            icon={faPlus}
                            className="ml-2 text-secondary cursor-pointer"
                            htmlFor="manageWallet"
                        />
                    </label>
                    {wallets && wallets.length === 0 && (
                        <FontAwesomeIcon
                            id="ExecuteSearchWalletRefreshWalletsListButton"
                            name="ExecuteSearchWalletRefreshWalletsListButton"
                            icon={faRefresh}
                            className="ml-2 text-secondary cursor-pointer"
                            onClick={() => {
                                RefreshWalletsList();
                            }}
                        />
                    )}
                </div>
                {currentNetwork && currentWallet && (
                    <div>
                        <p className="text-secondary pt-2">Address</p>
                        <div className="flex items-center pt-2">
                            <input type="text" placeholder="Address" name="address" maxLength={42} autoComplete="new-password" spellCheck="false" value={currentWallet.address || ""} disabled />
                            <FontAwesomeIcon
                                id="ExecuteSearchWalletAddressCopyButton"
                                name="ExecuteSearchWalletAddressCopyButton"
                                icon={faCopy}
                                onClick={() => {
                                    navigator.clipboard.writeText(currentWallet.address);
                                }}
                                className="text-secondary pl-2 cursor-pointer max-h5 min-h-5"
                            />
                            <a href={currentNetwork.blockExplorerURL + "/address/" + currentWallet.address} target="_blank">
                                <FontAwesomeIcon
                                    id="ExecuteSearchWalletAddressBlockExplorerButton"
                                    name="ExecuteSearchWalletAddressBlockExplorerButton"
                                    icon={faCube}
                                    className="ml-2 text-secondary cursor-pointer max-h-5 min-h-5"
                                />
                            </a>
                            <FontAwesomeIcon icon={faGasPump} className={`pl-4 ${currentWalletBalance <= 0.01 ? "text-red" : ""}`} />
                            <span className={`pl-1 ${currentWalletBalance <= 0.01 ? "text-red" : ""}`}>{currentWalletBalance}</span>
                            {currentNetwork.type === "testnet" && (
                                <FontAwesomeIcon
                                    id="ExecuteSearchWalletAddressFaucetButton"
                                    name="ExecuteSearchWalletAddressFaucetButton"
                                    icon={faFaucet}
                                    className="pl-1 text-secondary cursor-pointer"
                                    onClick={() => {
                                        openFaucetPage();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
                {/* <div className="hidden">
                    <p className="text-secondary pt-2">Private Key</p>
                    <div className="flex items-center pt-2">
                        <input type="password" placeholder="Private Key" name="pk" maxLength={42} autoComplete="new-password" value={currentWallet.pk || ""} disabled />
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default SearchWallet;
