import { useContext } from "react";
import { AppContext } from "@context/AppContext";
import BoltIcon from "@mui/icons-material/Bolt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCalendar, faHeadphones, faStopwatch } from "@fortawesome/free-solid-svg-icons";

import Web3 from "web3"; // Trigger

const ExecuteFunction = () => {
    const { executeFunction, executing, currentNetwork, currentContract, refreshFunctionsABI } = useContext(AppContext);

    // Doesn't work until we have a RPC that supports eth_subscribe
    async function addTrigger() {
        console.log("currentNetwork", currentNetwork);
        const _web3 = new Web3(currentNetwork.RPCs[0].URL);
        console.log("currentContract", currentContract);
        const _abi = await refreshFunctionsABI(currentNetwork.id, currentContract.implementation);
        console.log("currentABI", _abi);
        const _contract = new _web3.eth.Contract(_abi, currentContract.implementation);

        _contract.events
            .Value1Set({
                filkter: {},
                fromBlock: 0,
            })
            .on("connected", function (subscriptionId) {
                console.log("VALUE1SET EVENT REGISTERED! - connected", subscriptionId);
            })
            .on("data", function (event) {
                console.log("VALUE1SET EVENT TRIGGERED! - data", event);
            })
            .on("error", function (error, receipt) {
                // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                console.log("VALUE1SET EVENT ERROR! - data", error, receipt);
            });
    }

    return (
        <div className="flex flex-row mt-5 items-center">
            <button
                className="btn-default"
                onClick={() => {
                    executeFunction(true);
                }}>
                Simulate
                <FontAwesomeIcon id="ExecuteFunctionSimulateButton" name="ExecuteFunctionSimulateButton" icon={faBolt} className="pl-2" />
            </button>
            <button
                className="btn-default ml-4"
                onClick={() => {
                    executeFunction();
                }}>
                Execute
                <FontAwesomeIcon id="ExecuteFunctionExecuteButton" name="ExecuteFunctionExecuteButton" icon={faBolt} className="pl-2" />
            </button>
            <button
                className="btn-default ml-4"
                onClick={() => {
                    addTrigger();
                }}
                disabled>
                Create Trigger
                <FontAwesomeIcon id="ExecuteFunctionCreateTriggerButton" name="ExecuteFunctionCreateTriggerButton" icon={faStopwatch} className="pl-2" />
            </button>
            <button
                className="btn-default ml-4"
                onClick={() => {
                    scheduleFunction();
                }}
                disabled>
                Schedule
                <FontAwesomeIcon id="ExecuteFunctionScheduleButton" name="ExecuteFunctionScheduleButton" icon={faCalendar} className="pl-2" />
            </button>
            {executing && (
                <div>
                    <span className="loader ml-1"></span>
                </div>
            )}
        </div>
    );
};

export default ExecuteFunction;
