export function initTracking() {
    // Do not attach event listeners or do anything else if on localhost (or 127.0.0.1 for Vite)
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        return;
    }

    function trackEvent(eventName, data) {
        try {
            const sessionId = getSessionId();
            // const browserInfo = getBrowserInfo();

            const payload = {
                sessionId,
                eventName,
                data,
            };

            console.log("Tracking Data:", JSON.stringify(payload));
            // console.log(payload);

            // Send the data to the API
            // fetch("http://localhost:3001/logger/log", {
            fetch("https://api.devweb3.co/logger/log", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            }).then((response) => {
                console.log("Response:", response);
                //response.json();
            });
            //.then((data) => console.log("Success:", data));
            //.catch((error) => console.error("Error:", error));
        } catch (error) {}
    }

    function getSessionId() {
        let sessionId = localStorage.getItem("sessionId");
        const lastEventTimestamp = localStorage.getItem("lastEventTimestamp");
        const currentTime = Date.now();

        if (!sessionId || !lastEventTimestamp || currentTime - lastEventTimestamp > 8 * 60 * 60 * 1000) {
            // More than 8 hours since last event or no session ID, generate a new one
            sessionId = generateUniqueId();
            localStorage.setItem("sessionId", sessionId);
        }

        // Update the timestamp of the last event
        localStorage.setItem("lastEventTimestamp", currentTime.toString());

        return sessionId;
    }

    function generateUniqueId() {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }

    function getBrowserInfo() {
        const ua = navigator.userAgent;
        // const browserName = ua.match(/(Chrome|Firefox|Safari|Edge|Opera|MSIE|Trident)/)[0];
        // const browserVersion = ua.match(/(Chrome|Firefox|Safari|Edge|Opera|MSIE|Trident)\/(\d+)/)[2];
        // const os = ua.match(/(Windows|Mac|Linux|Android|iOS)/)[0];

        return {
            ua,
            // browserName,
            // browserVersion,
            // os,
        };
    }

    function generateXPath(element) {
        if (element.id) return `//*[@id="${element.id}"]`;
        const path = [];
        while (element.parentNode) {
            const index = Array.prototype.indexOf.call(element.parentNode.childNodes, element) + 1;
            path.unshift(`${element.tagName}:nth-child(${index})`);
            element = element.parentNode;
        }
        return path.join(" > ");
    }

    function attachEventListeners() {
        document.addEventListener("click", function (event) {
            const target = event.target;
            // const xpath = generateXPath(target);
            // const rect = target.getBoundingClientRect();
            const info = target.getAttribute("data-info");
            if (!target.name && !target.id) return;
            trackEvent("click", {
                currentUrl: window.location.href,
                elementType: target.tagName,
                elementName: target.name,
                elementId: target.id,
                ...(!target.id && !target.name && { elementClass: target.className }),
                // elementText: target.textContent,
                // elementHTML: target.outerHTML,
                // elementXPath: xpath,
                // screenResolution: `${window.innerWidth}x${window.innerHeight}`,
                // elementPosition: {
                //     top: rect.top,
                //     left: rect.left,
                //     width: rect.width,
                //     height: rect.height,
                // },
                ...(info && { info }),
            });
        });
    }

    // Call the function to attach event listeners
    attachEventListeners();
}
