import SearchNetwork from "../network/SearchNetwork";
import SearchContract from "../contract/SearchContract";
import SearchWallet from "../wallet/SearchWallet";
import FunctionSection from "../function/FunctionSection";
import ExecuteFunction from "./ExecuteFunction";
import ManageNetwork from "../network/ManageNetwork";
import ManageContract from "../contract/ManageContract";
import ManageWallet from "../wallet/ManageWallet";
import HistoryConfirmation from "../modals/HistoryConfirmation";
import ExecutionDetail from "@pages/queue/ExecutionDetail";
import Settings from "../settings/Settings";

const Execute = () => {
    return (
        <>
            <p className="text-white font-semibold text-xl">Executor</p>
            <div>
                <SearchNetwork />
            </div>
            <div>
                {/* <SearchNetwork /> */}
                <SearchContract />
                <SearchWallet />
                <FunctionSection />
                <Settings />
                <ExecuteFunction />
            </div>
            <div>
                {/* <ManageNetwork /> */}
                <ManageContract />
                <ManageWallet />
                <HistoryConfirmation />
                <ExecutionDetail />
            </div>
        </>
    );
};

export default Execute;
