import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { NotifyContainer } from "@util/component";
import ReactJson from "react-json-view";
import { ModalTheme } from "@util/enum";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBugSlash, faCubes } from "@fortawesome/free-solid-svg-icons";
import PopupConfirmation from "@components/modals/PopupConfirmation";

const Account = () => {
    const { connectedAccount, getUserData, userLogout, cleanAllUserData, contracts, wallets } = useContext(AppContext);
    const [contractsAccount, setContractsAccount] = useState(null);
    const [walletsAccount, setWalletsAccount] = useState(null);
    const [queueListAccount, setQueueListAccount] = useState(null);
    const [networkRPCsAccount, setNetworkRPCsAccount] = useState(null);
    const [networkGasPricesAccount, setNetworkGasPricesAccount] = useState(null);
    const [RPCActiveAccount, setRPCActiveAccount] = useState(null);
    const [executionHistoryAccount, setExecutionHistoryAccount] = useState(null);
    const [popupMessage1, setPopupMessage1] = useState(null);
    const [popupMessage2, setPopupMessage2] = useState(null);
    const [popupCallback, setPopupCallback] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getUserInfo();
    }, [connectedAccount, contracts, wallets]);

    async function getUserInfo() {
        const _contractsData = await getUserData("contracts");
        setContractsAccount(_contractsData);
        const walletsData = await getUserData("wallets");
        setWalletsAccount(walletsData);
        const queueListData = await getUserData("queuelist");
        setQueueListAccount(queueListData);
        const networkrpcsData = await getUserData("networkrpcs");
        setNetworkRPCsAccount(networkrpcsData);
        const networkgaspricesData = await getUserData("networkgasprices");
        setNetworkGasPricesAccount(networkgaspricesData);
        const networkrpcactiveData = await getUserData("networkrpcactive");
        setRPCActiveAccount(networkrpcactiveData);
        const executionhistoryData = await getUserData("executionhistory");
        setExecutionHistoryAccount(executionhistoryData);
    }

    const confirmLogout = () => {
        setPopupMessage1("Are you sure you want to log off?");
        setPopupMessage2(null);
        setPopupCallback("logout");
        window.popupConfirmation.showModal();
    };

    const confirmDataDeletion = () => {
        setPopupMessage1("Are you really sure you want to delete all your data?");
        setPopupMessage2("Please note that this action is irreversible and cannot be undone.");
        setPopupCallback("datadeletion");
        window.popupConfirmation.showModal();
    };

    const handleConfirmations = (option) => {
        window.popupConfirmation.close();
        console.log("handleConfirmation - User selected:", option);
        if (popupCallback === "logout" && option) {
            console.log("Log out the user");
            userLogout();
            navigate("/");
        }

        if (popupCallback === "datadeletion" && option) {
            cleanAllUserData();
        }
    };

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                {connectedAccount && (
                    <>
                        <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                            <div>
                                <p className="text-white font-semibold text-xl">Account Information</p>
                                <p className="text-secondary">
                                    ID: <span className="text-light">{connectedAccount}</span>
                                </p>
                                <p className="mt-5">
                                    <button
                                        className="bg-[#322B26] p-4 rounded-lg cursor-pointer text-secondary text-sm font-semibold"
                                        onClick={() => {
                                            confirmLogout();
                                        }}>
                                        LOG OUT
                                    </button>
                                </p>
                            </div>
                            <p className="text-white font-semibold text-xl pt-8">Your Data</p>
                            {connectedAccount && (
                                <p className="my-5">
                                    <button
                                        className="bg-[#322B26] p-4 rounded-lg cursor-pointer text-secondary text-sm font-semibold"
                                        onClick={() => {
                                            confirmDataDeletion();
                                        }}>
                                        DELETE ALL MY DATA
                                    </button>
                                </p>
                            )}
                            <p className="text-secondary pt-2">Contracts</p>
                            <div className="pt-1 ReactJson-container">
                                {contractsAccount && <ReactJson src={contractsAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                            <p className="text-secondary pt-2">Wallets</p>
                            <div className="pt-1 ReactJson-container">
                                {walletsAccount && <ReactJson src={walletsAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                            <p className="text-secondary pt-2">Queue List</p>
                            <div className="pt-1 ReactJson-container">
                                {queueListAccount && <ReactJson src={queueListAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                            <p className="text-secondary pt-2">Network RPCs</p>
                            <div className="pt-1 ReactJson-container">
                                {networkRPCsAccount && <ReactJson src={networkRPCsAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                            <p className="text-secondary pt-2">Network Gas Prices</p>
                            <div className="pt-1 ReactJson-container">
                                {networkGasPricesAccount && <ReactJson src={networkGasPricesAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                            <p className="text-secondary pt-2">RPC Active</p>
                            <div className="pt-1 ReactJson-container">
                                {RPCActiveAccount && <ReactJson src={RPCActiveAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                            <p className="text-secondary pt-2">Execution History</p>
                            <div className="pt-1 ReactJson-container">
                                {executionHistoryAccount && <ReactJson src={executionHistoryAccount} iconStyle="triangle" collapsed={true} displayDataTypes={false} theme={ModalTheme} />}
                            </div>
                        </div>
                    </>
                )}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
            <PopupConfirmation mainMessage={popupMessage1} message={popupMessage2} onSelection={handleConfirmations} />
        </div>
    );
};

export default Account;
