import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import HistoryItem from "@components/history/HistoryItem";
import { saveDataStorage, getDataStorage, addNewItemArray } from "@util/util";
import { LocalStorage, FunctionGasType, ValidationStepOperator } from "@util/enum";
import { notify } from "@util/component";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddIcon from "@mui/icons-material/Add";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function ValidationStep({ open, closeModal }) {
    const { executionHistory, currentNetwork, createQueueValidationStep, updateQueueValidationStep, executionQueueDetail } = useContext(AppContext);
    const [inputValues, setInputValues] = useState({});
    const [operation, setOperation] = useState();
    const [operationDisplay, setOperationDisplay] = useState();
    const [validationValue, setValidationValue] = useState();

    useEffect(() => {
        if (open && executionQueueDetail && executionQueueDetail.type === "validation") {
            // TODO: grab validationToEdit and update the selection and input in the screen
            setOperation(executionQueueDetail.validation.operation);
            setOperationDisplay(executionQueueDetail.validation.operationDisplay);
            setValidationValue(executionQueueDetail.validation.value);
            // console.log("ValidationStep executionQueueDetail", executionQueueDetail);
        } else {
            if (open) {
                setOperation(null);
                setOperationDisplay(null);
                setValidationValue(null);
            }
        }
    }, [executionQueueDetail]);

    // useEffect(() => {
    //     if (open) {
    //         setOperation(null);
    //         setOperationDisplay(null);
    //         setValidationValue(null);
    //     }
    // }, [open]);

    const handleInputChange = (event) => {
        //console.log("handleInputChange", event.target.name, event.target.value);
        //const _input = event.target.value.replace(/[^0-9]/g, "");
        const _input = event.target.value; // Accept text and addresses
        setInputValues((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: _input,
            };
        });

        setValidationValue(_input);
    };

    function createValidationStep() {
        if (!operation || !validationValue) {
            notify("You have to select a operation and enter a value to be validated");
            return;
        }
        // console.log("BEFORE ITEM ADDED", currentQueue.queue);
        createQueueValidationStep(operationDisplay, operation, validationValue);
        // console.log("ITEM ADDED", currentQueue.queue);
        closeModal(false);
    }

    function updateValidationStep() {
        if (!operation || !validationValue) {
            notify("You have to select a operation and enter a value to be validated");
            return;
        }
        // console.log("BEFORE ITEM ADDED", currentQueue.queue);
        updateQueueValidationStep(operationDisplay, operation, validationValue);
        // console.log("ITEM ADDED", currentQueue.queue);
        closeModal(false);
    }

    function setOperationValue(e) {
        console.log("ValidationStep setOperationValue e.target", e.target);
        setOperation(e.target.name);
        setOperationDisplay(e.target.innerText);
    }

    return (
        <>
            <div className={`modal ${open ? "modal-open" : ""}`}>
                <div className="modal-box min-w-[800px] max-h-[85%]">
                    <h3 className="font-bold text-lg text-white">Validation Step</h3>
                    <div className="pt-4">
                        <p className="pt-2 pb-1 text-secondary">Operation</p>
                        <ul id="results" className="ul-main">
                            <button
                                type="button"
                                name={ValidationStepOperator.equal}
                                key={ValidationStepOperator.equal}
                                onClick={(e) => {
                                    setOperationValue(e);
                                }}
                                className={`ul-item ${operation === ValidationStepOperator.equal ? "bg-[#1a1511]" : ""}`}>
                                {"Equals"}
                            </button>
                            <button
                                type="button"
                                name={ValidationStepOperator.less}
                                key={ValidationStepOperator.less}
                                onClick={(e) => {
                                    setOperationValue(e);
                                }}
                                className={`ul-item ${operation === ValidationStepOperator.less ? "bg-[#1a1511]" : ""}`}>
                                {"Less Than"}
                            </button>
                            <button
                                type="button"
                                name={ValidationStepOperator.greater}
                                key={ValidationStepOperator.greater}
                                onClick={(e) => {
                                    setOperationValue(e);
                                }}
                                className={`ul-item ${operation === ValidationStepOperator.greater ? "bg-[#1a1511]" : ""}`}>
                                {"Greater Than"}
                            </button>
                            <button
                                type="button"
                                name={ValidationStepOperator.equalOrLess}
                                key={ValidationStepOperator.equalOrLess}
                                onClick={(e) => {
                                    setOperationValue(e);
                                }}
                                className={`ul-item ${operation === ValidationStepOperator.equalOrLess ? "bg-[#1a1511]" : ""}`}>
                                {"Equal or Less Than"}
                            </button>
                            <button
                                type="button"
                                name={ValidationStepOperator.equalOrGreater}
                                key={ValidationStepOperator.equalOrGreater}
                                onClick={(e) => {
                                    setOperationValue(e);
                                }}
                                className={`ul-item ${operation === ValidationStepOperator.equalOrGreater ? "bg-[#1a1511]" : ""}`}>
                                {"Equal or Greater Than"}
                            </button>
                            <button
                                type="button"
                                name={ValidationStepOperator.notEqual}
                                key={ValidationStepOperator.notEqual}
                                onClick={(e) => {
                                    setOperationValue(e);
                                }}
                                className={`ul-item ${operation === ValidationStepOperator.notEqual ? "bg-[#1a1511]" : ""}`}>
                                {"Not Equals"}
                            </button>
                        </ul>
                        <p className="pt-2 pb-1 text-secondary">Value</p>
                        <div>
                            <input type="text" name="validationValue" maxLength={80} autoComplete="new-password" spellCheck="false" onChange={handleInputChange} value={validationValue || ""} />
                            {/* {validationMessages["newRPCURL"] && <ValidationMessage message={validationMessages["newRPCURL"]} />} */}
                        </div>
                    </div>
                    <div className="pt-6">
                        <p className="text-light font-semibold">
                            Previous step result value should be {operationDisplay} than/to {validationValue}
                        </p>
                    </div>
                    <div className="modal-action gap-4 pt-12">
                        {!executionQueueDetail && (
                            <label
                                //htmlFor="validationStep"
                                className="btn-default"
                                onClick={() => {
                                    createValidationStep();
                                }}>
                                ADD
                                <AddIcon className="pl-2" />
                            </label>
                        )}
                        {executionQueueDetail && (
                            <label
                                //htmlFor="validationStep"
                                className="btn-default"
                                onClick={() => {
                                    updateValidationStep();
                                }}>
                                UPDATE
                                <FontAwesomeIcon icon={faPenToSquare} className="pl-2" />
                            </label>
                        )}
                        <label
                            //htmlFor="validationStep"
                            className="btn-default"
                            onClick={() => {
                                closeModal();
                            }}>
                            CLOSE
                            <CloseOutlined className="pl-2" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ValidationStep;
