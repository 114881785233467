import { useState, useEffect } from "react";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPaperPlane, faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

const FeedbackComponent = ({ position, delay = 0, cookieName }) => {
    const [feedback, setFeedback] = useState("");
    const [showTextarea, setShowTextarea] = useState(false);
    const [selected, setSelected] = useState(null);
    const [visible, setVisible] = useState(false);

    function setCookie() {
        Cookies.set(cookieName, true, { expires: 14, secure: true, sameSite: "strict", path: "/" });
    }

    useEffect(() => {
        const _token = Cookies.get(cookieName);
        if (_token) {
            setVisible(false);
            return;
        }

        const timer = setTimeout(() => {
            setVisible(true);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleFeedback = (like) => {
        setShowTextarea(true);
        setSelected(like ? "up" : "down");
        setCookie();
    };

    const handleSendFeedback = () => {
        // Here you can send the feedback to a server or handle it as you like
        console.log({ feedback, selected });
        // Hide the component
        setVisible(false);
        setCookie();
        notify("Thank you for your feedback!", "info");
    };

    const positionStyles = {
        bottomLeft: { bottom: "0", left: "0" },
        bottomCenter: { bottom: "0", left: "50%", transform: "translateX(-50%)" },
        bottomRight: { bottom: "0", right: "0" },
        middleLeft: { top: "50%", left: "0", transform: "translateY(-50%)" },
        middleCenter: { top: "50%", left: "50%", transform: "translate(-50%, -50%)" },
        middleRight: { top: "50%", right: "0", transform: "translateY(-50%)" },
        topLeft: { top: "0", left: "0" },
        topCenter: { top: "0", left: "50%", transform: "translateX(-50%)" },
        topRight: { top: "0", right: "0" },
    };

    const buttonStyles = {
        default: {
            border: "none",
            padding: "10px",
            marginRight: "10px",
        },
        selected: {
            background: "#000",
            // background: "#4CAF50",
            color: "#ffb564",
            // color: "#fff",
        },
        selectedRed: {
            // background: "#F44336",
            background: "#000",
            color: "#ffb564",
            // color: "#fff",
        },
        notSelected: {
            background: "#000",
            // background: "#333",
            color: "#333",
            // color: "#777",
        },
        notSelectedRed: {
            background: "#000",
            // background: "#333",
            // color: "#777",
            color: "#333",
        },
        send: {
            // background: "#4CAF50",
            background: "#ffb564",
            // color: "#fff",
            color: "#000",
            border: "none",
            padding: "10px",
        },
    };

    if (!visible) return null;

    return (
        <div
            style={{
                background: "#333",
                color: "#fff",
                padding: "10px",
                position: "fixed",
                ...positionStyles[position],
                zIndex: "9999",
            }}>
            <div className="flex justify-between mb-2">
                <div>
                    <button
                        id="feedbackButtonThumbsUp"
                        name="feedbackButtonThumbsUp"
                        className="rounded-lg"
                        onClick={() => handleFeedback(true)}
                        style={{
                            ...buttonStyles.default,
                            ...(selected === "up" ? buttonStyles.selected : selected === "down" ? buttonStyles.notSelected : buttonStyles.selected),
                        }}>
                        <FontAwesomeIcon id="feedbackButtonThumbsUpIcon" name="feedbackButtonThumbsUpIcon" icon={faThumbsUp} />
                    </button>
                    <button
                        id="feedbackButtonThumbsDown"
                        name="feedbackButtonThumbsDown"
                        className="rounded-lg"
                        onClick={() => handleFeedback(false)}
                        style={{
                            ...buttonStyles.default,
                            ...(selected === "down" ? buttonStyles.selectedRed : selected === "up" ? buttonStyles.notSelectedRed : buttonStyles.selectedRed),
                        }}>
                        <FontAwesomeIcon id="feedbackButtonThumbsDownIcon" name="feedbackButtonThumbsDownIcon" icon={faThumbsDown} />
                    </button>
                </div>
                {selected && (
                    <button
                        id="feedbackButtonClose"
                        name="feedbackButtonClose"
                        className="pr-2"
                        onClick={() => {
                            setCookie();
                            setVisible(false);
                        }}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                )}
            </div>
            {showTextarea && (
                <div>
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        rows="3"
                        style={{
                            width: "100%",
                            background: "#555",
                            color: "#fff",
                            border: "none",
                            padding: "10px",
                        }}
                        className="rounded-lg"
                        placeholder={selected === "down" ? "Tell us how to improve" : "What you liked?"}></textarea>
                    <div style={{ marginTop: "5px" }}>
                        <button id="feedbackButtonSend" name="feedbackButtonSend" onClick={handleSendFeedback} style={buttonStyles.send} className="rounded-lg w-full">
                            {/* Send ▶️ */}
                            <FontAwesomeIcon id="feedbackButtonSendIcon" name="feedbackButtonSendIcon" icon={faEnvelope} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbackComponent;
