import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { timeSince, isObjEmpty } from "../../util/util";
import { ModalTheme } from "../../util/enum";
import ReactJson from "react-json-view";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const PopupCompilationResults = ({ lastCompilationInfo }) => {
    // console.log("PopupCompilationResults lastCompilationInfo", lastCompilationInfo);
    return (
        <dialog id="popupCompilationResults" className="modal rounded-lg bg-transparent">
            <form method="dialog" className="modal-box text-light min-w-[80%]">
                <p className="text-white font-bold text-lg">Compilation Result</p>
                {lastCompilationInfo && (
                    <div className="bg-black py-1 rounded-lg ReactJson-container">
                        <ReactJson src={lastCompilationInfo} iconStyle="triangle" collapsed={5} displayDataTypes={false} theme={ModalTheme} />
                    </div>
                )}
                {!lastCompilationInfo && <p className="pt-2">😒 What you are expecting to see here if you didn't compile anything...</p>}
                <div className="modal-action flex justify-end">
                    <button id="PopupCompilationResultsCloseButton" name="PopupCompilationResultsCloseButton" className="btn-default mt-2 justify-end">
                        Close
                        <CloseOutlined className="pl-2 text-red" />
                    </button>
                </div>
            </form>
        </dialog>
    );
};

export default PopupCompilationResults;
