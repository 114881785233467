import { useEffect, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { LocalStorage } from "@util/enum";
import { getDataStorage } from "@util/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faGears, faCalculator, faFloppyDisk, faCode, faPersonRunning, faHome, faLocationPin, faWallet, faClone, faRectangleList, faCoins, faBars } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@components/shared/Tooltip/Tooltip";
import PopupNetworkApp from "@components/modals/network/PopupNetworkApp";
import { useLocation } from "react-router-dom";
import { LogoMin } from "../shared/Logo/Logo";

const SideMenu = () => {
    const { connectedAccount, syncing } = useContext(AppContext);
    const { collapseSidebar, collapsed } = useProSidebar();

    const location = useLocation();

    useEffect(() => {
        collapseSidebar(getDataStorage(LocalStorage.SideMenuState) ?? true);
    }, []);

    const styleLevel0 = (active) => {
        return {
            paddingLeft: "5px",
            paddingRight: "5px",
            color: "#fff",
            backgroundColor: active ? undefined : undefined,
            "&:hover": {
                backgroundColor: "#3d2413 !important",
                color: "#fff !important",
                // borderRadius: "8px !important",
                // fontWeight: "bold !important",
            },
        };
    };

    const styleLevel1 = (active) => {
        return {
            paddingLeft: "5px",
            paddingRight: "5px",
            color: "#fff",
            backgroundColor: active ? "#000" : "#000",
            "&:hover": {
                backgroundColor: "#3d2413 !important",
                color: "#fff !important",
                // borderRadius: "8px !important",
                // fontWeight: "bold !important",
            },
        };
    };

    const handleRunnerAPIDocsClick = () => {
        window.open("https://devweb3.gitbook.io/runner-api/", "_blank");
    };

    const sidebarStyles = {
        marginLeft: collapsed ? "0px" : "0px",
        marginRight: collapsed ? "0px" : "0px",
        paddingLeft: collapsed ? "0px" : "0px",
        paddingRight: collapsed ? "0px" : "0px",
    };

    const menuIconStyle = {
        marginRight: collapsed ? "0px" : "",
    };

    return (
        <div className="flex flex-col h-full">
            <div className="flex-grow-0 flex flex-col" style={{ height: "100%" }}>
                {/* <div className="flex h-full"> */}
                <Sidebar
                    style={sidebarStyles}
                    backgroundColor="#1a1511"
                    width={"180px"}
                    collapsedWidth={"45px"}
                    breakPoint="lg"
                    rootStyles={{
                        borderWidth: "0px",
                        marginTop: "0px",
                        position: "relative",
                        height: "100%",
                    }}>
                    <Menu
                        menuItemStyles={{
                            // marginRight: "0px",
                            // ".menu-anchor": {
                            //     backgroundColor: "red",
                            //     display: "flex",
                            //     height: "100%",
                            //     // "&:hover": {
                            //     //     backgroundColor: "green",
                            //     // },
                            // },
                            button: ({ level, active, disabled }) => {
                                if (level === 0) return styleLevel0(active);
                                if (level === 1) return styleLevel1(active);
                            },
                            icon: () => {
                                return { marginRight: "0px" };
                            },
                        }}>
                        {location.pathname === "/editor" && (
                            <>
                                <div
                                    className="flex items-center justify-center py-4 w-full cursor-pointer"
                                    onClick={() => {
                                        collapseSidebar();
                                        saveDataStorage(LocalStorage.SideMenuState, !collapsed);
                                    }}>
                                    <button id="NavBarSideMenuCollapseButton" name="NavBarSideMenuCollapseButton" className="text-white">
                                        <LogoMin id="SideMenuLogoIcon" name="SideMenuLogoIcon" size={20} backgroundColor="bg-input" foregroundColor="#FFF" />
                                        {/* {!collapsed && (
                                        <span id="SideMenuLogoText" name="SideMenuLogoText" className="">
                                            DevWeb3
                                        </span>
                                    )} */}
                                    </button>
                                </div>
                                {/* <div className="flex items-center justify-center py-4 w-full cursor-pointer">
                                    <button
                                        id="NavBarSideMenuCollapseButton"
                                        name="NavBarSideMenuCollapseButton"
                                        className="text-white w-[45px]"
                                        onClick={() => {
                                            collapseSidebar();
                                            saveDataStorage(LocalStorage.SideMenuState, !collapsed);
                                        }}>
                                        <FontAwesomeIcon id="NavBarSideMenuCollapseButtonIcon" name="NavBarSideMenuCollapseButtonIcon" icon={faBars} />
                                    </button>
                                </div> */}
                            </>
                        )}
                        {/* {location.pathname != "/editor" && ( */}
                        <MenuItem
                            id="SideMenuLinkHomeIconMenuItem"
                            name="SideMenuLinkHomeIconMenuItem"
                            className="pt-2"
                            icon={<FontAwesomeIcon id="SideMenuLinkHomeIcon" name="SideMenuLinkHomeIcon" icon={faHome} title="Home Page" />}
                            component={<Link id="SideMenuLinkHomeLink" name="SideMenuLinkHomeLink" to="/" />}>
                            {!collapsed && (
                                <span id="SideMenuLinkHome" name="SideMenuLinkHome" className="pl-2">
                                    Home
                                </span>
                            )}
                        </MenuItem>
                        {/* )} */}
                    </Menu>
                    <div className={`pt-4 pl-6 pb-4 ${collapsed ? "opacity-0" : "opacity-100"} `}>
                        <span className="text-md">Main</span>
                    </div>
                    <Menu
                        menuItemStyles={{
                            // marginRight: "0px",
                            // ".menu-anchor": {
                            //     backgroundColor: "red",
                            //     display: "flex",
                            //     height: "100%",
                            //     // "&:hover": {
                            //     //     backgroundColor: "green",
                            //     // },
                            // },
                            button: ({ level, active, disabled }) => {
                                if (level === 0) return styleLevel0(active);
                                if (level === 1) return styleLevel1(active);
                            },
                            icon: () => {
                                return { marginRight: "0px" };
                            },
                        }}>
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuSourcecodeIcon" name="SideMenuSourcecodeIcon" icon={faCode} />}
                            component={<Link id="SideMenuSourcecodeLink" name="SideMenuSourcecodeLink" to="/sourcecode" />}
                            title="Code Editor">
                            {!collapsed && (
                                <span id="SideMenuSourcecodeText" name="SideMenuSourcecodeText" className="pl-2">
                                    Code Editor
                                </span>
                            )}
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuExecutorIcon" name="SideMenuExecutorIcon" icon={faBolt} />}
                            component={<Link id="SideMenuExecutorLink" name="SideMenuExecutorLink" to="/execute" />}
                            title="Executor">
                            {!collapsed && (
                                <span id="SideMenuExecutorText" name="SideMenuExecutorText" className="pl-2">
                                    Executor
                                </span>
                            )}
                        </MenuItem>
                        {/* <MenuItem icon={<FontAwesomeIcon icon={faListOl} />} component={<Link to="/queuelist" />}>
                            {!collapsed && <span className="pl-2">Queue</span>}
                        </MenuItem> */}
                        {/* <MenuItem icon={<CodeIcon />} component={<Link to="/editor" />}>
                                {!collapsed && <span className="pl-2">Code Editor</span>}
                            </MenuItem> */}
                        <SubMenu label="Runner API" icon={<FontAwesomeIcon id="SideMenuRunnerIcon" name="SideMenuRunnerIcon" icon={faPersonRunning} />} title="Runner API">
                            <MenuItem component={<Link id="SideMenuRunnerAPIKeyLink" name="SideMenuRunnerAPIKeyLink" to="/runnerAPI" />}>
                                <span className="pl-1" id="SideMenuRunnerAPIKeyText" name="SideMenuRunnerAPIKeyText">
                                    API Key
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleRunnerAPIDocsClick();
                                }}>
                                <span className="pl-1" id="SideMenuRunnerDocsText" name="SideMenuRunnerDocsText">
                                    Docs
                                </span>
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                    <div className={`pt-6 pl-6 pb-4 ${collapsed ? "opacity-0" : "opacity-100"} `}>
                        <span id="SideMenuToolsText" name="SideMenuToolsText" className="text-md">
                            Tools
                        </span>
                    </div>
                    <Menu
                        menuItemStyles={{
                            // marginRight: "0px",
                            // ".menu-anchor": {
                            //     backgroundColor: "red",
                            //     display: "flex",
                            //     height: "100%",
                            //     // "&:hover": {
                            //     //     backgroundColor: "green",
                            //     // },
                            // },
                            button: ({ level, active, disabled }) => {
                                if (level === 0) return styleLevel0(active);
                                if (level === 1) return styleLevel1(active);
                            },
                            icon: () => {
                                return { marginRight: "0px" };
                            },
                        }}>
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuConverterIcon" name="SideMenuConverterIcon" icon={faCalculator} />}
                            component={<Link id="SideMenuConverterLink" name="SideMenuConverterLink" to="/converter" />}
                            title="ETH Converter">
                            {!collapsed && (
                                <span id="SideMenuConverterText" name="SideMenuConverterText" className="pl-2">
                                    Converter
                                </span>
                            )}
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuCreateWalletIcon" name="SideMenuCreateWalletIcon" icon={faWallet} />}
                            component={<Link id="SideMenuCreateWalletLink" name="SideMenuCreateWalletLink" to="/createwallet" />}
                            title="Create Wallet">
                            {!collapsed && (
                                <span id="SideMenuCreateWalletText" name="SideMenuCreateWalletText" className="pl-2">
                                    Create Wallet
                                </span>
                            )}
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuAddressFromPKIcon" name="SideMenuAddressFromPKIcon" icon={faLocationPin} />}
                            component={<Link id="SideMenuAddressFromPKLink" name="SideMenuAddressFromPKLink" to="/addressfrompk" />}
                            title="Address from PK">
                            {!collapsed && (
                                <span id="SideMenuAddressFromPKText" name="SideMenuAddressFromPKText" className="pl-2">
                                    Address from PK
                                </span>
                            )}
                        </MenuItem>
                        {/* <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuProxyInfoIcon" name="SideMenuProxyInfoIcon" icon={faClone} />}
                            component={<Link id="SideMenuProxyInfoLink" name="SideMenuProxyInfoLink" to="/implementation" />}
                            title="Proxy Info">
                            {!collapsed && (
                                <span id="SideMenuProxyInfoText" name="SideMenuProxyInfoText" className="pl-2">
                                    Proxy Info
                                </span>
                            )}
                        </MenuItem> */}
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuABIGeneratorIcon" name="SideMenuABIGeneratorIcon" icon={faRectangleList} />}
                            component={<Link id="SideMenuABIGeneratorLink" name="SideMenuABIGeneratorLink" to="/abi" />}
                            title="ABI Generator">
                            {!collapsed && (
                                <span id="SideMenuABIGeneratorText" name="SideMenuABIGeneratorText" className="pl-2">
                                    ABI Generator
                                </span>
                            )}
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon id="SideMenuWalletBalanceIcon" name="SideMenuWalletBalanceIcon" icon={faCoins} />}
                            component={<Link id="SideMenuWalletBalanceLink" name="SideMenuWalletBalanceLink" to="/balance" />}
                            title="Wallet Balance">
                            {!collapsed && (
                                <span id="SideMenuWalletBalanceText" name="SideMenuWalletBalanceText" className="pl-2">
                                    Wallet Balance
                                </span>
                            )}
                        </MenuItem>
                        {/* <SubMenu label="Settings" icon={<FontAwesomeIcon icon={faWallet} />}>
                                <MenuItem component={<Link to="/tools/create" />}> Create</MenuItem>
                                <MenuItem component={<Link to="/tools/balances" />}> Balances</MenuItem>
                            </SubMenu> */}
                    </Menu>
                    {connectedAccount && (
                        <>
                            <div className={`pt-6 pl-6 pb-4 ${collapsed ? "opacity-0" : "opacity-100"} `}>
                                <span className="text-md">Other</span>
                            </div>
                            <Menu
                                menuItemStyles={{
                                    ".menu-anchor": {
                                        backgroundColor: "red",
                                        display: "flex",
                                        height: "100%",
                                        // "&:hover": {
                                        //     backgroundColor: "green",
                                        // },
                                    },
                                    button: ({ level, active, disabled }) => {
                                        if (level === 0) return styleLevel0(active);
                                        if (level === 1) return styleLevel1(active);
                                    },
                                }}>
                                <SubMenu label="Settings" icon={<FontAwesomeIcon icon={faGears} />} title="Settings">
                                    <MenuItem component={<Link id="SideMenuAccountLink" name="SideMenuAccountLink" to="/account" />} title="My Account">
                                        <span id="SideMenuAccountText" name="SideMenuAccountText" className="pl-1">
                                            Account
                                        </span>
                                    </MenuItem>
                                    <MenuItem component={<Link id="SideMenuNetworksLink" name="SideMenuNetworksLink" to="/networks" />} title="Networks">
                                        <span id="SideMenuNetworksText" name="SideMenuNetworksText" className="pl-1">
                                            Networks
                                        </span>
                                    </MenuItem>
                                    <MenuItem component={<Link id="SideMenuContractsLink" name="SideMenuContractsLink" to="/contracts" />} title="Contracts">
                                        <span id="SideMenuContractsText" name="SideMenuContractsText" className="pl-1">
                                            Contracts
                                        </span>
                                    </MenuItem>
                                    <MenuItem component={<Link id="SideMenuWalletsLink" name="SideMenuWalletsLink" to="/wallets" />} title="Wallets">
                                        <span id="SideMenuWalletsText" name="SideMenuWalletsText" className="pl-1">
                                            Wallets
                                        </span>
                                    </MenuItem>
                                </SubMenu>
                            </Menu>
                        </>
                    )}
                    <div
                        style={{
                            position: "absolute", // Set absolute positioning
                            bottom: "0", // Stick to bottom
                            width: "100%", // Take full width
                            paddingBottom: "10px", // Add padding for visual flair
                        }}>
                        <Menu
                            menuItemStyles={{
                                button: ({ level, active, disabled }) => {
                                    if (level === 0) return styleLevel0(active);
                                    if (level === 1) return styleLevel1(active);
                                },
                                icon: () => {
                                    return { marginRight: "0px" };
                                },
                            }}>
                            {/* <MenuItem
                                icon={<FontAwesomeIcon icon={faNetworkWired} />}
                                onClick={() => {
                                    window.popupNetworkApp.showModal();
                                }}>
                                {!collapsed && <span className="pl-2">Network</span>}
                            </MenuItem> */}
                            {location.pathname != "/" && !connectedAccount && (
                                <MenuItem
                                    icon={<FontAwesomeIcon id="SideMenuLoginIcon" name="SideMenuLoginIcon" icon={faFloppyDisk} />}
                                    component={<Link id="SideMenuLoginLink" name="SideMenuLoginLink" to="/login" />}
                                    title="Login">
                                    {!collapsed && (
                                        <span id="SideMenuLoginLogin" name="SideMenuLoginLogin" className="pl-2">
                                            Login
                                        </span>
                                    )}
                                </MenuItem>
                            )}
                            {location.pathname != "/" && connectedAccount && (
                                <MenuItem
                                    icon={<FontAwesomeIcon id="SideMenuLoginIcon" name="SideMenuLoginIcon" icon={faFloppyDisk} />}
                                    component={<Link id="SideMenuLoginLink" name="SideMenuLoginLink" to="/login" />}
                                    title="Data Sync is On">
                                    {!collapsed && (
                                        <span id="SideMenuLoginLogin" name="SideMenuLoginLogin" className="pl-2">
                                            Data Sync is <span className="text-green">On</span>
                                        </span>
                                    )}
                                </MenuItem>
                            )}
                        </Menu>
                    </div>
                </Sidebar>
            </div>
            {/* <div className="flex-grow"></div>
            <div className="flex-grow-0 flex flex-col items-center max-w-[45px]">
                {syncing && (
                    <div className="relative w-full text-center py-4">
                        <span className="loaderSpinner ml-1"></span>
                    </div>
                )}
                <div
                    className="relative w-full text-center py-4 cursor-pointer"
                    onClick={() => {
                        window.popupNetworkApp.showModal();
                    }}>
                    <FontAwesomeIcon icon={faNetworkWired} className="text-light" />
                </div>
                {location.pathname != "/" && !connectedAccount && (
                    <Link to="/login" className="cursor-pointer">
                        <div className="relative w-full text-center py-4">
                            <Tooltip position="top-right" text="Data synchronization is either limited or not occurring due to your current logged-out status.">
                                <FontAwesomeIcon icon={faFloppyDisk} className="text-light" />
                                <FontAwesomeIcon icon={faTriangleExclamation} className="text-red h-3 absolute top-3 left-3" />
                            </Tooltip>
                        </div>
                    </Link>
                )}
                {location.pathname != "/" && connectedAccount && (
                    <Link to="/account" className="cursor-pointer relative">
                        <div className="relative w-full text-center py-4">
                            <FontAwesomeIcon icon={faFloppyDisk} className="text-light" />
                            <FontAwesomeIcon icon={faCheck} className="text-green h-3 absolute bottom-4 left-3" />
                        </div>
                    </Link>
                )}
                {location.pathname === "/" && !connectedAccount && (
                    <Link to="/login" className="cursor-pointer">
                        <span className="flex items-center border-2 h-10 input text-light font-semibold bg-input border-[#AA6840] focus:outline-0 cursor-pointer">
                            Login
                            <FontAwesomeIcon icon={faLockOpen} className="pl-2" />
                        </span>
                    </Link>
                )}
            </div> */}
            <PopupNetworkApp />
        </div>
    );
};

export default SideMenu;
