import { useEffect, useState, useContext } from "react";
import { AppContext } from "@context/AppContext";
import SearchbarDropdown from "./SearchbarDropdown";
import AddIcon from "@mui/icons-material/Add";
import { isObjEmpty } from "@util/util";

// ref: https://github.com/HuakunShen/demo/blob/master/React/search-bar/src/App.jsx

function SearchNetwork() {
    const { networkOptions, networkSettingsOptions, setNetworkSettingsOptions, networks } = useContext(AppContext);
    const [currentNetworkSearchInput, setCurrentNetworkSearchInput] = useState();

    useEffect(() => {
        setNetworkSettingsOptions(networks);
    }, [networks]);

    // useEffect(() => {
    //     setCurrentNetworkSettings(currentNetwork);
    // }, [currentNetwork]);

    // console.log("SearchNetwork Networks", networks);
    // console.log("SearchNetwork NetworksOptions", networkOptions);
    //  console.log("SearchNetwork CurrentNetwork", currentNetwork);

    const onInputChange = (event) => {
        if (event.target.value) {
            console.log("onInputChange - event.target.value", event.target.value);
            setNetworkSettingsOptions(networks.filter((option) => option.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())));
            setCurrentNetworkSearchInput(event.target.value);
        } else {
            setCurrentNetworkSearchInput("");
            setNetworkSettingsOptions(networks);
            //setCurrentNetwork({});
        }
    };

    return (
        <div>
            <SearchbarDropdown
                options={networkSettingsOptions}
                onInputChange={onInputChange}
                placeholder="Select a Network"
                setOptions={setNetworkSettingsOptions}
                currentNetworkSearchInput={currentNetworkSearchInput}
                setCurrentNetworkSearchInput={setCurrentNetworkSearchInput}
            />
        </div>
    );
}

export default SearchNetwork;
