// import React from "react";
// import { getImageUrl } from "@util/util";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";

const ErrorPage = () => {
    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-full">
                <p className="text-5xl font-bold text-white">404</p>
                <p className="text-3xl font-bold mt-4 text-white">OOOOOOPS! You are not supposed to be here.</p>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
        </div>
    );
};

export default ErrorPage;
