import { FileUtil } from "@util/fileUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

const FilePanel = ({ selectedItem, tabsLocalContractsSourceCode, activeTab }) => {
    // console.log("FilePanel selectedItem", selectedItem);
    if (!selectedItem) return;

    function saveFile() {
        // ! BUG: not true that activeTab is the same for fileHanles and tabsLocalContractsSourceCode - tabsLocalContractsSourceCode only 0 is filled with the last double clicked file
        FileUtil.writeToFile(selectedItem.handle, tabsLocalContractsSourceCode[activeTab].code);
    }

    return (
        <div className="py-2">
            <p className="px-2 text-white font-semibold text-md">File Details</p>
            <div className="px-2 pt-1 text-light font-normal">
                <p>
                    <span className="text-secondary">Name:</span> <span>{selectedItem && selectedItem.name}</span>
                </p>
                <p>
                    <span className="text-secondary">Size:</span> <span>{selectedItem && FileUtil.convertFileSize(selectedItem.size)}</span>
                </p>
                <p>
                    <span className="text-secondary">Type:</span> <span>{selectedItem && FileUtil.getFileType(selectedItem.extension ?? selectedItem.name)}</span>
                </p>
            </div>
            <div className="flex justify-center pt-2">
                <p>
                    <button
                        className="btn btn-default"
                        onClick={() => {
                            saveFile();
                        }}>
                        Save
                        <FontAwesomeIcon icon={faFloppyDisk} className="pl-3" />
                    </button>
                    <button
                        className="btn btn-default ml-2"
                        onClick={() => {
                            alert("Create a file like new file button and save it");
                        }}>
                        Save as
                        <FontAwesomeIcon icon={faFloppyDisk} className="pl-3" />
                        ...
                    </button>
                    {/* <input type="checkbox" checked="checked" className="checkbox" />
                        <span>Autosave</span> */}
                </p>
            </div>
        </div>
    );
};

export default FilePanel;
