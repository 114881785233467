import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import PopupNetworkSearchNetwork from "./PopupNetworkSearchNetwork";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const PopupNetwork = ({ currentEditorNetwork, setCurrentEditorNetwork }) => {
    return (
        <dialog id="popupNetwork" className="modal rounded-lg bg-transparent">
            <form method="dialog" className="modal-box text-light w-[500px] min-h-[650px]">
                <h3 className="font-bold text-lg text-white">Network</h3>
                <div className="pt-4">
                    <p className="text-secondary">Current Network</p>
                    <p>
                        {currentEditorNetwork && (
                            <span>
                                {currentEditorNetwork.name} ({currentEditorNetwork.id})
                            </span>
                        )}
                        {!currentEditorNetwork && <span>&nbsp;</span>}
                    </p>
                    <p className="pt-4 text-secondary">Select a New Network</p>
                    <PopupNetworkSearchNetwork setSelectedNetwork={setCurrentEditorNetwork} />
                </div>
                <div className="modal-action flex justify-end pt-6">
                    <button id="PopupNetworkCloseButton" name="PopupNetworkCloseButton" className="btn-default mt-2 justify-end">
                        Close
                        <CloseOutlined className="pl-2 text-red" />
                    </button>
                </div>
            </form>
        </dialog>
    );
};

export default PopupNetwork;
