import { useEffect, useContext, useState } from "react";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import Execute from "./execute/Execute";
import Result from "./result/Result";
import History from "./history/History";
import SideMenu from "@components/sidemenu/SideMenu";
import { NotifyContainer } from "@util/component";

const ExecutePage = () => {
    const { connectedAccount, dataLoaded, currentNetwork } = useContext(AppContext);
    const [userLogged, setUserLogged] = useState("loading");
    console.log("Execute render", connectedAccount, dataLoaded);

    useEffect(() => {
        if (connectedAccount && !dataLoaded) {
            setUserLogged("loading");
        }
        if (!connectedAccount) {
            setUserLogged(false);
        }
        if (connectedAccount && dataLoaded) {
            setUserLogged(true);
        }
    }, [connectedAccount, dataLoaded]);

    if (userLogged === "loading") {
        return (
            <div className="container">
                <nav className="navbar">
                    <NavBar />
                </nav>
                <aside className="sidebar">
                    <SideMenu />
                </aside>
                <div className="container content-full text-lg text-secondary">
                    <p>Loading Page...</p>
                    <p className="loaderSpinner mt-4"></p>
                    {/* <p className="pt-10">Don't forget to select a network to start</p> */}
                </div>
                <footer className="footer">
                    <Footer />
                </footer>
                <NotifyContainer />
            </div>
        );
    }

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            {userLogged === true && (
                <main className="content-grid">
                    <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                        <Execute />
                    </div>
                    <div className="min-[1920px]:col-span-5 2xl:col-span-6 col-span-12 p-2">
                        <Result />
                    </div>
                    <div className="min-[1920px]:col-span-3 2xl:col-span-12 col-span-12 p-2">
                        <History behavior={"execute"} />
                    </div>
                </main>
            )}
            {userLogged === false && (
                <main className="content-full px-4">
                    <p className="text-lg text-secondary">You need to be authenticated to use this page.</p>
                    <p className="text-lg text-light pt-2">Click on the top right button to go to the Login page.</p>
                </main>
            )}
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default ExecutePage;
