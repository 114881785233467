import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faLocationDot, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Web3util } from "@util/web3util";
import ValidationMessage from "@components/shared/ValidationMessage";
import { Helmet } from "react-helmet";

import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import Web3 from "web3";
import axios from "axios";
import abiFile from "./abirouter.json";

const Test = () => {
    const { getTokenBalances, networks } = useContext(AppContext);
    const [tokens, setTokens] = useState();

    useEffect(() => {
        if (networks) {
            console.log("Networks", networks);

            // (async () => {
            //     try {
            //         const balances = await getTokenBalances("0x0CB3BaF8C1932504825B7254E2c6f9C6b5b4EE03");
            //         setTokens(balances); // Now we're setting the state after the promise resolves
            //     } catch (error) {
            //         console.error("Failed to get token balances:", error);
            //     }
            // })();
        }
    }, [networks]);

    async function getABIFunction(contractAddress) {
        const response = await axios.get(`https://api-testnet.polygonscan.com/api?module=contract&action=getabi&address=${contractAddress}&apikey=3JG4Y1U7X173H987KRXF428KEMK1XIH76N`);
        if (response && response.data && response.data.status === "1" && response.data.result) {
            const abi = JSON.parse(response.data.result);
            const functions = abi.filter((item) => item.type === "function");
            console.log("ABI", abi);
            console.log("Functions", functions);
        }
    }

    // return (
    //     <div>
    //         {
    //             JSON.stringify(tokens, null, 2)

    //             // getBalances()
    //         }
    //     </div>
    // );

    async function article() {
        const web3 = new Web3("https://data-seed-prebsc-2-s3.binance.org:8545/");

        // Function to calculate transaction cost in USD
        async function calculateTransactionCostInUSD(gasPriceGwei, gasUsed, ethPriceUSD) {
            // Convert gas price from Gwei to Wei
            const gasPriceWei = web3.utils.toWei(gasPriceGwei.toString(), "gwei");

            // Calculate total cost in Wei
            const totalCostWei = gasPriceWei * gasUsed;

            // Convert total cost to ETH
            const totalCostEth = web3.utils.fromWei(totalCostWei.toString(), "ether");

            // Convert total cost to USD
            const totalCostUSD = totalCostEth * ethPriceUSD;

            return totalCostUSD;
        }

        // Example usage
        const gasPriceGwei = 17; // Example gas price in Gwei
        const gasUsed = 21000; // Standard gas limit for a simple transaction
        const ethPriceUSD = 2500; // Current price of ETH in USD

        calculateTransactionCostInUSD(gasPriceGwei, gasUsed, ethPriceUSD)
            .then((cost) => console.log(`Total Transaction Cost: $${cost.toFixed(2)} USD`))
            .catch((error) => console.error(error));
    }

    async function testDEX() {
        // const web3 = new Web3("https://rpc.ankr.com/polygon/345dbaf3419e8fff776111ddc60566f02f97e2707082085096e6689319878930"); // polygon
        const web3 = new Web3("https://rpc.ankr.com/eth/345dbaf3419e8fff776111ddc60566f02f97e2707082085096e6689319878930"); // ethereum
        // const routerAddress = "0x93bcDc45f7e62f89a8e901DC4A0E2c6C427D9F25"; // uniswap v2 router plygon
        const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"; // uniswap v2 router ethereum
        const amountIn = web3.utils.toWei("1", "ether");
        // const path = ["0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"]; // WETH to usdt polygon
        // const path = ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48']; // WETH to usdc ethereum // 6 decimals
        const path = ["0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", "0xdAC17F958D2ee523a2206206994597C13D831ec7"]; // WETH to usdt ethereum // 6 decimals
        const contractABI = abiFile;

        //         Coins/Tokens:
        // WETH - 0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619
        // Link - 0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39
        // Matic - 0x0000000000000000000000000000000000001010
        // UNI - 0xb33eaad8d922b1083446dc23f610c2567fb5180f
        // USDC - 0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359
        // USDT - 0xc2132D05D31c914a87C6611C10748AEb04B58e8F

        const contract = new web3.eth.Contract(contractABI, routerAddress);

        contract.methods
            .getAmountsOut(amountIn, path)
            .call()
            .then((result) => {
                console.log("Value from Contract function getAmountsOut:", result);
                console.log("Price is ", parseInt(result[path.length - 1]) / 10 ** 6);
            })
            .catch((error) => {
                console.error("Error calling Contract function getAmountsOut:", error);
            });
    }

    async function bridgeToBlast() {
        console.log("Starting the bridge");
        const web3Sepolia = new Web3("https://rpc.ankr.com/eth_sepolia/345dbaf3419e8fff776111ddc60566f02f97e2707082085096e6689319878930"); // ethereum sepolia
        const web3BlastSepolia = new Web3("https://sepolia.blast.io"); // blast sepolia

        const PRIVATE_KEY = "8bef0d0259a3c4532f788aa2b7842e50a8d8f759edba07933f949ca89f914953"; // airdrop wallet

        const BlastBridgeAddress = "0xc644cc19d2A9388b71dd1dEde07cFFC73237Dca8";

        const amountToBridge = web3Sepolia.utils.toWei("0.1", "ether");

        // Transaction to send 0.1 Sepolia ETH
        console.log("Preparing the transaction");
        const tx = {
            to: BlastBridgeAddress,
            value: amountToBridge,
            gas: 1000000, // Set an appropriate gas limit
        };

        // Sign the transaction
        console.log("Signing the transaction");
        const signedTx = await web3Sepolia.eth.accounts.signTransaction(tx, PRIVATE_KEY);
        console.log("Transaction signed", signedTx);

        // Send the signed transaction
        console.log("Sending the transaction");
        web3Sepolia.eth
            .sendSignedTransaction(signedTx.rawTransaction)
            .on("receipt", async (receipt) => {
                console.log("Transaction receipt:", receipt);

                // Confirm the bridged balance on Blast
                // const balance = await web3BlastSepolia.getBalance(wallet.address);
                // console.log(`Balance on Blast: ${ethers.utils.formatEther(balance)} ETH`);
            })
            .on("error", (error) => {
                console.error("Error sending transaction:", error);
            });
    }
    // async function setValueInContract() {
    //     const web3 = new Web3("https://rpc-mumbai.maticvigil.com");

    //     // Set the contract ABI and address
    //     const contractABI = abiFile;
    //     const contractAddress = "0xd76d65ff12e41c989c7c8478d8f75CfcAd658f43";

    //     // Create contract instance
    //     const contract = new web3.eth.Contract(contractABI, contractAddress);

    //     // The account address and private key
    //     const accountAddress = "0x26459582f78Aa7710aE001311a478C897a9A1368"; // update it with your new wallet address
    //     const privateKey = "0x6d2610c35c6a534f69404896b273bc6708b8ef91595b378b8752bbd6b79ffad8"; // update it with your new wallet private key

    //     // Data for the setValue1 function
    //     const valueToSend = 33; // Example value

    //     // Create a transaction object
    //     const tx = {
    //         to: contractAddress,
    //         data: contract.methods.setValue1(valueToSend).encodeABI(),
    //         gas: 2000000, // Set an appropriate gas limit
    //     };

    //     // Sign the transaction
    //     const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);

    //     // Send the signed transaction
    //     web3.eth
    //         .sendSignedTransaction(signedTx.rawTransaction)
    //         .on("receipt", (receipt) => {
    //             console.log("Transaction receipt:", receipt);
    //         })
    //         .on("error", (error) => {
    //             console.error("Error sending transaction:", error);
    //         });
    // }

    async function getProfit() {
        // ! UPdate the code to do like this: https://www.youtube.com/watch?v=e0MSnHcvDJk
        // - case but this is likely a very liquid pool and most good Arbitrage opportunities are going to be
        //- found on less liquid pools so you may want to give this a try on some less mainstream tokens

        // Routers Ethereum:
        // Uniswap v2 - 0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D
        // Sushiswap v2 - 0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F

        const web3 = new Web3("https://rpc.ankr.com/eth/345dbaf3419e8fff776111ddc60566f02f97e2707082085096e6689319878930"); // ethereum
        const router1Address = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"; // uniswap v2 router ethereum
        const router2Address = "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F"; // sushiswap v2 router ethereum
        const amountIn = web3.utils.toWei("0.1", "ether");
        const path1 = ["0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", "0x514910771AF9Ca656af840dff83E8264EcF986CA"]; // WETH to LINK ethereum // 6 decimals
        const path2 = ["0x514910771AF9Ca656af840dff83E8264EcF986CA", "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"]; // LINK to WETH ethereum // 6 decimals
        const contractABI = abiFile;
        const contract1 = new web3.eth.Contract(contractABI, router1Address);
        const contract2 = new web3.eth.Contract(contractABI, router2Address);
        const _result1 = await contract1.methods.getAmountsOut(amountIn, path1).call();
        console.log("_result1", _result1);
        const _amount1 = _result1[path1.length - 1];
        console.log("Amount 1", _amount1 / 10 ** 18);
        const _result2 = await contract2.methods.getAmountsOut(_amount1, path2).call();
        const _amount2 = _result2[path2.length - 1];
        console.log("Amount 2", _amount2 / 10 ** 18);
        console.log("Result ", (_amount2 - amountIn) / 10 ** 18);

        console.log("Initial amount", amountIn, amountIn / 10 ** 18);
        console.log("Received Back", _amount2, _amount2 / 10 ** 18);
        if (_amount2 > amountIn) {
            console.log("We had a profit of ", (_amount2 - amountIn) / 10 ** 18);
        } else {
            console.log("We had a loss of ", (_amount2 - amountIn) / 10 ** 18);
        }
    }

    return (
        <div className="container">
            <Helmet>
                <meta charset="UTF-8" />
                <title>Check the balances of any wallet | DevWeb3</title>
                <meta name="description" content="Check the balances of any web3 wallet in ethereum blockchain, polygon blockchain and binance blockchain." />
                <meta property="og:title" content="Check the balances of any wallet | DevWeb3" />
                <meta property="og:description" content="Check the balances of any web3 wallet in ethereum blockchain, polygon blockchain and binance blockchain." />
                <meta name="twitter:title" content="Check the balances of any wallet | DevWeb3" />
                <meta name="twitter:description" content="Check the balances of any web3 wallet in ethereum blockchain, polygon blockchain and binance blockchain." />
            </Helmet>
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-grid">
                <div className="min-[1920px]:col-span-4 2xl:col-span-6 col-span-12 p-2">
                    <h3 className="font-bold text-lg text-white">Get Wallet Balance</h3>
                    <div>
                        <button className="btn-default ml-4" onClick={() => getABIFunction("0x2519fBB78908a34C024c65713a55876E3a3aA629")}>
                            Get ABI and Functions List
                        </button>
                    </div>
                    <div>
                        <button
                            className="btn-default m-10"
                            onClick={() => {
                                article();
                            }}>
                            Article
                        </button>
                    </div>
                    <div>
                        <button
                            className="btn-default m-10"
                            onClick={() => {
                                testDEX();
                            }}>
                            TEST PRICE DEX
                        </button>
                        <button
                            className="btn-default m-10"
                            onClick={() => {
                                getProfit();
                            }}>
                            Arbitrage!!!
                        </button>
                        <button
                            className="btn-default m-10"
                            onClick={() => {
                                bridgeToBlast();
                            }}>
                            Bridge to Blast
                        </button>
                    </div>
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default Test;
