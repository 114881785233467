import { useContext } from "react";
import { AppContext } from "@context/AppContext";
import { tooLong, shortenAddress, timeSince, getImageUrl } from "@util/util";
import { notify } from "@util/component";
import { useNavigate } from "react-router-dom";
import BoltIcon from "@mui/icons-material/Bolt";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faTrash, faBolt } from "@fortawesome/free-solid-svg-icons";

const HistoryItem = (props) => {
    const { reexecuteFunction, setCurrentHistoryItem, addExecutionQueue, removeExecutionQueueItem, setExecutionQueueDetail, setCurrentQueue, removeQueueListItem } = useContext(AppContext);
    const { historyItem, behavior, parent, stateToBeUpdated } = props;
    const navigate = useNavigate();

    // Get the history data
    //console.log("HistoryItem - history", historyItem);

    // format paramters
    const formatParameters = (historyItem) => {
        let _strParameters = "";
        if (!historyItem.parameters || historyItem.parameters.length === 0 || Object.entries(historyItem.parameters).length === 0) {
            return _strParameters;
        } else if (Object.entries(historyItem.parameters).length === 1 && !Array.isArray(historyItem.parameters)) {
            if (!Object.entries(historyItem.parameters)[0].hasOwnProperty("name")) {
                _strParameters = "(" + Object.values(historyItem.parameters)[0].toString() + ")";
            } else {
                _strParameters = "(" + historyItem.parameters[0].name + ": " + historyItem.parameters[0].value + ")";
            }
            return _strParameters;
        } else {
            _strParameters = "(";
            historyItem.parameters.forEach((parameter) => {
                parameter.name ? (_strParameters += parameter.name + ": " + parameter.value + ", ") : (_strParameters += parameter.value + ", ");
            });
            _strParameters = `${_strParameters.slice(0, _strParameters.length - 2)})`;
        }

        return _strParameters;
    };

    let _strParameters;
    if (historyItem) _strParameters = formatParameters(historyItem);

    if (!historyItem) return null;

    if (behavior === "execute")
        return (
            <div className="rounded-lg flex flex-row mb-4 bg-black">
                <div className="flex items-center justify-center pl-4 pr-2">
                    <label
                        htmlFor="historyConfirmation"
                        className="flex items-center justify-center bg-[#322B26] rounded-lg cursor-pointer h-11 w-11"
                        onClick={() => {
                            notify("Function", historyItem.function.name, "executed.");
                            setCurrentHistoryItem(historyItem);
                        }}>
                        <FontAwesomeIcon icon={faBolt} className="text-secondary" />
                    </label>
                </div>
                <div className="p-2 w-full whitespace-nowrap">
                    <div className="p-2 w-full whitespace-nowrap">
                        <div className="flex w-full justify-between pr-2">
                            <label
                                htmlFor="executionDetail"
                                className="w-full text-left text-secondary pl-2 cursor-pointer"
                                onClick={() => {
                                    setExecutionQueueDetail(historyItem);
                                }}>
                                {historyItem.parameters && tooLong(historyItem.function.name + " " + _strParameters, 30)}
                            </label>
                        </div>
                        <div className="flex w-full justify-between px-2">
                            <label
                                htmlFor="executionDetail"
                                className="text-light cursor-pointer"
                                onClick={() => {
                                    setExecutionQueueDetail(historyItem);
                                }}>
                                {historyItem.contract.name}
                            </label>
                            {!historyItem.wallet && (
                                <label
                                    htmlFor="executionDetail"
                                    className="text-[#865C2F] cursor-pointer"
                                    onClick={() => {
                                        setExecutionQueueDetail(historyItem);
                                    }}>
                                    {timeSince(Date.parse(historyItem.timestamp))}
                                </label>
                            )}
                        </div>
                        <div className="flex w-full justify-between">
                            <div className="flex w-full justify-between px-2">
                                {historyItem.wallet && (
                                    <>
                                        <label
                                            htmlFor="executionDetail"
                                            className="text-light cursor-pointer"
                                            onClick={() => {
                                                setExecutionQueueDetail(historyItem);
                                            }}>
                                            {historyItem.wallet.name} ({shortenAddress(historyItem.wallet.address)})
                                        </label>
                                        <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    if (behavior === "queueItemToBeAdded") {
        return (
            <div className="rounded-lg flex flex-row mb-4 bg-black">
                <div className="flex items-center justify-center pl-4 pr-2">
                    <label
                        // htmlFor="historyConfirmation"
                        className="bg-[#322B26] p-2 rounded-lg cursor-pointer"
                        onClick={() => {
                            notify("The item was added to the queue");
                            addExecutionQueue(historyItem);
                        }}>
                        <AddIcon className="text-secondary" />
                    </label>
                </div>
                <div className="p-2 w-full ReactJson-container">
                    <div className="flex w-full justify-between pr-2">
                        <span className="w-full text-left text-secondary pl-2">{historyItem.parameters && historyItem.function.name + " " + _strParameters}</span>
                    </div>
                    <div className="flex w-full justify-between px-2">
                        <p className="text-light">{historyItem.contract.name}</p>
                        {!historyItem.wallet && <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>}
                    </div>
                    <div className="flex w-full justify-between">
                        <div className="flex w-full justify-between px-2">
                            {historyItem.wallet && (
                                <>
                                    <p className="text-light">
                                        {historyItem.wallet.name} ({shortenAddress(historyItem.wallet.address)})
                                    </p>
                                    <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (behavior === "queueItem") {
        return (
            <div className="rounded-lg flex flex-row mb-2 p-2">
                <div className="flex items-center justify-center pl-4 pr-2">
                    <label
                        // htmlFor="popupQueueItemSettings"
                        className="flex items-center justify-center bg-[#322B26] rounded-lg cursor-pointer h-11 w-11"
                        onClick={() => {
                            notify("The item was removed from the queue");
                            removeExecutionQueueItem(parent, historyItem);
                        }}>
                        <FontAwesomeIcon icon={faTrash} className="text-secondary" />
                    </label>
                </div>
                <div className="flex items-center justify-center px-2">
                    <label
                        htmlFor="popupQueueItemSettings"
                        className="flex items-center justify-center bg-[#322B26] rounded-lg cursor-pointer h-11 w-11"
                        onClick={() => {
                            setExecutionQueueDetail(historyItem);
                        }}>
                        <FontAwesomeIcon icon={faGear} className="text-secondary" />
                    </label>
                </div>
                <div className="p-2 w-full ReactJson-container">
                    <div className="flex w-full justify-between pr-2">
                        <span className="w-full text-left text-secondary pl-2">{historyItem.parameters && historyItem.function.name + " " + _strParameters}</span>
                    </div>
                    <div className="flex w-full justify-between px-2">
                        <p className="text-light">{historyItem.contract.name}</p>
                        {!historyItem.wallet && <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>}
                    </div>
                    <div className="flex w-full justify-between">
                        <div className="flex w-full justify-between px-2">
                            {historyItem.wallet && (
                                <>
                                    <p className="text-light">
                                        {historyItem.wallet.name} ({shortenAddress(historyItem.wallet.address)})
                                    </p>
                                    <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (behavior === "queueItemValidation")
        return (
            <div className="rounded-lg flex flex-row mb-2 p-2">
                <div className="flex items-center justify-center pl-4 pr-2">
                    <label
                        // htmlFor="historyConfirmation"
                        className="flex items-center justify-center bg-[#322B26] rounded-lg cursor-pointer h-11 w-11"
                        onClick={() => {
                            notify("The item was removed from the queue");
                            removeExecutionQueueItem(parent, historyItem);
                        }}>
                        <FontAwesomeIcon icon={faTrash} className="text-secondary" />
                    </label>
                </div>
                <div className="flex items-center justify-center pl-2 pr-2">
                    <label
                        //htmlFor="popupQueueItemSettings"
                        className="flex items-center justify-center bg-[#322B26] rounded-lg cursor-pointer h-11 w-11"
                        onClick={() => {
                            console.log("HistoryItem - queueItemValidation historyItem", historyItem);
                            setExecutionQueueDetail(historyItem);
                            stateToBeUpdated(true); // show validationstep popup
                        }}>
                        <FontAwesomeIcon icon={faGear} className="text-secondary" />
                    </label>
                </div>
                <div className="p-2 w-full break-all">
                    <div className="flex w-full pr-2">
                        {/* <QuestionMarkOutlinedIcon className="text-secondary min-h-5 max-h-5" /> */}
                        <span className="text-secondary pl-2 italic">Previous step result should be</span>
                        <span className="text-secondary pl-1 italic">
                            <strong>{historyItem.validation.operationDisplay}</strong> than/to <strong>{tooLong(historyItem.validation.value, 20)}</strong>
                        </span>
                    </div>
                </div>
            </div>
        );
    if (behavior === "queueListItem")
        return (
            <div className="rounded-lg flex flex-row mb-2 p-2">
                <div className="flex items-center justify-center pl-4 pr-2">
                    <button className="bg-[#322B26] p-2 rounded-lg cursor-pointer">
                        <ZoomInOutlinedIcon
                            className="text-secondary min-h-5 max-h-5"
                            onClick={() => {
                                setCurrentQueue(historyItem);
                                navigate("/queue");
                            }}
                        />
                    </button>
                    <label
                        htmlFor="queueListItemRemoval"
                        className="bg-[#322B26] p-2 rounded-lg cursor-pointer ml-4"
                        onClick={() => {
                            removeQueueListItem(historyItem);
                        }}>
                        <DeleteForeverIcon className="text-secondary min-h-5 max-h-5" />
                    </label>
                </div>
                <div className="p-2 w-full whitespace-nowrap">
                    <div className="flex w-full justify-between pr-2">
                        <span className="w-full text-left text-secondary pl-2">{historyItem.name}</span>
                    </div>
                    <div className="flex w-full justify-between px-2">
                        {!historyItem.queue && <p className="text-light">{"No queue assigned"}</p>}
                        {historyItem.queue && <p className="text-light">{"3 contracts, 2 wallets, 15 functions and 34 steps"}</p>}
                    </div>
                </div>
            </div>
        );
    if (behavior === "queueResults") {
        if (historyItem.type === "execution") {
            return (
                <div className="rounded-lg flex flex-row mb-2 p-2 bg-black">
                    <div className="flex flex-col items-center justify-center pl-4 pr-2">
                        <label
                            htmlFor="executionDetail"
                            className="bg-[#322B26] p-2 rounded-lg cursor-pointer"
                            onClick={() => {
                                setExecutionQueueDetail(historyItem);
                            }}>
                            {!historyItem.error ? <CheckIcon className="text-secondary text-green" /> : <CheckIcon className="text-secondary text-red" />}
                        </label>
                    </div>
                    <div className="p-2 w-full whitespace-nowrap">
                        <div className="flex w-full justify-between pr-2">
                            <span className="w-full text-left text-secondary pl-2">{historyItem.parameters && tooLong(historyItem.function.name + " " + _strParameters, 55)}</span>
                        </div>
                        <div className="flex w-full justify-between px-2">
                            <p className="text-light">{historyItem.contract.name}</p>
                            {!historyItem.wallet && <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>}
                        </div>
                        <div className="flex w-full justify-between">
                            <div className="flex w-full justify-between px-2">
                                {historyItem.wallet && (
                                    <>
                                        <p className="text-light">
                                            {historyItem.wallet.name} ({shortenAddress(historyItem.wallet.address)})
                                        </p>
                                        <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (historyItem.type === "validation") {
            console.log("HistoryItem valdiation troubleshooting", historyItem);
            return (
                <div className="rounded-lg flex flex-row mb-2 p-2 bg-black">
                    <div className="flex flex-col items-center justify-center pl-4 pr-2">
                        <label
                            htmlFor="executionDetail"
                            className="bg-[#322B26] p-2 rounded-lg cursor-pointer"
                            onClick={() => {
                                setExecutionQueueDetail(historyItem);
                            }}>
                            {historyItem.result.validationResult ? <CheckIcon className="text-secondary text-green" /> : <CheckIcon className="text-secondary text-red" />}
                        </label>
                    </div>
                    <div className="p-2 w-full break-words">
                        <div className="flex w-full justify-between px-2">
                            <p className="text-secondary italic">
                                {historyItem.result.previousResult.function} result {historyItem.result.previousResult.result} is {historyItem.result.validation.operationDisplay}{" "}
                                {historyItem.result.validation.value}
                            </p>

                            {!historyItem.wallet && <p className="text-[#865C2F] whitespace-nowrap pl-2">{timeSince(Date.parse(historyItem.timestamp))}</p>}
                        </div>
                    </div>
                </div>
            );
        }
    }

    // <div className="rounded-lg flex flex-row mb-4">
    //     {behavior === "execute" && (
    //         <div className="flex items-center justify-center pl-4">
    //             <label
    //                 htmlFor="historyConfirmation"
    //                 className="bg-[#322B26] h-7 px-1 rounded-lg cursor-pointer"
    //                 onClick={() => {
    //                     notify("Function", historyItem.function.name, "executed.");
    //                     setCurrentHistoryItem(historyItem);
    //                 }}>
    //                 <BoltIcon className="text-secondary min-h-4 max-h-4" />
    //             </label>
    //         </div>
    //     )}
    //     {behavior === "queue" && (
    //         <div className="flex items-center justify-center pl-4 pr-2">
    //             <label
    //                 htmlFor="historyConfirmation"
    //                 className="bg-[#322B26] p-2 rounded-lg cursor-pointer"
    //                 onClick={() => {
    //                     notify("The item was added to the queue");
    //                     addExecutionQueue(historyItem);
    //                 }}>
    //                 <AddIcon className="text-secondary" />
    //             </label>
    //         </div>
    //     )}
    //     {behavior === "queueItem" && (
    //         <div className="flex items-center justify-center pl-4 pr-2">
    //             <label
    //                 htmlFor="historyConfirmation"
    //                 className="bg-[#322B26] p-2 rounded-lg cursor-pointer"
    //                 onClick={() => {
    //                     notify("The item was removed from the queue");
    //                     removeExecutionQueue(historyItem);
    //                 }}>
    //                 <RemoveIcon className="text-secondary" />
    //             </label>
    //         </div>
    //     )}
    //     {behavior === "queueResults" && (
    //         <div className="flex flex-col items-center justify-center pl-4 pr-2">
    //             <label
    //                 htmlFor="executionDetail"
    //                 className="bg-[#322B26] p-2 rounded-lg cursor-pointer"
    //                 onClick={() => {
    //                     setExecutionQueueDetail(historyItem);
    //                 }}>
    //                 <CheckIcon className="text-secondary text-green" />
    //             </label>
    //         </div>
    //     )}
    {
        /* <div className="p-2 w-full whitespace-nowrap">
                    <p className="text-secondary">
                        {historyItem.function.name} {historyItem.parameters && tooLong(_strParameters, 40)}
                    </p>
                    <p className="text-light pt-1">{historyItem.network.name}</p>
                    <div className="flex w-full justify-between pr-2">
                        <p className="text-light">{historyItem.contract.name}</p>
                        {!historyItem.wallet && <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>}
                    </div>
                    <div className="flex w-full justify-between">
                        <div className="flex w-full justify-between pr-2">
                            {historyItem.wallet && (
                                <>
                                    <p className="text-light">
                                        {historyItem.wallet.name} ({shortenAddress(historyItem.wallet.address)})
                                    </p>
                                    <p className="text-[#865C2F]">{timeSince(Date.parse(historyItem.timestamp))}</p>
                                </>
                            )}
                        </div> */
    }
    {
        /* <div className="bg-[#322B26] p-0 rounded-lg">
                        <CheckIcon className="text-secondary cursor-pointer text-green p-1" />
                    </div> */
    }
    {
        /* </div>
                    {behavior != "queueItem" && typeof historyItem.result != "object" && (
                        <div className="pt-2">
                            <p className="text-secondary">
                                Result: <span className="text-light">{historyItem.result}</span>
                            </p>
                        </div>
                    )}
                </div> */
    }
    // </div>
    // );
};

export default HistoryItem;
