import { useEffect, useState, useCallback, useContext } from "react";
import { AppContext } from "@context/AppContext";
import ReactFlow, { applyEdgeChanges, applyNodeChanges } from "reactflow";
import "reactflow/dist/style.css";
import SideMenu from "@components/sidemenu/SideMenu";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import { NotifyContainer } from "@util/component";

import { Web3util } from "@util/web3util";

// TODO: Styling - https://reactflow.dev/docs/examples/styling/tailwind/ - match the site style
// TODO: Zoom - https://reactflow.dev/docs/examples/interaction/contextual-zoom/ - start seeing all the steps and offers zoom buttons
// TODO: Label - https://reactflow.dev/docs/examples/edges/edge-label-renderer/ - validation true or false
// TODO: During the execution of a queue, change the colors fit the steps was succesfully execute or error. Validation step should display if the result is true or false, maybe disabling the edge animation or change the color to gray?

// - Only 1 step on _test1 is displayed using the new json format for queue

const _test1 = {
    steps: [
        {
            id: 1,
            type: "execution",
            detail: {
                contract: "Test Contract Multicall Mumbai",
                proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                networkId: 80001,
                function: "getValue1",
                abi: [
                    {
                        anonymous: false,
                        inputs: [
                            {
                                indexed: false,
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "Value1Set",
                        type: "event",
                        signature: "0x806af2e1b19269eed208fdf67b89f671bfd543ec6026849a0c24e94edde57bdb",
                    },
                    {
                        anonymous: false,
                        inputs: [
                            {
                                indexed: false,
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "Value2Set",
                        type: "event",
                        signature: "0xfa48d1630c4cfeab607eb78220f9a57f1c2822b3ecd0503fe6f8828f9833711d",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "functionA",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "pure",
                        type: "function",
                        constant: true,
                        signature: "0x6c33137f",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "functionB",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "pure",
                        type: "function",
                        constant: true,
                        signature: "0x44901729",
                    },
                    {
                        inputs: [],
                        name: "getValue1",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x60d586f8",
                    },
                    {
                        inputs: [],
                        name: "getValue2",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0xc515205d",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "multicall",
                        outputs: [],
                        stateMutability: "nonpayable",
                        type: "function",
                        signature: "0xfee503d7",
                    },
                    {
                        inputs: [],
                        name: "value1",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x3033413b",
                    },
                    {
                        inputs: [],
                        name: "value2",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x5d33a27f",
                    },
                ],
                parameters: [],
            },
            next: 2,
        },
        {
            id: 2,
            type: "execution",
            detail: {
                contract: "Test Contract Multicall Mumbai",
                proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                networkId: 80001,
                function: "getValue2",
                abi: [
                    {
                        anonymous: false,
                        inputs: [
                            {
                                indexed: false,
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "Value1Set",
                        type: "event",
                        signature: "0x806af2e1b19269eed208fdf67b89f671bfd543ec6026849a0c24e94edde57bdb",
                    },
                    {
                        anonymous: false,
                        inputs: [
                            {
                                indexed: false,
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "Value2Set",
                        type: "event",
                        signature: "0xfa48d1630c4cfeab607eb78220f9a57f1c2822b3ecd0503fe6f8828f9833711d",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "functionA",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "pure",
                        type: "function",
                        constant: true,
                        signature: "0x6c33137f",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "functionB",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "pure",
                        type: "function",
                        constant: true,
                        signature: "0x44901729",
                    },
                    {
                        inputs: [],
                        name: "getValue1",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x60d586f8",
                    },
                    {
                        inputs: [],
                        name: "getValue2",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0xc515205d",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "multicall",
                        outputs: [],
                        stateMutability: "nonpayable",
                        type: "function",
                        signature: "0xfee503d7",
                    },
                    {
                        inputs: [],
                        name: "value1",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x3033413b",
                    },
                    {
                        inputs: [],
                        name: "value2",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x5d33a27f",
                    },
                ],
                parameters: [],
            },
            next: 3,
        },
        {
            id: 3,
            type: "execution",
            detail: {
                contract: "Test Contract Multicall Mumbai",
                proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                networkId: 80001,
                function: "getValueABC",
                abi: [
                    {
                        anonymous: false,
                        inputs: [
                            {
                                indexed: false,
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "Value1Set",
                        type: "event",
                        signature: "0x806af2e1b19269eed208fdf67b89f671bfd543ec6026849a0c24e94edde57bdb",
                    },
                    {
                        anonymous: false,
                        inputs: [
                            {
                                indexed: false,
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "Value2Set",
                        type: "event",
                        signature: "0xfa48d1630c4cfeab607eb78220f9a57f1c2822b3ecd0503fe6f8828f9833711d",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "functionA",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "pure",
                        type: "function",
                        constant: true,
                        signature: "0x6c33137f",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "functionB",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "pure",
                        type: "function",
                        constant: true,
                        signature: "0x44901729",
                    },
                    {
                        inputs: [],
                        name: "getValue1",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x60d586f8",
                    },
                    {
                        inputs: [],
                        name: "getValue2",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0xc515205d",
                    },
                    {
                        inputs: [
                            {
                                internalType: "uint8",
                                name: "value",
                                type: "uint8",
                            },
                        ],
                        name: "multicall",
                        outputs: [],
                        stateMutability: "nonpayable",
                        type: "function",
                        signature: "0xfee503d7",
                    },
                    {
                        inputs: [],
                        name: "value1",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x3033413b",
                    },
                    {
                        inputs: [],
                        name: "value2",
                        outputs: [
                            {
                                internalType: "uint8",
                                name: "",
                                type: "uint8",
                            },
                        ],
                        stateMutability: "view",
                        type: "function",
                        constant: true,
                        signature: "0x5d33a27f",
                    },
                ],
                parameters: [],
            },
            next: 4,
        },
    ],
};

const _queue = {
    steps: [
        {
            id: 1,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionA",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: 2,
        },
        {
            id: 2,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionB",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: 3,
        },
        {
            id: 3,
            type: "validation",
            condition: {
                operation: "greater",
                value: 5,
            },
            paths: {
                true: 4,
                false: 5,
            },
        },
        {
            id: 4,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionC",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: 7,
        },
        {
            id: 5,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionD",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: 6,
        },
        {
            id: 6,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionE",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: null,
        },
        {
            id: 7,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionF",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: 8,
        },
        {
            id: 8,
            type: "validation",
            condition: {
                operation: "greater",
                value: 5,
            },
            paths: {
                true: 9,
                false: null,
            },
        },
        {
            id: 9,
            type: "execution",
            detail: {
                contract: {
                    name: "Test Contract Multicall Mumbai",
                    proxy: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    implementation: "0xc678968e125A3CCF95b170887cEf311e4A011625",
                    networkId: 80001,
                },
                function: "functionG",
                abi: {
                    inputs: [
                        {
                            internalType: "address",
                            name: "pool",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amountBuy",
                            type: "uint256",
                        },
                    ],
                    name: "multicall",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                    signature: "0xfee503d7",
                },
                parameters: [
                    {
                        internalType: "address",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountBuy",
                        type: "uint256",
                    },
                ],
                paramatersValues: ["0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE", "1"],
                wallet: {
                    name: "Test User BacaDex",
                    address: "0x4F40c559ced9Eb519E5b03fb79F93d08d04E6aEE",
                    pk: "1050fbdab7ff5bb8de8348b539d9ed75e52795df39c0e2ab4cc667f726bfbd68",
                },
            },
            next: null,
        },
    ],
};

const QueueFlow = () => {
    const { lastExecution } = useContext(AppContext);
    const [nodes, setNodes] = useState(null);
    const [edges, setEdges] = useState(null);

    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes]);
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);
    // TODO: Click example
    const onNodeClick = (event, node) => {
        console.warn("When the user clicks on a node, we should have the entire queue, go to the step (same id of the node) and show the details of that function/step");
        console.log("onNodeClick - don't need to hold all the data (detail of step)", node);
    };

    function getReactFlowElements(json) {
        console.log("getReactFlowElements is called");
        if (!json) return;

        const steps = json.steps;

        const _nodes = [];
        const visited = new Set();
        const distanceY = 100;

        function traverse(id, x, maxY) {
            if (visited.has(id)) return;

            visited.add(id);

            const step = steps.find((s) => s.id === id);

            if (!step) return;

            let node;

            if (step.type === "execution") {
                const y = maxY + distanceY;
                let _type = "default";
                if (id === 1) _type = "input";
                if (!step.next) _type = "output";
                node = {
                    id: step.id.toString(),
                    type: _type,
                    data: {
                        label: step.detail.function,
                        detail: step.detail,
                    },
                    position: { x, y },
                };
                maxY = y;
            } else if (step.type === "validation") {
                const parent = steps.find((s) => s.next === step.id && s.type === "execution");
                if (!parent) return;
                const parentElement = _nodes.find((n) => n.id === parent.id.toString());
                if (!parentElement) return;
                const y = parentElement.position.y + distanceY;
                node = {
                    id: step.id.toString(),
                    type: "default",
                    data: { label: `Condition: ${step.condition.operation} ${step.condition.value}`, detail: step.detail },
                    position: { x, y },
                };

                const truePath = step.paths.true;
                const falsePath = step.paths.false;

                if (typeof truePath !== "undefined") {
                    traverse(truePath, x + distanceY, y);
                }

                if (typeof falsePath !== "undefined") {
                    traverse(falsePath, x - distanceY, y);
                }
            }

            if (node) _nodes.push(node);

            const next = step.next;
            if (typeof next !== "undefined") {
                traverse(next, x, maxY);
            }
        }
        traverse(steps[0].id, 0, 0);
        setNodes(_nodes);

        const _edges = [];
        _queue.steps.forEach((step) => {
            if (step.type === "validation") {
                if (step.paths.true) {
                    const trueEdge = {
                        id: `${step.id}-true`,
                        source: step.id.toString(),
                        target: step.paths.true.toString(),
                        type: "smoothstep",
                        animated: true,
                        // style: { stroke: "#FFF" },
                    };
                    _edges.push(trueEdge);
                }
                if (step.paths.false) {
                    const falseEdge = {
                        id: `${step.id}-false`,
                        source: step.id.toString(),
                        target: step.paths.false.toString(),
                        type: "smoothstep",
                        animated: true,
                        // style: { stroke: "#FFF" },
                    };
                    _edges.push(falseEdge);
                }
            } else if (step.next) {
                const edge = {
                    id: `${step.id}-${step.next}`,
                    source: step.id.toString(),
                    target: step.next.toString(),
                    animated: true,
                    // style: { stroke: "#FFF" },
                };
                _edges.push(edge);
            }
        });
        setEdges(_edges);

        console.log("nodes", _nodes, "edges", _edges);
    }

    useEffect(() => {
        getReactFlowElements(_test1);
        // Clean the ABI
        // TODO: Review and test it
        const _functionAbi = Web3util.cleanABIs(_test1);
        console.log("CLEAN ABI _test1, cleaned", _test1, _functionAbi);
    }, []);

    // useEffect(() => {
    //     let _json;
    //     if (lastExecution) _json = Web3util.transformExecution2Queue(lastExecution);
    //     console.log("lastExecution cleaned - _json", _json);
    // }, [lastExecution]);

    return (
        <div className="master">
            <NavBar />
            <div className="flex flex-row main-container bg-[#1a1511]">
                <div>
                    <SideMenu />
                </div>
                <div className="py-4 pl-2 bg-[#1f1c1a] w-full">
                    {nodes && edges && <ReactFlow nodes={nodes} edges={edges} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} onNodeClick={onNodeClick} fitView />}
                </div>
            </div>
            <Footer />
            <NotifyContainer />
        </div>
    );
};

export default QueueFlow;
