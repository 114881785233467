import React from "react";
import "./Tooltip.css"; // Create a separate CSS file for Tooltip styles

const Tooltip = ({ position, text, children }) => {
    const getTooltipStyle = () => {
        switch (position) {
            case "top":
                return { bottom: "100%", left: "50%", transform: "translateX(-50%)" };
            case "bottom":
                return { top: "100%", left: "50%", transform: "translateX(-50%)" };
            case "right":
                return { top: "50%", left: "100%", transform: "translateY(-50%)" };
            case "left":
                return { top: "50%", right: "100%", transform: "translateY(-50%)" };
            case "bottom-left":
                return { top: "100%", right: "0" };
            case "bottom-right":
                return { top: "100%", left: "0" };
            case "top-right":
                return { bottom: "100%", left: "0" };
            case "top-left":
                return { bottom: "100%", right: "0" };
            default:
                return {};
        }
    };

    return (
        <div className="tooltip text-left">
            <span className="tooltiptext" style={getTooltipStyle()}>
                {text}
            </span>
            {children}
        </div>
    );
};

export default Tooltip;
