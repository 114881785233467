import { useState } from "react";
import "./Tabs.css";

function Tabs({ tabsData }) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="pt-2">
            <div className="tabs">
                {tabsData &&
                    tabsData.map((tab, index) => (
                        <div id="TabsTab" name="TabsTab" data-info={tab.title} key={index} className={`tab ${activeTab === index ? "active" : ""}`} onClick={() => handleTabClick(index)}>
                            <span className="tab.title">{tab.title}</span>
                        </div>
                    ))}
            </div>
            {tabsData && <div className="content">{tabsData[activeTab].content}</div>}
        </div>
    );
}

export default Tabs;
