import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import { getImageUrl } from "@util/util";
import { useNavigate } from "react-router-dom";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import PopupVideo from "./PopupVideo";
import VideoComponent from "./VideoComponent";
// import { LogoResponsive } from "@components/shared/Logo/Logo";
import { LogoResponsiveFloat } from "@components/shared/Logo/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";

function Idea() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="180" className="bg-black p-12">
            <path
                d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2 5.2-7.1 10.4-14.2 15.4-21.4 19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3 5 7.2 10.2 14.3 15.4 21.4 19.8 27.1 39.7 54.4 49.2 86.2h160zm-80 128c44.2 0 80-35.8 80-80v-16H112v16c0 44.2 35.8 80 80 80zm-80-336c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112 8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"
                fill="#ffffff"></path>
        </svg>
    );
}

const MainPage = () => {
    const { getCurrentOnboardingStep, getOnboardingStatus, dataLoaded, connectedAccount } = useContext(AppContext);
    const navigate = useNavigate();

    async function goToNextPage() {
        // const _currentStatus = await getOnboardingStatusMainPage();
        // console.log("MainPage goToNextPage _currentStatus", _currentStatus);
        // if (_currentStatus) navigate("/execute");
        // else navigate("/onboarding");
        if (connectedAccount) navigate("/execute");
        else navigate("/tools/sourcecode");
    }

    async function goToTool(tool) {
        if (tool === "editor") navigate("/tools/sourcecode");
        if (tool === "editorexample") navigate("/editor?network=80001&contract=0xa4f2faFF63Af94B02d188C667509B592E8aa00C7");
        if (tool === "runner") window.open("https://devweb3.gitbook.io/runner-api/", "_blank");
    }

    // useEffect(() => {
    //getOnboardingStatusMainPage();
    // }, [dataLoaded, connectedAccount]);

    // async function getOnboardingStatusMainPage() {
    //     const _status = await getOnboardingStatus();
    //     if (!_status || _status.status != "finished") {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    return (
        <div>
            <nav className="navbar">
                <NavBar />
            </nav>
            <main>
                <div className="flex justify-center bg-black pt-10 px-2">
                    {/* <div className="flex flex-row py-10 max-w-[1024px]">
                        <div className="w-8/12">
                            <p className="text-8xl text-white font-semibold text-left">Execute, Test, and Validate Your Contracts</p>
                            <p className="text-2xl text-white pt-4 text-left">Unleash Your Creativity and Build the Future of Web3 with Powerful Solidity Development Tools.</p>
                        </div>
                        <div className="w-4/12 pl-6 invert min-w-[250px]">
                            <LogoResponsive backgroundColor="#FFF" foregroundColor="#000" />
                        </div>
                    </div> */}
                    <div className="flex flex-col md:flex-row items-center py-10 max-w-[1024px]">
                        <div className="text-center md:text-left md:w-8/12 mb-6">
                            <p className="text-4xl md:text-8xl text-white font-semibold">Execute, Test, and Validate Your Contracts</p>
                            <p className="text-lg md:text-2xl text-white pt-4">Unleash Your Creativity and Build the Future of Web3 with Powerful Solidity Development Tools.</p>
                        </div>
                        <div className="w-4/12 md:pl-6 invert md:min-w-[250px] max-w-[150px] ">
                            <LogoResponsiveFloat id="LogoResponsiveFloat" name="LogoResponsiveFloat" backgroundColor="#FFF" foregroundColor="#000" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center bg-black py-14 px-2">
                    <button
                        id="MainPageGetStartedTopButton"
                        name="MainPageGetStartedTopButton"
                        className="btn-main"
                        onClick={() => {
                            goToNextPage();
                        }}>
                        Get Started
                    </button>
                    <p className="text-[12px] text-white pt-2">It's Free!</p>
                </div>
                <div className="flex justify-center bg-white px-2">
                    <div className="max-w-[1024px] justify-start py-20">
                        <p className="text-4xl text-black font-semibold">Powerful Tools for Developers</p>
                        <p className="text-xl text-black pt-1">Ready and easy-to-use tools to bring your ideas to life.</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pt-10 justify-start">
                            <div className="flex flex-col items-start cursor-pointer">
                                <img src={`${getImageUrl("editor")}`} className="w-full h-[180px] zoomable-image" />
                                <p className="text-black">
                                    <span className="font-semibold">Editor</span>
                                </p>
                                <p className="text-black">
                                    <span className="text-black text-sm">Edit, execute and deploy your contracts directly from the blockchain</span>
                                </p>
                                <p className="flex w-full justify-center mt-2">
                                    <button
                                        id="MainPageEditorTryItButton"
                                        name="MainPageEditorTryItButton"
                                        className="btn-main-inverted text-white"
                                        onClick={() => {
                                            goToTool("editor");
                                        }}>
                                        Try It
                                    </button>
                                    <button
                                        id="MainPageEditorExampleButton"
                                        name="MainPageEditorExampleButton"
                                        className="btn-main-inverted text-white ml-4"
                                        onClick={() => {
                                            goToTool("editorexample");
                                        }}>
                                        example contract
                                    </button>
                                </p>
                            </div>
                            <div
                                id="MainPageRunnerApi"
                                name="MainPageRunnerApi"
                                className="flex flex-col items-start cursor-pointer"
                                onClick={() => {
                                    goToTool("runner");
                                }}>
                                <img src={`${getImageUrl("runnerapi")}`} className="w-full h-[180px] zoomable-image" />
                                <p className="text-black">
                                    <span className="font-semibold">Runner API</span>
                                </p>
                                <p className="text-black">
                                    <span className="text-black text-sm">Integrate your Web 2.0 application with smart contracts and blockchain data</span>
                                </p>
                                <p className="flex w-full justify-center mt-2">
                                    <button className="btn-main-inverted text-white">DOCS</button>
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                {/* <label htmlFor="popupVideo" className="cursor-pointer"> */}
                                <label className="cursor-pointer">
                                    <a id="MainPageExecutorYoutubeLink" name="MainPageExecutorYoutubeLink" href="https://www.youtube.com/watch?v=PjrfFV8Kjxk" target="_blank">
                                        <img src={`${getImageUrl("executor")}`} className="w-full h-[180px] zoomable-image" />
                                        <p className="text-black font-semibold text-lg">Executor</p>
                                        <p className="text-black text-sm">Execute and test any method of your contract quickly and easily</p>
                                        <p className="flex w-full justify-center mt-2">
                                            <button id="MainPageExecutorSeeInActionButton" name="MainPageExecutorSeeInActionButton" className="btn-main-inverted text-white">
                                                see in action
                                            </button>
                                        </p>
                                    </a>
                                </label>
                            </div>
                            <div className="flex flex-col items-start ">
                                <img src={`${getImageUrl("queue")}`} className="w-full h-[180px]" />
                                <p className="text-black">
                                    <span className="font-semibold">Queue</span> - Coming Soon!
                                </p>
                            </div>
                            <div className="flex flex-col items-start">
                                <div className="bg-black w-full">{Idea()}</div>
                                <p className="text-black text-start">
                                    <span className="font-semibold">Share Your Own Ideas on @DevWeb3co</span>
                                </p>
                            </div>
                        </div>
                        <p className="flex w-full justify-center pt-10">
                            <button
                                id="MainPageGetStarted2ndButton"
                                name="MainPageGetStarted2ndButton"
                                className="btn-main-inverted text-white"
                                onClick={() => {
                                    goToNextPage();
                                }}>
                                Get Started <FontAwesomeIcon id="MainPageGetStarted2ndButtonIcon" name="MainPageGetStarted2ndButtonIcon" icon={faRocket} />
                            </button>
                        </p>
                    </div>
                </div>
                <div className="flex justify-center px-2 bg-black">
                    <div className="w-[1024px] justify-start py-20 text-secondary">
                        <span className="text-4xl font-semibold">Review the code, fix it and deploy it again in seconds</span>
                        <p className="text-xl pt-4">Easiest tool to run any function of your contract</p>
                        <div
                            id="MainPageEditorYoutubeLink"
                            name="MainPageEditorYoutubeLink"
                            className="pt-10  cursor-pointer"
                            onClick={() => {
                                window.open("https://www.youtube.com/watch?v=zr318u4_gtw", "_blank");
                            }}>
                            <VideoComponent videoFileName={"editor"} />
                        </div>
                        <p className="pt-14">
                            <button
                                id="MainPageGetStarted3rdButton"
                                name="MainPageGetStarted3rdButton"
                                className="btn-main"
                                onClick={() => {
                                    goToNextPage();
                                }}>
                                Get Started
                            </button>
                        </p>
                    </div>
                </div>
                <div className="flex justify-center px-2">
                    <div className="w-[1024px] justify-start py-20 text-secondary">
                        <span className="text-4xl font-semibold">See how to save time and do more!</span>
                        <p className="text-xl pt-4">Execute and Test any function of yout contract in a few clicks</p>
                        <div className="pt-10">
                            <VideoComponent videoFileName={"executor_demo"} />
                        </div>
                        <p className="pt-14">
                            <button
                                id="MainPageGetStarted4thButton"
                                name="MainPageGetStarted4thButton"
                                className="btn-main"
                                onClick={() => {
                                    goToNextPage();
                                }}>
                                Get Started
                            </button>
                        </p>
                    </div>
                </div>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <PopupVideo videoName={"Executor"} videoFileName={"executor_demo"} />
        </div>
    );
};

export default MainPage;
