import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "@context/AppContext";
import SourceSelector from "./SourceSelector";
import LocalPanel from "./LocalPanel";
import NetworkPanel from "./NetworkPanel";
import FilePanel from "./FilePanel";
import ContractPanel from "./ContractPanel";
import DeploymentPanel from "./DeploymentPanel";
import CompilationPanel from "./CompilationPanel";
import DebugPanel from "./DebugPanel";
import ExecutionPanel from "./ExecutionPanel";
import Editor, { useMonaco } from "@monaco-editor/react";
import { notify, NotifyContainer } from "@util/component";
import { reverseArray, debounce } from "@util/util";
import "@css/Content.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { CodeEditorUtil } from "@util/codeEditorUtil";
import ResizeHandle from "./ResizeHandle";
import { FunctionGasType } from "@util/enum";
import PopupFunctionParameters from "./PopupFunctionParameters";
import { useNavigate } from "react-router-dom";
import FeedbackComponent from "@components/shared/Feedback/FeedbackComponent";
import { FileUtil } from "@util/fileUtil";

let autoCompileTimeout; // control de debounce for the autocompile

const Content = ({ network, contract }) => {
    const { contractsSourceCode, contractsInfo, getFunctions, loadContractSourceCodeEditor, getNetworkByID, networks, executeFunctionEditor, getFunctionGasType } = useContext(AppContext);
    const [activeTabLocalNetwork, setActiveTabLocalNetwork] = useState("local");
    const [activeTab, setActiveTab] = useState(-1);
    const [editorIsAvailable, setEditorIsAvailable] = useState(false);
    const [tabsNetworkContractsSourceCode, setTabsNetworkContractsSourceCode] = useState();
    const [tabsLocalContractsSourceCode, setTabsLocalContractsSourceCode] = useState();
    const [rootContract, setRootContract] = useState();
    const [rootContractSourceCode, setRootContractSourceCode] = useState();
    const [dependencesSourceCode, setDependencesSourceCode] = useState();
    // const [codeEditor, setCodeEditor] = useState();
    const [fileList, setFileList] = useState();
    const [fileListDir, setFileListDir] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemObj, setSelectedItemObj] = useState(null);
    const [lastCompilationInfo, setLastCompilationInfo] = useState(null);
    const [editorMode, setEditorMode] = useState(null);
    const [compilerVersion, setCompilerVersion] = useState();
    const [compilerOptimization, setCompilerOptimization] = useState(true);
    const [compilerRuns, setCompilerRuns] = useState(200);
    const [contractName, setContractName] = useState();
    const [currentCodeDebugDecoration, setCurrentCodeDebugDecoration] = useState();

    const [contractsOriginalNetworkEditor, setContractsOriginalNetworkEditor] = useState();
    const [contractsNetworkEditor, setContractsNetworkEditor] = useState();
    const [contractsWalletEditor, setContractsWalletEditor] = useState();
    const contractsWalletEditorRef = useRef(contractsWalletEditor);
    const contractsNetworkEditorRef = useRef(contractsNetworkEditor);
    const [contractsSourceCodeEditor, setContractsSourceCodeEditor] = useState();
    const [contractsSourceCodeEditorFlattened, setContractsSourceCodeEditorFlattened] = useState();
    const [contractsInfoEditor, setContractsInfoEditor] = useState();
    const [sourceCodeLoaded, setSourceCodeLoaded] = useState("loading");
    const [monacoIsSetup, setMonacoIsSetup] = useState(false);
    const [functionToExecute, setFunctionToExecute] = useState();
    const [functionToExecuteParameters, setFunctionToExecuteParameters] = useState();
    const [functionToExecuteType, setFunctionToExecuteType] = useState();
    const [executionResult, setExecutionResult] = useState();
    const [executingFunction, setExecutingFunction] = useState(false);
    const [issuesHighlighting, setIssuesHighlighting] = useState();
    const [errorHighlightCodeDecoration, setErrorHighlightCodeDecoration] = useState([]);
    const [initialMessage, setInitialMessage] = useState("Loading the Contract Code...");

    //const [callCompilation, setCallCompilation] = useState(false);
    const [callDeployment, setCallDeployment] = useState(false);

    // Left Panel Resizing
    const [isResizing, setIsResizing] = useState(false);
    const [initialX, setInitialX] = useState(0);
    const leftPanelInitialWidth = 350; // 350px plus the side menu
    const leftPanelMaxWidth = 700; // 350px plus the side menu
    const [tabLabelsWidth, setTabLabelsWidth] = useState(Math.max(350, leftPanelInitialWidth));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const compilationPanelRef = React.useRef(null);
    const navigate = useNavigate();

    // Control +  (Save Files)
    const [currentDirectory, setCurrentDirectory] = useState();
    const contractsSourceCodeEditorRef = useRef(contractsSourceCodeEditor);
    const eventListenerAddedRef = useRef(false);
    useEffect(() => {
        if (!eventListenerAddedRef.current) {
            document.addEventListener("keydown", handleKeyDown, true);
            eventListenerAddedRef.current = true;
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === "s") {
            e.preventDefault();
            saveFiles();
        }
    };
    async function saveFiles() {
        try {
            let _directory = currentDirectory;
            if (!_directory) {
                _directory = await FileUtil.getDirectory();
                setCurrentDirectory(_directory);
            }
            for (let contract = 0; contract < contractsSourceCodeEditorRef.length; contract++) {
                const _contract = contractsSourceCodeEditorRef[contract];
                const _newFile = await FileUtil.createNewFile(_contract.contract + ".sol", _directory);
            }
            notify("Files saved successfully", "info");
            console.debug("Files saved successfully");
        } catch (error) {
            if (error.message && error.message.includes("The user aborted a request")) {
                console.debug("User aborted the save request.");
                return; // Exit the function without notifying the user
            }
            notify("Problem saving your files. Please, try again.", "warn");
            console.log(error);
        }
    }

    // console.log("Content render");

    // - How it works v2 (July 2023)
    // The idea is to show or enable the user to some small maintenances in this version.
    // Is not allowed to create a new file for example
    // If there is network and contract address, the content will get the source code and show in read-only mode
    // The user shoud click on the button to save a local copy to change to a local mode

    // console.log("CodeEditor - Content - sourceCodeLoaded", sourceCodeLoaded);

    // HOW IT WORKS? States
    // 2 tabs - Local (local files in the machine) or Network (Imported from the Execute screen)
    // tabsNetworkContractsSourceCode, tabsLocalContractsSourceCode - contains all tabs
    //      each tab is a file (local) or contract (network)
    // activeTabLocalNetwork - The top active tab (local or network)
    // activeTab - the inner active tab (a tab in tabsNetworkContractsSourceCode or tabsLocalContractsSourceCode)
    // rootContract - indicate the root contract when you have more than 1 loaded (common becasue you have imports) - TODO: FIX IT ! We should have one for local and one for network, or only for network makes sense?
    // rootContractSourceCode - root contract source code
    // fileList - List of files (when a user open a directory)
    // fileListDir - Directory of List of files
    // selectedItem - Store the file id and is used by FileTree component to know which file the user was clicked
    // selectedItemObj - When the user double click a file or contract (network), this item is loaded with it
    // lastCompilationInfo - Store the last compilation info - Should have one for local and another for network?
    // editorMode - Change the behaviour of the page to Debug, New File(s), Directory or ????
    // newFileHandle - When you create a new file, store it - Change to an array or find a way to use the same structure that we have for directories using the base directory of the file
    //      - Should be possible to edit more than one new file at the same time

    // Local Machine - Files
    // User has to select a directory to sert as a root
    // After this, he can create new files or edit them
    // If he changes the directory, load a new set of files in the state - alert about unsaved changes

    // TODO: Create a behaviour to handle requests comming from user not logged on. Receive the contract using query string and use methods to get the source code.

    const monaco = useMonaco();
    const editorRef = useRef(null);

    const handleResize = (event) => {
        setInitialX(0);
        setIsResizing(true);
    };

    useEffect(() => {
        contractsWalletEditorRef.current = contractsWalletEditor;
    }, [contractsWalletEditor]);

    useEffect(() => {
        contractsNetworkEditorRef.current = contractsNetworkEditor;
    }, [contractsNetworkEditor]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (isResizing) {
                let newWidth = event.clientX - 85 - initialX; // 85px of the sidemenu side menu
                // console.log("event.clientX initialX newWidth", event.clientX, initialX, newWidth);
                if (newWidth >= leftPanelMaxWidth) newWidth = leftPanelMaxWidth;
                setTabLabelsWidth(Math.max(newWidth, leftPanelInitialWidth));
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing, initialX]);

    useEffect(() => {
        if (!network || !contract) {
            notify("Select a network and a contract to use the code editor", "info");
            setInitialMessage("Select a network and a contract to use the code editor. Redirecting you to the Initial Page...");
            setTimeout(() => {
                navigate("/sourcecode");
            }, 4000);
            return;
        }
        loadSourceCode();

        return;

        // OLD - Network
        if (contractsSourceCode) {
            // console.log("contractsSourceCode", contractsSourceCode);
            setTabsNetworkContractsSourceCode(reverseArray(contractsSourceCode));
            // console.log("tabsNetworkContractsSourceCode", tabsNetworkContractsSourceCode);
            setActiveTabLocalNetwork("network");
            // Se the root contract name and code
            if (contractsInfo.ContractName) setRootContract(contractsInfo.ContractName);
            contractsSourceCode.forEach((contract) => {
                if (contract.contract === contractsInfo.ContractName) setRootContractSourceCode(contract.code);
            });
        }

        console.log("Content MODE:", mode, "tx", tx);
        // if (mode === "debug") {
        //     setEditorMode("debug");
        //     console.warn("Develop the debug mode");
        // }
    }, []);

    // useEffect(() => {
    //     try {
    //         if (monaco) {
    //             if (!monacoIsSetup) {
    //                 // console.log("here is the monaco instance - adding styles:", monaco);
    //                 CodeEditorUtil.addSolidity(monaco, executeFunctionFromEditor);
    //                 setMonacoIsSetup(true);
    //             }
    //         }
    //     } catch (error) {
    //         setMonacoIsSetup(false);
    //     }
    // }, [monaco]);

    function handleEditorDidMount(editor, monaco) {
        // console.log("onMount: the editor instance:", editor);
        // console.log("onMount: the monaco instance:", monaco);

        try {
            if (editor) {
                // used to manage the current value
                editorRef.current = editor;
                setEditorIsAvailable(true);
                console.debug("Editor Instance ready to be used");
                //loadSourceCodeIntoEditor();

                // Setup theme and other config
                CodeEditorUtil.addSolidity(monaco, executeFunctionFromEditor);

                // Setup actions (context menu)
                if (window.monaco.editor.getEditors()[0]) CodeEditorUtil.addActions(executeFunctionFromEditor);
                else console.warn("Can't find an Editor instance to add actions");
            }
        } catch (error) {
            console.error(error);
        }
    }
    // useEffect(() => {
    //     try {
    //         if (monaco) {
    //             const model = window.monaco.editor.getModel();
    //             const value = model.getValue();
    //             console.log("monaco value", value);
    //         }
    //         if (monaco && tabsNetworkContractsSourceCode && tabsNetworkContractsSourceCode[activeTab] && tabsNetworkContractsSourceCode[activeTab].code) {
    //             if (window.monaco.editor.getEditors()[0]) CodeEditorUtil.addActions(executeFunctionFromEditor);
    //             else console.warn("Can't find an Editor instance");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }, [tabsNetworkContractsSourceCode]);

    async function executeFunctionFromEditor(type_, function_) {
        console.log("executeFunctionFromEditor", type_, function_);

        if (type_ === "compile") {
            if (compilationPanelRef.current) {
                compilationPanelRef.current.compile();
            }
            return;
        } else if (type_ === "deploy") {
            setCallDeployment(true);
            return;
        }
        // else if (type_ === "verify") { // autoverify is implemented, still makes sense?
        //     setCallVerification(true);
        //      return;
        // }

        const proxy_ = null; // TODO: How and where it will be used? The user should say that is a proxy ? How to identify?
        const implementation_ = contract;

        // Get more info about the function
        // ! BUG it seems if the stat ei not loaded before this command was "rendered", the state is undefined
        // ! BUG wallet pk is not being sent
        console.log("executeFunctionFromEditor contractsNetworkEditor", contractsNetworkEditor);
        console.log("executeFunctionFromEditor contractsNetworkEditorRef.current", contractsNetworkEditorRef.current);
        let abi;
        let functions;
        let _function;
        if (contractsInfoEditor.isProxy) {
            [abi, functions] = await getFunctions(contractsNetworkEditorRef.current.id, contractsInfoEditor.implementation.addressFromNetwork);
            _function = functions.find((item) => item.name === function_);
        } else {
            [abi, functions] = await getFunctions(contractsNetworkEditorRef.current.id, contract);
            _function = functions.find((item) => item.name === function_);
        }
        console.log("executeFunctionFromEditor _function", _function);
        // If the function was not found, it means the code was changed
        if (!_function) {
            notify("Function to execute was not found in the deployed contract. You need to deploy the actual code to be able to execute it.", "warn");
            return;
        }

        // For payable and non-payable functions, a wallet should be set to pay for the gas fees
        console.log("executeFunctionFromEditor _function.stateMutability", _function.stateMutability);
        let _wallet;
        if (getFunctionGasType(_function.stateMutability) === FunctionGasType.PayGasFee && type_ === "execution") {
            console.log("executeFunctionFromEditor contractsWalletEditor", contractsWalletEditor);
            console.log("executeFunctionFromEditor contractsWalletEditorRef.current", contractsWalletEditorRef.current);
            if (!contractsWalletEditorRef.current) {
                // TODO: Open Wallet popup
                notify("This function requires gas to run, you need to set a wallet");
                window.popupWallet.showModal();
                return;
            }
            _wallet = contractsWalletEditorRef.current;
        }

        // show PopupFunctionParameters
        //if (_function.inputs.length > 0) {
        setFunctionToExecute(_function);
        console.log("Opening the popupFunctionParameters - type_", type_);
        setFunctionToExecuteType(type_);
        window.popupFunctionParameters.showModal();
        console.log("function with parameters - modal called");
        return;
        //}

        // view/pure without parameters
        //executeFunctionEditor(type_, network, proxy_, implementation_, function_, null, null, null);
    }

    async function loadSourceCode() {
        try {
            const [_contractInfo, _contractsCode] = await loadContractSourceCodeEditor(network, contract);

            // if the implementation address from the Block Explorer is different from the Network, show an alert
            if (
                _contractInfo.implementation &&
                _contractInfo.implementation.address &&
                _contractInfo.implementation.address.toLowerCase() !== _contractInfo.implementation.addressFromNetwork.toLowerCase()
            ) {
                notify(
                    "The registred implementation address in the Network is different from the Block Explorer. Submit a new contract verification of the new implementation contract in the Block Explorer. If you already did, wait at least 15 minute and check it again.",
                    "warn"
                );
            }

            // Info: all contracts at the root level will have "contracts/"
            // e.g. path: "contracts/PistonToken.sol"
            // All imported contracts will have their entire path
            // e.g. path: "@openzeppelin/contracts-upgradeable/token/ERC20/ERC20Upgradeable.sol"
            // This will be helful to find the contracts and flatten them

            if (!_contractsCode) {
                // console.warn("Content - loadSourceCode - Source code not available");
                setContractsSourceCodeEditor();
                setContractsInfoEditor();
                setSourceCodeLoaded(false);
            } else {
                console.debug("Content - loadSourceCode - Source code IS available - _contractsCode", _contractsCode);
                setContractsSourceCodeEditor(_contractsCode);
                setContractsInfoEditor(_contractInfo);
                setSourceCodeLoaded(true);
            }
        } catch (error) {
            // - TODO: Show a message in the page about not be able to load the contract source code
            //notify("Not able to load the source code for this contract. Review if it's published on the block explorer and try again.", "error");
            console.error(error);
            setContractsSourceCodeEditor();
            setContractsInfoEditor();
            setSourceCodeLoaded(false);
        }
    }
    useEffect(() => {
        if (contractsSourceCodeEditor) {
            // setTabsNetworkContractsSourceCode(reverseArray(contractsSourceCodeEditor));
            setTabsNetworkContractsSourceCode(contractsSourceCodeEditor);
            // console.log("tabsNetworkContractsSourceCode", tabsNetworkContractsSourceCode);
            setActiveTabLocalNetwork("network");
            // Se the root contract name and code
            if (contractsInfoEditor.isProxy) setRootContract(contractsInfoEditor.implementation.details.ContractName);
            else if (contractsInfoEditor.ContractName) setRootContract(contractsInfoEditor.ContractName);
            contractsSourceCodeEditor.forEach((contract) => {
                if (contract.contract === contractsInfoEditor.ContractName) setRootContractSourceCode(contract.code);
            });

            // Control + s
            contractsSourceCodeEditorRef.current = contractsSourceCodeEditor;
        }
    }, [contractsSourceCodeEditor]);
    useEffect(() => {
        if (networks) {
            const contractNetworkName = getNetworkByID(network);
            setContractsOriginalNetworkEditor(contractNetworkName);
            setContractsNetworkEditor(contractNetworkName);
            console.debug("Networks Loaded", contractNetworkName);
        } else {
            console.debug("Unable to load networks. Check messages below.");
        }
    }, [networks]);

    useEffect(() => {
        if (contractsInfoEditor && contractsNetworkEditor) document.title = contractsInfoEditor.ContractName + " (" + contractsNetworkEditor.name + ")";
    }, [contractsNetworkEditor, contractsInfoEditor]);

    // Handle the tab changing using Ref and not state (ref doesn't losse the value when re-renders)
    const changingContractsEditorRef = useRef(false);

    // Editor code load
    function loadSourceCodeIntoEditor() {
        if (editorRef.current && editorIsAvailable && contractsSourceCodeEditor && activeTab >= 0) {
            changingContractsEditorRef.current = true;
            editorRef.current.setValue(contractsSourceCodeEditor[activeTab].code);
        }
    }

    // When user click in a different contract, load the code into the editor
    useEffect(() => {
        loadSourceCodeIntoEditor();
    }, [activeTab, editorIsAvailable]);

    // - When the user types something, should trigger the autocompile
    // - When the user changes tabs, shouldn't
    const handleEditorChange = debounce((value, event) => {
        // BUG: When you change a tab, the code is updated and the autocompile is started...
        if (changingContractsEditorRef.current) {
            changingContractsEditorRef.current = false;
            return;
        } else {
            // console.log("autocompilation activated");
        }
        if (activeTabLocalNetwork === "local") {
            tabsLocalContractsSourceCode[activeTab].code = value;
            setTabsLocalContractsSourceCode(tabsLocalContractsSourceCode);
            setRootContractSourceCode(value);
        } else {
            tabsNetworkContractsSourceCode[activeTab].code = value;
            setTabsNetworkContractsSourceCode(tabsNetworkContractsSourceCode);
            if (tabsNetworkContractsSourceCode[activeTab].contract === rootContract) setRootContractSourceCode(value);
        }
        autoCompile();
    }, 500); // 500 is fine, debounce is working for code changes

    const autoCompile = () => {
        // console.log("autocompilation being executed");
        clearTimeout(autoCompileTimeout); // Clear previous debounce
        autoCompileTimeout = setTimeout(() => {
            if (compilationPanelRef.current) {
                // console.log("compilation called");
                compilationPanelRef.current.compile(true);
            }
        }, 3000);
    };

    useEffect(() => {
        if (tabsNetworkContractsSourceCode && tabsNetworkContractsSourceCode[activeTab] && issuesHighlighting) {
            // Filter the items based on the current tab
            const currentFile = tabsNetworkContractsSourceCode[activeTab].path;
            const issues = issuesHighlighting.filter((issue) => issue.file === currentFile);
            if (issues.length > 0) {
                CodeEditorUtil.highlightErrorCode(setErrorHighlightCodeDecoration, errorHighlightCodeDecoration, issues[0].highlight);
                CodeEditorUtil.addErrorMarker(issues[0].marker);
            } else {
                // TODO: check if this ever will be executed, since when you have errors, the ".issues" is null and the useEffect is not activated
                CodeEditorUtil.highlightErrorCode(setErrorHighlightCodeDecoration, errorHighlightCodeDecoration, []);
                CodeEditorUtil.addErrorMarker(null);
            }
        }

        if (!issuesHighlighting && editorIsAvailable) {
            CodeEditorUtil.highlightErrorCode(setErrorHighlightCodeDecoration, errorHighlightCodeDecoration, []);
            CodeEditorUtil.addErrorMarker(null);
        }
    }, [issuesHighlighting]);

    // console.log("contractsSourceCode", contractsSourceCode);
    // console.log("tabsNetworkContractsSourceCode", tabsNetworkContractsSourceCode);
    // console.log("tabsLocalContractsSourceCode", tabsLocalContractsSourceCode);

    // TODO: Get the version from the block Explorer (working for a single contract)
    // ! 'latest' for solidity version should be accepted and passed to the API
    // ! DEBUG: Select the text - https://stackoverflow.com/questions/57246356/how-to-highlight-merge-conflict-blocks-in-monaco-editor-like-vscode
    // https://snyk.io/advisor/npm-package/monaco-editor/functions/monaco-editor.Range
    //      window.editor.getModel().getValueInRange(window.editor.getSelection())

    // const handleContextMenu = (event) => {
    //     // Your custom logic for the right-click event
    //     console.log("Right-click event occurred!", event);
    // };

    // Mobile
    useEffect(() => {
        if (screenWidth >= 1024) setTabLabelsWidth(Math.max(350, leftPanelInitialWidth));
        else setTabLabelsWidth(0);
    }, []);

    return (
        <div className="vertical-tab bg-default">
            <div className="tab-labels flex flex-col md:flex-row overflow-x-auto resize-x md:resize-none min-w-0" style={{ width: tabLabelsWidth }}>
                <div>
                    {/* // - Disabled to only show the source code of a contract in the Execute Page */}
                    {/* <SourceSelector activeTabLocalNetwork={activeTabLocalNetwork} setActiveTabLocalNetwork={setActiveTabLocalNetwork} />
                    {activeTabLocalNetwork === "local" && (
                        <LocalPanel
                            fileListDir={fileListDir}
                            setFileListDir={setFileListDir}
                            fileList={fileList}
                            setFileList={setFileList}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            setSelectedItemObj={setSelectedItemObj}
                            tabsLocalContractsSourceCode={tabsLocalContractsSourceCode}
                            setTabsLocalContractsSourceCode={setTabsLocalContractsSourceCode}
                            setActiveTab={setActiveTab}
                            setEditorMode={setEditorMode}
                            setRootContractSourceCode={setRootContractSourceCode}
                            setDependencesSourceCode={setDependencesSourceCode}
                        />
                    )} */}
                    {activeTabLocalNetwork === "network" && (
                        <div className="hidden lg:block">
                            <NetworkPanel
                                tabsNetworkContractsSourceCode={tabsNetworkContractsSourceCode}
                                contractsInfoEditor={contractsInfoEditor}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                rootContract={rootContract}
                                contractsNetworkEditor={contractsNetworkEditor}
                                setContractsNetworkEditor={setContractsNetworkEditor}
                                setContractsWalletEditor={setContractsWalletEditor}
                                contractsWalletEditor={contractsWalletEditor}
                            />
                        </div>
                    )}
                    {/* <div className="flex flex-col w-full items-center justify-center unselectable hidden lg:block">
                        <p className="py-1 text-secondary text-center font-semibold">This feature still in development.</p>
                        <p className="py-1 text-secondary text-center font-semibold">Use at your own risk.</p>
                        <p className="py-1 text-secondary text-center font-semibold">Never use a wallet with your real assets.</p>
                    </div> */}
                    {/* <div className="flex flex-col w-full items-center justify-center unselectable">
                        <p className="py-2 text-secondary text-center">The changes are not saved. Create a copy in your local machine to be able to save the code.</p>
                        <button className="btn-default" onClick={() => alert("open popup so choose a folder, create a copy of the files in the directory")}>
                            Save Files
                        </button>
                    </div> */}
                </div>
                <div className="tab-buttons">
                    {activeTabLocalNetwork === "local" && <FilePanel selectedItem={selectedItemObj} tabsLocalContractsSourceCode={tabsLocalContractsSourceCode} activeTab={activeTab} />}
                    {((activeTabLocalNetwork === "local" && tabsLocalContractsSourceCode) || (activeTabLocalNetwork === "network" && tabsNetworkContractsSourceCode)) && (
                        <div className="hidden lg:block">
                            <ContractPanel
                                activeTabLocalNetwork={activeTabLocalNetwork}
                                setContractName={setContractName}
                                contractAddress={contract}
                                contractName={contractName}
                                compilerVersion={compilerVersion}
                                setCompilerVersion={setCompilerVersion}
                                compilerOptimization={compilerOptimization}
                                setCompilerOptimization={setCompilerOptimization}
                                compilerRuns={compilerRuns}
                                setCompilerRuns={setCompilerRuns}
                                contractsInfoEditor={contractsInfoEditor}
                                contractsNetworkEditor={contractsNetworkEditor}
                                contractsOriginalNetworkEditor={contractsOriginalNetworkEditor}
                            />
                        </div>
                    )}

                    <div className="hidden lg:block">
                        <ExecutionPanel currentFunction={functionToExecute} executionResult={executionResult} executingFunction={executingFunction} network={contractsNetworkEditor} />
                    </div>

                    {/* // - Disabled to only show the source code of a contract in the Execute Page */}
                    {/* {mode === "debug" && <DebugPanel setCurrentCodeDebugDecoration={setCurrentCodeDebugDecoration} currentCodeDebugDecoration={currentCodeDebugDecoration} />} */}
                    <div className="hidden lg:block">
                        <CompilationPanel
                            sourceCode={contractsSourceCodeEditor} // TODO: Only with contract without depedences, add imports
                            dependencesSourceCode={dependencesSourceCode}
                            contractsInfo={contractsInfoEditor}
                            compilerVersion={compilerVersion}
                            compilerOptimization={compilerOptimization}
                            compilerRuns={compilerRuns}
                            contractName={contractName}
                            setLastCompilationInfo={setLastCompilationInfo}
                            lastCompilationInfo={lastCompilationInfo}
                            activeTabLocalNetwork={activeTabLocalNetwork}
                            setRootContract={setRootContract}
                            setContractsSourceCodeEditorFlattened={setContractsSourceCodeEditorFlattened}
                            activeTab={activeTab}
                            setIssuesHighlighting={setIssuesHighlighting}
                            // setCallCompilation={setCallCompilation}
                            // callCompilation={callCompilation}
                            ref={compilationPanelRef}
                        />
                        <DeploymentPanel
                            activeTabLocalNetwork={activeTabLocalNetwork}
                            rootContract={rootContract}
                            contractsInfo={contractsInfoEditor}
                            compilationResult={lastCompilationInfo}
                            contractSourceCode={rootContractSourceCode}
                            contractContructorArguments={""}
                            compilerVersion={compilerVersion}
                            optimizationUsed={compilerOptimization}
                            optimizationRuns={compilerRuns}
                            contractsSourceCodeEditorFlattened={contractsSourceCodeEditorFlattened} // Network - contract source code flattened
                            contractsNetworkEditor={contractsNetworkEditor}
                            contractsWalletEditor={contractsWalletEditor}
                            setCallDeployment={setCallDeployment}
                            callDeployment={callDeployment}
                        />
                    </div>
                </div>
            </div>

            <ResizeHandle onMouseDown={handleResize} />

            {sourceCodeLoaded === false && (
                <div className="tab-content content-full text-center">
                    <div className="h-20">
                        <p className="text-lg text-secondary h-7">There is an issue loading the Contract Code.</p>
                        <p className="text-lg text-light h-7">Be sure the code is published on the current Network Block Explorer ({contractsNetworkEditor.blockExplorerURL}).</p>
                        <p className="text-lg text-light h-7">Or try again refreshing the page</p>
                    </div>
                </div>
            )}
            {sourceCodeLoaded === "loading" && (
                <div className="tab-content content-full text-center">
                    <div className="h-20">
                        <p className="text-lg text-light">{initialMessage}</p>
                    </div>
                </div>
            )}
            {(tabsNetworkContractsSourceCode || tabsLocalContractsSourceCode) && (
                <div className="tab-content">
                    <FeedbackComponent position="bottomRight" delay={5000} cookieName={"fbtEditor"} />
                    <Editor
                        language="solidity"
                        //theme="vs-dark"
                        theme="solidityTheme"
                        scrollBeyondLastLine={false}
                        value={
                            "// 😭 There is an issue setting the right initial contract.\n// 👈 Please select any contract on Contract Explorer to start.\n// 🛠️ We are working to fix it as soon as possible."
                        }
                        // value={
                        //     activeTabLocalNetwork === "network" && tabsNetworkContractsSourceCode
                        //         ? tabsNetworkContractsSourceCode[activeTab].code
                        //         : activeTabLocalNetwork === "local" && tabsLocalContractsSourceCode
                        //         ? tabsLocalContractsSourceCode[activeTab].code
                        //         : null
                        // }
                        onChange={handleEditorChange}
                        // onContextMenu={handleContextMenu}
                        onMount={handleEditorDidMount}
                    />
                </div>
                // TODO: Check this code to know more how to configur Monaco - Util/CodeEditorUtil
            )}
            <NotifyContainer />
            <PopupFunctionParameters
                network={contractsNetworkEditor}
                contract={contract}
                contractsInfoEditor={contractsInfoEditor}
                currentFunction={functionToExecute}
                wallet={contractsWalletEditor}
                functionToExecuteType={functionToExecuteType}
                setParametersValues={setFunctionToExecuteParameters}
                setExecutionResult={setExecutionResult}
                setExecutingFunction={setExecutingFunction}
            />
        </div>
    );
};

export default Content;
