import { useState } from "react";
import Web3 from "web3";

const getOpcodes = () => {
    const [opcodes, setOpcodes] = useState(null);
    const [tx, setTx] = useState(null);

    function handleInput(event) {
        setTx(event.target.value);
    }

    function getOpcodesTransaction() {
        const web3 = new Web3(new Web3.providers.HttpProvider("http://192.168.1.92:8545/"));
        const _options = { enableMemory: true, disableStack: false, disableStorage: false, enableReturnData: true };
        web3.currentProvider.send(
            {
                // // {tracer: 'callTracer'}
                // method: "debug_traceTransaction",
                // params: [txHash, { tracer: "callTracer" }],
                // jsonrpc: "2.0",
                // id: new Date().getTime(),

                // Default
                method: "debug_traceTransaction",
                // REF: https://geth.ethereum.org/docs/developers/evm-tracing/built-in-tracers
                params: [tx, _options], // FULL TRACE - command by command
                jsonrpc: "2.0",
                id: new Date().getTime(),
            },
            function (error, result) {
                console.log("error", error);
                console.log("result", result);
                setOpcodes(result);
            }
        );
    }

    return (
        <div>
            <p>OPCODES FOR </p>
            <input name="txInput" onChange={handleInput}></input>
            <button className="btn" onClick={() => getOpcodesTransaction()}>
                Get Opcodes
            </button>
            <pre>{JSON.stringify(opcodes, null, 4)}</pre>
        </div>
    );
};

export default getOpcodes;
