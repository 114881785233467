import { useState, useContext, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import NavBar from "@components/navbar/NavBar";
import Footer from "@components/footer/Footer";
import SideMenu from "@components/sidemenu/SideMenu";
import { notify, NotifyContainer } from "@util/component";
import ValidationMessage from "@components/shared/ValidationMessage";
import { validateEmail } from "@util/util";
import { Web3util } from "@util/web3util";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faCopy } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const {
        sendAuthEmail,
        validateUserAuthToken,
        connectedAccount,
        getOnboardingStatus,
        updateOnboardingStatus,
        loadUserData,
        addDemoContractsNewUser,
        createNewWallet,
        validateUserAuthTokenWallet,
        setDataLoaded,
        checkConnectedUser,
    } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordProvided, setPasswordProvided] = useState(null);
    const [loginType, setLoginType] = useState("new");
    const [validationMessages, setValidationMessages] = useState({});
    const [validationMode, setValidationMode] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get("e") && searchParams.get("t") && !connectedAccount) {
            processUserValidation();
        }
    }, []);

    useEffect(() => {
        //checkLoginStatus();
    }, [connectedAccount]);

    async function checkLoginStatus() {
        console.log("Checking Connected Account", connectedAccount);
        if (connectedAccount) {
            // TODO: Check if this user was onboarded to not load data again
            const _status = await getOnboardingStatus();
            if (!_status || _status.status != "finished") {
                // TODO: Load the account with examples and test data
                await updateOnboardingStatus();
            }
            await loadUserData(connectedAccount);
            if (!_status || _status.status != "finished") {
                // TODO: load test contracts only if is the onboarding
                console.log("Login - checkLoginStatus - addDemoContractsNewUser");
                await addDemoContractsNewUser();
                // Add a new wallet
                await createNewWallet("Demo Wallet - Get MATIC in the Faucet");
            }
            // TODO: create a wallet and assign to this user (send an email with the PK)
            navigate("/execute");

            // const _status = await getOnboardingStatus();
            // if (!_status || _status.status != "finished") {
            //     navigate("/onboarding");
            // }
            // else {
            //     navigate("/login");
            // }
        }
    }

    async function processUserValidation() {
        console.log("Login - got a token", searchParams.get("e"));
        setValidationMode(true);
        await validateUserToken(searchParams.get("e"), searchParams.get("t"));
    }

    async function validateUserToken(email, token) {
        const _result = await validateUserAuthToken(email, token);
        console.log("Login validateUserToken", _result);
        if (_result) {
            notify("You are authenticated");
        } else notify("Issue authenticating your user. Please request a new email and try again.", "error");
    }

    const updateValidationMessages = (event, message) => {
        setValidationMessages((prevValues) => {
            return {
                ...prevValues,
                [event.target.name]: message,
            };
        });
    };

    function handleInputChange(event) {
        // console.log("validateEmail", validateEmail(event.target.value));

        if (event.target.name === "passwordProvided") {
            setPasswordProvided(event.target.value);
        }

        if (!validateEmail(event.target.value) && event.target.value.length > 9) {
            updateValidationMessages(event, "Email should be valid");
            return;
        }

        updateValidationMessages(event, null);
        setEmail(event.target.value);
    }

    function sendLink() {
        if (!email) {
            notify("Please, provide a valid email");
            return;
        }
        notify("Check your email and follow the provided link");
        sendAuthEmail(email);
    }

    function generatePassword() {
        const _password = Web3util.createNewWallet();
        console.log("generatePassword _password", _password);
        if (_password) {
            setPassword(_password.privateKey);
            navigator.clipboard.writeText(_password.privateKey);
        } else notify("Error generating the password. Please try again.", "warn");
    }

    async function processLoginPassword() {
        const _userValidation = await validateUserAuthTokenWallet(password);
        console.log("Login processLoginPassword", _userValidation);
        if (_userValidation) {
            notify("You are authenticated");
        } else notify("There was a problem during the login process. Please try again.", "warn");
    }

    async function processLoginPasswordExistingAccount() {
        const _userValidation = await validateUserAuthTokenWallet(passwordProvided);
        console.log("Login processLoginPassword", _userValidation);
        if (_userValidation) {
            notify("You are authenticated");
            setLoginType("existing");
        } else notify("There was a problem during the login process. Please try again.", "warn");

        // Load the user data
        checkConnectedUser();

        // TODO: Present the button start here, but with the text "Welcome Back"

        // The user already has an account
        // Validate the pw and show a message if the account exists
        // create a token and save it
        // load all existing user data
    }

    async function loadSampleData() {
        await addDemoContractsNewUser();
        console.log("processLoginPassword - Contracts Demo added to the new account");
        await createNewWallet("Demo Wallet - Get MATIC in the Faucet");
        console.log("processLoginPassword - New Demo Wallet added to the new account");
        //checkConnectedUser(); // reload user data from DB and mark the data as loaded
        setDataLoaded(true);
        redirectToFirstPage();
    }

    async function redirectToFirstPage() {
        // // - TERRIBLE AND UGLY - but we have to wait until the cookie is set
        // console.log("redirectToFirstPage waiting for the cookie to be set", connectedAccount);
        // setTimeout(function () {
        // await loadUserData(connectedAccount);
        //     console.log("redirectToFirstPage cookie set ?!, loading user data", connectedAccount);
        // }, 2000);
        // setTimeout(function () {
        setDataLoaded(true);
        console.log("redirectToFirstPage navigate(/execute", connectedAccount);
        navigate("/execute");
        // }, 2000);
    }

    console.log("Login Page connectedAccount", connectedAccount);

    return (
        <div className="container">
            <nav className="navbar">
                <NavBar />
            </nav>
            <aside className="sidebar">
                <SideMenu />
            </aside>
            <main className="content-full">
                <h3 className="font-bold text-3xl text-white pt-2">Welcome to DevWeb3</h3>
                {!validationMode && !connectedAccount && (
                    <>
                        <h1 className="pt-5 text-white">Choose how to sync your data</h1>
                        <div className="md:w-[700px] mt-5 border-[#AA6840] border-[1px] p-4 text-light rounded-lg">
                            <div className="flex flex-col items-center justify-center w-full">
                                <p className="text-left w-full">
                                    New account based in a password that will be <strong>displayed only once and cannot be recovered</strong>. This method provides enhanced security; however, it is
                                    important to note that if you lose the password, <strong>there is no way to retrieve your data</strong>.
                                </p>
                                {/* <div className="bg-yellow-600"> */}
                                {/* <h3 className="text-lg text-white pt-10">Enter a valid email</h3> */}
                                {/* <div className="w-full mt-6"> */}
                                <div className="flex items-center justify-center w-full mt-6">
                                    <input
                                        type="text"
                                        name="password"
                                        onChange={handleInputChange}
                                        className="max-w-xl"
                                        placeholder="Generated Password"
                                        autoComplete="new-password"
                                        value={password || ""}
                                        disabled></input>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="text-secondary ml-2 cursor-pointer w-8"
                                        onClick={() => {
                                            navigator.clipboard.writeText(password);
                                        }}
                                    />
                                </div>
                                {password && <p className="flex justify-center text-red text-sm w-full">SAVE THIS PASSWORD, IT WILL NEVER BE DISPLAYED AGAIN!</p>}
                                <div className="flex items-center justify-center w-full mt-6">
                                    <button
                                        className="btn-default"
                                        onClick={() => {
                                            generatePassword();
                                        }}>
                                        Generate One-Time Password
                                    </button>
                                    {password && (
                                        <button
                                            className="btn-default ml-5"
                                            onClick={() => {
                                                processLoginPassword();
                                            }}>
                                            Continue
                                        </button>
                                    )}
                                </div>
                                {/* </div> */}
                                {/* </div> */}
                                <p className="pt-8 text-left w-full">Login using an existing account with previous provided password</p>
                                <div className="flex items-center justify-center w-full mt-6">
                                    <input
                                        type="text"
                                        name="passwordProvided"
                                        onChange={handleInputChange}
                                        className="max-w-xl"
                                        placeholder="Existing Account Password"
                                        autoComplete="new-password"
                                        value={passwordProvided || ""}></input>
                                </div>
                                <div className="flex justify-center w-full mt-5">
                                    {passwordProvided && (
                                        <button
                                            className="btn-default ml-5"
                                            onClick={() => {
                                                processLoginPasswordExistingAccount();
                                            }}>
                                            Login
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="md:w-[700px] mt-5 border-[#AA6840] border-[1px] p-4 text-light rounded-lg">
                            <p>
                                We only use your email as login information to store the data you provide on our platform.{" "}
                                <strong>We never use your email address to send marketing offers or share your data with any third-parties.</strong>
                            </p>
                            <div className="flex flex-col items-center justify-center w-full">
                                <div className="w-full mt-6">
                                    <input type="text" name="email" onChange={handleInputChange} className="max-w-xl" placeholder="Email" autoComplete="new-password"></input>
                                    {validationMessages["email"] && <ValidationMessage message={validationMessages["email"]} />}
                                    <div className="flex justify-center w-full mt-5">
                                        <button
                                            className="btn-default"
                                            onClick={() => {
                                                sendLink();
                                            }}>
                                            Send Magic Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-[700px] mt-5 border-[#AA6840] border-[1px] p-4 text-light rounded-lg">
                            <p>x
                                We would like to remind you to <strong>never use a wallet containing real investment assets on our platform</strong>. We utilize the private keys of wallets to execute
                                calls to contracts, which may be stored in our database. This approach is implemented for enhanced efficiency and faster processing. To ensure safety, we recommend
                                using wallets specifically created for executing functions in contracts and limiting the amount of assets stored in Mainnets wallets to minimize potential issues.
                                <br />
                                <br />
                                If you wish to use a new wallet, you can create one by accessing the Settings - Wallets option menu. We encourage you to familiarize yourself with our platform by doing
                                so.
                                <br />
                                <br />
                                Our team strives to provide the best possible service to our users, and we welcome your feedback through our Social Media links in the footer.
                                <br />
                                <br />
                                By using DevWeb3, you agree to abide by these terms and conditions. Please note that we do not accept responsibility for any loss or damage resulting from the use of
                                this platform. Kindly use DevWeb3 at your own risk.
                            </p>
                        </div>
                        <h3 className="flex items-center text-lg text-white pt-2">
                            <FontAwesomeIcon icon={faArrowCircleLeft} className="text-secondary pr-2" /> or start using our Tools on the Left Menu
                        </h3>
                    </>
                )}
                {connectedAccount && loginType === "new" && (
                    <>
                        <h3 className="text-lg text-white pt-10">You are authenticated as {connectedAccount}. Enjoy!</h3>
                        <div className="flex justify-center w-full pt-10">
                            <button
                                className="btn-default"
                                onClick={() => {
                                    loadSampleData();
                                }}>
                                Load Sample Data and Start
                            </button>
                            <button
                                className="btn-default ml-10"
                                onClick={() => {
                                    redirectToFirstPage();
                                }}>
                                Start Here
                            </button>
                        </div>
                    </>
                )}
                {connectedAccount && loginType === "existing" && (
                    <>
                        <h3 className="text-lg text-white pt-10 font-semibold">Welcome Back.</h3>
                        <h3 className="text-lg text-white pt-5">You are authenticated as {connectedAccount}.</h3>
                        <h3 className="text-lg text-white pt-5">Enjoy!</h3>
                        <div className="flex justify-center w-full pt-10">
                            <button
                                className="btn-default"
                                onClick={() => {
                                    redirectToFirstPage();
                                }}>
                                Go to Executor
                            </button>
                        </div>
                    </>
                )}
            </main>
            <footer className="footer">
                <Footer />
            </footer>
            <NotifyContainer />
        </div>
    );
};

export default Login;
