import { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "@context/AppContext";
import { FunctionGasType } from "@util/enum";
import { dateDefaultFormat } from "@util/util";
import ResultPayable from "./ResultPayable";
import ResultView from "./ResultView";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Web3util } from "@util/web3util";

const Result = ({ currentFunction, lastExecution, network }) => {
    const { getFunctionGasType } = useContext(AppContext);

    // console.log("Results render");

    let _functionGasType;
    if (lastExecution && lastExecution.function) _functionGasType = getFunctionGasType(lastExecution.function.stateMutability);

    useEffect(() => {
        if (lastExecution) {
            const _parameters = reorganizeParameters();
            if (_parameters) lastExecution.function.parameters = _parameters;
        }
    }, [lastExecution]);

    // Reorganize Parameters to have value in the same object
    function reorganizeParameters() {
        // BUG: lastExecution is being returned with "data", incorrect format
        console.log("lastExecution", lastExecution);
        if (lastExecution.provider) return lastExecution.function.inputs;
        let _parameters = [];
        for (let index = 0; index < lastExecution.function.inputs.length; index++) {
            const input = lastExecution.function.inputs[index];
            const parameter = lastExecution.input.parameters[index];
            input.value = parameter;
            _parameters.push(input);
        }

        console.debug("Result - Parameters to call the function (in expected order by the contract)", _parameters);
        return _parameters;
    }

    // if (lastExecution) reorganizeParameters();

    return (
        <div>
            {lastExecution && _functionGasType === FunctionGasType.NoGasFee && <ResultView currentFunction={currentFunction} lastExecution={lastExecution} network={network} />}
            {lastExecution && _functionGasType === FunctionGasType.PayGasFee && <ResultPayable currentFunction={currentFunction} lastExecution={lastExecution} network={network} />}
        </div>
    );
};

export default Result;
