import { useRef, useEffect } from "react";
import { getVideoUrl, getImageUrl } from "@util/util";

const VideoComponent = ({ videoFileName }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        let observer;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // console.log("playing video");
                    videoElement.play();
                } else {
                    // console.log("playing paused");
                    videoElement.pause();
                }
            });
        };

        const options = {
            threshold: 0.5, // Adjust the threshold as needed to enable the video
        };

        observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(videoElement);

        return () => {
            if (observer) {
                observer.unobserve(videoElement);
            }
        };
    }, []);

    return (
        <div>
            <video ref={videoRef} loop muted preload="none" poster={`${getImageUrl("queue")}`}>
                <source src={`${getVideoUrl(videoFileName)}`} type="video/mp4" />
            </video>
        </div>
    );
};

export default VideoComponent;
