import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import { Web3util } from "@util/web3util";
import { notify } from "@util/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faBolt, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { dateDefaultFormat, defaultNumberFormatter, moneyNumberFormatter, ether, getImageUrl, capitalize, extractJSON } from "@util/util";
import Accordion from "@components/shared/Accordion/Accordion";
import PopupExecutionResult from "./PopupExecutionResult";

const ExecutionPanel = ({ currentFunction, executionResult, executingFunction, network }) => {
    const { deployContract, verifyContract, currentNetwork, loadCompilerVersions } = useContext(AppContext);
    const [deployWallet, setDeployWallet] = useState(null);
    const [running, setRunning] = useState(false);
    const [executionPanelWasOpen, setExecutionPanelWasOpen] = useState(false);

    useEffect(() => {
        if (executingFunction) setExecutionPanelWasOpen(true);
    }, [executingFunction]);

    return (
        <div className="py-1">
            <Accordion
                id="ExecutionPanelAccordion"
                name="ExecutionPanelAccordion"
                title={"Execution"}
                opened={executingFunction || executionPanelWasOpen}
                items={[
                    executingFunction ? (
                        <p className="text-secondary font-semibold text-md">
                            <FontAwesomeIcon icon={faTriangleExclamation} className="text-secondary pr-2" fade />
                            Executing Function {currentFunction && currentFunction.name}
                            <FontAwesomeIcon icon={faRotate} spin={executingFunction} className="text-secondary ml-2" />
                        </p>
                    ) : null,
                    <div className="items-center mt-1 text-light font-normal">
                        <p className="h-6">
                            <span className="text-secondary">Function: </span>
                            {currentFunction && <span>{currentFunction && currentFunction.name}</span>}
                            {!currentFunction && <span>Execute a function using F8</span>}
                        </p>
                        <p className="h-6">
                            <span className="text-secondary">Last Execution at:</span>
                            {executionResult && <span>{executionResult && dateDefaultFormat(executionResult.timestamp)}</span>}
                            {!executionResult && <span className="text-light"> N/A</span>}
                        </p>
                    </div>,
                    <div className="flex items-center justify-center mt-4">
                        <button
                            id="ExecutionPanelExecutionResultButton"
                            name="ExecutionPanelExecutionResultButton"
                            className="btn-default"
                            onClick={() => {
                                window.popupExecutionResult.showModal();
                            }}>
                            Execution Result
                            <FontAwesomeIcon icon={faBolt} className="pl-3" />
                        </button>
                    </div>,
                    <PopupExecutionResult currentFunction={currentFunction} executionResult={executionResult} network={network} />,
                ]}
            />
        </div>
    );
};

export default ExecutionPanel;
